<div class="wrapper" [ngClass]="{'fullscreen-photo-mode': showFullScreenImage}">
    <div class="container">
      <div class="pictures" *ngIf="!showFullScreenImage">
          <div class="pictures__title">{{'PROFILE.PICTURES' | translate}}</div>
          <div class="photos" *ngIf="(userPhotos$ | async) as userPhotos">
            <!-- <div class="photos" *ngIf="userPhotos"> -->
            <div class="image-wrapper" *ngFor="let userImage of userPhotos; let i = index; let last = last; trackBy: trackByItems">
              <img *ngIf="userImage" (click)="toggleFullscreen(i, userImage.image, userImage.id); toggleBackButton(false); setWrapperHeight()" src="{{userImage.image | userAvatarUrl}}" class="image">
              <!-- <img *ngIf="userImage" (click)="showImageAtHome(userImage.image, userImage.id)" src="{{userImage.image | userAvatarUrl}}" class="image"> -->
              <!-- <div class="fullscreen-photo" *ngIf="showFullScreen[i] === true">
                  <img src="{{baseURL}}large/{{replacer(userImage.image)}}" class="fullscreen-image">
                  <div class="buttons-wrapper">
                    <button 
                      (click)="toggleFullscreen(i); toggleBackButton(true)"
                      class="button button__alt">{{"ADMIN.CLOSE_BUTTON" | translate}}</button>
                    <button *ngIf="isMine" 
                        (click)="deleteUserPhoto(userImage.id, i)"
                        class="button button__main">{{"ADMIN.USERS_TABLE.COLUMN_NAME.DELETE" | translate}}</button>
                  </div>
              </div> -->
            </div>
            <button *ngIf="userPhotos.length === queryParams.pageSize * queryParams.page"
              (click)="loadMorePhotos()"
              class="button button__main transparent">
              {{"GLOBAL.LOAD_MORE" | translate}}
          </button>
          </div>
          <app-file-input *ngIf='isMine' [displayType]="FileInputDisplayTypes.UserImage" [accept]="'image/*'"></app-file-input>
        </div>


        <div class="fullscreen-photo" *ngIf="showFullScreenImage">
                <img src="{{baseURL}}large/{{replacer(showFullScreenImage.imageName)}}" class="fullscreen-image">
                <div class="buttons-wrapper">
                  <button 
                    (click)="toggleFullscreen(showFullScreenImage.imageIndex); toggleBackButton(true)"
                    class="button button__alt">{{"ADMIN.CLOSE_BUTTON" | translate}}</button>
                  <button *ngIf="isMine" 
                      (click)="deleteUserPhoto(showFullScreenImage.imageId, showFullScreenImage.imageIndex)"
                      class="button button__main">{{"ADMIN.USERS_TABLE.COLUMN_NAME.DELETE" | translate}}</button>
                </div>
            </div>
</div>
