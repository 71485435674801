import { Injectable } from '@angular/core';
import { ApiBaseService } from './api/api-base.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetMentionUsersService extends ApiBaseService {

  timer = 1 * 1000 * 60; // minutes value

  usersList: any;
  timeStamp = 0;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getMentionUsersList(): Observable<any> {
    if (this.timeStamp + this.timer > new Date().getTime()) {
      return of(this.usersList);
    } else {
      this.usersList = null;
      this.timeStamp = 0;
      return this.fetchMentionUsersList();
    }
  }

  fetchMentionUsersList() {
    return this.getData('account/people').pipe(
      map(data => {
        this.usersList = data;
        this.timeStamp = new Date().getTime();
        return data;
      })
    );
  }
}
