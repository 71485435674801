import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import {  TopNotificationsService } from '../../services/top-notifications.service';
import {  AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.less']
})
export class FacebookComponent implements OnInit {

  accessToken: string;
  error: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private topNotificationsService: TopNotificationsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.accessToken = new URLSearchParams(this.route.snapshot.fragment).get('access_token');
    this.error = new URLSearchParams(this.route.snapshot.fragment).get('error');
    if (!this.accessToken) {
      this.topNotificationsService.notify(this.route.snapshot.queryParams['error_description'], 'error');
      this.router.navigate(['/']);
    } else {
      this.authService.facebookLogin(this.accessToken);
    }
  }

}
