<div class="teams-list-header__full-width" *ngIf="showAtStatistics"> {{ "STATISTICS.ALL_TEAMS" | translate }}</div>

<div class="team-members-size" (click)="showTeamMembersSizePopup()">
    <img src="assets/images/info_icon.svg"> {{"GROUPS.TEAMS.TEAM_MEMBERS_SIZE.INFO"|translate}}
</div>

<div class="teams-list-header__team-types">
    <div class="open">
        <div class="circle"></div>{{ "GROUP.TEAMS.TEAM_TYPE.OPEN" | translate }}
    </div>
    <div class="private">
        <svg viewBox="0 0 24 24">
            <path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
        {{ "GROUP.TEAMS.TEAM_TYPE.PRIVATE" | translate }}
    </div>
</div>

<div class="teams-list">
    <div class="teams-list__header">
        <div class="team-list__team-name">{{ "GROUP.TEAMS.LIST.TABLE_HEADER.1" | translate }}</div>
        <div class="team-list__team-captain">{{ "GROUP.TEAMS.LIST.TABLE_HEADER.2" | translate }}</div>
        <div class="team-list__team-participants-count">{{ "GROUP.TEAMS.LIST.TABLE_HEADER.3" | translate }}</div>
    </div>
    <div class="divider"></div>
    <div class="teams-list__table" *ngIf="!isLoading">
        <ng-container
        *ngIf="teamList && teamList.length > 0; then teams; else noTeams"></ng-container>
        <ng-template #noTeams>
            <div class="no-teams">{{ "GROUP.TEAMS.LIST_HEADER.CREATE_TEAM_TEXT" | translate }}</div>
        </ng-template>
        <ng-template #teams>
            <div class="teams-list__list" *ngFor="let team of teamList; trackBy: trackByItems">
                <div class="team-list__team-name break cancel">
                    <ng-container
                        *ngIf="team.isPrivate; then privateIcon; else openIcon">
                    </ng-container>
                    <ng-template #privateIcon>
                        <svg viewBox="0 0 24 24">
                            <path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                        </svg>
                    </ng-template>
                    <ng-template #openIcon>
                        <div class="circle"></div>
                    </ng-template>
                    
                    <div class="text">{{team.name}}</div>
                </div>
                <div class="team-list__team-captain break cancel">{{team.captainName}}</div>
                <div class="team-list__team-participants-count">
                    <a [ngClass]="{'full-team': team.membersCount === 0}" (click)="showTeamMembersList(team)">{{team.membersCount}}</a>
                    <ng-container
                        *ngIf="team.isFull; then showFullTeamButton; else showActionButton">
                    </ng-container>
                    <ng-template #showFullTeamButton>
                        <a [ngClass]="{'full-team': team.isFull}" class="button button__alt">{{ "TEAM.FULL" | translate }}</a>
                    </ng-template>
                    <ng-template #showActionButton>
                        <ng-container
                        *ngIf="team.isPrivate; then private; else open"></ng-container>
                        <ng-template #private>
                            <ng-container
                            *ngIf="team.isConfirm; then showConfirm; else showJoin"></ng-container>
                            <ng-template #showConfirm>
                                <div class="confirm-request">
                                    <div class="text">{{ "GROUP.TEAMS.LIST.REQUEST_ACCESS" | translate }}</div>
                                    <div class="buttons-wrapper">
                                        <div class="confirm" (click)="requestToJoinThePrivateTeam(team)">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                            </svg>
                                        </div>
                                        <div class="cancel" (click)="confirmRequestSend(team)">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #showJoin>
                                <a *ngIf="userTeamStatus(team) === 'join'" class="button button__alt" [ngClass]="{'full-team': showAtStatistics}" (click)="showAtStatistics ? null : confirmRequestSend(team)">{{ "TEAM.JOIN" | translate}}</a>
                            </ng-template>
                            <a *ngIf="userTeamStatus(team) === 'Pending'" class="button button__alt pending full-team">{{ "TEAM.WAITING_FOR_APPROVE" | translate}}</a>
                        </ng-template>
                        <ng-template #open>
                            <a class="button button__alt" [ngClass]="{'full-team': showAtStatistics}" (click)="showAtStatistics ? null : joinTheTeam(team.id)">{{ "TEAM.JOIN" | translate }}</a>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <div class="no-more-teams" *ngIf="!showAtStatistics">{{ "GROUP.TEAMS.LIST.NO_MORE_TEAMS" | translate }}</div>
        </ng-template>
    </div>
    <div class="spinner" *ngIf="isLoading">
        <mat-progress-spinner diameter="35" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>
<div class="divider mini"></div>
<div class="go-up" *ngIf="showAtStatistics">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" (click)="scrollToGroupTop()">
        <g id="Group_982" data-name="Group 982" transform="translate(-38 -1768)">
          <circle id="Ellipse_364" data-name="Ellipse 364" cx="14" cy="14" r="14" transform="translate(66 1796) rotate(180)" fill="#00c7c2"/>
          <path id="Path_36" data-name="Path 36" d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z" transform="translate(59 1787) rotate(180)" fill="#fff"/>
        </g>
      </svg>
    <div class="text" (click)="scrollToGroupTop()">{{ "TEAM.STATISTICS.TO_THE_GROUP" | translate }}</div>
</div>

<div class="teams-list-header__create" *ngIf="!showAtStatistics">
    <div class="teams-list-header__main">
        <a class="button button__alt square"
            (click)="createNewTeam()">{{ "GROUP.TEAMS.LIST_HEADER.CREATE_TEAM" | translate }}</a>
    </div>
</div>