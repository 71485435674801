import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '../utils/app.constants';

@Pipe({
  name: 'userAvatarUrl'
})
export class UserAvatarUrlPipe implements PipeTransform {

  transform(avatarName: any): string {
    if (avatarName) {
      return `${AppConstants.api.mediaFilesUrl}small/${avatarName.trim().replace(/\"/g, '')}`;
    } else {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    }
  }

}
