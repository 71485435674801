import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabModel } from '../../../models/tab.model';
import { Router } from '@angular/router';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: [ './tabs.component.less' ]
})
export class TabsComponent {
	@Input() tabs: TabModel[];
	@Input() isRedirect = false;
	@Input() currentTab: string;
	@Input() oneLineTabs = false;
	@Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();

	constructor(private router: Router, private passingDataService: PassingDataService) {}

	isTabActive(value: string): boolean {
		if (this.isRedirect) {
			return this.router.url.indexOf(value) !== -1;
		} else {
			return this.currentTab === value;
		}
	}

	changeTab(value: string) {
		if (this.tabs.length === 1) {
			return;
		} else {
			if (this.isRedirect) {
				this.router.navigate([ value ]);
			} else {
				this.passingDataService.setTeamActiveTab(null);
				this.currentTab = value;
				this.tabChanged.emit(value);
			}
		}
	}
}
