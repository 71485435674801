import { HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { base64ToFile, ImageCroppedEvent } from "ngx-image-cropper";
import { Observable } from "rxjs";
import { OnboardingImages } from "src/app/models/onboarding-image.model";
import { ApiFeedService } from "src/app/services/api/api-feed.service";
import { ApiUserProfileService } from "src/app/services/api/api-user-profile.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { LoadingSpinnerService } from "src/app/services/loading-spinner.service";
import { v4 as uuidv4 } from "uuid";

import { base64images } from "../../../enums/base64images.enum";
import { FileInputDisplayTypes } from "../../../enums/FileInputDisplayTypes.enum";
import { ApiMediaFilesService } from "../../../services/api/api-media-files.service";
import { TopNotificationsService } from "../../../services/top-notifications.service";
import { AppConstants } from "../../../utils/app.constants";

function _window(): any {
  return window;
}

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.less"],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor, OnDestroy {
  @Input("accept") accept: string;
  @Input("text") text: string;
  @Input("displayType") type: FileInputDisplayTypes;

  @Output() onVideoUploaded = new EventEmitter<any>();
  @Output() onVideoUploadProgress = new EventEmitter<number>();
  @Output() newUserAvatar = new EventEmitter<string>();
  @Output() onBoardingImages = new EventEmitter<OnboardingImages>();

  /* @Input() fileData: string | ArrayBuffer; */
  @Input() prevImage: string;
  @Input() prevVideo: string;
  @Input() postVideoUrl: string;
  @Input() userAvatar;
  @ViewChild("fileInputContainer") fileInputContainer: ElementRef;

  fileData;
  imageUrl: string;
  FileInputDisplayTypes = FileInputDisplayTypes;
  isLoading = false;
  error: string;
  errorType: string;
  uploadSizeLimitReached: string;

  croppedImage: any = null;
  imageChangedEvent;
  imageAspectRatio: any;
  imageAsEvent;
  thumbnail;
  uniqueId: string;
  backgroundColor = "#ffffff";
  imageFormat = "jpeg";
  currentFile: File;
  videoUrl;

  shouldEraseUlopadedData$;
  private propagateChange = (_: any) => {};

  get imgURL() {
    return this.imageUrl;
  }

  constructor(
    private mediaFilesService: ApiMediaFilesService,
    private userProfile: ApiUserProfileService,
    private translate: TranslateService,
    private topNotificationsService: TopNotificationsService,
    private sanitizer: DomSanitizer,
    private loadingSpinnerService: LoadingSpinnerService,
    private apiFeedService: ApiFeedService,
    private ga: GoogleAnalyticsService,
    private renderer: Renderer2
  ) {
    this.uniqueId = uuidv4();
    this.translate.get("GLOBAL.FILE_UPLOAD_ERROR").subscribe((value) => {
      this.error = value;
    });
    this.translate
      .get("GLOBAL.FILE_UPLOAD_ERROR.FILE_TYPE_ERROR")
      .subscribe((value) => {
        this.errorType = value;
      });
    this.translate
      .get("GLOBAL.FILE_UPLOAD_ERROR.VIDEO_SIZE_LIMIT_REACHED")
      .subscribe((value) => {
        this.uploadSizeLimitReached = value;
      });
    this.shouldEraseUlopadedData$ =
      this.apiFeedService.shouldEraseUlopadedData$.subscribe(() => {
        this.eraseUlopadedData();
      });
  }

  sanitizeFile(prFile: any) {
    if (prFile) {
      prFile = prFile.replace(/\"/g, "");
    }
    return `${AppConstants.api.mediaFilesUrl}${prFile}`;
  }

  sanitizeImage(prImage: any) {
    if (prImage) {
      prImage = prImage.replace(/\"/g, "");
    }
    if (
      this.type === FileInputDisplayTypes.BootcampChallenge ||
      this.type === FileInputDisplayTypes.BootcampStepsChallenge
    ) {
      return this.sanitizer.bypassSecurityTrustStyle(
        `url('${AppConstants.api.mediaFilesUrl}${prImage}')`
      );
    } else
      return this.sanitizer.bypassSecurityTrustStyle(
        `url('${AppConstants.api.mediaFilesUrl}small/${prImage}')`
      );
  }

  sanitizeThumbnail(thumb: any) {
    if (thumb) {
      thumb = thumb.replace(/\"/g, "");
    }
    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(`${thumb}`);
  }

  sanitizeAvatar(userAvatar: any) {
    if (userAvatar) {
      userAvatar = userAvatar.replace(/\"/g, "");
    }
    return this.sanitizer.bypassSecurityTrustStyle(`url('${userAvatar}')`);
  }

  setSanitizedUrl(imageOrVideo: string) {
    if (imageOrVideo) {
      imageOrVideo = imageOrVideo.replace(/\"/g, "").trim();
    }
    return imageOrVideo.includes("jpeg")
      ? this.sanitizer.bypassSecurityTrustUrl(
          `${AppConstants.api.mediaFilesUrl}small/${imageOrVideo}`
        )
      : this.sanitizer.bypassSecurityTrustUrl(
          `${AppConstants.api.mediaFilesUrl}${imageOrVideo}`
        );
  }

  writeValue(obj: any): void {
    this.imageUrl = null;
    this.fileData = null;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  saveImage() {
    this.userProfile
      .postUserPhotos(
        this.imageUrl.slice(AppConstants.api.mediaFilesUrl.length)
      )
      .subscribe(
        () => {
          this.ga.eventEmitter(
            "added-photo-to-gallery",
            "profile-engagements",
            "added-photo-to-gallery"
          );
        },
        () => {},
        () => {
          this.imageUrl = "";
          this.userProfile.getUserPhotos();
          this.userProfile.getTwoUserPhotos();
        }
      );
  }

  eraseUlopadedData() {
    this.imageUrl = null;
    this.thumbnail = null;
    this.prevVideo = null;
    this.videoUrl = null;
  }

  onFileChanged(event: any) {
    const fileReader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (this.postVideoUrl) {
        this.postVideoUrl = null;
      }

      this.fileData = null;
      const file = event.target.files[0];
      this.isLoading = true;

      let tempType = null;
      if (this.type === FileInputDisplayTypes.PostMedia) {
        if (event.target.files[0].type.includes("image")) {
          this.type = FileInputDisplayTypes.PostImage;
          tempType = FileInputDisplayTypes.PostMedia;
          this.thumbnail = null;
        } else if (event.target.files[0].type.includes("video")) {
          this.type = FileInputDisplayTypes.Video;
          tempType = FileInputDisplayTypes.PostMedia;
          this.imageUrl = null;
        }
      }

      if (this.type === FileInputDisplayTypes.MyAccountAvatar) {
        this.loadingSpinnerService.show.next();
        fileReader.readAsDataURL(file);
        this.currentFile = file;
        fileReader.addEventListener("loadend", (e) => {
          this.getImageParams(e).subscribe(
            () => {},
            () => {},
            () => {
              this.imageChangedEvent = event;
            }
          );
        });
      } else if (this.type === FileInputDisplayTypes.File) {
        fileReader.readAsDataURL(file);
        this.currentFile = file;
        this.fileData = file;

        const fileType = this.currentFile.type.toLowerCase().split("/")[1];
        this.imageFormat = fileType;
        this._uploadFile(file);
      } else if (
        this.type === FileInputDisplayTypes.Image ||
        this.type === FileInputDisplayTypes.GroupImage ||
        this.type === FileInputDisplayTypes.BootcampChallenge ||
        this.type === FileInputDisplayTypes.BootcampStepsChallenge
      ) {
        fileReader.readAsDataURL(file);
        this.currentFile = file;
        this.fileData = file;
        const fileType = this.currentFile.type.toLowerCase().split("/")[1];
        this.imageFormat = fileType;
        if (fileType === "png") {
          this.backgroundColor = undefined;
        } else {
          this.backgroundColor = "#ffffff";
        }
        fileReader.addEventListener("loadend", (e) => {
          this.getImageParams(e).subscribe(
            () => {},
            () => {},
            () => {
              this.imageChangedEvent = event;
            }
          );
        });
      } else if (this.type === FileInputDisplayTypes.PostImage) {
        if (tempType) {
          this.type = tempType;
        }
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("loadend", (e) => {
          this.getImageParams(e).subscribe(
            () => {},
            () => {},
            () => {
              this.imageChangedEvent = event;
            }
          );
        });
      } else if (this.type === FileInputDisplayTypes.UserImage) {
        fileReader.readAsDataURL(file);
        this.currentFile = file;
        fileReader.addEventListener("loadend", (e) => {
          this.getImageParams(e).subscribe(
            () => {},
            () => {},
            () => {
              this.imageChangedEvent = event;
            }
          );
        });
      } else if (this.type === FileInputDisplayTypes.Video) {
        if (tempType) {
          this.type = tempType;
        }
        if (file.size <= 524288000) {
          if (file.type.match("video/")) {
            this.mediaFilesService.uploadVideo(file).subscribe(
              (video_event) => {
                this.thumbnail = null;
                if (video_event.type === HttpEventType.UploadProgress) {
                  this.onVideoUploadProgress.emit(
                    Math.round((video_event.loaded / video_event.total) * 100)
                  );
                } else if (video_event.type === HttpEventType.Response) {
                  fileReader.readAsArrayBuffer(file);
                  fileReader.addEventListener("loadend", (e) => {
                    this.setVideoThumbnail(file);
                    this.propagateChange(video_event.body);
                    fileReader.readAsDataURL(file);
                    fileReader.onload = (event) => {
                      this.videoUrl = (<FileReader>event.target).result;
                    };
                    // After handling the file upload, reset the input element
                    this.resetFileInput();
                  });
                }
              },
              () => {
                this.handleError();
                // After handling the file upload, reset the input element
                this.resetFileInput();
              }
            );
          } else {
            this.isLoading = false;
            this.topNotificationsService.notify(this.errorType, "error");
            this.resetFileInput();
          }
        } else {
          this.isLoading = false;
          this.topNotificationsService.notify(
            this.uploadSizeLimitReached,
            "error"
          );
          this.resetFileInput();
        }
      } else {
        if (this.type === FileInputDisplayTypes.Avatar) {
          this.loadingSpinnerService.show.next();
          fileReader.readAsDataURL(file);
          fileReader.addEventListener("loadend", (e) => {
            this.getImageParamsForAvatar(e).subscribe(
              () => {},
              () => {},
              () => {
                this.imageChangedEvent = event;
              }
            );
          });
        } else {
          fileReader.readAsDataURL(file);
          fileReader.addEventListener("loadend", (e) => {
            this.getImageParams(e).subscribe(
              () => {},
              () => {},
              () => {
                this.imageChangedEvent = event;
              }
            );
          });
        }
      }
    }
  }

  getImageParamsForAvatar(e): Observable<any> {
    const i = new Image();
    i.src = e.target.result;
    i.onload = () => {
      if (i.naturalWidth && i.naturalHeight) {
        this.imageAspectRatio = this.reduceRatio(i.width, i.height);
      }
    };
    return new Observable((o) => {
      o.next(true);
      o.complete();
    });
  }

  getImageParams(e): Observable<any> {
    const i = new Image();
    i.src = e.target.result;
    i.onload = () => {
      if (i.naturalWidth && i.naturalHeight) {
        this.imageAspectRatio = this.reduceRatio(i.width, i.height);
        this.fileData = e.target.result;
      }
    };
    return new Observable((o) => {
      o.next(true);
      o.complete();
    });
  }

  reduceRatio(width, height) {
    let gcd, temp, divisor;
    gcd = function (a, b) {
      if (b === 0) {
        return a;
      }
      return gcd(b, a % b);
    };
    if (!this.isInteger(width) || !this.isInteger(height)) {
      return;
    }
    if (width === height) {
      return 1;
    }
    if (+width < +height) {
      temp = width;
      width = height;
      height = temp;
    }
    divisor = gcd(+width, +height);
    return "undefined" === typeof temp
      ? (this.imageAspectRatio = width / divisor / (height / divisor))
      : (this.imageAspectRatio = height / divisor / (width / divisor));
  }

  isInteger(value) {
    return /^[0-9]+$/.test(value);
  }

  imageCropped(image: ImageCroppedEvent) {
    this.croppedImage = base64ToFile(image.base64);
    if (this.croppedImage) {
      if (this.type === FileInputDisplayTypes.Avatar) {
        this.fileData = image.base64;
        this.isLoading = false;
        this.imageChangedEvent = "";
        setTimeout(() => {
          this.loadingSpinnerService.hide.next();
          this.propagateChange(this.fileData);
        }, 0);
      } else {
        const fileToSend = new File(
          [this.croppedImage],
          this.currentFile.name,
          { type: this.croppedImage.type }
        );
        if (
          this.type === FileInputDisplayTypes.BootcampChallenge ||
          this.type === FileInputDisplayTypes.BootcampStepsChallenge
        ) {
          this._uploadStaticImages(fileToSend, image);
        } else {
          this.mediaFilesService.uploadImage(fileToSend).subscribe(
            (imageUrl: any) => {
              this.imageUrl =
                AppConstants.api.mediaFilesUrl + imageUrl.replace(/\"/g, "");
              if (this.type === FileInputDisplayTypes.MyAccountAvatar) {
                this.fileData = image.base64;
                this.newUserAvatar.emit(imageUrl);
              }
              if (
                this.type === FileInputDisplayTypes.Image ||
                this.type === FileInputDisplayTypes.GroupImage ||
                this.type === FileInputDisplayTypes.BootcampChallenge ||
                this.type === FileInputDisplayTypes.BootcampStepsChallenge
              ) {
                this.fileData = image.base64;
              }
              this.propagateChange(imageUrl);
            },
            () => {
              this.loadingSpinnerService.hide.next();
              this.handleError();
            },
            () => {
              this.loadingSpinnerService.hide.next();
              this.isLoading = false;
              this.imageChangedEvent = "";
            }
          );
        }
      }
    } else {
      this.loadingSpinnerService.hide.next();
    }
  }

  _uploadFile(fileToSend: File) {
    this.mediaFilesService.uploadFile(fileToSend).subscribe(
      (fileUrl: any) => {
        this.imageUrl =
          AppConstants.api.mediaFilesUrl + fileUrl.replace(/\"/g, "");

        this.propagateChange(fileUrl);
      },
      () => {
        this.loadingSpinnerService.hide.next();
        this.handleError();
      },
      () => {
        this.loadingSpinnerService.hide.next();
        this.isLoading = false;
      }
    );
  }

  _uploadStaticImages(fileToSend: File, image: ImageCroppedEvent) {
    this.mediaFilesService
      .uploadStaticImages(FileInputDisplayTypes[this.type], fileToSend)
      .subscribe(
        (imageUrl: any) => {
          this.imageUrl =
            AppConstants.api.mediaFilesUrl + imageUrl.replace(/\"/g, "");
          this.fileData = image.base64;
          this.propagateChange(imageUrl);
        },
        () => {
          this.loadingSpinnerService.hide.next();
          this.handleError();
        },
        () => {
          this.loadingSpinnerService.hide.next();
          this.isLoading = false;
          this.imageChangedEvent = "";
        }
      );
  }

  getThumbnail(isVideoUploaded: string) {
    return isVideoUploaded ? base64images.videoSuccessUploaded : null;
  }

  private setVideoThumbnail(file) {
    /* this.sanitizeThumbnail(file); */
    this.thumbnail = base64images.videoSuccessUploaded;
    this.fileData = this.thumbnail;
    this.isLoading = false;
    this.onVideoUploaded.next({
      video: file,
      thumbnail: this.thumbnail,
    });
    /* const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          this.fileData = canvas.toDataURL();
          setTimeout(() => {
            this.sanitizeThumbnail(this.fileData);
            this.onVideoUploaded.next(
              {
                'video': file,
                'thumbnail': this.fileData
              }
            );
            this.isLoading = false;
          }, 100);
        }, 500);
        });
        video.preload = 'auto';
        video.src = (window.URL ? URL : webkitURL).createObjectURL(file);
        video.autoplay = true;
        if (!video.currentTime || video.currentTime === 0) {
          video.pause();
          video.currentTime = 0.1;
        } */
  }

  private handleError() {
    this.isLoading = false;
    this.topNotificationsService.notify(this.error, "error");
  }

  ngOnDestroy() {
    this.shouldEraseUlopadedData$.unsubscribe();
  }
  // Function to reset the file input
  resetFileInput() {
    this.videoUrl = "";
    if (this.fileInputContainer) {
      this.fileInputContainer.nativeElement.value = "";
    }
  }
}
