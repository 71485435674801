import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GroupModel } from 'src/app/models/group.model';

@Component({
	selector: 'app-group-teams',
	templateUrl: './group-teams.component.html',
	styleUrls: [ './group-teams.component.less' ]
})
export class GroupTeamsComponent {
	@Input() group: GroupModel;
	@Output() closeCreateOrJoinTeam = new EventEmitter<boolean>();
	privateTeamId: number = null;
	privateTeamRequestWasSent = false;

	togglePrivateRequestSent() {
		this.privateTeamRequestWasSent = !this.privateTeamRequestWasSent;
	}

	closeCreateTeam() {
		this.closeCreateOrJoinTeam.emit(true);
	}

	setPrivateTeamId(privateTeamId) {
		this.privateTeamId = privateTeamId;
	}
}
