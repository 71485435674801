<div class="container">
  <div class="wrapper" id='wrapperStyle'>
    <div class="slider" id="slider">
      <app-slider [itemsLength]="4" [itemsPerSlide]="1" [dots]="true" [transparentWhiteDots]="true"
                  [isSliderInfinite]="true">
        <div class="slider__item">
          <div class="slider__item--wrapper">
            <svg class="main-logo" id="Lager_1" data-name="Lager 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1856.14 603.45">
              <defs><style>.cls-1 { fill: #ffffff; }</style></defs>
              <path class="cls-1"
                    d="M1002.26,684c37.23,26.13,54.58,66.39,54.58,124.3,0,67.8-20.91,143.37-52.54,190.69-38.76,57.21-97.93,83.34-189.73,83.34-120.37,0-175-48-175-154,0-74.15,20.4-149.73,52-192.81,21.94-30.37,55.6-54.38,91.3-65,22.44-7.07,66.82-12,104-12C943.61,658.56,976.76,665.62,1002.26,684ZM806.4,831.59c-7.14,15.54-12.24,46.62-12.24,69.92,0,36.73,13.78,54.39,42.85,54.39,25,0,42.33-14.13,54.57-44.5,7.65-19.77,13.26-49.43,13.26-70.62,0-31.08-14.79-47.32-43.35-47.32C835,793.46,818.13,805.46,806.4,831.59Z"
                    transform="translate(-381.33 -478.86)"/>
              <path class="cls-1"
                    d="M1496.5,1072.42l29.58-194.22a153.85,153.85,0,0,0,2.55-21.89c0-20.48-12.24-32.49-33.15-32.49-31.11,0-46.93,17.66-53,59.33l-29.07,189.27H1248.1l60.7-396.91h165.26l-9.19,60c36.22-51.56,71.92-69.92,134.66-69.92,69.87,0,104,36,104,108.76a359.08,359.08,0,0,1-4.08,51.56l-37.75,246.48Z"
                    transform="translate(-381.33 -478.86)"/>
              <path class="cls-1"
                    d="M493.48,591.43,444.57,910c-5.1,33.2-5.6,35.31-8.16,42.38-4.59,12-12.24,17.65-24,17.65-3.06,0-6.12-.7-13.77-2.12l-17.34,112.3c28.05,1.41,42.33,2.12,59.68,2.12,63.24,0,95.37-7.77,116.8-26.84,26-23.3,37.74-54.38,49-127.12l52-336.93Z"
                    transform="translate(-381.33 -478.86)"/>
              <path class="cls-1" d="M1108.05,675.47l-60.64,397h165.25l60.65-397Z" transform="translate(-381.33 -478.86)"/>
              <circle class="cls-1" cx="819.79" cy="82.16" r="82.16"/>
              <path class="cls-1"
                    d="M2237.47,1072.42c-14.13-3.37-44.86-12.85-70.47-40.6-46-49.82-35.81-115.54-34.31-124.23h0q.12-.8,35.78-232.08H2003.22l-8.67,55.79C1976.7,686.81,1943,665.62,1891,665.62c-56.62,0-103,26.13-131.59,74.15C1731.87,785.68,1711,878.2,1711,951.65c0,42.38,8.67,74.87,25.5,97.47,17.86,22.6,45.4,33.19,86.2,33.19,56.62,0,88.75-14.83,127-60l-7.65,50.14ZM1965,919.17c-10.71,26.13-29.07,41.66-50,41.66-24,0-37.74-19.77-37.74-53,0-60,26-103.12,61.71-103.12,24.49,0,38.26,18.36,38.26,53C1977.21,876.79,1972.62,900.1,1965,919.17Z"
                    transform="translate(-381.33 -478.86)"/>
            </svg>
            <div class="header">{{"LANDING.SCREEN_1" | translate}}</div>
          </div>
        </div>

        <div class="slider__item">
          <div class="slider__item--wrapper">
            <img class="count first" src="assets/images/landing_2_count.svg">
            <img class="icon first" src="assets/images/landing_2_icon.svg">
            <div class="header">{{"LANDING.SCREEN_2.HEAEDER" | translate}}</div>
            <div class="text">{{"LANDING.SCREEN_2.TEXT" | translate}}</div>
          </div>
        </div>

        <div class="slider__item">
          <div class="slider__item--wrapper">
            <img class="count second" src="assets/images/landing_3_count.svg">
            <img class="icon second" src="assets/images/landing_3_icon.svg">
            <div class="header">{{"LANDING.SCREEN_3.HEAEDER" | translate}}</div>
            <div class="text">{{"LANDING.SCREEN_3.TEXT" | translate}}</div>
          </div>
        </div>

        <div class="slider__item">
          <div class="slider__item--wrapper">
            <img class="count third" src="assets/images/landing_4_count.svg">
            <img class="icon third" src="assets/images/landing_4_icon.svg">
            <div class="header">{{"LANDING.SCREEN_4.HEAEDER" | translate}}</div>
            <div class="text">{{"LANDING.SCREEN_4.TEXT" | translate}}</div>
          </div>
        </div>
      </app-slider>
    </div>
    <div class="static">
      <!-- <button class="button full-width button__apple" (click)="signupApple()" *ngIf="loginWithAppleIsVisible()">{{'LANDING.APPLE_BUTTON' | translate}}</button> -->
      <div class="buttons-wrapper">
        <button class="button full-width tealish" [routerLink]="'start/signup'"><img src="assets/images/email-24px.svg">
          {{'LANDING.CREATE_ACCOUNT_BUTTON.EMAIL' | translate}}</button>
        <button class="button full-width facebook" (click)="signupFacebook()"><img
          src="assets/images/fb_white_icon.svg">{{'LANDING.CREATE_ACCOUNT_BUTTON.FACEBOOK' | translate}}</button>
      </div>
      <span>{{"LANDING.SIGN_IN.1" |translate}} <a [routerLink]="'start/login'">{{"GLOBAL.LOGIN"|translate}}</a></span>
      <a [routerLink]="'all-groups'">{{'LANDING.SEE_ALL_GROUPS' | translate}}</a>

      <a class="privacy-link" [routerLink]="'all-privacy'">{{"SETTINGS.PRIVACY"|translate}}</a>
    </div>

  </div>
</div>
