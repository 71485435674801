export enum FileInputDisplayTypes {
  MyAccountAvatar,
  Avatar,
  Image,
  GroupImage,
  Video,
  PostImage,
  UserImage,
  PostMedia,
  File,
  BootcampChallenge,
  BootcampStepsChallenge,
}
