import {Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, Renderer2} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../../utils/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { ApiUserProfileService, IUserPhoto } from 'src/app/services/api/api-user-profile.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { ApiAccountService, IInstagramLongLiveToken } from 'src/app/services/api/api-account.service';
import { StateService } from 'src/app/services/state.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-profile-info',
	templateUrl: './profile-info.component.html',
	styleUrls: [ './profile-info.component.less' ]
})
export class ProfileInfoComponent implements OnInit, OnChanges {
	@Input() bestHealthyFood;
	@Input() bestUnhealthyFood;
	@Input() dinnerWith;
	@Input() endedGames;
	@Input() favoriteTrainingUniform;
	@Input() favoriteWeightLossMethod;
	@Input() instagramId;
	@Input() instagramToken;
	@Input() instagramExpiresIn;
	@Input() isPrivatePhoto;
	@Input() superpower;
	@Input() userFriends;
	@Input() userFriendsLength;
	@Input() userName;
	@Input() userPhotos: IUserPhoto[];
	@Input() userPhotosLength;
	@Input() worstHabit;
	@Output() refetchUserProfile = new EventEmitter<any>();

	baseURL = AppConstants.api.mediaFilesUrl;
	emptyReport = '';
	friendsCounter: any = 0;
	gameWinner = '';
	isInstaConnection: boolean;
	isLoading = false;
	isMine: boolean;
	isNoFriends = false;
	isNoPhotos = false;
	isNoQuickFacts = false;
	picturesCounter = 0;
	recentMedia = [];
	reportSent = '';
	showReportProfile = false;
	userId;
	you = '';

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private userService: ApiUserProfileService,
		private topNotificationsService: TopNotificationsService,
		private loadingSpinnerService: LoadingSpinnerService,
		private errorsProcessorService: ErrorsProcessorService,
		private accountService: ApiAccountService,
		private stateService: StateService
	) {
		this.route.params.subscribe((params) => {
			this.isMine = !(params && params.id);
			this.userId = params.id;
		});
	}

	ngOnInit() {
		if (
			!this.bestHealthyFood &&
			!this.bestUnhealthyFood &&
			!this.favoriteTrainingUniform &&
			!this.worstHabit &&
			!this.superpower &&
			!this.dinnerWith &&
			!this.favoriteWeightLossMethod
		) {
			this.isNoQuickFacts = true;
		}
		if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.code) {
			this.getInstagramTokenByCode(this.route.snapshot.queryParams.code);
		}
		this.translate.stream('PROFILE.ENDED_GAME.WINNER').subscribe((data) => (this.gameWinner = data));
		this.translate.stream('PROFILE.YOU').subscribe((data) => (this.you = data));
		this.translate.stream('PROFILE.EMPTY_REPORT').subscribe((data) => (this.emptyReport = data));
		this.translate.stream('PROFILE.REPORT_SENT').subscribe((data) => (this.reportSent = data));
	}

	ngOnChanges(changes: any) {
		if (changes.userPhotosLength || changes.userPhotos) {
			if (this.userPhotosLength === 0) {
				this.isNoPhotos = true;
			} else {
				this.isNoPhotos = false;
			}
		}
		if (changes.userFriendsLength || changes.userFriends) {
			if (this.userFriends.length === 0) {
				this.isNoFriends = true;
			} else {
				this.isNoFriends = false;
			}
			if (this.userFriends.length > 5) {
				this.userFriends = this.userFriends.slice(0, 5);
			}
		}
		if (changes.instagramToken) {
			this.checkInstagramConnection();
		}
	}

	replacer(image: string) {
		return image.replace(/\"/g, '');
	}

	showAllPictures() {
		this.router.navigate([ 'pictures' ], { relativeTo: this.route });
	}

	toggleInstagramConnection() {
    // const child = document.createElement('a');
    // child.href = 'https://instagram.com/accounts/logout';
    // child.target = '_blank';
    //
    // this.renderer.appendChild(this.elemRef.nativeElement, child);
    // child.click();
		if (this.isMine) {
			if (!this.isInstaConnection) {
				this.accountService.redirectToInstagramOAuth();
			} else {
				this.accountService.deleteInstagramToken().subscribe(() => {
					this.isInstaConnection = false;
					// refetching profile to make sure that there is no instagram data
					this.refetchUserProfile.next();
				});
			}
		} else {
			this.redirectToInstagramUserProfile();
		}
	}

	getInstagramTokenByCode(code: string) {
		this.router.navigate([], { queryParams: null });
		this.loadingSpinnerService.show.next();
		// getting a short live token
		this.accountService.getInstagramShortLivedTokenByAuthCode(code).subscribe(
			(response) => {
				const tokenObj: IInstagramLongLiveToken = {
					id: response.user_id,
					accessToken: response.access_token,
					expiresIn: null
				};
				// getting a long live token
				this.accountService
					.getInstagramLongLivedTokenFromShortLive(response.access_token)
					.subscribe((longLiveTokenDTO) => {
						tokenObj.accessToken = longLiveTokenDTO.access_token;
						tokenObj.expiresIn = this.caluculateExpInDate(Number(longLiveTokenDTO.expires_in));

						this.saveInstagramLongLiveToken(tokenObj);
					});
			},
			(err) => this.loadingSpinnerService.hide.next()
		);
	}

	caluculateExpInDate(expires_in: number): string {
		// transform expires_in ms into the days
		// and calculate the expiration date in days
		const expInDays = Math.round(expires_in / 60 / 60 / 24);
		// expInDate example: 2020-07-31T10:13:53+03:00
		const expInDate = moment().add(expInDays, 'days').format().toString();
		return expInDate;
	}

	saveInstagramLongLiveToken(instagramToken: IInstagramLongLiveToken) {
		this.accountService.updateInstagramToken(instagramToken).subscribe(
			() => {
				if (instagramToken) {
					this.getInstagramMediaIds(instagramToken.accessToken);
				}
			},
			(err) => this.loadingSpinnerService.hide.next()
		);
	}

	getInstagramMediaIds(longLiveToken: string) {
		this.loadingSpinnerService.hide.next();
		this.isLoading = true;
		this.accountService.getInstagramMediaIds(longLiveToken, this.isMine ? null : this.instagramId).subscribe(
			(res) => {
				const mediaIDs = [];
				res.data.forEach((element) => {
					if (element.id) {
						mediaIDs.push(this.accountService.getInstagramMediaViaGraphQl(element.id, longLiveToken));
					}
				});
				this.getInstagramImagesByIds(mediaIDs);
			},
			(err) => {
				this.isLoading = false;
				this.isInstaConnection = false;
			}
		);
	}

	getInstagramImagesByIds(mediaIDs: any[]) {
		if (mediaIDs && mediaIDs.length >= 1) {
			forkJoin([ ...mediaIDs ]).subscribe((data: any) => {
				this.recentMedia.length === 0;
				data.forEach((element) => {
					if (element && element.media_type === 'IMAGE' && this.recentMedia.length < 15) {
						this.recentMedia.push(element.media_url);
					}
				});
				if (this.recentMedia.length >= 1) {
					this.isInstaConnection = true;
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.isInstaConnection = false;
				}
			}, (err) => (this.isInstaConnection = false));
		}
	}

	getInstagramMediaGraph(token) {
		this.accountService.getInstagramMediaViaGraphQl(this.instagramId, token).subscribe((res) => {
			if (res && res.data) {
				this.recentMedia.length = 0;
				res.data.forEach((element) => {
					if (element.media_type === 'IMAGE') {
						this.recentMedia.push(element);
					}
				});
			}
			this.recentMedia.length > 0 ? (this.isInstaConnection = true) : (this.isInstaConnection = false);
		});
	}

	redirectToInstagramUserProfile() {
		this.loadingSpinnerService.show.next();
		this.accountService.getInstagramUserName(this.instagramToken).subscribe(
			(user) => {
				if (user && user.username) {
					this.accountService.redirectToInstagramUserProfile(user.username);
					this.loadingSpinnerService.hide.next();
				}
			},
			(err) => this.loadingSpinnerService.hide.next()
		);
	}

	showAllFriends() {
		this.router.navigate([ 'friends' ], { relativeTo: this.route });
	}

	toggleReportProfile() {
		this.showReportProfile = !this.showReportProfile;
		this.stateService.setRouteData({ showBackButton: !this.showReportProfile });
	}

	sendReport(reportAnswer) {
		if (reportAnswer === '') {
			this.topNotificationsService.notify(this.emptyReport, 'error');
		} else {
			this.loadingSpinnerService.show.next();
			const report = {
				userId: this.route.snapshot.params.id,
				message: reportAnswer
			};
			this.userService.postReport(report).subscribe(
				() => {
					this.topNotificationsService.notify(this.reportSent, 'success');
				},
				(httpErrorResponse: HttpErrorResponse) => {
					this.loadingSpinnerService.hide.next();
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
				},
				() => {
					this.loadingSpinnerService.hide.next();
				}
			);
			this.toggleReportProfile();
		}
	}

	goToEndedGroup(id) {
		this.router.navigate([ '/home/groups/all/group/' + id ]);
	}

	updateInstagramTokenNewApi(oldToken: string) {
		this.accountService.updateInstagramTokenNewApi(oldToken).subscribe(
			(response) => {
				const tokenObj: IInstagramLongLiveToken = {
					id: this.instagramId,
					accessToken: response.access_token,
					expiresIn: null
				};
				tokenObj.expiresIn = this.caluculateExpInDate(Number(response.expires_in));

				this.saveInstagramLongLiveToken(tokenObj);
			},
			(error) => {
				/*
					error object from instagram
					error: {
						error: {
							code: 10
							error_subcode: 2207054
							error_user_msg: "You cannot refresh access tokens for Instagram private accounts. To generate a new access token, reauthorize the user as documented here https://developers.facebook.com/docs/instagram-basic-display-api/guides/getting-access-tokens-and-permissions""
							error_user_title: "Permission Denied"
							fbtrace_id: "AfplYeCgZ4A0-75A-mTL2QS"
							is_transient: false
							message: "Application does not have permission for this action"
							type: "OAuthException"
							__proto__: Object
						}
						__proto__: {Object}
						headers: d {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ}
						message: "Http failure response for https://graph.instagram.com/refresh_access_token?access_token=TOKEN&grant_type=ig_refresh_token: 400 OK"
						name: "HttpErrorResponse"
						ok: false
						status: 400
						statusText: "OK"
						url: "https://graph.instagram.com/refresh_access_token?access_token=TOKEN&grant_type=ig_refresh_token"
						__proto__: w
					}

					error.code should be 10
					error.error_subcode should be 2207054
					if YES - account is private and we can't update token automatically
				*/

				if (
					error.error.error.code === 10 &&
					error.error.error.error_subcode === 2207054 &&
					this.instagramToken
				) {
					this.getInstagramMediaIds(this.instagramToken);
				}
			}
		);
	}

	checkInstagramConnection() {
		if (this.instagramToken && this.instagramId && this.instagramExpiresIn) {
			// if token experation date is now
			if (this.isMine && moment() <= moment(this.instagramExpiresIn).subtract(3, 'days')) {
				this.updateInstagramTokenNewApi(this.instagramToken);
				return;
			} else if (this.isMine && moment() >= moment(this.instagramExpiresIn).subtract(1, 'day')) {
				this.isInstaConnection = false;
				const tokenObj: IInstagramLongLiveToken = {
					id: null,
					accessToken: null,
					expiresIn: null
				};
				this.saveInstagramLongLiveToken(tokenObj);
				return;
			} else if (!this.isMine && moment() >= moment(this.instagramExpiresIn).subtract(1, 'day')) {
				this.isInstaConnection = false;
				return;
			} else {
				this.isInstaConnection = true;
				this.getInstagramMediaIds(this.instagramToken);
			}
		} else {
			this.isInstaConnection = false;
		}
	}
}
