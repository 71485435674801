<div class="wrapper">
    <div class="container">
      <div class="friends">
        <!-- <a class="button__back" (click)="goBack()">
          <img src="assets/images/arrow-lg-left.png"
               srcset="assets/images/arrow-lg-left@2x.png 2x,
               assets/images/arrow-lg-left@3x.png 3x"
               class="arrow-lg-left">
        </a> -->
          <div class="friends__title">{{'PROFILE.FRIENDS' | translate}}</div>
          <div class="friend" *ngFor="let friend of userFriends; trackBy: trackByItems">
            <div class="avatar" *ngIf="friend">
              <img  class="avatar__img"
                    src="{{friend.image | userAvatarUrl}}" 
                    (click)="showSelectedFriend(friend.id)">
            </div>
            <div class="friend-name">
              <span (click)="showSelectedFriend(friend.id)">{{friend.name}}</span>
              <p class="remove" (click)="removeSelectedFriend(friend.id)" *ngIf="!parentRouteId">{{'PROFILE.REMOVE' | translate}}</p>
            </div>
          </div> 
      </div> 
    </div>
</div>
