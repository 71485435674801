import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PassingDataService, IJoinPopupAtHome } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-after-join-group-popup',
	templateUrl: './after-join-group-popup.component.html',
	styleUrls: [ './after-join-group-popup.component.less' ]
})
export class AfterJoinGroupPopupComponent implements OnInit {
	@Input() popupData: IJoinPopupAtHome;

	pageNumber: number;

	constructor(private router: Router, private passingDataService: PassingDataService) {}

	ngOnInit(): void {
		this.pageNumber = 1;
	}

	switchPage() {
		this.pageNumber++;
	}

	redirectToInviteFriends() {
		this.router.navigate([ `home/groups/mine/group/${this.popupData.groupId}/invite` ]);
		this.closePopup();
	}
	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
