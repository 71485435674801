<div class="user-lineChart-wrapper" style="display: block;">
    <canvas baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    #lineChartCanvas></canvas>
  </div>
