import { Pipe, PipeTransform } from '@angular/core';
import { CordovaService } from '../services/cordova.service';

@Pipe({
	name: 'linkHightlight'
})
export class LinkHightlightPipe implements PipeTransform {
	constructor(private cordova: CordovaService) {}

	private regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

	transform(text: string, inlineCss = null): string {
		let newText;
		if (this.cordova.onCordova) {
      if (text) {
        newText = text.replace(
          this.regex,
          `<b ${inlineCss
            ? 'style=' + inlineCss
            : ''}><a style="cursor:pointer" href="javascript:window.open(\`$1\`, \`_blank\`, \'location=no\');" >$1</a></b>`
        );
      }
			if (newText && newText.indexOf('href="www.') !== -1) {
				newText = newText.replace(/href=\"www\./g, 'href="https://www.');
			}
		} else {
		  if (text) {
        newText = text.replace(
          this.regex,
          `<b${inlineCss
            ? ' style=' + inlineCss
            : ''}><a target="_blank" style="cursor:pointer" href="$1">$1</a></b>`
        );
      }
			if (newText && newText.indexOf('href="www.') !== -1) {
				newText = newText.replace(/href=\"www\./g, 'href="https://www.');
			}
		}
		return newText;
	}
}
