export interface TeamMembersDTO {
	amount: number;
	members: TeamMembers[];
}

export interface TeamMembers {
	userId: number;
	userName: string;
	email: string;
	city: string;
	userImage: string;
	isCaptain: boolean;
	teamId: number;
	teamName: string;
	groupId: number;
	groupName: string;
}

export interface TeamStatisticsDTO {
	amount: number;
	statistic: MemberStatistic[];
}

export interface MemberStatistic {
	userId: number;
	userName: string;
	userImage: string;
	teamName: string;
	isPrivate: boolean;
	value: number;
	currentWeight: number;
	initialWeight: number;
	targetWeight: number;
	lastMeasurementDate: string;
	privateStatistic: boolean;
	city: string;
	isCaptain: boolean;
}

export interface TeamCompositionDTO {
	groupId: number;
	groupName: string;
	currentGroupSize: number;
	teamId: number;
	teamName: string;
	currentTeamSize: number;
	minTeamSize: number;
	maxTeamSize: number;
	availableTeamSize: number;
}

export interface TeamsCompositionDTO {
	groupId: number;
	groupName: string;
	currentGroupSize: number;
	minTeamSize: number;
	maxTeamSize: number;
}

import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/services/api/api-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamsCreateOrJoinListDTO } from '../../../group-teams/teams-list/teams-list.component';
import {
	EditTeamInterfaceDTO,
	CreateTeamInterfaceDTO
} from '../../../../../create-team-module/components/team-creation/team-creation.component';

@Injectable({
	providedIn: 'root'
})
export class TeamService extends ApiBaseService {
	constructor(protected http: HttpClient) {
		super(http);
	}

	createTeam(groupId: number, payload: CreateTeamInterfaceDTO): Observable<EditTeamInterfaceDTO> {
		return this.postData(`group/${groupId}/teams`, payload);
	}

	deleteTeam(groupId: number, teamId: number): Observable<any> {
		return this.deleteData(`group/${groupId}/teams/${teamId}`);
	}

	getTeams(groupId: number): Observable<TeamsCreateOrJoinListDTO> {
		return this.getData(`group/${groupId}/teams`);
	}

	getTeam(groupId: number, teamId: number): Observable<EditTeamInterfaceDTO> {
		return this.getData(`group/${groupId}/teams/${teamId}`);
	}

	getTeamComposition(groupId: number, teamId: number): Observable<TeamCompositionDTO> {
		return this.getData(`group/${groupId}/teams/${teamId}/composition`);
	}

	getTeamsComposition(groupId: number): Observable<TeamsCompositionDTO> {
		return this.getData(`group/${groupId}/teams/composition`);
	}

	getTeamMembers(groupId: number, teamId: number): Observable<TeamMembersDTO> {
		return this.getData(`group/${groupId}/teams/${teamId}/users`);
	}

	getTeamMembersStatistics(groupId: number, teamId: number): Observable<TeamStatisticsDTO> {
		return this.getData(`group/${groupId}/teams/${teamId}/statistic`);
	}

	getTeamMembersWithoutTeam(groupId: number): Observable<TeamMembersDTO> {
		return this.getData(`group/${groupId}/noteam`);
	}

	joinToTheTeam(groupId: number, teamId: number, userId: number): Observable<any> {
		const payload = { groupId, teamId, userId };
		return this.postData(`group/${groupId}/teams/${teamId}/adduser`, payload);
	}

	leaveTheTeam(groupId: number, teamId: number, userId: number): Observable<any> {
		return this.deleteDataById(`group/${groupId}/teams/${teamId}/users/${userId}`);
	}

	cancelJoinRequest(groupId: number, teamId: number, userId: number): Observable<any> {
		return this.postData(`group/${groupId}/teams/${teamId}/canceljoinrequest`, { groupId, teamId, userId });
	}

	moveMemberBetweenTeams(groupId: number, oldTeamId: number, newTeamId: number, userId: number): Observable<any> {
		const payload = { groupId, oldTeamId, newTeamId, userId };
		return this.postData(`group/${groupId}/teams/${newTeamId}/moveuser`, payload);
	}

	requestToJoinThePrivateTeam(groupId: number, teamId: number): Observable<any> {
		return this.postData(`group/${groupId}/teams/${teamId}/adduser`, {});
	}

	updateTeam(groupId: number, teamId: number, payload: EditTeamInterfaceDTO): Observable<EditTeamInterfaceDTO> {
		return this.putData(`group/${groupId}/teams/${teamId}`, payload);
	}

	setNewTeamCaptain(groupId: number, teamId: number, userId: number): Observable<any> {
		const payload = { userId };
		return this.putData(`group/${groupId}/teams/${teamId}/setcaptain`, payload);
	}
}
