<div class="wrapper" [ngClass]="{'mini': !showMiniMenu}">
  <div class="container">
    <div class="privacy">
      <a class="button__back" (click)="goBack()">
        <img src="assets/images/arrow-lg-left.png" srcset="assets/images/arrow-lg-left@2x.png 2x,
                   assets/images/arrow-lg-left@3x.png 3x" class="arrow-lg-left">
        <div class="title">{{'SETTINGS.PRIVACY' | translate}}</div>
      </a>
      <h1>{{ "SETTINGS.PRIVACY.H1" | translate}}</h1>
      <p>{{ "SETTINGS.PRIVACY.P1" | translate}}</p>
      <p>{{ "SETTINGS.PRIVACY.P2" | translate}}</p>
      <h2>{{ "SETTINGS.PRIVACY.H2_1" | translate}}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_1.P2" | translate}}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_1.P1" | translate}}</p>
      <h2>{{ "SETTINGS.PRIVACY.H2_2" | translate}}</h2>
      <ul>
        <li style="cursor: pointer" (click)="scrollTo(howYouCanControlYourInformation)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI1" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(whatInformationWeCollectAndWhy)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI2" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(howWeHandleInformation)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI3" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(howWeShareInformationWithThirdParties)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI4" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(howLongIsPersonalDataStores)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI5" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(howTheInformationIsStoredAndProtected)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI6" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(changesToThePrivacyPolicy)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI7" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(cookies)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI8" | translate}}</li>
        <li style="cursor: pointer" (click)="scrollTo(howToContactUs)">{{ "SETTINGS.PRIVACY.H2_2.UL.LI9" | translate}}</li>
      </ul>
      <h2 #howYouCanControlYourInformation>{{ "SETTINGS.PRIVACY.H2_3" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_3.P1" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_3.P2" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_3.P3" | translate }}</p>
      <p><i>{{ "SETTINGS.PRIVACY.H2_3.P4" | translate }}</i></p>
      <h2 #whatInformationWeCollectAndWhy>{{ "SETTINGS.PRIVACY.H2_4" | translate }}</h2>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P1.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P1" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P2.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P2" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P3.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P3" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P4.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P4" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P5.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P5" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P6.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P6" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P7.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P7" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_4.P8.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_4.P8" | translate }}
      </p>
      <h2 #howWeHandleInformation>{{ "SETTINGS.PRIVACY.H2_5" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_5.P1" | translate }}</p>
      <ul>
        <li>{{ "SETTINGS.PRIVACY.H2_5.UL.LI1" | translate }}</li>
        <li>{{ "SETTINGS.PRIVACY.H2_5.UL.LI2" | translate }}</li>
        <li>{{ "SETTINGS.PRIVACY.H2_5.UL.LI3" | translate }}</li>
      </ul>
      <p>{{ "SETTINGS.PRIVACY.H2_5.P2" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_5.P3" | translate }}</p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_5.P4.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_5.P4" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_5.P5.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_5.P5" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_5.P6.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_5.P6" | translate }}
      </p>
      <h2 #howWeShareInformationWithThirdParties>{{ "SETTINGS.PRIVACY.H2_6" | translate }}</h2>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_6.P1.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_6.P1" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_6.P2.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_6.P2" | translate }}<a href="https://trustly.com/_/legal/?privacypolicy={{usersCurrnetLanguage}}" style="font-weight: bold;"> {{ "SETTINGS.PRIVACY.H2_6.P2_LINK" | translate }}</a> {{ "SETTINGS.PRIVACY.H2_6.P2_PART2" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_6.P3.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_6.P3" | translate }}
      </p>
      <p>
        <b>{{ "SETTINGS.PRIVACY.H2_6.P4.B" | translate }}</b>{{ "SETTINGS.PRIVACY.H2_6.P4" | translate }}
      </p>
      <p>{{ "SETTINGS.PRIVACY.H2_6.P5" | translate }}</p>
      <h2 #howLongIsPersonalDataStores>{{ "SETTINGS.PRIVACY.H2_7" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_7.P1" | translate }}</p>
      <h2 #howTheInformationIsStoredAndProtected>{{ "SETTINGS.PRIVACY.H2_8" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_8.P1" | translate }}</p>
      <h2 #changesToThePrivacyPolicy>{{ "SETTINGS.PRIVACY.H2_9" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_9.P1" | translate }}</p>
      <h2 #cookies>{{ "SETTINGS.PRIVACY.H2_10" | translate }}</h2>
      <h2>{{ "SETTINGS.PRIVACY.H2_11" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_11.P1" | translate }}</p>
      <h2>{{ "SETTINGS.PRIVACY.H2_12" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_12.P1" | translate }}</p>
      <h2>{{ "SETTINGS.PRIVACY.H2_13" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_13.P1" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_13.P2" | translate }}</p>
      <h2 #howToContactUs>{{ "SETTINGS.PRIVACY.H2_14" | translate }}</h2>
      <p>{{ "SETTINGS.PRIVACY.H2_14.P1" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_14.P2" | translate }}</p>
      <p>{{ "SETTINGS.PRIVACY.H2_14.P3" | translate }}</p>
    </div>
  </div>
</div>
