import { Component, OnInit, Input } from '@angular/core';
import { SingleDataSet } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ApiGroupsService } from '../../../../services/api/api-groups.service';
import { GroupModel } from '../../../../models/group.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-group-doughnut-chart',
	templateUrl: './group-doughnut-chart.component.html',
	styleUrls: [ './group-doughnut-chart.component.less' ]
})
export class GroupDoughnutChartComponent implements OnInit {
	@Input() isGroupStarted: boolean;
	@Input() group: GroupModel;

	statistics;
	statisticsPercent;
	dropTarget;
	leftToReach;
	reached;
	doughnutChartData: SingleDataSet = [ 0, 0 ];
	doughnutChartType: ChartType = 'doughnut';
	doughnutColors = [
		{
			backgroundColor: [ 'rgba(226,233,241, 1)', 'rgba(251,111,68, 1)' ],
			borderColor: [ 'rgba(226,233,241, 1)', 'rgba(251,111,68, 1)' ],
			borderWidth: 10,
			backgroundBorderRadius: 10,
			borderAlign: 'center'
		}
	];

	doughnutOptions = {
		scaleShowVerticalLines: false,
		responsive: true,
		rotation: 2 * Math.PI,
		circumference: 2 * Math.PI,
		cutoutPercentage: 96.5,
		legend: {
			display: false
		},
		tooltips: {
			enabled: false
		}
	};

	constructor(private router: Router, private groupsApiService: ApiGroupsService) {}

	ngOnInit() {
		this.getGroupStatistics(this.group.id);
	}

	getGroupStatistics(id: string) {
		this.groupsApiService.getGroupStatistics(id).subscribe((stats) => {
			this.statistics = stats;
			if (
				this.statistics.initialWeight === 0 ||
				this.statistics.currentWeight === 0 ||
				this.statistics.targetWeight === 0 ||
				this.statistics.initialWeight <= this.statistics.targetWeight ||
				this.statistics.initialWeight <= this.statistics.currentWeight
			) {
				this.doughnutChartData[0] = 100;
				this.doughnutChartData[1] = 0;
				this.statisticsPercent = 0 + '%';
			} else if (this.statistics.currentWeight <= this.statistics.targetWeight) {
				this.doughnutChartData[0] = 0;
				this.doughnutChartData[1] = 100;
				this.dropTarget = this.statistics.initialWeight - this.statistics.targetWeight;
				this.statisticsPercent =
					Math.floor(
						(this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget
					) + '%';
			} else {
				this.dropTarget = this.statistics.initialWeight - this.statistics.targetWeight;
				this.leftToReach =
					(this.dropTarget - (this.statistics.initialWeight - this.statistics.currentWeight)) *
					100 /
					this.dropTarget;
				this.reached = (this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget;
				this.doughnutChartData[0] = this.leftToReach;
				this.doughnutChartData[1] = this.reached;
				this.statisticsPercent =
					Math.floor(
						(this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget
					) + '%';
			}
		});
	}

	registerWeight() {
		this.router.navigate([ `home/register-weight/unofficial/${this.group.id}` ]);
	}
}
