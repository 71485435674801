import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AppConstants } from '../../../utils/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { AppLocalStorage } from '../../../utils/app.local.storage';
import { RolesComponent } from '../../_abstract/roles.component';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: [ './main-menu.component.less' ]
})
export class MainMenuComponent extends RolesComponent implements OnInit {
	isDefaultLanguage: boolean;
	baseURL = AppConstants.api.mediaFilesUrl;
	appVersion = AppConstants.appVersion || null;

	@Input() isActive = false;
	@Output() isCloseMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() user;
	@Input() showMenuItem;

	constructor(
		authService: AuthService,
		private apiUserProfile: ApiUserProfileService,
		private translate: TranslateService
	) {
		super(authService);
	}

	ngOnInit() {
		this.isDefaultLanguage = this.translate.currentLang === 'sv';
	}

	closeMenu() {
		this.isCloseMenu.next();
	}

	changeLanguage(isDefaultLanguage) {
		this.isDefaultLanguage = isDefaultLanguage;
		/* const lang = this.isDefaultLanguage ? 'sv' : 'en';
    AppLocalStorage.add(AppConstants.keys.defaultLanguage, lang);
    this.translate.use(lang);
    if (this.authService.isAuthorized()) {
      this.apiUserProfile.changeNotificationLanguage(lang).subscribe();
    } */
	}
}
