import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { AuthService } from 'src/app/services/auth.service';
import { TeamService } from '../../group-statistics/team-statistics-tabs/service/team-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StateService } from 'src/app/services/state.service';

@Component({
	selector: 'app-teams-success-request-sent',
	templateUrl: './teams-success-request-sent.component.html',
	styleUrls: [ './teams-success-request-sent.component.less' ]
})
export class TeamsSuccessRequestSentComponent implements AfterViewInit {
	@Input() groupId: string;
	@Input() privateTeamId: number;
	@Output() privateRequestCanceled = new EventEmitter<boolean>();
	errorsProcessorService: any;
	topNotificationsService: any;

	constructor(
		private auth: AuthService,
		private router: Router,
		private apiGroupService: ApiGroupsService,
		private teamsService: TeamService,
		private loadingSpinnerService: LoadingSpinnerService,
		private stateService: StateService
	) {}

	ngAfterViewInit() {
		const currentComponentElement = document.getElementById(`teamsSuccessRequestTop`);
		currentComponentElement.scrollIntoView();
	}

	backToGroup() {
		this.stateService.setHideBottomMenu('show');
		// delete group from cache then reset and go back
		this.apiGroupService.deleteItemFormLastGroupFetchArray(this.groupId);
		// Force reload group component
		const uri = `/home/groups/mine/group/${this.groupId}`;
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([ uri ]));
	}

	cancelPrivateRequest() {
		if (this.privateTeamId) {
			this.loadingSpinnerService.show.next();
			this.teamsService
				.cancelJoinRequest(Number(this.groupId), this.privateTeamId, this.auth.getUser().id)
				.subscribe(
					() => {
						this.privateRequestCanceled.emit(true);
						this.loadingSpinnerService.hide.next();
					},
					(httpErrorResponse: HttpErrorResponse) => {
						const error = this.errorsProcessorService.process(httpErrorResponse);
						this.topNotificationsService.notify(error, 'error');
						this.loadingSpinnerService.hide.next();
					}
				);
		}
	}
}
