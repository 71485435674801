import {Subject} from 'rxjs';
import {StorageDecorator} from './storage.decorator';

@StorageDecorator('sessionStorage')
export class AppSessionStorage {
    static currentStorageValue: Subject<any> = new Subject<any>();


    static add(key: string, item: any): void {
    }

    static get(key: string): any {
    }

    static getObject(key: string): any {
    }

    static remove(key: string): void {
    }

    static clear(): void {
    }

    static getCurrentSessionStorage() {
        return AppSessionStorage.currentStorageValue.asObservable();
    }
}
