import { Component, Input } from '@angular/core';
import { AppConstants } from 'src/app/utils/app.constants';
import { TeamService, MemberStatistic } from '../service/team-service.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
	selector: 'app-group-statistics-team-statistics',
	templateUrl: './group-statistics-team-statistics.component.html',
	styleUrls: [ './group-statistics-team-statistics.component.less' ]
})
export class GroupStatisticsTeamStatisticsComponent {
	@Input() groupId: number;
	@Input() groupIsCanJoin: boolean;
	@Input() team;
	@Input() teamMembersStatistics: MemberStatistic[];

	baseURL = AppConstants.api.mediaFilesUrl;
	groupTop: HTMLElement;
	isLoading = false;
	isShowLeamTeamConfirmation = false;
	// UNNEEDED teamStatistics and should be replaced with teamMembersStatistics

	constructor(
		private teamService: TeamService,
		private loadingSpinnerService: LoadingSpinnerService,
		private teamsService: TeamService,
		private apiGroupService: ApiGroupsService,
		private passingDataService: PassingDataService,
		private auth: AuthService,
		private router: Router,
		private errorsProcessorService: ErrorsProcessorService,
		private topNotificationsService: TopNotificationsService
	) {}

	toggleLeaveTeam() {
		this.isShowLeamTeamConfirmation = !this.isShowLeamTeamConfirmation;
	}
	confirmLeaveTeam() {
		this.loadingSpinnerService.show.next();
		this.teamsService.leaveTheTeam(this.groupId, this.team.id, this.auth.getUser().id).subscribe(
			() => {
				// delete group from cache then reset and go back
				this.apiGroupService.deleteItemFormLastGroupFetchArray(String(this.groupId));
				// set FEED as active tab at the group's page
				this.passingDataService.setMainActiveTab('teams');
				// Forece reload group component
				const uri = `/home/groups/mine/group/${this.groupId}`;
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
					this.router.navigate([ uri ], {
						queryParams: { presetTab: true }
					})
				);
				this.loadingSpinnerService.hide.next();
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
			}
		);
	}
	goToUserProfile(id: number) {
		if (id !== this.auth.getUser().id) {
			this.passingDataService.setMainActiveTab('statistics');
			this.router.navigate([ `home/profile/${id}` ]);
		}
	}
	handleError(httpErrorResponse: HttpErrorResponse) {
		const error = this.errorsProcessorService.process(httpErrorResponse);
		this.topNotificationsService.notify(error, 'error');
		this.loadingSpinnerService.hide.next();
	}

	trackByItems(index: number, item: any): number {
		return Number(item.id);
	}
}
