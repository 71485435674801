import { Component, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { merge, Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FileInputDisplayTypes } from 'src/app/enums/FileInputDisplayTypes.enum';
import { ApiUserProfileService, IUserPhoto } from 'src/app/services/api/api-user-profile.service';
import { AppConstants } from 'src/app/utils/app.constants';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
	selector: 'app-profile-pictures',
	templateUrl: './profile-pictures.component.html',
	styleUrls: [ './profile-pictures.component.less' ]
})
export class ProfilePicturesComponent implements OnInit, OnDestroy {
	isPublicMode = false;
	isMine = false;
	FileInputDisplayTypes = FileInputDisplayTypes;
	userPhotos$: Observable<IUserPhoto[]>;
	/* shouldPicturesBeReFetched$: Subscription = Subscription.EMPTY; */
	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription;

	queryParams = {
		page: 1,
		pageSize: 12
	};
	showFullScreen = [];
	showFullScreenImage: null | {
		imageId: number;
		imageIndex: number;
		imageName: string;
	};
	baseURL = AppConstants.api.mediaFilesUrl;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private authService: AuthService,
		private stateService: StateService,
		private route: ActivatedRoute,
		public userProfile: ApiUserProfileService,
		private passingDataServiece: PassingDataService,
		private loadingSpinnerService: LoadingSpinnerService,
		private element: ElementRef,
		private renderer: Renderer2
	) {
		this.userPhotos$ = this.userProfile.getUserPhotos$;
	}

	ngOnInit() {
		this.isPublicMode = !this.authService.isAuthorized();
		merge(this.route.params, this.route.data).pipe(debounceTime(1)).subscribe(() => {
			const route = this.route.snapshot;
			if (route.parent.params.id) {
				this.getUserPhotos(route.parent.params.id, this.queryParams);
			} else {
				this.getUserPhotos(null, this.queryParams);
			}
			if (route.data) {
				this.stateService.setRouteData(route.data);
				this.isMine = route.data.isMine;
			}
		});
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe((resizeEvent) => {
			this.setWrapperHeight();
		});
	}

	toggleFullscreen(imageIndex: number, imageName = null, imageId = null) {
		this.showFullScreen[imageIndex] = !this.showFullScreen[imageIndex];
		this.showFullScreen[imageIndex] === true
			? (this.showFullScreenImage = { imageName, imageIndex, imageId })
			: (this.showFullScreenImage = null);
	}

	setWrapperHeight() {
		/* setTimeout(() => {
			const fullscreenWrapper = this.element.nativeElement.querySelector('.fullscreen-photo');
			if (fullscreenWrapper) {
				this.renderer.setStyle(fullscreenWrapper, 'minHeight', window.innerHeight - 50 + 'px');
			}
		}, 100); */
	}

	replacer(image: string) {
		return image.replace(/\"/g, '');
	}

	getUserPhotos(id: number = null, queryParams = null) {
		this.userProfile.getUserPhotos(id, queryParams);
	}

	showImageAtHome(image: string, imageId: number) {
		// UNCOMMENT this code if fullscreen image will be needed as a top level popup
		/* this.passingDataServiece.setImageFileName(this.replacer(image).trim(), imageId, this.isMine);
		if (this.isMine) {
			this.shouldPicturesBeReFetched$.unsubscribe();
			this.shouldPicturesBeReFetched$ = this.passingDataServiece.removedImageId$.subscribe((removedImageId) => {
				if (removedImageId !== null) {
					this.passingDataServiece.setRemovedImageId(null);
					this.getUserPhotos();
					this.userProfile.getTwoUserPhotos();
					this.loadingSpinnerService.hide.next();
				}
			});
		} */
	}

	loadMorePhotos(id: number = null) {
		this.queryParams.page++;
		this.userProfile.getUserPhotos(id, this.queryParams);
	}

	toggleBackButton(shouldBackButtonBeShown: boolean) {
		this.stateService.setHideBottomMenu(shouldBackButtonBeShown ? 'show' : 'hide');
		this.stateService.setRouteData({ showBackButton: shouldBackButtonBeShown });
	}

	deleteUserPhoto(imageId: number = null, i: number) {
		if (imageId) {
			/* this.showFullScreen[i] = false; */
			this.loadingSpinnerService.show.next();
			this.userProfile.deleteUserPhoto(imageId).subscribe(
				() => {
					this.toggleBackButton(true);
					this.getUserPhotos();
					this.userProfile.getTwoUserPhotos();
					this.loadingSpinnerService.hide.next();
				},
				() => this.toggleBackButton(true),
				() => {
					/* this.showFullScreen[i] = false; */
					this.toggleFullscreen(i);
				}
			);
		} else {
			/* this.showFullScreen[i] = false; */
			this.toggleFullscreen(i);
		}
	}

	/* goBack() {
		this.routerInterceptorService.goBack();
	} */

	trackByItems(index: number, item: any): number | string {
		/* console.log(index, item); */
		return /* Number(item.id) */ Number(item.id);
	}

	ngOnDestroy() {
		/* this.shouldPicturesBeReFetched$.unsubscribe(); */
		this.resizeSubscription$.unsubscribe();
	}
}
