
export class GroupsFilteringQueryParams {
  page: number;
  pageSize: number;
  name: string;
  startDate: string;
  endDate: string;
  bettingAmount: string;
  minAmountOfParticipants: string;
  maxAmountOfParticipants: string;
  bonusprizes: boolean;
  private: boolean;

  constructor(data: any = {}) {
    this.page = data.page || 1;
    this.pageSize = 5;
    this.name = data.name || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.bettingAmount = data.bettingAmount || '';
    this.minAmountOfParticipants = data.minAmountOfParticipants || '';
    this.maxAmountOfParticipants = data.maxAmountOfParticipants || '';
    this.bonusprizes = data.bonusprizes || '';
    this.private = data.private || '';
  }

  toJson() {
    return {
      'page': this.page,
      'pageSize': this.pageSize,
      'name': this.name,
      'startDate': this.startDate,
      'endDate': this.endDate,
      'bettingAmount': this.bettingAmount,
      'minAmountOfParticipants': this.minAmountOfParticipants,
      'maxAmountOfParticipants': this.maxAmountOfParticipants,
      'bonusprizes': this.bonusprizes,
      'private': this.private
    };
  }
}
