<div class="doughnut" *ngIf="statistics">
    <div *ngIf="!showUpdateGoalWeight" class="doughnut__canvas">
      <div class="percent-value">{{statisticsPercent}}</div>
      <canvas
              baseChart
              [data]="doughnutChartData"
              [chartType]="doughnutChartType"
              [options]="doughnutOptions"
              [colors]="doughnutColors">
      </canvas>
    </div>
    <div class="doughnut__data">
      <div class="stats-data">{{ "GROUP.STATISTICS.START_WEIGHT" | translate }} <span>{{statistics.initialWeight}}Kg</span></div>
      <div class="stats-data">{{ "GROUP.STATISTICS.CURRENT_WEIGHT" | translate }} <span>{{statistics.currentWeight}}Kg</span></div>
      <div class="stats-data">{{ "GROUP.STATISTICS.GOAL_WEIGHT" | translate }} <span>{{statistics.targetWeight}}Kg</span></div>
    </div>
</div>
<a *ngIf="isMineProfile" class="button fullwidth button__main" (click)="registerWeight()">{{ "GLOBAL.UPDATE_WEIGHT" | translate }}</a>
<a *ngIf="isMineProfile" class="button fullwidth button__main" (click)="toggleShowUpdateGoalWeight()">{{ "PROFILE.UPDATE_GOAL_WEIGHT" | translate }}</a>
 
<div class="set-goal-weight" *ngIf="showUpdateGoalWeight">
  <div class="wrapper">
    <img class="close-button" (click)="toggleAndEmit()"
          src="assets/images/cancel.png"
          srcset="assets/images/cancel@2x.png 2x,
                  assets/images/cancel@3x.png 3x">

    <span class="title">{{ "PROFILE.UPDATE_GOAL_WEIGHT.TITLE" | translate }}</span>
    <span>{{ "PROFILE.UPDATE_GOAL_WEIGHT.OLD_TARGET" | translate }}: {{statistics.targetWeight}}Kg</span>
    <div class="field">
      <input type="number" placeholder="{{ 'PROFILE.UPDATE_GOAL_WEIGHT.INPUT_PLACEHOLDER' | translate }}" #newGoal>
    </div>
    <a class="button fullwidth button__main" (click)="updateGoalWeight(newGoal.value)">{{ "GLOBAL.SAVE" | translate }}</a>
  </div>
</div>