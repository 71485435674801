import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../utils/app.constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { GroupsFilteringQueryParams } from '../../../models/groups-filtering-query-params';
import { DatepickerHelper } from '../../../utils/datepicker-helper';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { merge } from 'rxjs/internal/observable/merge';
import { DateAdapter } from '@angular/material/core';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';

@Component({
  selector: 'app-group-filters',
  templateUrl: './group-filters.component.html',
  styleUrls: ['./group-filters.component.less'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        overflow: 'hidden',
        height: '*'
      })),
      state('out', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class GroupFiltersComponent implements OnInit {

  betsArray: string[] = AppConstants.bets;
  helpMenuOpen = 'out';
  isOpen = false;
  form: FormGroup;
  queryParams: GroupsFilteringQueryParams;
  type: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dateAdapter: DateAdapter<Date>,
              private groupsApiService: ApiGroupsService) {
    this.dateAdapter.setLocale(AppLocalStorage.getObject(AppConstants.keys.defaultLanguage));
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
  }

  ngOnInit() {
    this.queryParams = new GroupsFilteringQueryParams(this.route.snapshot.queryParams);
    this.type = this.route.snapshot.params.type;
    this.form = new FormGroup({
      'name': new FormControl(this.queryParams.name),
      'startDate': new FormControl(this.queryParams.startDate),
      'endDate': new FormControl(this.queryParams.endDate),
      'bettingAmount': new FormControl(this.queryParams.bettingAmount),
      'minAmountOfParticipants': new FormControl(this.queryParams.minAmountOfParticipants),
      'maxAmountOfParticipants': new FormControl(this.queryParams.maxAmountOfParticipants),
      'bonusprizes': new FormControl(this.queryParams.bonusprizes),
      'private': new FormControl(this.queryParams.private),
    });

    this.form.controls.name.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(
      (term: string) => {
        this.type === 'all' ? this.groupsApiService.dropIsAll() : this.groupsApiService.dropIsMy();
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {name: term},
          queryParamsHandling: 'merge' });
      }
    );

    merge(this.route.params, this.route.queryParams).pipe(debounceTime(1)).subscribe(
      () => {
        const params = this.route.snapshot.params;
        const queryParams = this.route.snapshot.queryParams;

        if (this.form.value.name && !queryParams.name) {
          // Cache invalidate on tab change after user made some filtering
          this.type === 'all' ? this.groupsApiService.dropIsAll() : this.groupsApiService.dropIsMy();
        }

        if (this.isOpen) {
          this.toggleFilters();
        }
        if (params.type !== this.type && !(queryParams && Object.keys(queryParams).length)) {
          this.form.reset();
        }
        this.type = params.type;

        if (queryParams && Object.keys(queryParams).length) {
          this.form.patchValue(queryParams);
        }
      }
    );
  }

  toggleFilters(): void {
    this.isOpen = this.helpMenuOpen === 'out';
    this.helpMenuOpen = this.helpMenuOpen === 'out' ? 'in' : 'out';
  }

  filterGroups() {
    this.type === 'all' ? this.groupsApiService.dropIsAll() : this.groupsApiService.dropIsMy();
    const formValue = this.form.value;
    this.formatDateValues(formValue);
    this.queryParams = new GroupsFilteringQueryParams(formValue);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams.toJson(),
      queryParamsHandling: 'merge' });

    this.toggleFilters();
  }

  private formatDateValues(data: any) {
    data.startDate = data.startDate && DatepickerHelper.format(data.startDate);
    data.endDate = data.endDate && DatepickerHelper.format(data.endDate);
  }

}
