import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppLocalStorage } from '../../../utils/app.local.storage';
import { AppConstants } from '../../../utils/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';

function _window(): any {
	return window;
}
@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: [ './privacy.component.less' ]
})
export class PrivacyComponent implements OnInit, OnDestroy {
	showMiniMenu = false;
	usersCurrnetLanguage;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private auth: AuthService,
		private router: Router,
		private stateService: StateService
	) {
		setTimeout(() => {
			this.stateService.setHideBottomMenu('hide');
		}, 200);
	}

	ngOnInit() {
		this.usersCurrnetLanguage = AppLocalStorage.get('defaultLanguage').replace(/\"/g, '');
		this.showMiniMenu = this.auth.isAuthorized();
	}

	scrollTo(target: HTMLElement) {
		target.scrollIntoView({ behavior: 'smooth' });
	}

	goBack() {
		this.showMiniMenu
			? /* this.routerInterceptorService.goBack() */ this.router.navigate([ '/home/menu' ])
			: this.router.navigate([ '..' ]);
	}

	ngOnDestroy() {
		this.stateService.setHideBottomMenu('show');
	}
}
