import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
@Component({
	selector: 'app-discount-codes-popup',
	templateUrl: './discount-codes-popup.component.html',
	styleUrls: [ './discount-codes-popup.component.less' ]
})
export class DiscountCodesPopupComponent {
	@Input() popupData;
	constructor(private ga: GoogleAnalyticsService) {}
	sendGaEvent() {
		this.ga.eventEmitter('discount-code-click', 'rewards-engagement', 'discount-code-click');
	}
}
