<ng-container 
    *ngIf="isLoading; then showSpinner; else showData">
</ng-container>

<ng-template #showData>
    <ng-container 
        *ngIf="isShowLeamTeamConfirmation; then showLeaveConfirmation; else showTeamStatistics">
    </ng-container>
    <ng-template #showTeamStatistics>
        <div class="team-statistics" *ngFor="let result of teamMembersStatistics; trackBy: trackByItems">
            <div class="team-statistics__teammate">
                <div class="team-statistics__teammate--base-info">
                    <div class="image-wrapper"> 
                        <img class="image" src="{{result.userImage  | userAvatarUrl}}">
                    </div>
                    <a class="teammate-name-and-city" (click)="goToUserProfile(result.userId)">
                        <b>{{result.userName}}</b>
                        <span class="user-city">{{result.city}}&nbsp;
                            <span *ngIf="result.isCaptain">{{ "GROUP.TEAMS.LIST.TABLE_HEADER.2" | translate }}</span>
                        </span>
                    </a>
                </div>
                <div class="team-statistics__teammate--additional-info">
                    <ng-container 
                        *ngIf="result.targetWeight > 0; then showLastMeasurementDate; else showNoLastDate">
                    </ng-container>
                    <ng-template #showLastMeasurementDate>
                        <div class="teammate-percent"
                            [ngClass]="{'text-color-wite': result.value >= 100}"
                            [style.background]="result.value | progressFillPercentage">
                            <span>{{result.value}}{{ "TEAM.STATISTICS.PERCENT_OF_GOAL" | translate }}</span>
                        </div>
                        <div class="teammate-last-update">
                                <div class="update-text">{{ "TEAM.STATISTICS.UPDATED" | translate }}: {{result.lastMeasurementDate | amDateFormat: 'DD-MM-YYYY'}}</div>
                        </div>
                        </ng-template>
                        <ng-template #showNoLastDate>
                            <div class="teammate-percent no-statistics">
                                <span>{{ "TEAM.STATISTICS.NO_STATISTICS" | translate }}</span>
                            </div>
                            <div class="teammate-last-update">
                                <div class="update-text">{{ "TEAM.STATISTICS.NO_UPDATE" | translate }}</div>
                            </div>
                        </ng-template>
                </div>
            </div>
        </div>
        <a *ngIf="groupIsCanJoin" class="button button__main transparent leave" (click)="toggleLeaveTeam()">{{ "TEAM.LEAVE" | translate }}</a>
    </ng-template>
    <ng-template #showLeaveConfirmation>
        <div class="my-team-header__leave-confirmation">
            <div class="attention-text">{{ "TEAM.LEAVE.CONFIRM.TEXT" | translate}}</div>
            <a class="button button__alt" (click)="confirmLeaveTeam()">{{ "TEAM.LEAVE.CONFIRM" | translate}}</a>
            <a class="button button__alt cancel" (click)="toggleLeaveTeam()">{{ "GLOBAL.CANCEL" | translate}}</a>
        </div>
    </ng-template>
</ng-template>

<ng-template #showSpinner>
    <div class="spinner">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
</ng-template>