import { Component /* Input */ } from '@angular/core';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-team-members-size-popup',
	templateUrl: './team-members-size-popup.component.html',
	styleUrls: [ './team-members-size-popup.component.less' ]
})
export class TeamMembersSizePopupComponent {
	/* @Input() popupData; */

	constructor(private passingDataService: PassingDataService) {}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
