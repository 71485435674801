import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { map as _map } from "lodash-es";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { GroupModel, GroupStatisticsModel } from "../../models/group.model";
import { GroupsFilteringQueryParams } from "../../models/groups-filtering-query-params";
import { AppConstants } from "../../utils/app.constants";
import { ApiBaseService } from "./api-base.service";

export interface ITeamByToken {
  groupId: number;
  groupName: string;
  teamId: number;
  teamName: string;
}

export interface IGroupPredefinedDiscountCodes {
  discountCodeNameEn: string;
  discountCodeNameSv: string;
  discountCodeLink: string;
  discountCodeDescriptionEn: string;
  discountCodeDescriptionSv: string;
}

export class LastGroupFetch {
  group: any;
  timeStamp: number;
  constructor(data: any) {
    this.group = data;
    this.timeStamp = new Date().getTime();
  }
}

@Injectable()
export class ApiGroupsService extends ApiBaseService {
  minuteValue = 3; // replace this value to more\less cache interval
  groupsTimer = this.minuteValue * 1000 * 60; // minutes value
  groupTimer = this.minuteValue * 1000 * 60; // minutes value

  lastGroupsFetch: {
    isMy: {
      groupsFetched: boolean;
      timeStamp: number;
      counter: number;
    };
    isAll: {
      groupsFetched: boolean;
      timeStamp: number;
      counter: number;
    };
  };

  lastGroupFetchArray = [];

  tempAllGroups: any;
  tempMyGroups: any;

  updateFeed$: EventEmitter<number> = new EventEmitter();

  constructor(protected http: HttpClient) {
    super(http);
    this.lastGroupsFetch = {
      isMy: {
        groupsFetched: false,
        timeStamp: 0,
        counter: 0,
      },
      isAll: {
        groupsFetched: false,
        timeStamp: 0,
        counter: 0,
      },
    };
  }

  getUpdateFeed(id) {
    this.updateFeed$.next(id);
  }

  dropIsMy() {
    this.lastGroupsFetch.isMy.counter = 0;
    this.lastGroupsFetch.isMy.timeStamp = 0;
    this.tempMyGroups = null;
  }
  dropIsAll() {
    this.lastGroupsFetch.isAll.counter = 0;
    this.lastGroupsFetch.isAll.timeStamp = 0;
    this.tempAllGroups = null;
  }
  dropAllFetches() {
    this.lastGroupFetchArray.length = 0;
  }

  getGroups(
    queryParams: GroupsFilteringQueryParams,
    isUserGroups: boolean,
    isFirstTimeLoad: boolean = false
  ): Observable<any> {
    // Check is mine groups requested and requested first page
    if (
      isUserGroups &&
      queryParams.page === 1 &&
      this.lastGroupsFetch.isMy.counter > 0
    ) {
      // Check requested myGroups earlier
      if (this.lastGroupsFetch.isMy.groupsFetched) {
        // If was requested and it was longer then 3min ago
        if (
          this.lastGroupsFetch.isMy.timeStamp + this.groupsTimer <
          new Date().getTime()
        ) {
          this.lastGroupsFetch.isMy.groupsFetched = false;
        }
        // Check is mine groups was not requested earlier
      } else if (!this.lastGroupsFetch.isMy.groupsFetched) {
        this.lastGroupsFetch.isMy.timeStamp = new Date().getTime();
        this.lastGroupsFetch.isMy.groupsFetched = true;
      }
      this.lastGroupsFetch.isMy.counter++;
      // If mine groups not fetched - we should fetch them
      if (!this.lastGroupsFetch.isMy.groupsFetched) {
        this.dropIsMy();
        return this.fetchGroupsData(queryParams, isUserGroups, isFirstTimeLoad);
      } else {
        // In other case saved dataObject should be returned as Observable
        return of(this.tempMyGroups);
      }
      // Check is all groups requested and requested first page
    } else if (
      !isUserGroups &&
      queryParams.page === 1 &&
      this.lastGroupsFetch.isAll.counter > 0
    ) {
      // Check requested allGroups earlier
      if (this.lastGroupsFetch.isAll.groupsFetched) {
        // If was requested and it was longer then 3min ago
        if (
          this.lastGroupsFetch.isAll.timeStamp + this.groupsTimer <
          new Date().getTime()
        ) {
          this.lastGroupsFetch.isAll.groupsFetched = false;
        }
        // Check is all groups was not requested earlier
      } else if (!this.lastGroupsFetch.isAll.groupsFetched) {
        this.lastGroupsFetch.isAll.timeStamp = new Date().getTime();
        this.lastGroupsFetch.isAll.groupsFetched = true;
      }
      this.lastGroupsFetch.isAll.counter++;
      // If all groups not fetched - we should fetch them
      if (!this.lastGroupsFetch.isAll.groupsFetched) {
        this.dropIsAll();
        return this.fetchGroupsData(queryParams, isUserGroups, isFirstTimeLoad);
      } else {
        // In other case saved dataObject should be returned as Observable
        return of(this.tempAllGroups);
      }
    } else {
      return this.fetchGroupsData(queryParams, isUserGroups, isFirstTimeLoad);
    }
  }

  fetchGroupsData(
    queryParams: GroupsFilteringQueryParams,
    isUserGroups,
    isFirstTimeLoad
  ) {
    const params = this.getApiParameters(queryParams, isUserGroups);
    if (isFirstTimeLoad) {
      return this.getData("start", params).pipe(
        map((data: any) => {
          data.groups = _map(data.groups, (item) => {
            return new GroupModel(item);
          });
          if (queryParams.page === 1 && isUserGroups) {
            this.tempMyGroups = data;
            this.lastGroupsFetch.isMy.counter++;
          } else if (queryParams.page === 1 && !isUserGroups) {
            this.tempAllGroups = data;
            this.lastGroupsFetch.isAll.counter++;
          }
          return data;
        })
      );
    } else {
      return this.getData("group", params).pipe(
        map((data: any) => {
          data.groups = _map(data.groups, (item) => {
            return new GroupModel(item);
          });
          if (queryParams.page === 1 && isUserGroups) {
            this.tempMyGroups = data;
            this.lastGroupsFetch.isMy.counter++;
          } else if (queryParams.page === 1 && !isUserGroups) {
            this.tempAllGroups = data;
            this.lastGroupsFetch.isAll.counter++;
          }
          return data;
        })
      );
    }
  }

  private getApiParameters(
    queryParams: GroupsFilteringQueryParams,
    isUserGroups: boolean
  ): HttpParams {
    return new HttpParams()
      .set("page", String(queryParams.page))
      .set("pageSize", String(queryParams.pageSize))
      .set("name", queryParams.name)
      .set("startDate", queryParams.startDate)
      .set("endDate", queryParams.endDate)
      .set("bettingAmount", queryParams.bettingAmount)
      .set("minAmountOfParticipants", queryParams.minAmountOfParticipants)
      .set("maxAmountOfParticipants", queryParams.maxAmountOfParticipants)
      .set("bonusprizes", String(queryParams.bonusprizes))
      .set("private", String(queryParams.private))
      .set("userGroups", String(isUserGroups));
  }

  createGroup(group: GroupModel): Observable<GroupModel> {
    this.dropIsAll();
    this.dropIsMy();
    return this.postData("group", group);
  }

  updateGroup(group: GroupModel): Observable<GroupModel> {
    this.dropIsAll();
    this.dropIsMy();
    this.deleteItemFormLastGroupFetchArray(group.id);
    return this.putData(`group/${group.id}`, group);
  }

  getGroup(id: string): Observable<GroupModel> {
    if (this.lastGroupFetchArray.length > 0) {
      for (let i = 0; i <= this.lastGroupFetchArray.length - 1; i++) {
        if (id === String(this.lastGroupFetchArray[i].group.id)) {
          if (
            this.lastGroupFetchArray[i].timeStamp + this.groupTimer >
            new Date().getTime()
          ) {
            return of(new GroupModel(this.lastGroupFetchArray[i].group));
          } else {
            this.deleteItemFormLastGroupFetchArray(id);
            return this.fetchGroupData(id);
          }
        }
      }
    }
    return this.fetchGroupData(id);
  }

  deleteItemFormLastGroupFetchArray(id: string) {
    if (id) {
      if (this.lastGroupFetchArray.length > 0) {
        for (let i = 0; i <= this.lastGroupFetchArray.length - 1; i++) {
          if (String(id) === String(this.lastGroupFetchArray[i].group.id)) {
            this.lastGroupFetchArray.splice(i, 1);
            return;
          }
        }
      }
    }
  }

  fetchGroupData(id: string) {
    return this.getData<GroupModel>(`group/${id}`).pipe(
      map((data) => {
        this.lastGroupFetchArray.push(new LastGroupFetch(data));
        return new GroupModel(data);
      })
    );
  }

  dropAllCacheData(groupId: string) {
    this.dropIsAll();
    this.dropIsMy();
    this.deleteItemFormLastGroupFetchArray(groupId);
  }

  getGroupsParticipants(groupId: string): Observable<any> {
    const params = new HttpParams()
      .set("page", "1")
      .set("pageSize", "1000")
      .set("showEmail", "false");
    return this.getData(`group/${groupId}/participants`, params);
  }
  getGroupsParticipantsAdmin(groupId: string): Observable<any> {
    const params = new HttpParams()
      .set("page", "1")
      .set("pageSize", "1000")
      .set("showEmail", "true");
    return this.getData(`group/${groupId}/participants`, params);
  }

  getGroupByToken(token: string): Observable<GroupModel> {
    return this.getData<GroupModel>(`group/getByToken/${token}`).pipe(
      map((data) => {
        return new GroupModel(data);
      })
    );
  }

  getTeamByIniteToken(token: string): Observable<ITeamByToken> {
    return this.getData(`group/getTeamByToken/${token}`);
  }

  deleteGroup(id: string): Observable<any> {
    return this.http.delete(AppConstants.api.baseUrl + `group/${id}`);
  }

  isFreeGroup(id: string, isFree: boolean): Observable<any> {
    let param = new HttpParams().set("isFree", `${isFree}`);
    return this.postData(`group/${id}/set-free`, {}, param);
  }

  deleteOwnGroup(id: string): Observable<any> {
    this.dropIsAll();
    this.dropIsMy();
    return this.http.delete(AppConstants.api.baseUrl + `group/${id}/deleteown`);
  }

  getGroupStatistics(id: string): Observable<GroupStatisticsModel> {
    return this.getData<GroupStatisticsModel>(`group/${id}/statistic`).pipe(
      map((data) => {
        return new GroupStatisticsModel(data);
      })
    );
  }

  postGroupStatistics(id: any, weight: string, waist: string) {
    return this.postData<any>(`group/${id}/statistic`, {
      weight: weight,
      waist: waist,
    });
  }

  postProfileStatistics(weight: string, waist: string) {
    return this.postData<any>(`userProfile/history`, {
      weight: weight,
      waist: waist,
    });
  }

  joinGroup(id: string): Observable<GroupModel> {
    return this.postData<GroupModel>(`group/${id}/join`, {});
  }

  isNameTaken(name: string): Observable<boolean> {
    return this.postData("group/isNameTaken", { name: name });
  }

  getInviteToken(id: string): Observable<string> {
    const options = { responseType: "text" as "json" };
    return this.http.get<string>(
      `${AppConstants.api.baseUrl}group/${id}/inviteToken`,
      options
    );
  }

  addToGroup(id: string, token: any): Observable<any> {
    let body;
    if (token) {
      body = {
        token: token,
        nonce: null,
      };
    } else {
      body = {
        token: null,
        nonce: null,
      };
    }
    this.dropIsMy();
    this.deleteItemFormLastGroupFetchArray(id);
    return this.postData(`group/${id}/adduser`, body);
  }

  getDiscountCodes(): Observable<IGroupPredefinedDiscountCodes[]> {
    return this.getData(`discountCodes`);
  }

  saveDiscountCodes(payload: IGroupPredefinedDiscountCodes[]): Observable<any> {
    return this.postData(`admin/discountCodes`, payload);
  }
}
