import { environment } from '../../environments/environment';

export const AppConstants = {
	appVersion: environment.appVersion,
	baseFrontendUrl: environment.baseFrontendUrl,
	api: {
		baseUrl: environment.apiBaseUrl,
		mediaFilesUrl: environment.mediaFilesUrl,
		facebook: 'https://www.facebook.com/v7.0/dialog/oauth?',
		instagram: 'https://www.instagram.com/',
		instagramOAuth: 'https://api.instagram.com/oauth/authorize',
		instagramAccessToken: 'https://api.instagram.com/oauth/access_token',
		instagramRedirectURI: environment.baseFrontendUrl + 'home/profile',
		instagramScope: 'user_profile,user_media',
		instagramGraphQlBaseURI: 'https://graph.instagram.com',
		instagramRecentMedia: 'https://api.instagram.com/v1/users/self/media/recent/'
	},
	keys: {
		authResponse: 'authResponse',
		routerHistory: 'routerHistory',
		joinGroup: 'joinGroup',
		defaultLanguage: 'defaultLanguage',
		invite: 'invite',
		rm: 'rm'
	},
	emailRegexp:
		'^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
	bets: [ '200', '300', '500' ],
	winnerPointsAmount: 60,
	giftCards: [
		50,
		100,
		150,
		200,
		250,
		300,
		350,
		400,
		450,
		500,
		550,
		600,
		650,
		700,
		750,
		800,
		850,
		900,
		950,
		1000,
		1050,
		1100,
		1150,
		1200,
		1250,
		1300,
		1350,
		1400
	],
	registerWeightVideoUrls: {
    en: [ 'https://www.youtube.com/embed/wl-lpI7qTso', 'https://www.youtube.com/embed/26RmLfCei_E' ],
		sv: [ 'https://www.youtube.com/embed/Llh0TbBDBM4', 'https://www.youtube.com/embed/o3P1RtBwX4Q' ],
	},
	gridConfiguration: {
		defaultPageSize: 20,
		searchPageSize: 5,
		availablePageSize: [ 20, 100, 150 ]
	},
	clientId: environment.instagramClientId,
	instagramAppId: environment.instagramAppId,
	mediaSizeToFetch: 5,
	googleAnalyticsKey: environment.googleAnalyticsConfig
};
