<div class="wrapper">
    <div class="block">
        <div class="title">{{"PROFILE.QUICK_FACTS" | translate}}</div>
        <div class="quick-facts" *ngIf="isNoQuickFacts">{{isMine ? this.you : userName}} {{"PROFILE.QUICK_FACTS.NO_QUICK_FACTS" | translate}}</div>
        <ul class="quick-facts" *ngIf="!isNoQuickFacts">
          <li>{{"PROFILE.QUICK_FACTS.1" | translate}} - <span>{{bestHealthyFood}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.2" | translate}} - <span>{{bestUnhealthyFood}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.3" | translate}} - <span>{{favoriteTrainingUniform}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.4" | translate}} - <span>{{favoriteWeightLossMethod}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.5" | translate}} - <span>{{worstHabit}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.6" | translate}} - <span>{{superpower}}</span></li>
          <li>{{"PROFILE.QUICK_FACTS.7" | translate}}: <span>{{dinnerWith}}</span></li>
        </ul>
      </div>
      <div class="block">
        <div class="title">{{"PROFILE.PICTURES" | translate}}</div>
        <div class="user" *ngIf="isNoPhotos">{{isMine ? this.you : userName}} {{"PROFILE.USER_PHOTOS.NO_USER_PHOTOS" | translate}}
            <a class="button button__main transparent small"
              (click)="showAllPictures()"
                *ngIf="isNoPhotos && isMine">
                {{ "GLOBAL.ADD" | translate }}
            </a>
        </div>
        <div class="user" *ngIf="!isNoPhotos && !isPrivatePhoto">
            <div class="pictures" *ngIf="userPhotos.length > 0">
                <div class="image-wrapper" *ngFor="let image of userPhotos">
                    <img src="{{image.image | userAvatarUrl}}">
                </div>
                <div class="image-wrapper">
                  <div class="show-more" (click)="showAllPictures()">{{userPhotosLength <= 2 ? "" : "+"+(userPhotosLength-2)}}
                    <img style="object-fit: none;" src="assets/images/arrow-lg-right.png" *ngIf="userPhotosLength <= 2"></div>
                </div>
            </div>
        </div>
        <div class="user" *ngIf="!isNoPhotos && isPrivatePhoto">
          {{userName}} {{ "PROFILE.PICTURES.HIDDEN" | translate }}
        </div>
      </div>
      <div class="block">
        <div class="title">{{"PROFILE.LATEST_INSTAGRAM_INSERT" | translate}}</div>
        <div class="instagram" [ngClass]="{'spinner-wrapper': isLoading}">
          <ng-container *ngIf="isLoading; then showSpinner; else showData"></ng-container>
          <ng-template #showSpinner>
            <mat-progress-spinner diameter="50" color="accent" mode="indeterminate"></mat-progress-spinner>
          </ng-template>
          <ng-template #showData>
            <div *ngIf="!isInstaConnection">{{isMine ? this.you : userName}} {{"PROFILE.USER_INSTAGRAM.NO_USER_INSTAGRAM" | translate}}</div>
            <div class="pictures" *ngIf="isInstaConnection">
                <div class="image-wrapper" *ngFor="let imgUrl of recentMedia">
                  <img class="insta" src="{{imgUrl}}">
              </div>
            </div>
          </ng-template>
          <div class="follow">
            <div class="button button__main" (click)="toggleInstagramConnection()" *ngIf="isInstaConnection || isMine">
              <span *ngIf="!isInstaConnection && isMine">{{"PROFILE.CONNECT_INSTAGRAM" | translate}}</span>
              <span *ngIf="isInstaConnection && isMine">{{"PROFILE.DISCONNECT_INSTAGRAM" | translate}}</span>
              <span *ngIf="isInstaConnection && !isMine">{{"PROFILE.FOLLOW_INSTAGRAM" | translate}}</span>              
              <img src="assets/images/instagram.png"
                    srcset="assets/images/instagram@2x.png 2x,
                            assets/images/instagram@3x.png 3x">
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="title">{{"PROFILE.FRIENDS" | translate}}</div>
        <div class="friends" *ngIf="userFriendsLength === 0">{{isMine ? this.you : userName}} {{"PROFILE.USER_FRIENDS.NO_USER_FRIENDS" | translate}}</div>
        <div class="friends" *ngIf="userFriendsLength > 0">
          <div class="pictures" *ngIf="userFriends">
            <div class="image-wrapper" *ngFor="let friend of userFriends">
              <img class="avatar" src="{{friend.image ? baseURL+'small/'+replacer(friend.image) : 'assets/images/profile@3x.png'}}">
            </div>
            <div class="image-wrapper">
                <div class="avatar__show-more" (click)="showAllFriends()">
                <img src="assets/images/arrow-lg-right.png" *ngIf="userFriendsLength <= 5">
                {{userFriendsLength <= 5 ? "" : "+"+(userFriendsLength - 5)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="title">{{"PROFILE.ENDED_GAMES" | translate}}</div>
        <div class="ended-games" *ngIf="endedGames">
          <div class="game" *ngFor="let endedGame of endedGames" (click)="goToEndedGroup(endedGame.id)">
            <div class="game-image">
                <img src="{{endedGame.image ? baseURL+'small/'+replacer(endedGame.image) : 'assets/images/group-img.jpg'}}">
            </div>
            <div class="game-description">
              <p class="text">{{endedGame.name}}</p>
              <span class="game-date">{{endedGame.endDate | date}}</span>
              <a class="game-winner">{{endedGame.isWinner ? gameWinner : ''}}</a>
              <a class="game-winner">{{endedGame.isMVP ? 'MVP' : ''}}</a>
            </div>
          </div>
        </div>
        <div class="ended-games" *ngIf="!endedGames">
          {{isMine ? this.you : userName}} {{ "PROFILE.NO_GAMES" | translate }}
        </div>
        <p class="report" *ngIf="!isMine" (click)="toggleReportProfile()">{{"PROFILE.REPORT_PROFILE" | translate}}</p>
      </div>
      
      <div class="showReportProfile" *ngIf="showReportProfile">
        <div class="wrapper">
          <img class="close-button" (click)="toggleReportProfile()" 
                src="assets/images/cancel.png"
                srcset="assets/images/cancel@2x.png 2x,
                        assets/images/cancel@3x.png 3x">
          <span class="title">{{"PROFILE.REPORT_USERS" | translate}}</span>
          <p class="text">{{"PROFILE.REPORT_USERS.TEXT" | translate}}</p>
          <div class="field">
            <textarea #reportAnswer [rows]="4" placeholder="{{'PROFILE.TEXTAREA_PLACEHOLDER' | translate}}"></textarea>
          </div>
          <button class="button button__alt" (click)="sendReport(reportAnswer.value)">
              {{"PROFILE.REPORT" | translate}}
          </button>
          <button class="button button__alt transparent" (click)="toggleReportProfile()">
            {{"GLOBAL.UNDO" | translate}}
          </button>
      
        </div>
      </div>
</div>
