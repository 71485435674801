import { Component, OnInit } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';
import { UserModel } from 'src/app/models/user.model';
import { AppConstants } from '../../../../utils/app.constants';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-profile-friends',
	templateUrl: './profile-friends.component.html',
	styleUrls: [ './profile-friends.component.less' ]
})
export class ProfileFriendsComponent implements OnInit {
	baseURL = AppConstants.api.mediaFilesUrl;
	isPublicMode = false;
	passedValue = { showBackButton: true, scrollToTop: true };
	parentRouteId = null;
	uID: UserModel;
	userFriends;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private authService: AuthService,
		private stateService: StateService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: ApiUserProfileService,
		private loadingSpinnerService: LoadingSpinnerService,
		private passingDataService: PassingDataService
	) {}

	ngOnInit() {
		this.isPublicMode = !this.authService.isAuthorized();
		this.uID = this.authService.getUser();
		merge(this.route.params, this.route.data).pipe(debounceTime(1)).subscribe(() => {
			const route = this.route.snapshot;
			if (route.parent.params.id) {
				this.parentRouteId = +route.parent.params.id;
				this.getUserFriends(this.parentRouteId);
			} else {
				this.getUserFriends();
			}
			this.stateService.setRouteData(route.data);
			if (this.parentRouteId) {
				this.updatedBackButtonValue(this.parentRouteId);
			}
			/* this.stateService.setRouteData({ showBackButton: true }); */
		});
	}

	updatedBackButtonValue(id) {
		this.passingDataService.setRouteParamsFromChildComponent(id ? { id: id } : null);
	}

	getUserFriends(id = null) {
		this.loadingSpinnerService.show.next();
		this.updatedBackButtonValue(id);
		this.userService.getUserFriends(id).subscribe((data) => {
			this.userFriends = data.friends;
			this.loadingSpinnerService.hide.next();
		});
	}

	showSelectedFriend(id: any) {
		if (this.uID.id === id || this.uID.id === this.parentRouteId) {
			id = '';
		}
		/* this.updatedBackButtonValue(id); */

		this.router.navigate([ `home/profile/${id}` ]);
	}

	removeSelectedFriend(id: number) {
		this.userService.deleteUserFriend(id).subscribe(() => {
			this.getUserFriends();
		});
	}

	/* goBack() {
		this.routerInterceptorService.goBack(false);
	} */

	trackByItems(index: number, item: any): number {
		return Number(item.id);
	}
}
