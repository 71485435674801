export interface IPointsTransfer {
	popupType: string;
	totalPointsAmount: number;
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ApiPaymentService } from 'src/app/services/api/api-payment.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-points-transfer-popup',
	templateUrl: './points-transfer-popup.component.html',
	styleUrls: [ './points-transfer-popup.component.less' ]
})
export class PointsTransferPopupComponent implements OnInit, OnDestroy {
	@Input() popupData: IPointsTransfer;

	form: FormGroup;

	transferPoints$;
	subscription: Subscription = Subscription.EMPTY;

	constructor(
		private passingDataService: PassingDataService,
		private topNotificationsService: TopNotificationsService,
		private paymentService: ApiPaymentService,
		private errorsProcessorService: ErrorsProcessorService,
		private loadingSpinnerService: LoadingSpinnerService
	) {
		this.transferPoints$ = (amount) => this.paymentService.transferPoints(amount);
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			amount: new FormControl(
				null,
				Validators.compose([
					Validators.required,
					Validators.max(this.popupData.totalPointsAmount),
					Validators.min(300)
				])
			)
		});
		this.subscription.add(this.transferPoints$);
	}

	transferPointsToTheWallet() {
		if (this.form.valid) {
			this.loadingSpinnerService.show.next();
			this.transferPoints$(this.form.value.amount).subscribe(
				(httpResponse) => {
					this.closePopup();
				},
				(httpErrorResponse: HttpErrorResponse) => {
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
					this.loadingSpinnerService.hide.next();
				}
			);
		} else if (!this.form.value.amount || this.form.value.amount === '') {
			const control = this.form.get('amount');
			control.markAsDirty();
			control.markAllAsTouched();
			control.setErrors({ required: true });
		}
	}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
