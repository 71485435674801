<div class="static">
    <span class="wellcome">{{"AFTER_JOIN_GROUP_POPUP.WELLCOME" | translate}}</span>
    <span class="group-name break cancel">{{popupData.groupName}}</span>
    <span class="details">{{"AFTER_JOIN_GROUP_POPUP.DETAILS" | translate}}</span>
</div>
<div class="dynamic" [ngSwitch]="pageNumber">
    <ng-container *ngSwitchCase="1">
        <span class="title">{{"AFTER_JOIN_GROUP_POPUP.1.TITLE" | translate}}</span>
        <div class="ul-wrapper">
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.1.LI_1" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.1.LI_2" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.1.LI_3" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.1.LI_4" | translate}}</span>
            </div>
        </div>
        <a (click)="switchPage()" class="button button__alt">{{"AFTER_JOIN_GROUP_POPUP.1.ACTION_BTN" | translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>    
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <span class="title">{{"AFTER_JOIN_GROUP_POPUP.2.TITLE" | translate}}</span>
        <div class="ul-wrapper">
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.2.LI_1" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.2.LI_2" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.2.LI_3" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.2.LI_4" | translate}}</span>
            </div>
        </div>
        <a (click)="switchPage()" class="button button__alt">{{"AFTER_JOIN_GROUP_POPUP.2.ACTION_BTN" | translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <span class="title">{{"AFTER_JOIN_GROUP_POPUP.3.TITLE" | translate}}</span>
        <div class="ul-wrapper">
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.3.LI_1" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.3.LI_2" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.3.LI_3" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.4.LI_4" | translate}}</span>
            </div>
        </div>
        <a (click)="switchPage()" class="button button__alt">{{"AFTER_JOIN_GROUP_POPUP.3.ACTION_BTN" | translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
    </ng-container>
    <ng-container *ngSwitchCase="4">
        <span class="title">{{"AFTER_JOIN_GROUP_POPUP.4.TITLE" | translate}}</span>
        <div class="ul-wrapper">
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.4.LI_1" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.4.LI_2" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.4.LI_3" | translate}}</span>
            </div>
            <div class="ul">
                <img src="assets/images/li-bullet.svg">
                <span class="li">{{"AFTER_JOIN_GROUP_POPUP.4.LI_4" | translate}}</span>
            </div>
        </div>
        <a (click)="redirectToInviteFriends()" class="button button__alt no-mb">{{"AFTER_JOIN_GROUP_POPUP.4.ACTION_BTN" | translate}}</a>
        <div class="to-the-group" (click)="closePopup()" >{{"AFTER_JOIN_GROUP_POPUP.4.ACTION_BTN_2"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></div>
    </ng-container>
</div>