export class CommentModel {
  id: string;
  text: string;
  dateTime: string;
  userName: string;
  userImage: string;
  userCity: string;
  userId: number;
  amountOfLikes: number;
  userHasLikeComment: boolean;
  childCommentIds: number[];
  parentCommentId: number;
  amountOfSubcomments: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.text = data.text || '';
    this.dateTime = data.dateTime;
    this.userName = data.userName;
    this.userCity = data.userCity || '';
    this.userImage = data.userImage || null;
    this.userId = data.userId || null;
    this.amountOfLikes = data.amountOfLikes || 0;
    this.userHasLikeComment = data.userHasLikeComment || false;
    this.childCommentIds = data.childCommentIds || [];
    this.parentCommentId = data.parentCommentId || null;
    this.amountOfSubcomments = data.amountOfSubcomments || 0;
  }

  toPostJson() {
    return {
      text: this.text
    };
  }
}
