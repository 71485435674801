<div class="wrapper" [ngClass]="{'mini': !showMiniMenu}">
  <div class="container">
    <div class="how-it-works">
      <a class="button__back" (click)="goBack()">
        <img src="assets/images/arrow-lg-left.png"
              srcset="assets/images/arrow-lg-left@2x.png 2x,
              assets/images/arrow-lg-left@3x.png 3x"
              class="arrow-lg-left">
        <div class="title">{{"MAIN_MENU.ITEM_6" | translate}}</div>
      </a>
      <div class="accordion">
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">{{"HOW_IT_WORKS.ITEM_1.TITLE" | translate}}
            <img src="assets/images/Blue-arrow-right.png"
                  srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x"
                  class="endImg">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_1.TEXT.P1" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_1.TEXT.P2" | translate}}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">{{"HOW_IT_WORKS.ITEM_2.TITLE" | translate}}
            <img src="assets/images/Blue-arrow-right.png"
                  srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x"
                  class="endImg">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_2.TEXT.P1" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_2.TEXT.P2" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_2.TEXT.P3" | translate}}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">{{"HOW_IT_WORKS.ITEM_3.TITLE" | translate}}
            <img src="assets/images/Blue-arrow-right.png"
                  srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x"
                  class="endImg">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_3.TEXT.P1" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_3.TEXT.P2" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_3.TEXT.P3" | translate}}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">{{"HOW_IT_WORKS.ITEM_4.TITLE" | translate}}
            <img src="assets/images/Blue-arrow-right.png"
                  srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x"
                  class="endImg">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_4.TEXT" | translate}}</p>
          </div>
        </div>        
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">{{"HOW_IT_WORKS.ITEM_5.TITLE" | translate}}
            <img src="assets/images/Blue-arrow-right.png"
                  srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x"
                  class="endImg">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_5.TEXT.P1" | translate}}</p>
            <p class="entity-text">{{"HOW_IT_WORKS.ITEM_5.TEXT.P2" | translate}}</p>
          </div>
        </div>            
      </div>
    </div>
  </div>
</div>