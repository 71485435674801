import { Component, OnInit } from '@angular/core';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppLocalStorage } from '../../../utils/app.local.storage';
import { AppConstants } from '../../../utils/app.constants';
import { GroupModel } from 'src/app/models/group.model';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { ApiPaymentService } from 'src/app/services/api/api-payment.service';
import { concat } from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ApiLogsService, Log } from '../../../services/api/api-logs.service';
import { AuthService } from '../../../services/auth.service';

@Component({
	selector: 'app-pending',
	templateUrl: './pending.component.html',
	styleUrls: [ './pending.component.less' ]
})
export class PendingComponent implements OnInit {
	assetURL;
	group: GroupModel;
	groupId;
	inviteEntityFromService;
	inviteFriendToken;
	spinnerText;
	isPayWithPoints: boolean;

	addToGroupWithMoney$;
	addToGroupWithPoints$;
	/* getGroup$; */

	constructor(
		private topNotificationsService: TopNotificationsService,
		private errorsProcessorService: ErrorsProcessorService,
		private router: Router,
		private route: ActivatedRoute,
		private loadingSpinnerService: LoadingSpinnerService,
		private groupsApiService: ApiGroupsService,
		private translate: TranslateService,
		private passingDataServise: PassingDataService,
		private apiPaymentService: ApiPaymentService,
		private ga: GoogleAnalyticsService,
    private apiLogsService: ApiLogsService,
    private authService: AuthService,
	) {
		this.passingDataServise.inviteTokenForWebView$.subscribe((data) => (this.inviteEntityFromService = data));
		if (this.router.getCurrentNavigation()) {
			if (this.router.getCurrentNavigation().extras.state) {
				this.isPayWithPoints = this.router.getCurrentNavigation().extras.state.isPayWithPoints;
			} else {
				this.isPayWithPoints = null;
			}
		}

		this.addToGroupWithMoney$ = (groupId, token) => this.groupsApiService.addToGroup(groupId, token);
		this.addToGroupWithPoints$ = (groupId, token) =>
			this.apiPaymentService.payJoinGroupWithPoints(Number(groupId), token);
		/* this.getGroup$ = (groupId) => this.groupsApiService.getGroup(groupId); */

		this.translate
			.stream('SPINNER.LAST_STEP_TRUSTLY')
			.subscribe((data) => (this.isPayWithPoints ? '' : (this.spinnerText = data)));
	}

	ngOnInit() {
		this.loadingSpinnerService.show.next(this.spinnerText);
		this.groupId = this.route.snapshot.params.id;
		this.inviteFriendToken = this.checkInviteToken();
		AppLocalStorage.getObject(AppConstants.keys.defaultLanguage) === 'sv'
			? (this.assetURL = 'assets/images/register_weight_image.jpg')
			: (this.assetURL = 'assets/images/register_weight_image_en.png');

    console.log("PENDING");
    const user = this.authService.getUser();
    const log: Log = {
      operation: 'pending',
      userId: user.id,
      groupId: this.groupId,
      inviteToken: this.inviteFriendToken
    };
    this.apiLogsService.sendLog(log).subscribe((response) => {
      console.log(response);
    }, (error) => {
      console.log(error);
    });

		const subscription = [];
		if (this.isPayWithPoints) {
			subscription.push(this.addToGroupWithPoints$(this.groupId, this.inviteFriendToken));
		} else {
			subscription.push(this.addToGroupWithMoney$(this.groupId, this.inviteFriendToken));
		}
		/* subscription.push(this.getGroup$(this.groupId)); */

		concat(...subscription).subscribe(
			(data: GroupModel | any) => {
				/* if (data && data.name) {
					this.group = data;
				} else { */
				const action = this.isPayWithPoints ? 'joined-group-wallet' : 'joined-group-trustly';
				this.ga.eventEmitter(action, 'group-conversions', action);
				this.groupsApiService.dropAllCacheData(this.groupId);
				this.redirectIntoJoinedGroup();
				/* } */
			},
			(httpErrorResponse: HttpErrorResponse) => {
				this.errorHandle(httpErrorResponse);
			},
			() => {
				if (this.inviteFriendToken !== null) {
					this.ga.eventEmitter('joined-group-invite', 'invite-conversions', 'joined-group-invite');
					AppLocalStorage.remove(AppConstants.keys.invite);
				}
				this.loadingSpinnerService.hide.next();
			}
		);
	}

	checkInviteToken() {
		// We should check: is invite token belongs to this group
		const inviteEntity = AppLocalStorage.getObject(AppConstants.keys.invite)
			? AppLocalStorage.getObject(AppConstants.keys.invite)
			: null;

		// Check that stored token exists and it's belong to the current group
		let tokenMatchedToGroupId = null;
		let tokenFromUrl = null;
		if (this.route.snapshot.params.token) {
			tokenFromUrl = this.route.snapshot.params.token;
		}
		if (tokenFromUrl) {
			tokenMatchedToGroupId = tokenFromUrl;
		} else {
			if (inviteEntity) {
				if (Number(inviteEntity.groupId) === Number(this.groupId)) {
					tokenMatchedToGroupId = inviteEntity.token;
				}
			}
		}
		return tokenMatchedToGroupId;
	}

	redirectIntoJoinedGroup() {
		this.router.navigate([ `/home/groups/mine/group/${this.groupId}` ], {
			queryParams: { joinedNow: 'true' }
		});
	}

	errorHandle(gttpError: HttpErrorResponse) {
		const error = this.errorsProcessorService.process(gttpError);
		this.topNotificationsService.notify(error, 'error');
		this.loadingSpinnerService.hide.next();
		this.router.navigate([ '/home/groups/all/group/' + this.groupId ]);
	}
}
