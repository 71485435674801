import {nativeWindow} from './window.ref';

export function StorageDecorator(storageType: string) {
    return function (target: any) {

        target.add = (key: string, value: any) => {
            nativeWindow.instance[storageType].setItem(key, JSON.stringify(value));
        };

        target.get = (key: string) => {
            return nativeWindow.instance[storageType].getItem(key);
        };

        target.getObject = (key: string) => {
            return JSON.parse(nativeWindow.instance[storageType].getItem(key));
        };

        target.remove = (key: string) => {
            nativeWindow.instance[storageType].removeItem(key);
        };

        target.clear = () => {
          nativeWindow.instance[storageType].clear();
        };
    };
}
