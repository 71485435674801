import { Component, OnInit } from '@angular/core';
import { CompletedGroupModel } from 'src/app/models/completed-group.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';
import { RenderConfetti } from '../../../utils/confetti';

@Component({
	selector: 'app-finished-mvp',
	templateUrl: './finished-mvp.component.html',
	styleUrls: [ './finished-mvp.component.less' ]
})
export class FinishedMvpComponent implements OnInit {
	groupId: string;
	completedGroup: CompletedGroupModel;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiUserProfileService: ApiUserProfileService
	) {}

	ngOnInit() {
		this.groupId = this.route.snapshot.params.id;
		this.apiUserProfileService.getSingleCompletedGame(this.groupId).subscribe(
			(data) => {
				this.completedGroup = data;
				if (this.completedGroup.isMVP || this.completedGroup.isMVP2) {
					RenderConfetti();
				} else {
					this.goToAllGroups();
				}
			},
			(error) => {
				if (error.status === 404) {
					this.goToAllGroups();
				}
			}
		);
	}

	goToAllGroups() {
		this.router.navigate([ 'home/groups/all' ]);
	}
}
