import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule, Guards } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LandingComponent } from './components/landing/landing.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpAuthInterceptor } from './interceptors/http-auth.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { ApiAccountService } from './services/api/api-account.service';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/_layout/header/header.component';
import { GroupsComponent } from './components/groups/groups.component';
import { ApiGroupsService } from './services/api/api-groups.service';
import { ApiMediaFilesService } from './services/api/api-media-files.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import { TopNotificationsComponent } from './components/_layout/top-notifications/top-notifications.component';
import { TopNotificationsService } from './services/top-notifications.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MainMenuComponent } from './components/_layout/main-menu/main-menu.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GroupFiltersComponent } from './components/_layout/group-filters/group-filters.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import * as Hammer from 'hammerjs';
import { CordovaService } from './services/cordova.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { ErrorsProcessorService } from './services/errors-processor.service';
import { PwaUpdateService } from './services/pwa-update.service';
import { GroupComponent } from './components/group/group.component';
import { StateService } from './services/state.service';
import { GroupInfoComponent } from './components/_layout/group-info/group-info.component';
import { GroupsDropdownComponent } from './components/_layout/groups-dropdown/groups-dropdown.component';
import { ScrollTopButtonComponent } from './components/_layout/scroll-top-button/scroll-top-button.component';
import { ApiMeasurementService } from './services/api/api-measurement.service';
import { MeasurementService } from './services/measurement.service';
import { RouterInterceptorService } from './services/router-interceptor.service';
import { PaymentComponent } from './components/payment/payment.component';
import { ApiPaymentService } from './services/api/api-payment.service';
import { UnauthorizedJoinGroupComponent } from './components/unauthorized-join-group/unauthorized-join-group.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileInfoComponent } from './components/_layout/profile-info/profile-info.component';
import { ProfilePicturesComponent } from './components/_layout/profile-info/profile-pictures/profile-pictures.component';
import { ProfileFriendsComponent } from './components/_layout/profile-info/profile-friends/profile-friends.component';
import { GroupFeedComponent } from './components/_layout/group-feed/group-feed.component';
import { GroupPostComponent } from './components/_layout/group-post/group-post.component';
import { ApiFeedService } from './services/api/api-feed.service';
import { ApiUserProfileService } from './services/api/api-user-profile.service';
import { PayoutComponent } from './components/payout/payout.component';
import { ChartsModule } from 'ng2-charts';
import { NotificationMenuComponent } from './components/_layout/notification-menu/notification-menu.component';
import { FinishedGroupComponent } from './components/finished-group/finished-group.component';
import { ProfileStatisticsComponent } from './components/_layout/profile-statistics/profile-statistics.component';
import { GroupStatisticsComponent } from './components/_layout/group-statistics/group-statistics.component';
import { GroupLineChartComponent } from './components/_layout/group-statistics/group-line-chart/group-line-chart.component';
import { GroupDoughnutChartComponent } from './components/_layout/group-statistics/group-doughnut-chart/group-doughnut-chart.component';
import { ProfileDoughnutChartComponent } from './components/_layout/profile-statistics/profile-doughnut-chart/profile-doughnut-chart.component';
import { ProfileLineChartComponent } from './components/_layout/profile-statistics/profile-line-chart/profile-line-chart.component';
import { FacebookComponent } from './components/facebook/facebook.component';
import { HttpLoaderFactory, SharedModule } from './shared-module/shared.module';
import { InvitesComponent } from './components/invites/invites.component';
import { PendingComponent } from './components/payment/pending/pending.component';
import { FinishedMvpComponent } from './components/_layout/finished-mvp/finished-mvp.component';
import { LogoutComponent } from './components/_layout/logout/logout.component';
import { TimeAgoComponent } from './components/time-ago/time-ago.component';
import { MentionModule } from 'angular-mentions';
import { SubcommentsComponent } from './components/_layout/group-post/subcomments/subcomments.component';
import { GroupTeamsComponent } from './components/_layout/group-teams/group-teams.component';
import { TeamsListHeaderComponent } from './components/_layout/group-teams/teams-list-header/teams-list-header.component';
import { TeamsListComponent } from './components/_layout/group-teams/teams-list/teams-list.component';
import { TeamsListFooterComponent } from './components/_layout/group-teams/teams-list-footer/teams-list-footer.component';
import { TeamsBottomFixedMenuComponent } from './components/_layout/group-teams/teams-bottom-fixed-menu/teams-bottom-fixed-menu.component';
import { TeamStatisticsTabsComponent } from './components/_layout/group-statistics/team-statistics-tabs/team-statistics-tabs.component';
import { GroupStatisticsMyTeamComponent } from './components/_layout/group-statistics/team-statistics-tabs/group-statistics-my-team/group-statistics-my-team.component';
import { GroupStatisticsTeamStatisticsComponent } from './components/_layout/group-statistics/team-statistics-tabs/group-statistics-team-statistics/group-statistics-team-statistics.component';
import { TeamsSuccessRequestSentComponent } from './components/_layout/group-teams/teams-success-request-sent/teams-success-request-sent.component';
import { BottomMenuComponent } from './components/bottom-menu/bottom-menu.component';
import { HomePopupsComponent } from './components/home/home-popups/home-popups.component';
import { InviteToGroupPopupComponent } from './components/home/home-popups/invite-to-group-popup/invite-to-group-popup.component';
import { AfterJoinGroupPopupComponent } from './components/home/home-popups/after-join-group-popup/after-join-group-popup.component';
import { PointsTransferPopupComponent } from './components/home/home-popups/points-transfer-popup/points-transfer-popup.component';
import { WalletPayoutPopupComponent } from './components/home/home-popups/wallet-payout-popup/wallet-payout-popup.component';
import { ParticipantsPopupComponent } from './components/home/home-popups/participants-popup/participants-popup.component';
import { DiscountCodesPopupComponent } from './components/home/home-popups/discount-codes-popup/discount-codes-popup.component';
import { StartWeightInfoPopupComponent } from './components/home/home-popups/start-weight-info-popup/start-weight-info-popup.component';
import { ShowPaymentConfirmationPopupComponent } from './components/home/home-popups/show-payment-confirmation-popup/show-payment-confirmation-popup.component';
import { TeamMembersSizePopupComponent } from './components/home/home-popups/team-members-size-popup/team-members-size-popup.component';
import { ApiLogsService } from './services/api/api-logs.service';

@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
	overrides = {
		swipe: { direction: Hammer.DIRECTION_ALL }
	};
	buildHammer(element: HTMLElement) {
		const mc = new Hammer(element, {
			touchAction: 'pan-y'
		});
		return mc;
	}
}

@NgModule({
	declarations: [
		AppComponent,
		LandingComponent,
		HomeComponent,
		HeaderComponent,
		GroupsComponent,
		TopNotificationsComponent,
		MainMenuComponent,
		ClickOutsideDirective,
		GroupFiltersComponent,
		InputTrimDirective,
		InfiniteScrollDirective,
		GroupComponent,
		GroupInfoComponent,
		GroupsDropdownComponent,
		ScrollTopButtonComponent,
		PaymentComponent,
		UnauthorizedJoinGroupComponent,
		ProfileComponent,
		ProfileInfoComponent,
		ProfilePicturesComponent,
		ProfileFriendsComponent,
		GroupFeedComponent,
		GroupPostComponent,
		PayoutComponent,
		NotificationMenuComponent,
		FinishedGroupComponent,
		ProfileStatisticsComponent,
		GroupStatisticsComponent,
		GroupLineChartComponent,
		GroupDoughnutChartComponent,
		ProfileDoughnutChartComponent,
		ProfileLineChartComponent,
		FacebookComponent,
		InvitesComponent,
		PendingComponent,
		FinishedMvpComponent,
		LogoutComponent,
		TimeAgoComponent,
		SubcommentsComponent,
		GroupTeamsComponent,
		TeamsListHeaderComponent,
		TeamsListComponent,
		TeamsListFooterComponent,
		TeamsBottomFixedMenuComponent,
		TeamStatisticsTabsComponent,
		GroupStatisticsMyTeamComponent,
		GroupStatisticsTeamStatisticsComponent,
		TeamsSuccessRequestSentComponent,
		BottomMenuComponent,
		HomePopupsComponent,
		InviteToGroupPopupComponent,
		AfterJoinGroupPopupComponent,
		PointsTransferPopupComponent,
		WalletPayoutPopupComponent,
		ParticipantsPopupComponent,
		DiscountCodesPopupComponent,
		StartWeightInfoPopupComponent,
		ShowPaymentConfirmationPopupComponent,
		TeamMembersSizePopupComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ HttpClient ]
			}
		}),
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		ChartsModule,
		MentionModule,
		HammerModule
	],
	providers: [
		Guards,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpAuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: AppHammerConfig
		},
		CordovaService,
		ApiAccountService,
		ApiGroupsService,
		ApiMediaFilesService,
		LoadingSpinnerService,
		TopNotificationsService,
		ErrorsProcessorService,
		PwaUpdateService,
		StateService,
		ApiMeasurementService,
		MeasurementService,
		RouterInterceptorService,
		ApiPaymentService,
		ApiFeedService,
		ApiUserProfileService,
    ApiLogsService
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
