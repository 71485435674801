import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, ElementRef } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-profile-line-chart',
  templateUrl: './profile-line-chart.component.html',
  styleUrls: ['./profile-line-chart.component.less']
})
export class ProfileLineChartComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() chartData;

  public lineChartData: ChartDataSets[] = [
    { data: [], label: '' }
  ];
  public lineChartLabels: Label[];
  public lineChartOptions: (ChartOptions) = {};
  public lineChartColors: Color[] = [{}];
  public lineChartLegend = false;
  public lineChartType = 'line';

  @ViewChild('lineChartCanvas', { static: true }) canvas: ElementRef;
  public gradient;
  public minWeight = 0;
  public maxWeight = 0;

  constructor() { }

  ngOnInit() {
    this.getStats();
  }

  ngAfterViewInit() {
    this.gradient = this.canvas.nativeElement.getContext('2d')
                    .createLinearGradient(0, 0, 0, this.canvas.nativeElement.offsetHeight / 1.25);
    this.gradient.addColorStop(0, 'rgba(30, 198, 193, 0.3)');
    this.gradient.addColorStop(1, 'rgba(30, 198, 193, 0.0)');
    this.lineChartColors = [
      {
        backgroundColor: this.gradient,
        borderColor: 'rgba(30, 198, 193, 1)',
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(30, 198, 193, 1.0)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(30, 198, 193, 1.0)'
      }
    ];
}

  ngOnChanges() {
    this.getStats();
  }

  getStats() {
    if (this.chartData) {
      const lineChartData: ChartDataSets[] = new Array();
      const lineChartLabels: Label[] = new Array();
      const valuesArray = [];
      lineChartData[0] = { data: new Array(), label: '' };
      for (let i = 0; i < this.chartData.length; i++) {
        lineChartData[0].data[i] = this.chartData[i].value;
        lineChartLabels.push(this.chartData[i].date.substr(0, this.chartData[i].date.length - 17).substr(5).replace(/-/, '/'));
        valuesArray.push(this.chartData[i].value);
      }
      this.lineChartData = lineChartData;
      this.minWeight = this.roundToFive(Math.min(...valuesArray));
      this.maxWeight = this.roundToFive(Math.max(...valuesArray));
      this.lineChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: '#846e6e'
            }
          }],
          yAxes: [
            {
              id: 'y-axis-0',
              position: 'left',
              gridLines: {
                display: true,
                color: 'rgba(226, 233, 241, 0.5)',
                lineWidth: 0.5
              },
              ticks: {
                fontColor: '#1ec6c1',
                min: this.minWeight - 5,
                max: this.maxWeight + 5,
                stepSize: 5
              }
            }
          ]
        }
      };
      setTimeout(() => { this.lineChartLabels = lineChartLabels; }, 0); // hack to update chartLabels dinamiclly
    }
  }

  roundToFive(number) {
    return Math.round(number / 5) * 5;
  }

}
