import { Injectable } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppLocalStorage } from '../utils/app.local.storage';
import { AppConstants } from '../utils/app.constants';
import { UrlParcer } from '../utils/url-parcer';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from './state.service';
import { PassingDataService } from './passing-data.service';

@Injectable()
export class RouterInterceptorService {
	private _previousUrl: string;
	private _previousUrlIndex: number;
	private _currentUrl: string;
	private _routeHistory: string[];
	private _userId = null;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private translateService: TranslateService,
		private stateService: StateService,
		private passingDataService: PassingDataService
	) {
		this._routeHistory = [];
		this._previousUrlIndex = this.getPreviousUrlIndex();
		if (AppLocalStorage.getObject(AppConstants.keys.authResponse)) {
			AppLocalStorage.getObject(AppConstants.keys.authResponse).id
				? (this._userId = AppLocalStorage.getObject(AppConstants.keys.authResponse).id)
				: (this._userId = null);
		}
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			// Added if statement (the same removed from group component on normalizeLocalStorage()) with
			// exeptions that shouldn't be added to URLs
			if (
				!event.urlAfterRedirects.match('payment') &&
				!event.urlAfterRedirects.match('pending') &&
				!event.urlAfterRedirects.match('edit') &&
				!event.urlAfterRedirects.match('invite') &&
				!event.urlAfterRedirects.match('invites') &&
				!event.urlAfterRedirects.match('create-team') &&
				!event.urlAfterRedirects.match('edit-team') &&
				!event.urlAfterRedirects.match('logout') &&
				!event.urlAfterRedirects.match('team-join-requests')
			) {
				if (this._routeHistory[this._routeHistory.length - 1] !== event.urlAfterRedirects) {
					this._setURLs(event);
				} else {
					if (this._routeHistory.length <= 1) {
						// replacing backbutton with menu button
						this.stateService.setRouteData({ showBackButton: false });
					}
				}
			}
		});

		this.router.events
			.pipe(
				// filtering through NavigationEnd event
				filter((event) => event instanceof NavigationEnd),
				// switching stream to activatedRoute service
				map(() => this.activatedRoute),
				// we should get only parent routes (this is optional)
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				// also we get only primary outlets (this is optional)
				filter((route) => route.outlet === 'primary'),
				// choosing title
				mergeMap((route) => route.data)
			)
			// sets title
			.subscribe(
				(stateTitle) =>
					stateTitle.title
						? this.translateService.stream(stateTitle.title).subscribe((title) => {
								if (title) {
									this.titleService.setTitle(`Joina | ${title}`);
								} else {
									this.titleService.setTitle('Joina');
								}
							})
						: this.titleService.setTitle('Joina')
			);
	}

	private _setURLs(event: NavigationEnd): void {
		this._currentUrl = !this._currentUrl && this._routeHistory.length ? event.urlAfterRedirects : this._currentUrl;
		this._currentUrl = event.urlAfterRedirects;
		this._routeHistory.push(event.urlAfterRedirects);
		if (this._routeHistory.length > 10) {
			this._routeHistory.shift();
		}
		this._previousUrlIndex = this.getPreviousUrlIndex();
		AppLocalStorage.add(AppConstants.keys.routerHistory, this._routeHistory);
	}

	get previousUrl(): string {
		return this._previousUrl;
	}

	get currentUrl(): string {
		return this._currentUrl;
	}

	get routeHistory(): string[] {
		return this._routeHistory;
	}

	private getPreviousUrlIndex() {
		return this._routeHistory.length - 1 - 1;
	}

	public setCustomPreviousUrl(url: string) {
		this._routeHistory.push(url);
		if (this._routeHistory.length > 10) {
			this._routeHistory.shift();
		}
		this._previousUrlIndex = this.getPreviousUrlIndex();
		AppLocalStorage.add(AppConstants.keys.routerHistory, this._routeHistory);
	}

	public deleteLastPreviousUrls(number: number) {
		if (this._routeHistory && this._routeHistory.length >= number) {
			this._routeHistory.length = this._routeHistory.length - number;
		}
	}

	goBack(toGroup?: boolean) {
		if (toGroup) {
			this.router.navigate([ '/home/groups/all' ]);
			return;
		}
		// BUG: try to fix bug with back button
		// Redirect user back to group from group invite page
		if (this.router.url.match('/invite')) {
			const currentUrl = this.router.url.split('/');
			let backUrlToGroup = '';
			for (let i = 0; i < currentUrl.length - 1; i++) {
				backUrlToGroup += currentUrl[i] + '/';
			}
			this.router.navigate([ backUrlToGroup ]);
			return;
		}

		/* // Redirect user back to mine group list from my group
		if (this.router.url.match('/mine/')) {
			this.router.navigate([ '/home/groups/mine' ]);
			return;
		}

		// Redirect user back to all group list from NON my group
		if (this.router.url.match('/groups/all/group/')) {
			this.router.navigate([ '/home/groups/all' ]);
			return;
		} */

		if (this.router.url.match('/profile/friends')) {
			this.router.navigate([ '/home/profile' ]);
		}

		if (this.router.url.match('/create-group/step2')) {
			this.router.navigate([ '/create-group/step1' ]);
		}
		if (this.router.url.match('/profile') && this.router.url.match('/pictures')) {
			this.stateService.setRouteData({
				showBackButton: this._routeHistory[this._previousUrlIndex] === '/home/profile' ? false : true,
				scrollToTop: true
			});
		}

		/* this.stateService.setRouteData({ showBackButton: shouldBackButtonBeShown }); */
		if (this.router.url.match('/profile/') && this.router.url.match('/friends')) {
			this.passingDataService.routeParamsFromChildComponent$
				.subscribe((parentRouteParams) => {
					this.stateService.setRouteData({
						showBackButton: parentRouteParams && parentRouteParams.id,
						scrollToTop: true
					});
					this.router.navigate([ `/home/profile/${parentRouteParams ? parentRouteParams.id : ''}` ]);
				})
				.unsubscribe();
		}

		// If first page don't have where to go back - back to all groups
		if (this._routeHistory.length === 1) {
			this.router.navigate([ '/home/groups/all' ]);
			return;
		}
		let route = this._routeHistory[this._previousUrlIndex];
		this._previousUrlIndex--;
		this._routeHistory.pop();
		this._currentUrl = route;
		if (route) {
			const queryParamsIndexStart = route.indexOf('?');
			if (queryParamsIndexStart > 0) {
				const queryParams = UrlParcer.getJsonFromUrl(route);
				route = route.substring(0, queryParamsIndexStart);
				this.router.navigate([ route ], { queryParamsHandling: 'merge', queryParams: queryParams });
			} else {
				this.router.navigate([ route ]);
			}
		}
	}
}
