import {
	Component,
	Input,
	OnInit,
	ViewChild,
	ElementRef,
	Renderer2,
	Output,
	OnDestroy,
	ComponentFactoryResolver,
	ViewContainerRef
} from '@angular/core';
import { ApiFeedService } from '../../../services/api/api-feed.service';
import { PostModel } from '../../../models/post.model';
import { FormControl, FormGroup, Validators, RequiredValidator } from '@angular/forms';
import { FormHelper } from '../../../utils/form-helper';
import { FileInputDisplayTypes } from '../../../enums/FileInputDisplayTypes.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TopNotificationsService } from '../../../services/top-notifications.service';
import { ErrorsProcessorService } from '../../../services/errors-processor.service';
import { RolesComponent } from '../../_abstract/roles.component';
import { ApiGroupsService } from '../../../services/api/api-groups.service';
import { GetMentionUsersService } from 'src/app/services/get-mention-users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { Subscription, concat } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PostFileInputComponent } from '../post-file-input/post-file-input.component';
import { PostMediaModel } from 'src/app/models/posts-media.model';
import { FormLengthValidator } from 'src/app/utils/forms-length-validator';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-group-feed',
	templateUrl: './group-feed.component.html',
	styleUrls: [ './group-feed.component.less' ],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					overflow: 'hidden',
					height: '*'
				})
			),
			state(
				'out',
				style({
					opacity: '0',
					overflow: 'hidden',
					height: '0px'
				})
			),
			transition('in => out', animate('400ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out'))
		])
	],
	entryComponents: [ PostFileInputComponent ]
})
export class GroupFeedComponent extends RolesComponent implements OnInit, OnDestroy {
	@Input()
	set commentId(commentId: string) {
		commentId ? (this._commentId = commentId) : (this._commentId = null);
	}
	@Input()
	set groupId(groupId: string) {
		if (groupId) {
			this._groupId = groupId;
		}
	}
	@Input()
	set postId(postId: string) {
		postId ? (this._postId = postId) : (this._postId = null);
	}

	@Input() isOnIOSDevice;
	@Input() isIOSSafari;
	@Input() isCanJoin;
	@Input() isInvited;
	@Input() isMineGroup: boolean;
	@Input() isGroupParticipant: boolean;
	@Input() teamId: number | null;
  @Input() isCreator: boolean;
  @Input() isGroupStarted: boolean;


	@Output() showSelectedCityParicipants = new EventEmitter();

	@ViewChild('admin') admin: ElementRef;
	@ViewChild('all') all: ElementRef;
	@ViewChild('friends') friends: ElementRef;
	@ViewChild('influencer') influencer: ElementRef;
	@ViewChild('my') my: ElementRef;
	@ViewChild('favorites') favorites: ElementRef;
	@ViewChild('teammates') teammates: ElementRef;
	@ViewChild('teamPrivate') teamPrivate: ElementRef;
	@ViewChild('scrollToComposePrivatePost') scrollToComposePrivatePost: ElementRef;

	@ViewChild('postInputFile', { read: ViewContainerRef })
	vc: ViewContainerRef;

	shouldEraseUlopadedData = new EventEmitter();

	_commentId: string;
	_groupId: string;
	_postId: string;

	currentFilterValue = '';
	currentUser = {
		image: '',
		name: '',
		role: ''
	};
	disableButton = false;
	expandForm = false;
	FileInputDisplayTypes = FileInputDisplayTypes;
	form: FormGroup;
	isAuth = false;
	isFormOpen = 'out';
	isLoading = true;
	isMiniFeedFilter = false;
	isMiniFeedFilterOpened = 'out';
	isMiniFeedFilterPopUp = false;
	isPostSending = false;
	loadedDataLength: number;
	maxMediaItems = 5;
	mediaItems = [];
	mentionUsers: string[] = [];
	postLength = FormLengthValidator.postLength;
	posts: PostModel[];
	restPosts: PostModel[];
	postsMediaItems: PostMediaModel[];
	postsUpdatedMediaItems: PostMediaModel[];
	redirectCase = false;
	routeSnapshot;
	showAllPosts = false;
	needScroll = false;
  filter = 'all';
	queryParams = {
		page: 1,
		pageSize: 10, // number of posts to fetch in one payload
		showprivate: true // new parameter to add ability to see private posts (feature added 09.2020)
	};
	// Subscriptions
	feedData$: Subscription = Subscription.EMPTY;
	scrollToComposePrivatePost$: Subscription = Subscription.EMPTY;
	updateFeed$: Subscription = Subscription.EMPTY;

	get addMediaButtonText() {
		return this.mediaItems.length > 0 && this.mediaItems.length < this.maxMediaItems
			? 'BUTTON.ADD_MORE_MEDIA'
			: 'BUTTON.ADD_MEDIA';
	}
	get canUserSavePost() {
		return (
			(this.form.value['text'] && this.form.value['text'].length <= this.postLength) ||
			(this.postsMediaItems.length > 0 && this.postsMediaItems.length <= this.maxMediaItems)
		);
	}
	get feedFilterValue() {
	  this.hideActivefilter();
		return this.currentFilterValue;
	}

	constructor(
		private auth: AuthService,
		private errorsProcessorService: ErrorsProcessorService,
		private feedApiService: ApiFeedService,
		private groupService: ApiGroupsService,
		private mentionService: GetMentionUsersService,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private router: Router,
		private topNotificationsService: TopNotificationsService,
		private translate: TranslateService,
		private factory: ComponentFactoryResolver,
		private passingDataService: PassingDataService,
		private ga: GoogleAnalyticsService
	) {
		super(auth);
		if (this.auth.isAuthorized()) {
			this.currentUser = this.auth.getUser();
			this.isAuth = true;
		}
		this.routeSnapshot = this.route.snapshot;
	}

	ngOnInit() {
		this.posts = [];
		if (window.innerWidth <= 450) {
			this.isMiniFeedFilter = true;
			this.translate.stream('GROUP.FEED.FILTER_ALL').subscribe((data) => (this.currentFilterValue = data));
		}
		if (this.isAuth) {
			this.updateFeed$ = this.groupService.updateFeed$.subscribe((groupId) => {
				this.getPosts(groupId);
			});
			this.redirectCase = this.routeSnapshot.params.post || this.routeSnapshot.params.comment || false;
			this.init();
		} else {
			this.isLoading = false;
		}
	}

	emitCityToGroupComponent(cityName) {
		if (cityName) {
			this.showSelectedCityParicipants.emit(cityName);
		}
	}

	getMentionUsers() {
		if (this.isAuth) {
			this.mentionService.getMentionUsersList().subscribe(
				(users) => {
					this.mentionUsers = [];
					if (users.length > 0) {
						users.forEach((user) => {
							this.mentionUsers.push(user.name);
						});
					}
				},
				() => {}
			);
		} else {
			this.mentionUsers = [];
		}
	}

	formatSelection(selection): string {
		return '<@' + selection.label + '>';
	}

	disableButtonToggler(eventName: string) {
		if (eventName === 'opened') {
			this.expandForm = true;
			this.disableButton = true;
		} else {
			this.expandForm = false;
			setTimeout(() => {
				this.disableButton = false;
			}, 200);
		}
	}

	shouldGetPosts() {
		return (
			((this.isAuth && this.isCanJoin) || this.isAdmin || this.isMineGroup) && !this._postId && !this._commentId
		);
	}
	shouldGetCurrentPost() {
		return (
			((this.isAuth && this.isCanJoin) || this.isAdmin || this.isMineGroup) && this._postId && !this._commentId
		);
	}
	shouldGetCurrentPostWithCurrentComment() {
		return ((this.isAuth && this.isCanJoin) || this.isAdmin || this.isMineGroup) && this._postId && this._commentId;
	}

	init() {
		this.isLoading = true;
		if (this.shouldGetPosts()) {
			this.getPosts();
		} else if (this.shouldGetCurrentPost()) {
      this.needScroll = true;
      // this.getPosts();
			this.getCurrentPost(this._postId);
		} else if (this.shouldGetCurrentPostWithCurrentComment()) {
      this.needScroll = true;
      // this.getPosts();
			this.getCurrentPost(this._postId /* , this._commentId */);
		} else {
			this.isLoading = false;
		}

		if (this.isMineGroup || this.isAdmin) {
			this.initForm();
		}
	}

  scrollToPost() {
    setTimeout(() => {
      if (this._commentId) {
        const commentElement = window.document.getElementById(`commentId-${this._commentId}`);
        if (commentElement) {
          commentElement.scrollIntoView({block: 'center', behavior: 'smooth' });
        } else {
          document.getElementById(`postId-${this._postId}`).scrollIntoView({block: 'start', behavior: 'smooth' });
        }
      } else {
        document.getElementById(`postId-${this._postId}`).scrollIntoView({block: 'start', behavior: 'smooth' });
      }
    }, 500);
  }

	getCurrentPost(postId) {
		this._postId = postId;
		this.isLoading = true;
		this.posts = [];
		// this.feedData$ = this.feedApiService.getCurrentPost(this._groupId, this._postId).subscribe(
    // (post: PostModel) => {
    this.feedData$ = this.feedApiService.getCurrentPostWithHistory(this._groupId, this._postId).subscribe(
    (posts: PostModel[]) => {
        if (posts.length > 10) {
          this.restPosts = posts.slice(0, Math.max(posts.length - 10, 0));
          this.posts = posts.slice(Math.max(posts.length - 10, 0));
        } else {
          this.posts = posts;
        }
				this.loadedDataLength = this.queryParams.pageSize;
				this.queryParams.page = Math.floor(posts.length / 10);
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				// this.showAllPosts = true;
				this.isLoading = false;
			},
			() => {
				this.getMentionUsers();
				// this.showAllPosts = true;
				this.isLoading = false;
				this.scrollToPost();
			}
		);
	}

	makeUniquePostsArray(postsArray: PostModel[]) {
		const unique = [];
		const distinct = [];
		for (let i = 0; i < postsArray.length; i++) {
			if (!unique[postsArray[i].id]) {
				distinct.push(postsArray[i]);
				unique[postsArray[i].id] = 1;
			}
		}
		return distinct.length > 0 ? distinct : [];
	}

  showRestPosts() {
	  const lastTen = this.restPosts.splice(this.restPosts.length - 10, 10);
    this.posts = lastTen.concat(this.posts);
  }

	getPosts(groupId?) {
		if (groupId) {
			this._groupId = groupId;
		}
		this.isLoading = true;
		if (
			Object.keys(this.queryParams).length === 3 ||
			(Object.keys(this.queryParams).length >= 4 && this.queryParams['all'] === 'true')
		) {
			let allPosts = [];
			let isPosts = 0;
			this.feedData$ = concat(
				this.feedApiService.getPinnedPosts(this._groupId),
				this.feedApiService.getPosts(this._groupId, this.queryParams)
			).subscribe(
				(data) => {
					if (!!isPosts) this.loadedDataLength = data.length || 0;
					allPosts.push(...data);
					isPosts++;
				},
				(httpErrorResponse: HttpErrorResponse) => {
					this.isLoading = false;
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
				},
				() => {
					this.posts = this.makeUniquePostsArray(allPosts);
					this.isLoading = false;
					this.getMentionUsers();
					this.checkSendPrivatePost();
          if ((this.shouldGetCurrentPost() || this.shouldGetCurrentPostWithCurrentComment()) && this.needScroll) {
            this.needScroll = false;
            this.scrollToPost();
          }
				}
			);
		} else {
			this.feedData$ = this.feedApiService.getPosts(this._groupId, this.queryParams).subscribe(
				(posts: PostModel[]) => {
					this.posts = posts;
					this.loadedDataLength = posts.length || 0;
				},
				(httpErrorResponse: HttpErrorResponse) => {
					this.isLoading = false;
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
				},
				() => {
					this.isLoading = false;
					this.getMentionUsers();
					this.checkSendPrivatePost();
          if ((this.shouldGetCurrentPost() || this.shouldGetCurrentPostWithCurrentComment()) && this.needScroll) {
            this.needScroll = false;
            this.scrollToPost();
          }
				}
			);
		}
	}

	checkSendPrivatePost() {
		this.scrollToComposePrivatePost$ = this.passingDataService.scrollToComposePrivatePost$.subscribe((payload) => {
			if (payload.anchor === '.header') {
				setTimeout(() => {
					this.isFormOpen = 'in';
					this.initForm(true);
					this.scrollToComposePrivatePost.nativeElement.scrollIntoView({ behavior: 'smooth' });
				}, 500);
			}
		});
	}

	initForm(isPrivatePreselected = false) {
		this.mediaItems.length = 0;
		this.postsMediaItems = [];
		this.form = new FormGroup({
			text: new FormControl(null, Validators.compose([ Validators.maxLength(this.postLength) ])),
			teamPrivatePost: new FormControl(isPrivatePreselected ? true : false)
		});
	}

	addMoreMedia() {
		const factory = this.factory.resolveComponentFactory(PostFileInputComponent);
		const componentRef = this.vc.createComponent(factory);
		componentRef.instance.filesCounter = this.mediaItems.length;
		componentRef.instance.type = FileInputDisplayTypes.PostMedia;
		componentRef.instance.accept = 'image/*, video/*';
		componentRef.instance.isIOSSafari = this.isIOSSafari;
		componentRef.instance.isOnIOSDevice = this.isOnIOSDevice;

		// Subscribtion on eventEmitter to kill each dinammically created component
		componentRef.instance.autoDestroy.subscribe(() => componentRef.destroy());
		// Subscription for get uploaded filenames
		componentRef.instance.uploadedFileName.subscribe((data) => {
			const postsMediaItemsLength = this.postsMediaItems.length;
			if (postsMediaItemsLength > 0) {
				for (let i = 0; i <= postsMediaItemsLength - 1; i++) {
					// if media items already got filename with current order number and passed filename is empty string
					// we should delete item from array
					if (this.postsMediaItems[i].order === data.order && data.fileName === '') {
						this.postsMediaItems.splice(i, 1);
						return;
					}
					// if media items already got filename with current order number and passed filename is NOT empty string
					// we shoulf update item in array
					if (this.postsMediaItems[i].order === data.order && data.fileName) {
						this.postsMediaItems[i] = data;
						return;
					}
					// we have passed all conditions - so just push new item into the array
					if (i === postsMediaItemsLength - 1) {
						this.postsMediaItems.push(data);
					}
				}
			} else {
				this.postsMediaItems.push(data);
			}
		});
		this.mediaItems.length++;
	}

	uploadedFileTypeCheck(fileName: string): boolean {
		return fileName.split('.')[1].includes('jpeg');
	}

	sendPost() {
		if (!this.form.valid) {
			FormHelper.markFormGroupTouched(this.form);
			return;
		}
		const post = new PostModel();
		post.text = this.form.value.text;
		post.userName = this.currentUser.name;
		post.userImage = this.currentUser.image;
		post.image = null;
		post.video = null;
		post.media = [];
		post.team = this.form.value.teamPrivatePost ? this.teamId : null;
		let order = 0;
		for (let i = 0; i <= this.postsMediaItems.length - 1; i++) {
			if (this.postsMediaItems[i].fileName) {
				this.postsMediaItems[i].order = order;
				post.media.push(this.postsMediaItems[i]);
				order++;
			}
		}
		if (post.media && !post.image) {
			for (let i = 0; i <= post.media.length - 1; i++) {
				if (post.media[i].contentType === 'image' && !post.image) {
					post.image = post.media[i].fileName;
				}
			}
		}
		if (post.media && !post.video) {
			for (let i = 0; i <= post.media.length - 1; i++) {
				if (post.media[i].contentType === 'video' && !post.video) {
					post.video = post.media[i].fileName;
				}
			}
		}

		this.isPostSending = true;
		this.feedApiService.sendPost(this._groupId, post).subscribe(
			(data: PostModel) => {
				/*  */
				const action = post.team ? 'post-private' : 'post-public';
				this.ga.eventEmitter(action, 'group-engagement', action);
				/*  */
				if (this.redirectCase) {
					this.redirectUserMainGroupsFeed();
				}
				post.id = data.id;
				this.cancel();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				this.feedApiService.updateShouldEraseUlopadedData();
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.isPostSending = false;
			},
			() => {
				this.init();
				this.isPostSending = false;
			}
		);
	}

	toggleForm() {
		this.isFormOpen = this.isFormOpen === 'out' ? 'in' : 'out';
		if (this.isFormOpen === 'out') {
			this.cancel();
		}
	}
	cancel() {
		if (!this.disableButton) {
			this.form.reset();
			this.isFormOpen = 'out';
			this.feedApiService.updateShouldEraseUlopadedData();
			this.mediaItems.length = 0;
			this.postsMediaItems = [];
		}
	}

	redirectUserMainGroupsFeed() {
		const redirectUrl = `/home/groups/${this.routeSnapshot.url[1].path}/group/${this.routeSnapshot.params.id}`;
		this.router.navigate([ redirectUrl ]);
	}

	loadAllPosts() {
		if (this.redirectCase) {
			this.redirectUserMainGroupsFeed();
		} else {
			this.isLoading = true;
			this.posts = [];
			this._commentId = null;
			this.queryParams.page = 1;
			this.getPosts();
		}
	}

	loadMorePosts() {
		this.queryParams.page++;
		const shouldFilterPinnedPosts =
			Object.keys(this.queryParams).length === 2 ||
			(Object.keys(this.queryParams).length === 3 && this.queryParams['all'] === 'true');
		this.feedData$ = this.feedApiService.getPosts(this._groupId, this.queryParams).subscribe(
			(data: PostModel[]) => {
				this.loadedDataLength = data.length;
        this.posts.push(...(shouldFilterPinnedPosts ? data.filter((post) => !post.pin) : data));
        this.posts = this.makeUniquePostsArray(this.posts);
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			}
		);
	}

	toggleMiniFeedFilterPopUp() {
		this.isMiniFeedFilterPopUp = !this.isMiniFeedFilterPopUp;
		this.isMiniFeedFilterPopUp ? (this.isMiniFeedFilterOpened = 'in') : (this.isMiniFeedFilterOpened = 'out');
	}

	setCurrentFilterValue(value: string) {
		if (this.isMiniFeedFilter && value) {
			this.currentFilterValue = value;
			this.toggleMiniFeedFilterPopUp();
		}
	}

	hideActivefilter() {
	  if (this.all) {
      this.renderer.removeClass(this.all.nativeElement, 'active');
      this.renderer.removeClass(this.my.nativeElement, 'active');
      this.renderer.removeClass(this.friends.nativeElement, 'active');
      this.renderer.removeClass(this.influencer.nativeElement, 'active');
      this.renderer.removeClass(this.admin.nativeElement, 'active');
      this.renderer.removeClass(this.favorites.nativeElement, 'active');
      this.renderer.removeClass(this.teammates.nativeElement, 'active');
      this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
      this.renderer.addClass(this[this.filter].nativeElement, 'active');
    }
  }

	showFilteredAll() {
		this.queryParams['all'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.all.nativeElement.innerHTML);
	}
	showFilteredMy() {
		this.queryParams['my'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.my.nativeElement.innerHTML);
	}
	showFilteredFriends() {
		this.queryParams['friends'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.friends.nativeElement.innerHTML);
	}
	showFilteredInfluencer() {
		this.queryParams['influencer'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.influencer.nativeElement.innerHTML);
	}
	showFilteredAdmin() {
		this.queryParams['admin'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.admin.nativeElement.innerHTML);
	}
	showFilteredFavorites() {
		this.queryParams['favorites'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.favorites.nativeElement.innerHTML);
	}
	showFilteredTeammates() {
		this.queryParams['teammates'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.teammates.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.teamPrivate.nativeElement, 'active');
		this.setCurrentFilterValue(this.teammates.nativeElement.innerHTML);
	}
	showFilteredTeamPrivate() {
		this.queryParams['privateteam'] = 'true';
		this.getPosts(this._groupId);
		this.renderer.addClass(this.teamPrivate.nativeElement, 'active');
		this.renderer.removeClass(this.favorites.nativeElement, 'active');
		this.renderer.removeClass(this.all.nativeElement, 'active');
		this.renderer.removeClass(this.my.nativeElement, 'active');
		this.renderer.removeClass(this.friends.nativeElement, 'active');
		this.renderer.removeClass(this.influencer.nativeElement, 'active');
		this.renderer.removeClass(this.admin.nativeElement, 'active');
		this.renderer.removeClass(this.teammates.nativeElement, 'active');
		this.setCurrentFilterValue(this.teamPrivate.nativeElement.innerHTML);
	}

	filterFeed(filterType: string) {
	  this.filter = filterType;
		this.feedData$.unsubscribe();
		this.posts = [];
		this._commentId = null;
		this.resetQueryParams();
		switch (filterType) {
			case 'all':
				this.showFilteredAll();
				break;
			case 'my':
				this.showFilteredMy();
				break;
			case 'friends':
				this.showFilteredFriends();
				break;
			case 'influencer':
				this.showFilteredInfluencer();
				break;
			case 'admin':
				this.showFilteredAdmin();
				break;
			case 'favorites':
				this.showFilteredFavorites();
				break;
			case 'teammates':
				this.showFilteredTeammates();
				break;
			case 'teamPrivate':
				this.showFilteredTeamPrivate();
				break;
			default:
				break;
		}
	}

	resetQueryParams() {
		this.queryParams = {
			page: 1,
			pageSize: 10,
			showprivate: true
		};
	}

	ngOnDestroy() {
		this.resetQueryParams();
		this.feedData$.unsubscribe();
		this.updateFeed$.unsubscribe();
		this.scrollToComposePrivatePost$.unsubscribe();
		this.passingDataService.setScrollToComposePrivatePost('none');
	}
}
