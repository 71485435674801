<div class="wrapper"
     appInfiniteScroll
     scrollPerecnt="70"
     immediateCallback="false"
     [scrollCallback]="scrollCallback"
     [ngClass]="{'public': isPublicMode}"
     #wrapper
>
  <div class="container">
    <app-tabs *ngIf="!isPublicMode" [tabs]="tabs" [isRedirect]="true"></app-tabs>
    <a class="button__back" (click)="goBack()" *ngIf="isPublicMode" >
      <img src="assets/images/arrow-lg-left.png"
           srcset="assets/images/arrow-lg-left@2x.png 2x,
             assets/images/arrow-lg-left@3x.png 3x"
           class="arrow-lg-left">
    </a>
    <app-group-filters></app-group-filters>

    <div class="content_wrapper">
      <app-group-card *ngFor="let group of groups; trackBy: trackByItems" [group]="group" [type]="isUserGroups ? 'mine' : 'all'" [isPublicMode]="isPublicMode"></app-group-card>

      <div class="spinner" *ngIf="isLoading">
        <mat-progress-spinner diameter="50" color="accent" mode="indeterminate"></mat-progress-spinner>
      </div>
      <div class="nothing_to_show" *ngIf="routeQueryParams && !isLoading && !groups?.length"> 
        {{'GROUPS.NO_GROUPS_FOUND' | translate }}
      </div>
      <div *ngIf="!isLoading && !groups?.length && isUserGroups">
        <app-groups-preview title="{{'GLOBAL.NO_MINE_GROUPS' | translate}}"></app-groups-preview>
      </div>
    </div>
  </div>
</div>
<app-scroll-top-button [element]="wrapperElement"></app-scroll-top-button>
<router-outlet></router-outlet>