export class LocalStorageUser {
  id: number;
  userName: string;
  image: string;
  role: string;
  token: string;

  constructor(data: any = {}) {
    this.id = data.id || null;
    this.userName = data.userName || '';
    this.image = data.image.replace(/\"/g, '') || '';
    this.role = data.role || '';
    this.token = data.token || '';
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { AppSessionStorage } from '../utils/app.session.storage';
import { AppConstants } from '../utils/app.constants';
import { UserModel } from '../models/user.model';
import { AppLocalStorage } from '../utils/app.local.storage';
import { BehaviorSubject, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiAccountService } from './api/api-account.service';
import { Router } from '@angular/router';
import { ErrorsProcessorService } from './errors-processor.service';
import { TopNotificationsService } from './top-notifications.service';
import { LoadingSpinnerService } from './loading-spinner.service';
import { UserRolesEnum } from '../enums/UserRoles.enum';

@Injectable()
export class AuthService {
  constructor(
    private accountApiService: ApiAccountService,
    private router: Router,
    private errorsProcessorService: ErrorsProcessorService,
    private topNotificationsService: TopNotificationsService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
  }

  onUnauthorize: EventEmitter<any> = new EventEmitter<any>();
  onAuthorize: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  localStorageUser = new BehaviorSubject<LocalStorageUser>({
    id: null,
    userName: '',
    image: '',
    role: '',
    token: ''
  });
  localStorageUser$ = this.localStorageUser.asObservable();
  isAuthed$ = of(this.isAuthorized);

  isAuthorized(): boolean {
    return this.isTokenExists() && this.isTokenValid();
  }

  isVIPInfluencer(): boolean {
    return this.isAuthorized() ? this.hasRole(UserRolesEnum.Influencer) : false;
  }

  isNormalUser(): boolean {
    return this.isAuthorized() ? this.hasRole(UserRolesEnum.User) : false;
  }

  isAdmin(): boolean {
    return this.isAuthorized() ? this.hasRole(UserRolesEnum.Admin) : false;
  }

  isReferee(): boolean {
    return this.isAuthorized() ? this.hasRole(UserRolesEnum.Referee) : false;
  }

  isCompany(): boolean {
    return this.isAuthorized() ? this.hasRole(UserRolesEnum.Company) : false;
  }

  unauthorize(): void {
    this.accountApiService.logoff().subscribe(() => {
      AppSessionStorage.clear();
      AppLocalStorage.remove(AppConstants.keys.authResponse);
      AppLocalStorage.remove(AppConstants.keys.routerHistory);
      AppLocalStorage.remove('showOnBoarding');
      this.onUnauthorize.emit();
    });
  }

  getToken(): string {
    const authResponse: any = AppLocalStorage.getObject(AppConstants.keys.authResponse);
    return (authResponse && authResponse.token) || '';
  }

  private isTokenExists(): boolean {
    const authResponse: any = AppLocalStorage.getObject(AppConstants.keys.authResponse);
    return !!(authResponse && authResponse.token);
  }

  private isTokenValid(): boolean {
    const authResponse: any = AppLocalStorage.getObject(AppConstants.keys.authResponse);
    return !!(authResponse && authResponse.expires && new Date() < new Date(authResponse.expires));
  }

  getUser() {
    this.localStorageUser.next(AppLocalStorage.getObject(AppConstants.keys.authResponse));
    return new UserModel(AppLocalStorage.getObject(AppConstants.keys.authResponse));
  }

  getSavedUserByToken(token) {
    this.loadingSpinnerService.show.next();
    AppLocalStorage.add(AppConstants.keys.authResponse, { token: token });
    this.accountApiService.getSavedUserByToken().subscribe(
      (data: LocalStorageUser) => {
        AppLocalStorage.add(AppConstants.keys.authResponse, data);
        AppLocalStorage.remove(AppConstants.keys.rm);
        this.localStorageUser.next(data);
        this.onAuthorize.next();
        this.router.navigate([ 'home/groups/all' ]);
      },
      () => {
        AppLocalStorage.remove(AppConstants.keys.authResponse);
        this.loadingSpinnerService.hide.next();
        this.router.navigate([ 'home/start/login' ]);
      },
      () => {
        this.loadingSpinnerService.hide.next();
      }
    );
  }

  hasRole(role: UserRolesEnum) {
    return this.isAuthorized() ? this.getUser().role === role : false;
  }

  facebookLogin(accessToken: string, spinnerText: string = 'Logging in...') {
    this.loadingSpinnerService.show.next(spinnerText);
    this.accountApiService.facebookLogin(accessToken).subscribe(
      (data) => {
        this.loadingSpinnerService.hide.next();
        AppLocalStorage.add(AppConstants.keys.authResponse, data);
        this.onAuthorize.next();
        this.router.navigate([ 'home/groups/all' ]);
      },
      (httpErrorResponse: HttpErrorResponse) => {
        this.loadingSpinnerService.hide.next();
        const error = this.errorsProcessorService.process(httpErrorResponse);
        this.topNotificationsService.notify(error, 'error');
        this.router.navigate([ '/' ]);
      }
    );
  }
}
