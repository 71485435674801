export interface IShowOnboarding {
	showOnboarding: boolean;
}

export interface IInstagramAccessTokenDTO {
	access_token: string;
	user_id: string;
}

export interface IInstagramLongLiveTokenDTO {
	access_token: string;
	token_type: string;
	expires_in: number;
}

export interface IInstagramLongLiveToken {
	id: string;
	accessToken: string;
	expiresIn: string;
}

export interface IInstagramUserName {
	id: number;
	username: string;
}

import { ApiBaseService } from './api-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginModel } from '../../models/login.model';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../utils/app.constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiAccountService extends ApiBaseService {
	constructor(protected http: HttpClient) {
		super(http);
	}

	signup(data, locale, inviteToken): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Accept-Language': locale
			})
		};
		const dataWithInviteToken = data;
		dataWithInviteToken.inviteToken = inviteToken;
		return this.postDataWithOptions('account/register', httpOptions, dataWithInviteToken);
	}

	logoff(): Observable<any> {
		return this.postData('auth/logoff', {});
	}

	updateAvatarAndName(data: any): Observable<any> {
		return this.postData('account', data);
	}

	changeEmailWithoutConfirmation(newEmail: string): Observable<any> {
		return this.postDataWithResponce('account/changeemail', { observe: 'response' }, { newEmail: newEmail });
	}

	updateEmailToken(data: any): Observable<any> {
		return this.getDataWithOptions('account/sendChangeEmailToken', data);
		/* return this.getData('account/sendChangeEmailToken', data); */
	}

	updateInstagramToken(data: any): Observable<any> {
		return this.postData('account/instagramToken', data);
	}

	deleteInstagramToken(): Observable<any> {
		return this.deleteDataToken('account/instagramToken');
	}

	updateEmail(id: string, newEmail: string, token: string): Observable<any> {
		const data = new HttpParams().set('id', id).set('newEmail', newEmail).set('token', token);
		return this.getData('account/changeEmail', data);
	}

	updatePassword(data: any): Observable<any> {
		return this.postData('account/changePassword', data);
	}

	login(data: LoginModel): Observable<any> {
		return this.postData('auth/login', data);
	}

	getSavedUserByToken(): Observable<any> {
		return this.postData('auth/getUserByToken', {});
	}

	facebookLogin(accessToken: string): Observable<any> {
		return this.postData('auth/facebookLogin', { accessToken: accessToken });
	}

	resetPassword(email: string): Observable<any> {
		return this.postData('account/resetPassword', { email: email });
	}

	confirmEmail(id: string, token: string): Observable<any> {
		const data = new HttpParams().set('id', id).set('token', token);
		return this.getData('account/confirmEmail', data);
	}

	deleteUsersAccount(): Observable<any> {
		return this.deleteSelfAccount('account/deleteself');
	}

	updateNotificationToken(token: string) {
		return this.postData('account/updateNotificationToken', { token: token });
	}

	getNotifications(page) {
		let pageIndex = new HttpParams().set('page', '1');
		if (page !== null && page !== undefined) {
			pageIndex = new HttpParams().set('page', page);
		}
		return this.getData('userProfile/notifications', pageIndex).pipe(
			map((data) => {
				return data;
			})
		);
	}

	markNotificationAsRead(id) {
		return this.putDataIdNotification('notifications/', id);
	}

	markAllNotificationsAsRead() {
		return this.postData('notifications/read', null);
	}

	deleteNotification(id) {
		return this.deleteDataById('notifications/', id);
	}

	getFacebookCredentials(): void {
		this.getData('auth/getFacebookCredentials').subscribe((data: any) => {
			window.location.href = `${AppConstants.api.facebook}client_id=${data.appId}&redirect_uri=${window.location
				.origin}/facebook&response_type=token&scope=email`;
		});
	}

	redirectToInstagramOAuth(): void {
		window.location.href = `${AppConstants.api
			.instagramOAuth}?client_id=${AppConstants.instagramAppId}&redirect_uri=${AppConstants.api
			.instagramRedirectURI}&scope=${AppConstants.api.instagramScope}&response_type=code`;
	}

	getInstagramShortLivedTokenByAuthCode(code: string): Observable<IInstagramAccessTokenDTO> {
		let body = new HttpParams()
			.set('client_id', environment.instagramAppId)
			.set('client_secret', environment.instagramAppSecret)
			.set('code', code)
			.set('grant_type', 'authorization_code')
			.set('redirect_uri', AppConstants.api.instagramRedirectURI);
		const headers = {
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
		};
		return this.http.post<IInstagramAccessTokenDTO>(AppConstants.api.instagramAccessToken, body, headers);
	}

	getInstagramLongLivedTokenFromShortLive(shortLiveToken: string): Observable<IInstagramLongLiveTokenDTO> {
		const headers = {
			headers: new HttpHeaders()
		};
		return this.http.get<IInstagramLongLiveTokenDTO>(
			`${AppConstants.api.instagramGraphQlBaseURI}/access_token?
		client_secret=${environment.instagramAppSecret}&
		access_token=${shortLiveToken}&
		grant_type=ig_exchange_token`,
			headers
		);
	}

	getInstagramMediaIds(longLiveToken: string, instagramId = null): Observable<any> {
		const headers = {
			headers: new HttpHeaders()
		};
		// BUG: with graph instagram API https://developers.facebook.com/support/bugs/228404711862192/
		const uri = /* instagramId
			? `${AppConstants.api.instagramGraphQlBaseURI}/${instagramId}?access_token=${longLiveToken}`
			:  */ `${AppConstants
			.api.instagramGraphQlBaseURI}/me/media?access_token=${longLiveToken}`;
		return this.http.get(uri, headers);
	}

	getInstagramMediaViaGraphQl(mediaId: string, longLiveToken: string): Observable<any> {
		const headers = {
			headers: new HttpHeaders()
		};
		return this.http.get(
			`${AppConstants.api
				.instagramGraphQlBaseURI}/${mediaId}?access_token=${longLiveToken}&fields=media_type,media_url`,
			headers
		);
	}

	updateInstagramTokenNewApi(oldToken: any): Observable<IInstagramLongLiveTokenDTO> {
		const headers = {
			headers: new HttpHeaders()
		};
		return this.http.get<IInstagramLongLiveTokenDTO>(
			`${AppConstants.api
				.instagramGraphQlBaseURI}/refresh_access_token?access_token=${oldToken}&grant_type=ig_refresh_token`,
			headers
		);
	}

	getInstagramUserName(longLiveToken: string): Observable<IInstagramUserName> {
		const headers = {
			headers: new HttpHeaders()
		};
		return this.http.get<IInstagramUserName>(
			`${AppConstants.api.instagramGraphQlBaseURI}/me?fields=id,username&access_token=${longLiveToken}`,
			headers
		);
	}

	redirectToInstagramUserProfile(userName: string): void {
		window.location.href = `${AppConstants.api.instagram}${userName}`;
	}

	getCurrentUserEmail(): Observable<string> {
		return this.getData(`account/userEmail`);
	}

	getShowOnboarding(): Observable<IShowOnboarding> {
		return this.getData('account/showOnboarding');
	}

	setShowOnboarding(payload: IShowOnboarding): Observable<IShowOnboarding> {
		return this.postData('account/showOnboarding', payload);
	}
}
