import { Component, OnInit } from '@angular/core';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { AppConstants } from 'src/app/utils/app.constants';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ApiAccountService, IShowOnboarding } from 'src/app/services/api/api-account.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { shareReplay } from 'rxjs/operators';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [ './home.component.less' ]
})
export class HomeComponent implements OnInit {
	baseURL = AppConstants.api.mediaFilesUrl;
	isAuthed;
	popupData;
	showOnBoarding: boolean;
	showPopup: boolean = false;
	slideCount = 0;

	homePopupData$;
	imageFileUrl$ = this.passingDataService.imageFileName$.pipe(shareReplay(1));
	hideBottomMenu$ = this.stateService.hideBottomMenu$.pipe(shareReplay(1));
	isAuthed$ = this.authService.isAuthed$.pipe(shareReplay(1));

	constructor(
		private apiAcountService: ApiAccountService,
		private loadingSpinnerService: LoadingSpinnerService,
		private passingDataService: PassingDataService,
		public readonly authService: AuthService,
		private stateService: StateService,
		public userProfile: ApiUserProfileService,
		public ga: GoogleAnalyticsService
	) {
		this.loadingSpinnerService.show.next();
		this.isAuthed = this.authService.isAuthorized();
		if (AppLocalStorage.getObject('showOnBoarding') === false) {
			this.dontShowOnboarding();
		} else if (AppLocalStorage.getObject('showOnBoarding') === true) {
			this.showOnBoarding = true;
			this.ga.eventEmitter('created-account', 'sign-ups', 'created-account');
			this.loadingSpinnerService.hide.next();
		} else {
			// make API call to get data and on the last slide - make a post API call; then add
			// showOnBoarding to the localStorage to make less calls in the future
			this.getShowOnboarding();
		}
		this.homePopupData$ = this.passingDataService.homePopupData$;
	}

	ngOnInit() {
		if (AppLocalStorage.getObject('routerHistory')) {
			AppLocalStorage.remove(AppConstants.keys.routerHistory);
		}
		// should popup be shown
		this.passingDataService.showPopupAtHome$.subscribe((condition) => {
			if (condition) {
				this.homePopupData$.subscribe((data) => (this.popupData = data));
			} else {
				this.popupData = {};
			}
			this.showPopup = condition;
		});
	}

	closeFullscreen() {
		this.passingDataService.setImageFileName(null);
	}

	dontShowOnboarding() {
		this.showOnBoarding = false;
		this.loadingSpinnerService.hide.next();
	}

	getShowOnboarding() {
		if (this.isAuthed) {
			this.apiAcountService.getShowOnboarding().subscribe(
				(data: IShowOnboarding) => {
					this.showOnBoarding = data.showOnboarding;
					AppLocalStorage.add('showOnBoarding', this.showOnBoarding);
					if (this.showOnBoarding) this.ga.eventEmitter('created-account', 'sign-ups', 'created-account');
					this.loadingSpinnerService.hide.next();
				},
				() => {
					this.dontShowOnboarding();
				}
			);
		} else {
			this.dontShowOnboarding();
		}
	}

	nextSlide() {
		if (this.slideCount < 5) {
			this.slideCount++;
		} else {
			this.setShowOnBoarding();
		}
	}

	setShowOnBoarding() {
		// this should be fired on the last onBoarding screen button
		this.loadingSpinnerService.show.next();
		const payload: IShowOnboarding = {
			showOnboarding: false
		};
		this.apiAcountService.setShowOnboarding(payload).subscribe(
			() => {
				this.showOnBoarding = false;
				if (AppLocalStorage.getObject('showOnBoarding')) {
					AppLocalStorage.remove('showOnBoarding');
				}
				AppLocalStorage.add('showOnBoarding', this.showOnBoarding);
				this.loadingSpinnerService.hide.next();
			},
			() => {
				this.dontShowOnboarding();
			}
		);
	}

	setSlideCount(silderCount: number) {
		this.slideCount = silderCount;
	}

	closePopup() {
		this.passingDataService.setShowPopupAtHome(false);
		this.passingDataService.passHomePopupData();
	}

	/* deleteUserImage(imageId: number) {
		this.loadingSpinnerService.show.next();
		this.userProfile.deleteUserPhoto(imageId).subscribe(
			() => {
				this.passingDataService.setRemovedImageId(imageId);
				this.closeFullscreen();
			},
			() => this.loadingSpinnerService.hide.next()
		);
	} */
}
