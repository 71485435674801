<ng-container
    *ngIf="this.group.isCanJoin && this.group.isParticipant; then userIsGroupParticipant; else userIsNotGroupParticipant"
></ng-container>

<ng-template #userIsGroupParticipant>
    <app-teams-list-header [group]="group" (closeCreateOrJoinTeam)="closeCreateTeam($event)"></app-teams-list-header>

    <ng-container *ngIf="privateTeamRequestWasSent; then showSuccess; else showTeamsList"></ng-container>
    <ng-template #showSuccess>
        <app-teams-success-request-sent [groupId]="group.id" [privateTeamId]="privateTeamId" (privateRequestCanceled)="togglePrivateRequestSent()"></app-teams-success-request-sent>
    </ng-template>
    <ng-template #showTeamsList>
        <app-teams-list [groupId]="group.id" (privateRequestSent)="togglePrivateRequestSent()" (privateTeamId)="setPrivateTeamId($event)"></app-teams-list>
        <app-teams-list-footer></app-teams-list-footer>
    </ng-template>

</ng-template>

<ng-template #userIsNotGroupParticipant>
    <p class="text">{{ "GROUP.TEAMS.USER_HASNT_JOINED" | translate }}</p>
</ng-template>