import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-participants-popup',
	templateUrl: './participants-popup.component.html',
	styleUrls: [ './participants-popup.component.less' ]
})
export class ParticipantsPopupComponent {
	@Input() popupData;
}
