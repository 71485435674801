import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, ElementRef
} from '@angular/core';
import { AppConstants } from '../../../utils/app.constants';
import { PostModel } from '../../../models/post.model';
import { ApiFeedService } from '../../../services/api/api-feed.service';
import { CommentModel } from '../../../models/comment.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { RolesComponent } from '../../_abstract/roles.component';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FileInputDisplayTypes } from '../../../enums/FileInputDisplayTypes.enum';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { PostFileInputComponent } from '../post-file-input/post-file-input.component';
import { PostMediaModel } from 'src/app/models/posts-media.model';
import { FormLengthValidator } from 'src/app/utils/forms-length-validator';
import { DomSanitizer } from '@angular/platform-browser';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-group-post',
	templateUrl: './group-post.component.html',
	styleUrls: [ './group-post.component.less' ],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					// overflow: 'hidden',
					height: '*'
				})
			),
			state(
				'out',
				style({
					opacity: '0',
					overflow: 'hidden',
					height: '0px'
				})
			),
			transition('in => out', animate('400ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out'))
		])
	],
	entryComponents: [ PostFileInputComponent ]
})
export class GroupPostComponent extends RolesComponent implements OnInit {
	@Input() isOnIOSDevice = false;
	@Input() isIOSSafari = false;
	@Input() isMineGroup: boolean;
  @Input() isCreator: boolean;
	@Input() isGroupParticipant: boolean;
	@Input() mentionUsers: string[];
	@Input() commentPostId: string;
	@Input() post: PostModel;
	@Input() showCommentId;
	@Input() teamId: number | null;

	@Output() emitCity = new EventEmitter();
	@Output() refetchPosts: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('postInputFile', { read: ViewContainerRef })
	vc: ViewContainerRef;

	adminUser = false;
	authedUser = false;

	baseBackURL = 'home';
	baseURL = AppConstants.api.mediaFilesUrl;

	commentForm: FormGroup;
	commentId: number;
	commentText = 'string';
	commentLength = FormLengthValidator.commentOrSubcommentsLength;
	comments: CommentModel[] = [];
	currentUser: UserModel;

	deleteType: string;
	disableButton = false;

	editType: string;
	errorTextUserNotFoundPart1: string;
	errorTextUserNotFoundPart2: string;
	expandForm = false;

	form: FormGroup;
	FileInputDisplayTypes = FileInputDisplayTypes;

	isCommentDialog = false;
	isCommentSending = false;
	isCommentsOpen = 'out';
	isCommentsOnCommentsOpen = 'out';
	isDialog = false;
	isOldStyleMedia = true;
	isLoadingComments = true;

	loadedDataLength: number;
	maxMediaItems = 5;
	mediaItems = [];

	passedComment: CommentModel;
	passedCommentId: number;
	postId: number;
	postImageUrl: string;
	postForm: FormGroup;
	postLength = FormLengthValidator.postLength;
	postsMediaItems: PostMediaModel[];
	private postsMediaItemsInitial: PostMediaModel[] = [];
	postVideoUrl: string;

	queryParams = {
		page: 1,
		pageSize: 10 // number of comments to fetch in one payload
	};

	showEditMenu = false;

	toggle = {
		isToggle: false,
		parentCommentId: null
	};

	get addMediaButtonText() {
		return this.mediaItems.length > 0 && this.mediaItems.length < this.maxMediaItems
			? 'BUTTON.ADD_MORE_MEDIA'
			: 'BUTTON.ADD_MEDIA';
	}
	get canUserSavePost() {
		return (
			(this.postForm.value['postText'] && this.postForm.value['postText'].length <= this.postLength) ||
			(this.postsMediaItems.length > 0 && this.postsMediaItems.length <= this.maxMediaItems)
		);
	}
	get canUserSaveComment() {
		return this.commentForm.value['text'] && this.commentForm.value['text'].length <= this.commentLength;
	}

	get isMinePost() {
		if (this.post && this.currentUser) {
			return +this.post.userId === +this.currentUser.id;
		} else {
			return false;
		}
	}

	constructor(
		private feedApiService: ApiFeedService,
		private auth: AuthService,
		private errorsProcessorService: ErrorsProcessorService,
		private topNotificationsService: TopNotificationsService,
		private route: ActivatedRoute,
		private router: Router,
		private loadingSpinnerService: LoadingSpinnerService,
		private translate: TranslateService,
		private routerInterceptorService: RouterInterceptorService,
		private factory: ComponentFactoryResolver,
		private sanitizer: DomSanitizer,
		private passingDataService: PassingDataService,
		private ga: GoogleAnalyticsService,
    private elRef: ElementRef
	) {
		super(auth);
	}

	ngOnInit() {
		this.mediaItems.length = 0;
		this.authedUser = this.auth.isAuthorized();
		this.adminUser = this.auth.isAdmin();
		if (this.authedUser) {
			this.initForm();
			this.post.userImage = this.post.userImage ? this.replacer(this.post.userImage) : '';
			this.postImageUrl = !!this.post.image
				? AppConstants.api.mediaFilesUrl + 'large/' + this.replacer(this.post.image)
				: null;
			this.postVideoUrl = !!this.post.video
				? AppConstants.api.mediaFilesUrl + this.replacer(this.post.video)
				: null;
			if (this.post.media && this.post.media.length > 0) {
				this.isOldStyleMedia = false;
			}
			if (!this.postImageUrl && this.post.media && this.post.media.length > 0) {
				for (let i = 0; i <= this.post.media.length - 1; i++) {
					if (this.post.media[i].contentType === 'image' && !this.postImageUrl) {
						this.postImageUrl = this.post.media[i].fileName;
					}
				}
			}
			if (!this.postVideoUrl && this.post.media && this.post.media.length > 0) {
				for (let i = 0; i <= this.post.media.length - 1; i++) {
					if (this.post.media[i].contentType === 'video' && !this.postVideoUrl) {
						this.postVideoUrl = this.post.media[i].fileName;
					}
				}
			}
			if (this.showCommentId && (this.commentPostId == this.post.id)) {
				this.toggleCommentsOpenOnce();
			}
			this.translate
				.stream('GROUP.POST.USER_NOT_FOUND.1')
				.subscribe((data) => (this.errorTextUserNotFoundPart1 = data));
			this.translate
				.stream('GROUP.POST.USER_NOT_FOUND.2')
				.subscribe((data) => (this.errorTextUserNotFoundPart2 = data));
			// to make url snapshot url from url - should take first 4 element and add params
			for (let i = 0; i <= 3; i++) {
				this.baseBackURL += '/' + this.route.snapshot.url[i].path;
			}
		}
	}

	mediaLinkBuilder(contentType: string, fileName: string): string {
		if (contentType === 'image') {
			return AppConstants.api.mediaFilesUrl + 'large/' + this.replacer(fileName);
		} else if (contentType === 'video') {
			return AppConstants.api.mediaFilesUrl + fileName + '?ngsw-bypass=true';
		}
	}

	videoTypeSetter(filename) {
		const extention = filename.trim().split('.');
		switch (extention[extention.length - 1].toLowerCase()) {
			case 'mp4':
				return 'video/mp4';
			case 'mov':
				return 'video/mp4';
			case 'qt':
				return 'video/quicktime';
			case 'webm':
				return 'video/webm';
			case 'flv':
				return 'video/x-flv';
			case 'ogg':
				return 'video/ogg';
			case 'avi':
				return 'video/x-msvideo';
			case '3gp':
				return 'video/3gpp';
			case 'mpeg':
				return 'video/mpeg';
			default:
				break;
		}
	}

	isFullscreenToggle(fileName?: string) {
		if (fileName) {
			this.passingDataService.setImageFileName(this.replacer(fileName));
		} else {
			this.passingDataService.setImageFileName(null);
		}
	}

	emitCityName(cityName) {
		if (cityName) {
			this.emitCity.emit(cityName);
		}
	}

	isEqualIds(id1, id2) {
		if (id1 && id2) {
			return +id1 === +id2;
		} else {
			return false;
		}
	}

	toggleEditMenu(commentId?) {
		if (commentId) {
			const comment = document.getElementById(this.post.id).querySelector(`#edit-popup${commentId}`);
			const wrapper = document.getElementById(this.post.id).querySelector(`#popup-wrapper${commentId}`);
			const commentText = document.getElementById(this.post.id).querySelector(`#text${commentId - 1}`);
			commentText.classList.toggle('expand');
			comment.classList.toggle('show');
			wrapper.classList.toggle('show');
		} else {
			this.showEditMenu = !this.showEditMenu;
		}
	}

	togglePin() {
		const call = this.post.pin
			? this.feedApiService.setPostAsUnpinned(this.post.id)
			: this.feedApiService.setPostAsPinned(this.post.id);
		call.subscribe((call) => this.refetchPosts.emit());
	}

	formatSelection(selection): string {
		return '<@' + selection.label + '>';
	}

	disableButtonToggler(eventName: string) {
		if (eventName === 'opened') {
			this.expandForm = true;
			this.disableButton = true;
		} else {
			this.expandForm = false;
			setTimeout(() => {
				this.disableButton = false;
			}, 200);
		}
	}

	initForm() {
		this.form = new FormGroup({
			text: new FormControl(null, Validators.compose([ Validators.maxLength(this.commentLength) ]))
		});
		this.currentUser = this.auth.getUser();
	}

	updateFavorites(isIcon: boolean = false) {
		if (this.isMineGroup) {
			if (!isIcon) {
				this.loadingSpinnerService.show.next();
			}
			this.feedApiService.updateFavorites(this.post.id).subscribe(
				() => {
					this.post.isFavorites = !this.post.isFavorites;
					if (!isIcon) {
						this.closeDialog();
					}
				},
				(httpErrorResponse: HttpErrorResponse) => {
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
					if (!isIcon) {
						this.loadingSpinnerService.hide.next();
						this.closeDialog();
					}
				},
				() => {
					if (this.post.isFavorites) {
						this.ga.eventEmitter('saved-post', 'group-engagement', 'saved-post');
						if (!isIcon) {
							this.loadingSpinnerService.hide.next();
						}
					} else {
						this.refetchPosts.emit();
						if (!isIcon) {
							this.loadingSpinnerService.hide.next();
						}
					}
				}
			);
		}
	}

	deletePost(postId?) {
		this.deleteType = 'Post';
		this.isDialog = true;
		postId ? (this.postId = postId) : (this.postId = null);
	}

	deleteComment(commentId?, commentText?) {
		this.commentText = commentText;
		this.deleteType = 'Comment';
		this.isDialog = true;
		commentId ? (this.commentId = commentId) : (this.commentId = null);
	}

	editPost(postId?) {
		this.isDialog = true;
		this.editType = 'Post';
		postId ? (this.postId = postId) : (this.postId = null);
		this.postsMediaItems = this.post.media;
		this.initPostForm();
		// Save initial media one on edit
		if (this.postsMediaItemsInitial.length === 0 && this.postsMediaItems.length > 0) {
			this.postsMediaItems.forEach((item) => this.postsMediaItemsInitial.push(item));
		}
	}

	editComment(commentId?, commentText?) {
		commentId ? (this.commentId = commentId) : (this.commentId = null);
		this.commentText = commentText;
		this.isDialog = true;
		this.editType = 'Comment';
		this.initCommentForm();
	}

	initPostForm() {
		if (this.isOldStyleMedia) {
			this.postForm = new FormGroup({
				postText: new FormControl(
					this.post.text,
					Validators.compose([ Validators.maxLength(this.postLength) ])
				),
				postImage: new FormControl(this.post.image || this.post.video),
				postTeamPrivate: new FormControl(this.post.teamPost || false)
			});
		} else {
			this.postForm = new FormGroup({
				postText: new FormControl(
					this.post.text,
					Validators.compose([ Validators.maxLength(this.postLength) ])
				),
				postTeamPrivate: new FormControl(this.post.teamPost || false)
			});
		}
		if (this.postsMediaItems.length > 0) {
			setTimeout(() => {
				this.postsMediaItems.forEach((mediaItem) => {
					if (mediaItem.contentType === 'image') {
						this.addMoreMedia(mediaItem.contentType, mediaItem.fileName);
					} else if (mediaItem.contentType === 'video') {
						this.addMoreMedia(mediaItem.contentType, mediaItem.fileName);
					}
				});
			}, 0);
		}
	}

	addMoreMedia(contentType?: string, fileName?: string) {
		const factory = this.factory.resolveComponentFactory(PostFileInputComponent);
		const componentRef = this.vc.createComponent(factory);
		componentRef.instance.filesCounter = this.mediaItems.length;
		componentRef.instance.type = FileInputDisplayTypes.PostMedia;
		componentRef.instance.accept = 'image/*, video/*';
		if (fileName) {
			if (contentType === 'image') {
				componentRef.instance.prevImage = fileName;
			} else if (contentType === 'video') {
				// postVideoUrl should be full URL
				componentRef.instance.postVideoUrl = this.mediaLinkBuilder('video', fileName);
			}
		}

		// Subscribtion on eventEmitter to kill each dinammically created component
		componentRef.instance.autoDestroy.subscribe(() => componentRef.destroy());
		// Subscription for get uploaded filenames
		componentRef.instance.uploadedFileName.subscribe((data) => {
			const postsMediaItemsLength = this.postsMediaItems.length;
			if (postsMediaItemsLength > 0) {
				for (let i = 0; i <= postsMediaItemsLength - 1; i++) {
					// if media items already got filename with current order number and passed filename is empty string
					// we should delete item from array
					if (this.postsMediaItems[i].order === data.order && data.fileName === '') {
						this.postsMediaItems.splice(i, 1);
						return;
					}
					// if media items already got filename with current order number and passed filename is NOT empty string
					// we shoulf update item in array
					if (this.postsMediaItems[i].order === data.order && data.fileName) {
						this.postsMediaItems[i] = data;
						return;
					}
					// we have passed all conditions - so just push new item into the array
					if (i === postsMediaItemsLength - 1) {
						this.postsMediaItems.push(data);
					}
				}
			} else {
				this.postsMediaItems.push(data);
			}
		});
		this.mediaItems.length++;
	}

	initCommentForm() {
		this.commentForm = new FormGroup({
			text: new FormControl(
				this.commentText,
				Validators.compose([
					Validators.required,
					Validators.maxLength(FormLengthValidator.commentOrSubcommentsLength)
				])
			)
		});
	}

	closeDialog() {
		if (!this.disableButton) {
			this.isDialog = false;
			this.deleteType = null;
			this.editType = null;
			this.postsMediaItems = this.postsMediaItemsInitial;
			this.post.media = this.postsMediaItemsInitial;
			this.feedApiService.updateShouldEraseUlopadedData();
			this.mediaItems.length = 0;
		}
	}

	uploadedFileTypeCheck(fileName: string): boolean {
		return fileName.split('.')[1].includes('jpeg');
	}

	updatePostOrComment() {
		if (!this.disableButton) {
			this.loadingSpinnerService.show.next();
			if (this.editType === 'Post') {
				if (this.postForm.valid) {
					const payload = {
						text: this.postForm.value.postText,
						image: null,
						video: null,
						media: [],
						team: this.postForm.value.postTeamPrivate ? this.teamId : null
					};
					let order = 0;
					for (let i = 0; i <= this.postsMediaItems.length - 1; i++) {
						if (this.postsMediaItems[i].fileName) {
							this.postsMediaItems[i].order = order;
							payload.media.push(this.postsMediaItems[i]);
							order++;
						}
					}
					if (payload.media && !payload.image) {
						for (let i = 0; i <= payload.media.length - 1; i++) {
							if (payload.media[i].contentType === 'image' && !payload.image) {
								payload.image = payload.media[i].fileName;
							}
						}
					}
					if (payload.media && !payload.video) {
						for (let i = 0; i <= payload.media.length - 1; i++) {
							if (payload.media[i].contentType === 'video' && !payload.video) {
								payload.video = payload.media[i].fileName;
							}
						}
					}
					this.feedApiService.editPost(this.postId, payload).subscribe(
						() => {
							this.refetchPosts.emit();
							this.closeDialog();
						},
						(httpErrorResponse: HttpErrorResponse) => {
							const error = this.errorsProcessorService.process(httpErrorResponse);
							this.topNotificationsService.notify(error, 'error');
							this.closeDialog();
							this.loadingSpinnerService.hide.next();
						},
						() => {
							this.loadingSpinnerService.hide.next();
						}
					);
				}
			} else {
				if (this.commentForm.valid && this.commentText !== this.commentForm.value.text) {
					const payload = {
						text: this.commentForm.value.text
					};
					this.feedApiService.editComment(this.commentId, payload).subscribe(
						() => {
							this.getComments();
							this.closeDialog();
						},
						(httpErrorResponse: HttpErrorResponse) => {
							const error = this.errorsProcessorService.process(httpErrorResponse);
							this.topNotificationsService.notify(error, 'error');
							this.closeDialog();
							this.loadingSpinnerService.hide.next();
						},
						() => {
							this.loadingSpinnerService.hide.next();
						}
					);
				}
			}
		}
	}

	likesCounterFromSubcomment(eventFromSubcomments) {
		// DIDN'T DO ANYTHING MAYBE IT'LL BE NEEDED IN FUTURE
	}

	deletePostOrComment(commentId?) {
		this.loadingSpinnerService.show.next();
		merge(this.route.params, this.route.data).pipe(debounceTime(1)).subscribe(() => {
			if (this.deleteType === 'Post') {
				this.feedApiService.deletePost(this.postId).subscribe(
					() => {
						this.refetchPosts.emit();
						this.closeDialog();
					},
					(httpErrorResponse: HttpErrorResponse) => {
						const error = this.errorsProcessorService.process(httpErrorResponse);
						this.topNotificationsService.notify(error, 'error');
						this.closeDialog();
						this.loadingSpinnerService.hide.next();
					},
					() => {
						this.loadingSpinnerService.hide.next();
					}
				);
			} else if (this.deleteType === 'Comment') {
				let commentHasSubcomments = 0;
				this.comments.forEach((comment) => {
					if (+comment.id === +this.commentId) {
						commentHasSubcomments = comment.amountOfSubcomments;
					}
				});
				this.feedApiService.deleteComment(this.commentId).subscribe(
					() => {
						this.getComments();
						this.post.amountOfComments--;
						if (commentHasSubcomments !== 0) {
							this.post.amountOfComments = this.post.amountOfComments - commentHasSubcomments;
						}
						this.closeDialog();
					},
					(httpErrorResponse: HttpErrorResponse) => {
						const error = this.errorsProcessorService.process(httpErrorResponse);
						this.topNotificationsService.notify(error, 'error');
						this.closeDialog();
						this.loadingSpinnerService.hide.next();
					},
					() => {
						this.loadingSpinnerService.hide.next();
					}
				);
			}
		});
	}

	addComment() {
		if (!this.disableButton) {
			const comment = new CommentModel(this.form.value);
			if (comment.text !== '') {
				this.isCommentSending = true;
				this.feedApiService.sendComment(this.post.id, comment).subscribe(
					(data: CommentModel) => {
						this.ga.eventEmitter('commented-on-post', 'group-engagement', 'commented-on-post');
						data.userName = this.currentUser.name;
						data.userImage = this.replacer(this.currentUser.image);
						this.comments.push(data);
						if (
							this.comments.length >= this.queryParams.pageSize &&
							this.loadedDataLength === this.queryParams.pageSize
						) {
							this.comments.pop();
						}
						this.post.amountOfComments++;
						this.form.reset();
					},
					(httpErrorResponse: HttpErrorResponse) => {
						const error = this.errorsProcessorService.process(httpErrorResponse);
						this.topNotificationsService.notify(error, 'error');
					},
					() => {
						this.isCommentSending = false;
					}
				);
			}
		}
	}

	increaseCommentCounter(dataFromSubcomment) {
		this.comments.forEach((comment) => {
			if (comment.id === dataFromSubcomment.parentCommentId) {
				comment.amountOfSubcomments++;
				this.post.amountOfComments++;
			}
		});
	}

	decreaseCommentCounter(dataFromSubcomment) {
		this.comments.forEach((comment) => {
			if (comment.id === dataFromSubcomment.parentCommentId) {
				comment.amountOfSubcomments--;
				this.post.amountOfComments--;
			}
		});
	}

	sendLike() {
		if (this.isMineGroup || this.isAdmin) {
			if (this.currentUser.id !== +this.post.userId) {
				this.feedApiService.sendLike(this.post.id).subscribe((data) => {
					this.post.userHasLikePost = !this.post.userHasLikePost;
					data.isLiked ? this.post.amountOfLikes++ : this.post.amountOfLikes--;
					if (data.isLiked) this.ga.eventEmitter('liked-post', 'group-engagement', 'liked-post');
				});
			}
		}
	}

	sendCommentLike(comment) {
		if (this.isMineGroup || this.isAdmin) {
			if (this.currentUser.id !== +comment.userId) {
				this.feedApiService.sendCommentLike(this.post.id, comment.id).subscribe((data) => {
					for (let i = 0; i < this.comments.length; i++) {
						if (this.comments[i].id === comment.id) {
							this.comments[i].userHasLikeComment = !this.comments[i].userHasLikeComment;
							data.isLiked ? this.comments[i].amountOfLikes++ : this.comments[i].amountOfLikes--;
							if (data.isLiked)
								this.ga.eventEmitter('liked-comment', 'group-engagement', 'liked-comment');
						}
					}
				});
			}
		}
	}

	toggleCommentsOpenOnce() {
		this.isCommentsOpen = 'in';
		this.feedApiService.getCurrentComment(this.showCommentId).subscribe((data) => {
			this.comments.push(data);
		});
	}

	toggleCommentsOpen() {
		this.isCommentsOpen = this.isCommentsOpen === 'out' ? 'in' : 'out';
		if (this.isCommentsOpen === 'in') {
			this.isLoadingComments = true;
			this.getComments();
      setTimeout(() => {
        this.elRef.nativeElement.classList.add('visible');
      }, 400);
		} else {
      this.elRef.nativeElement.classList.remove('visible');
			this.form.reset();
			this.queryParams = {
				page: 1,
				pageSize: 5
			};
			this.comments = [];
			this.isCommentsOnCommentsOpen = 'out';
		}
	}

	toggleCommentsOnCommentOpen(commentId) {
		this.isCommentsOnCommentsOpen = this.isCommentsOnCommentsOpen === 'out' ? 'in' : 'out';
		this.toggle = {
			isToggle: false,
			parentCommentId: commentId
		};
		if (this.isCommentsOnCommentsOpen === 'in') {
			this.toggle.isToggle = true;
		}
	}

	getComments() {
		this.feedApiService.getComments(this.post.id, this.queryParams).subscribe(
			(data: CommentModel[]) => {
				this.comments = data;
				this.loadedDataLength = data.length;
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				this.isLoadingComments = false;
			}
		);
	}

	loadMoreComments() {
		this.isLoadingComments = true;
		this.queryParams.page++;
		this.feedApiService.getComments(this.post.id, this.queryParams).subscribe(
			(data: CommentModel[]) => {
				this.loadedDataLength = data.length;
				this.comments.push(...data);
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				this.isLoadingComments = false;
			}
		);
	}

	goToProfile(userId?: number, commentId?: number) {
		if (!this.auth.isAuthorized()) {
			return;
		}
    if (userId && commentId) {
      this.setBackURL('post');
      this.router.navigate([ '/home/profile/' + userId ]);
    } else if (this.currentUser.id !== +this.post.userId) {
      this.setBackURL('post');
      this.router.navigate([ '/home/profile/' + this.post.userId ]);
    }
	}

	setBackURL(type: 'post' | 'comment' | 'subcomment', params?) {
		let backURL = '/' + this.baseBackURL;
		if (type === 'post') {
			backURL += '/' + this.post.id;
		} else {
			backURL += '/' + this.post.id + '/' + params;
		}
		this.routerInterceptorService.setCustomPreviousUrl(backURL);
	}

	goToProfileFromComment(userId, commentId) {
		if (!this.auth.isAuthorized()) {
			return;
		}
		if (this.currentUser.id !== +userId) {
			this.setBackURL('comment', commentId);
			this.router.navigate([ '/home/profile/' + userId ]);
		}
	}

	getUserId(name: string, commentId) {
		if (!this.auth.isAuthorized()) {
			return;
		} else {
			this.feedApiService.getUserIdFromName(name).subscribe(
				(userId) => {
					this.goToProfileFromComment(userId, commentId);
				},
				() => {
					const message = `${this.errorTextUserNotFoundPart1} ${name} ${this.errorTextUserNotFoundPart2}`;
					this.topNotificationsService.notify(message, 'error');
				}
			);
		}
	}

	getSelection(commentId = '') {
		const selection = window.getSelection();
		if (
			selection.toString().length === 0 &&
			(selection.anchorNode.parentElement.className === 'mention-user-found-post' ||
				selection.anchorNode.parentElement.className === 'mention-user-found-comment') &&
			selection.anchorNode.parentElement.innerHTML.match('@')
		) {
			const clickedUserName = selection.anchorNode.parentElement.innerHTML.trim().replace(/@/, '');
			if (clickedUserName !== this.currentUser.name) {
				this.getUserId(clickedUserName, commentId);
			}
		}
	}

	replacer(image: string) {
		return image.replace(/\"/g, '');
	}

	trackByItems(index: number, item: CommentModel): number {
		return Number(item.id);
	}
}
