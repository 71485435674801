import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../utils/app.constants';

export abstract class ApiBaseService {
	protected baseUrl: string;

	constructor(protected httpClient: HttpClient) {
		this.baseUrl = AppConstants.api.baseUrl;
	}

	protected getUrl(url): string {
		return this.baseUrl + url;
	}

	protected getData<T>(apiName: string, params?: HttpParams): Observable<T> {
		return this.httpClient.get<T>(this.baseUrl + apiName, { params } || null);
	}

	protected getDataWithOptions<T>(apiName: string, params?: HttpParams): Observable<any> {
		return this.httpClient.get<T>(this.baseUrl + apiName, { observe: 'response', params: params });
	}

	protected postData<T>(apiName: string, obj: object, params?: HttpParams): Observable<any> {
		return this.httpClient.post<T>(this.baseUrl + apiName, obj, { params });
	}

	protected postDataWithResponce<T>(apiName: string, obj: object, options?: any): Observable<any> {
		return this.httpClient.post<T>(this.baseUrl + apiName, options, obj);
	}

	protected postDataWithOptions<T>(apiName: string, obj: object, options?: any): Observable<any> {
		return this.httpClient.post<T>(this.baseUrl + apiName, options, obj);
	}

	protected putData<T>(apiName: string, obj: any, params?: HttpParams): Observable<T> {
		return this.httpClient.put<T>(this.baseUrl + apiName, obj, { params });
	}

	protected deleteDataToken<T>(apiName: string, token: string = '') {
		return this.httpClient.delete<T>(this.baseUrl + apiName + `${token}`);
	}

	protected deleteData<T>(apiName: string) {
		return this.httpClient.delete<T>(this.baseUrl + apiName);
	}

	protected payPalPayout<T>(apiName: string, amount: string) {
		return this.httpClient.post<T>(this.baseUrl + apiName, { amount: amount });
	}

	protected deletePostOrComments<T>(apiNameWithId: string) {
		return this.httpClient.delete<T>(this.baseUrl + apiNameWithId);
	}

	protected putDataIdNotification<T>(apiName: string, id: string = '') {
		const headers = new HttpHeaders().append('ngsw-bypass', 'true');
		return this.httpClient.put<T>(this.baseUrl + apiName + `${id}` + '/read', null, { headers });
	}

	protected deleteDataById<T>(apiName: string, id: string = '') {
		return this.httpClient.delete<T>(this.baseUrl + apiName + `${id}`);
	}

	protected deleteSelfAccount<T>(apiName: string) {
		return this.httpClient.delete<T>(this.baseUrl + apiName);
	}
}
