import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.less']
})
export class GroupInfoComponent implements OnInit {
  @Output() iWantToJoin = new EventEmitter();
  @Input() isCanJoin;
  @Input() isStarted;
  constructor() { }
  ngOnInit() {
  }
  joinGroup() {
    this.iWantToJoin.emit();
  }

}
