<div *ngIf="currentCommentToggler.isToggle" [@slideInOut]="currentCommentToggler.isToggle ? 'in' : 'out'">
    <form [formGroup]="form">
        <div class="field">
            <label [for]="'text-' + comment.id"><img class="mini-icon" src="assets/images/comment_grey.png" srcset="assets/images/comment_grey@2x.png 2x,
                  assets/images/comment_grey@3x.png 3x">&emsp;{{'CREATE_SUBCOMMENT.TITLE' | translate}}</label>
            <textarea [id]="'text-' + comment.id" formControlName="text" [rows]="3"
                placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}" [mention]="mentionUsers"
                [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}" (opened)="disableButtonToggler('opened')" (closed)="disableButtonToggler('closed')" #textArea></textarea>
            <div class="wr">
                <div class="err-wr">
                    <span class="error-validation"
                        *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
                        {{ "GROUP.POST.COMMENTS.LENGTH" | translate }}
                    </span>
                </div>
                <span class="char-counter">{{textArea.value.length}} / {{subCommentLength}}</span>
            </div>
        </div>

        <div class="buttons__wrapper">
            <button class="button button__main transparent" [disabled]="form.invalid" (click)="addSubComment()">
                {{ 'CREATE_POST.SEND' | translate }}
            </button>
        </div>
        <div class="spinner" *ngIf="isSubCommentSending">
            <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
    </form>

    <div class="list" [@slideInOut]="currentCommentToggler.isToggle ? 'in' : 'out'">
        <div *ngIf="comment.amountOfSubcomments >= 1" id="{{comment.id}}">
            <div class="comment" *ngFor="let subComment of subComments; let j = index; trackBy: trackByItems">
                <div class="image-wrapper" (click)="goToProfileFromComment(subComment.userId)">
                    <img src="{{subComment.userImage | userAvatarUrl}}"
                        alt="" class="image">
                </div>
                <div class="content">
                    <div class="content-wrapper">
                        <img src="assets/images/comment_grey.png" srcset="assets/images/comment_grey@2x.png 2x,
                        assets/images/comment_grey@3x.png 3x">
                        <div class="title" (click)="goToProfileFromComment(subComment.userId)">{{subComment.userName}} <span class="city">{{subComment.userCity}}</span>
                        </div>
                        <app-time-ago class="time-ago" [params]="[{'dateTime': subComment.dateTime}]"></app-time-ago>
                    </div>

                    <div class="text" id="text{{j}}-sub">
                        <span (click)="getSelection()" [innerHTML]="subComment.text | linkHightlight | mentionHightlight:'mention-user-found-subcomment' | safe:'html'"></span>
                    </div>

                    <div class="comment-like" (click)="sendSubcommentLike(subComment.id, subComment.userId)">
                        <img *ngIf="!subComment.userHasLikeComment" src="assets/images/heart-gray.png" srcset="assets/images/heart-gray@2x.png 2x,
                  assets/images/heart-gray@3x.png 3x">
                        <img *ngIf="subComment.userHasLikeComment" src="assets/images/heart-red.png" srcset="assets/images/heart-red@2x.png 2x,
                  assets/images/heart-red@3x.png 3x">
                        <span>{{subComment.amountOfLikes}}</span>
                    </div>

                </div>

                <div class="popup-wrapper" id="popup-wrapper{{j+1}}-sub" (click)="toggleSubcommentEditMenu(j+1)"></div>
                <div *ngIf="this.subComment.userId === currentUserId || isAdmin" class="edit-comment"
                    (click)="toggleSubcommentEditMenu(j+1)"><img src="assets/images/Edit.png" srcset="assets/images/Edit@2x.png 2x,
                  assets/images/Edit@3x.png 3x"></div>
                <div class="edit-popup" id="edit-popup{{j+1}}-sub">
                    <p class="edit-popup-entity mini"
                        (click)="toggleSubcommentEditMenu(j+1); showEditSubComment(subComment.id, subComment.text)">
                        <b>{{ "GROUP.POST.EDIT" | translate }}</b></p>
                    <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
                    <p class="edit-popup-entity mini"
                        (click)="toggleSubcommentEditMenu(j+1); showDeleteSubComment(subComment.id, subComment.text)">
                        <b>{{ "GROUP.POST.DELETE" | translate }}</b></p>
                    <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
                    <p class="edit-popup-entity mini alt-color" (click)="toggleSubcommentEditMenu(j+1)">
                        <b>{{ "CREATE_POST.CANCEL" | translate }}</b>
                    </p>
                </div>

            </div>
        </div>

        <div *ngIf="!isLoadingSubcomments && comment.amountOfSubcomments === 0" class="noComments">
            {{"GLOBAL.NOTHING_TO_SHOW" | translate}}
        </div>
        <button *ngIf="!isLoadingSubcomments && subComments.length !== 0 && subComments.length < comment.amountOfSubcomments"
            class="button button__main transparent" (click)="loadMoreSubcomments()">
            {{"GLOBAL.LOAD_MORE" | translate}}
        </button>

    </div>
</div>

<div class="delete-or-edit-dialog" [class.show]="this.isDialog">

    <div class="delete-or-edit-dialog-main" *ngIf="modalType === 'delete'">
        <div>
            <div class="header">{{ "GROUP.COMMENT.DELETE_HEADER" | translate }}</div>
            <div class="text" [innerHTML]="subCommentText | mentionHightlight:'mention-user-found-subcomment' | safe:'html'"></div>
        </div>
        <div class="delete-or-edit-dialog-buttons">
            <button class="button button__alt" (click)="deleteSubComment()">
                {{ 'ADMIN.USERS_TABLE.COLUMN_NAME.DELETE' | translate }}
            </button>
            <button class="button button__alt transparent" (click)="closeDialog()">
                {{ 'CREATE_POST.CANCEL' | translate }}
            </button>
        </div>
    </div>

    <div class="delete-or-edit-dialog-main" *ngIf="modalType === 'edit'">
        <div>
            <div class="header">{{ "GROUP.COMMENT.EDIT_HEADER" | translate }}</div>
            <form [formGroup]="commentForm" [ngClass]="{'expand' : expandForm === true}">
                <div class="field">
                    <textarea formControlName="text" [rows]="3"
                        placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}" [mention]="mentionUsers"
                        [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}" (opened)="disableButtonToggler('opened')" (closed)="disableButtonToggler('closed')" #textArea></textarea>
                    <div class="wr">
                        <div class="err-wr">
                            <span class="error-validation"
                                *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
                                {{ "GROUP.POST.COMMENTS.LENGTH" | translate }}
                            </span>
                        </div>
                        <span class="char-counter">{{textArea.value.length}} / {{subCommentLength}}</span>
                    </div>
                </div>
                <div class="buttons__wrapper">
                    <button class="button button__main transparent" (click)="closeDialog()">
                        {{ 'CREATE_POST.CANCEL' | translate }}
                    </button>
                    <button class="button button__main" [disabled]="commentForm.invalid" [ngClass]="{'disabled': commentForm.invalid}" (click)="updateSubComment()">
                        {{ 'GLOBAL.SAVE' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>