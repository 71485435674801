import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorsProcessorService {
	defaultError: string;
	constructor(private translate: TranslateService) {
		translate.stream('GLOBAL.DEFAULT_ERROR').subscribe((error) => (this.defaultError = error));
	}

	process(httpErrorResponse: HttpErrorResponse) {
		let error: string;

		if (httpErrorResponse) {
			if (httpErrorResponse.error && httpErrorResponse.error.details && httpErrorResponse.error.details.length) {
				error = httpErrorResponse.error.details;
			} else if (
				httpErrorResponse.error &&
				httpErrorResponse.error.message &&
				httpErrorResponse.error.message.length
			) {
				error = httpErrorResponse.error.message;
			}
		}

		if (!error) {
			error = this.defaultError;
		}

		return error;
	}
}
