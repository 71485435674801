<div class="static">
    <span class="title">{{"INVITE_TO_GROUP_POPUP.TITLE" | translate}}</span>
    <span class="welcome">{{"INVITE_TO_GROUP_POPUP.WELCOME" | translate}}</span>
    <span class="details">{{"INVITE_TO_GROUP_POPUP.DETAILS" | translate}}</span>
</div>
<div class="dynamic">
    <div class="team-name">
        <ng-container *ngIf="popupData.teamName; then showTeamName; else showNoTeam"></ng-container>
        <ng-template #showTeamName>{{"INVITE_TO_GROUP_POPUP.TEAM_NAME.1" | translate}} {{popupData.teamName}} {{"INVITE_TO_GROUP_POPUP.TEAM_NAME.2" | translate}}</ng-template>
        <ng-template #showNoTeam>{{"INVITE_TO_GROUP_POPUP.TEAM_NAME.NO_TEAM_NAME" | translate}}</ng-template>
    </div>
    <div class="group">
        <div class="group__header">
          <div class="group__img" #groupImage></div>
          <div class="group__content">
            <div class="group__title break cancel">{{popupData?.name}}</div>
            <div class="group__description break cancel">{{popupData?.description}}</div>
          </div>
        </div>
        <div class="group__properties">
          <!-- Bet -->
          <div class="property">
            <div class="property__value">{{popupData?.bet}}kr</div>
            <div class="property__title">{{'GLOBAL.BET_SHORT' | translate}}</div>
          </div>
          <!-- Participants -->
          <div class="property__divider"></div>
          <div class="property">
            <div class="property__value">{{popupData?.amountOfParticipants}}</div>
            <div class="property__title">{{'GLOBAL.PARTICIPANTS_SHORT' | translate}}</div>
          </div>
          <!-- Teams -->
          <div class="property__divider"></div>
          <div class="property">
            <div class="property__value">{{popupData?.teamsCount}}</div>
            <div class="property__title">{{'GLOBAL.TEAMS' | translate}}</div>
          </div>
          <!-- In The Team Pot -->
          <div class="property__divider"></div>
          <div class="property">
            <div class="property__value">{{popupData?.bettingAmount}}kr</div>
            <div class="property__title">{{'GLOBAL.IN_THE_POT' | translate}}</div>
          </div>
        </div>
        <a class="button button__alt" (click)="closePopup()">{{'GLOBAL.GO_TO_GROUP' | translate}}</a>
      </div>
</div>