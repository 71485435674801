export interface IWalletPayout {
	action: 'giftCard' | 'trustly';
	moneyAmount: number;
	giftCardAmount: number;
	userEmail: string;
	isMobileDevice: boolean;
}

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailValidator } from 'src/app/validators/e-mail.validator';
import { AppConstants } from 'src/app/utils/app.constants';
import { concat } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { CanPayout } from 'src/app/models/can-payout.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiAccountService } from 'src/app/services/api/api-account.service';
import { ApiPaymentService } from 'src/app/services/api/api-payment.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-wallet-payout-popup',
	templateUrl: './wallet-payout-popup.component.html',
	styleUrls: [ './wallet-payout-popup.component.less' ]
})
export class WalletPayoutPopupComponent implements OnInit {
	@Input() popupData: IWalletPayout;

	emailCardForm: FormGroup;
	form: FormGroup;
	giftCardAmountForm: FormGroup;
	giftCardsArray: number[] = AppConstants.giftCards.map((el) => el);
	requestedGiftCardValue = false;

	viewStatus: {
		giftCardSent: boolean;
		shouldUseNewEmail: boolean;
		trustlyUrl: any;
		isShowTrustly: boolean;
		showConfirmEmail: boolean;
		showRedeemPayout: boolean;
		canPayoutValue: number;
	};

	constructor(
		private translate: TranslateService,
		private apiAccountService: ApiAccountService,
		private paymentService: ApiPaymentService,
		private topNotificationsService: TopNotificationsService,
		private loadingSpinnerService: LoadingSpinnerService,
		private errorsProcessorService: ErrorsProcessorService,
		private sanitizer: DomSanitizer,
		private passingDataService: PassingDataService,
		private cdr: ChangeDetectorRef,
		private ga: GoogleAnalyticsService
	) {}

	ngOnInit(): void {
		this.viewStatus = {
			giftCardSent: false,
			shouldUseNewEmail: false,
			trustlyUrl: null,
			isShowTrustly: false,
			showConfirmEmail: false,
			showRedeemPayout: false,
			canPayoutValue: 0
		};
		if (this.popupData.action === 'giftCard') {
			this.initGiftCardAmountForm();
			this.initEmailCardForm();
		} else {
			this.initForm();
		}
	}

	initForm() {
		this.form = new FormGroup({
			ammount: new FormControl(
				null,
				Validators.compose([
					Validators.required,
					Validators.max(this.popupData.moneyAmount),
					Validators.min(10)
				])
			)
		});
	}

	initEmailCardForm() {
		this.emailCardForm = new FormGroup({
			email: new FormControl(null, Validators.compose([ EmailValidator ]))
		});
	}

	initGiftCardAmountForm() {
		this.giftCardAmountForm = new FormGroup({
			amount: new FormControl(
				null,
				Validators.compose([
					Validators.required,
					Validators.max(this.popupData.moneyAmount),
					Validators.min(10)
				])
			)
		});
	}

	checkCheckbox(checkedStatus: boolean) {
		this.viewStatus.shouldUseNewEmail = checkedStatus;
	}

	updateUserEmail(newEmail: string, requestedAmount) {
		this.loadingSpinnerService.show.next();
		let updateNotificationEmail = '';
		this.translate
			.stream('SETTINGS.MYACCOUNT.RELOGIN_NOTIFICATION_EMAIL')
			.subscribe((translation) => (updateNotificationEmail = translation));
		const updateEmail$ = this.apiAccountService.changeEmailWithoutConfirmation(newEmail);
		const requestGiftCard$ = this.paymentService.redeemPayoutGiftCardGogift(requestedAmount);
		const makePosts$ = concat(updateEmail$, requestGiftCard$);

		makePosts$
			.pipe(
				map((res: HttpResponse<any>) => {
					if (res.url) {
						if (res.url.includes('changeemail') && res.status === 200) {
							this.topNotificationsService.notify(updateNotificationEmail, 'success');
						} else if (res.url.includes('giftCardGogift') && res.status === 200) {
							this.viewStatus.giftCardSent = true;
							this.popupData.moneyAmount = this.popupData.moneyAmount - requestedAmount;
							this.ga.eventEmitter('withdrawal-giftcard', 'withdrawals', 'withdrawal-giftcard');
							this.cdr.detectChanges();
							this.loadingSpinnerService.hide.next();
						}
					}
				})
			)
			.subscribe(
				(expectingVoid) => {},
				(httpErrorResponse: HttpErrorResponse) => {
					if (httpErrorResponse.status !== 200) {
						this.topNotificationsService.notify(
							`${httpErrorResponse.error.message}\n${httpErrorResponse.error.details}`,
							'error'
						);
						this.loadingSpinnerService.hide.next();
					}
				}
			);
	}

	closeTrustly() {
		this.viewStatus.showRedeemPayout = false;
		this.viewStatus.isShowTrustly = false;
		this.form.reset();
		this.cdr.detectChanges();
	}

	switchToGiftCards() {
		this.popupData.action = 'giftCard';
		this.initGiftCardAmountForm();
		this.initEmailCardForm();
	}

	showRedeem() {
		this.loadingSpinnerService.show.next();
		const payload = {
			amount: 0,
			locale: AppLocalStorage.get(AppConstants.keys.defaultLanguage)
				? AppLocalStorage.get(AppConstants.keys.defaultLanguage).replace(/\"/g, '')
				: 'en'
		};
		this.paymentService.canPayout(payload).subscribe(
			(canPayout: CanPayout) => {
				if (canPayout.canCreatePayout) {
					this.viewStatus.showRedeemPayout = true;
				} else {
					this.viewStatus.showRedeemPayout = false;
					this.topNotificationsService.notify(canPayout.message, 'error');
				}
				this.viewStatus.canPayoutValue = canPayout.yearLimit - canPayout.current;
				this.cdr.detectChanges();
				this.loadingSpinnerService.hide.next();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.loadingSpinnerService.hide.next();
			}
		);
	}

	toggleShowConfirmEmail() {
		if (this.giftCardAmountForm.valid) {
			this.viewStatus.showConfirmEmail = !this.viewStatus.showConfirmEmail;
			this.cdr.detectChanges();
		} else if (!this.giftCardAmountForm.value.amount) {
			const control = this.giftCardAmountForm.get('amount');
			control.markAsDirty();
			control.markAllAsTouched();
			control.setErrors({ required: true });
		}
	}

	requestGiftCard() {
		if (
			this.emailCardForm.valid &&
			(this.emailCardForm.value.email !== null && this.emailCardForm.value.email !== '') &&
			this.viewStatus.shouldUseNewEmail
		) {
			// send request to change email and after that make a giftcard payout
			this.updateUserEmail(this.emailCardForm.value.email.trim(), this.giftCardAmountForm.value.amount);
		} else if (
			this.emailCardForm.valid &&
			(this.emailCardForm.value.email !== null && this.emailCardForm.value.email !== '') &&
			!this.viewStatus.shouldUseNewEmail
		) {
			// validation message - please click on checkbox to confirm use new email
			let errorMessage = '';
			this.translate
				.stream('MY_WALLET.POPUP.GIFT_CARD.USE_NEW_EMAIL.CONFIRM_NEW_EMAIL_CHECKBOX.INVALID')
				.subscribe((value) => (errorMessage = value));
			this.topNotificationsService.notify(errorMessage, 'error');
		} else if (
			this.emailCardForm.valid &&
			(this.emailCardForm.value.email === null || this.emailCardForm.value.email === '')
		) {
			// use old email and make a giftcard payout
			this.requestGogfitGiftCard(this.giftCardAmountForm.value.amount);
		}
	}

	requestGogfitGiftCard(ammount) {
		this.loadingSpinnerService.show.next();
		this.paymentService.redeemPayoutGiftCardGogift(ammount).subscribe(
			() => {
				this.viewStatus.giftCardSent = true;
				this.popupData.moneyAmount = this.popupData.moneyAmount - ammount;
				this.ga.eventEmitter('withdrawal-giftcard', 'withdrawals', 'withdrawal-giftcard');
				this.cdr.detectChanges();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.loadingSpinnerService.hide.next();
			},
			() => this.loadingSpinnerService.hide.next()
		);
	}

	payout() {
		if (this.form.valid && this.form.value.ammount > 10 && this.form.value.ammount <= this.popupData.moneyAmount) {
			this.loadingSpinnerService.show.next();
			const payload = {
				amount: this.form.value.ammount,
				locale: AppLocalStorage.get(AppConstants.keys.defaultLanguage)
					? AppLocalStorage.get(AppConstants.keys.defaultLanguage).replace(/\"/g, '')
					: 'en'
			};
			this.paymentService.canPayout(payload).subscribe(
				(canPayout: CanPayout) => {
					if (canPayout && canPayout.canCreatePayout) {
						this.makePayout(payload);
					} else {
						this.topNotificationsService.notify(canPayout.message, 'error');
						this.loadingSpinnerService.hide.next();
					}
				},
				(httpErrorResponse: HttpErrorResponse) => {
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
					this.loadingSpinnerService.hide.next();
					this.showRedeem();
				}
			);
		}
	}

	makePayout(payload) {
		this.loadingSpinnerService.show.next();
		this.paymentService.redeemPayout(payload).subscribe(
			(trustly) => {
				this.ga.eventEmitter('withdrawal-trustly', 'withdrawals', 'withdrawal-trustly');
				if (this.popupData.isMobileDevice) {
					this.loadingSpinnerService.hide.next();
					window.open(trustly.url, '_self');
				} else {
					this.viewStatus.trustlyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(trustly.url);
					this.viewStatus.isShowTrustly = true;
					this.cdr.detectChanges();
				}
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.loadingSpinnerService.hide.next();
				this.showRedeem();
			}
		);
	}

	loadedIFrame() {
		this.loadingSpinnerService.hide.next();
	}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
