<div [ngSwitch]="showOnBoarding">

  <ng-container *ngSwitchCase="true">
    <app-slider class="on-boarding" [sliderStatusInput]="slideCount" [itemsLength]="6" [itemsPerSlide]="1" [dots]="true" [isClickableDots]="false" [sendSliderStatusBack]="true" (currentSliderIndex)="setSlideCount($event)">
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_1.png" srcset="assets/images/slide_1@2x.png 2x,
               assets/images/slide_1@3x.png 3x">
          <h2>{{"ONBOARDING_SLIDER.1.H2"|translate}}</h2>
          <p>{{"ONBOARDING_SLIDER.1.P"|translate}}</p>
        </div>
        <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.1.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_2.png" srcset="assets/images/slide_2@2x.png 2x,
               assets/images/slide_2@3x.png 3x">
        <h2>{{"ONBOARDING_SLIDER.2.H2"|translate}}</h2>
        <p>{{"ONBOARDING_SLIDER.2.P"|translate}}</p>
        </div>
      <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.2.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_3.png" srcset="assets/images/slide_3@2x.png 2x,
               assets/images/slide_3@3x.png 3x">
          <h2>{{"ONBOARDING_SLIDER.3.H2"|translate}}</h2>
          <p>{{"ONBOARDING_SLIDER.3.P"|translate}}</p>
        </div>
        <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.3.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_4.png" srcset="assets/images/slide_4@2x.png 2x,
               assets/images/slide_4@3x.png 3x">
          <h2>{{"ONBOARDING_SLIDER.4.H2"|translate}}</h2>
          <p>{{"ONBOARDING_SLIDER.4.P"|translate}}</p>
        </div>
        <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.4.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_5.png" srcset="assets/images/slide_5@2x.png 2x,
               assets/images/slide_5@3x.png 3x">
          <h2>{{"ONBOARDING_SLIDER.5.H2"|translate}}</h2>
          <p>{{"ONBOARDING_SLIDER.5.P"|translate}}</p>
        </div>
        <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.5.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
      <div class="slider__item">
        <div class="text">
          <img class="main-slider-image" src="assets/images/slide_6.png" srcset="assets/images/slide_6@2x.png 2x,
               assets/images/slide_6@3x.png 3x">
          <h2>{{"ONBOARDING_SLIDER.6.H2"|translate}}</h2>
          <p>{{"ONBOARDING_SLIDER.6.P"|translate}}</p>
        </div>
        <a class="button button__alt" (click)="nextSlide()">{{"ONBOARDING_SLIDER.6.A"|translate}}<svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></a>
      </div>
    </app-slider>
    
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <app-header></app-header> 
    <div class="wrapper" [ngClass]="{'hidden': (imageFileUrl$ | async), 'not-authed': (!(isAuthed$ | async) || (hideBottomMenu$ | async))}">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-bottom-menu *ngIf="((isAuthed$ | async) && !(hideBottomMenu$ | async))" [ngClass]="{'hidden': (imageFileUrl$ | async)}"></app-bottom-menu>
    <div class="fullscreen-media" *ngIf="(imageFileUrl$ | async); let imageFileUrl">
      <img src="{{this.baseURL}}large/{{imageFileUrl.fileName ? imageFileUrl.fileName : imageFileUrl}}" (click)="closeFullscreen()">
      <!-- <button class="button button__alt" (click)="closeFullscreen()">{{"ADMIN.CLOSE_BUTTON" | translate}}</button> -->
      <!-- <button *ngIf="imageFileUrl.isImageMine" class="button button__main" (click)="deleteUserImage(imageFileUrl.fileId)">{{"ADMIN.USERS_TABLE.COLUMN_NAME.DELETE" | translate}}</button> -->
    </div>
    
    <div class="overlay" *ngIf="showPopup">
      <div class="popup-wrapper">
        <i class="close-popup material-icons" (click)="closePopup()">close</i>
        <app-home-popups [popupData]="popupData"></app-home-popups>
      </div>
    </div>
  </ng-container>

</div>