import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-home-popups',
	templateUrl: './home-popups.component.html',
	styleUrls: [ './home-popups.component.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePopupsComponent {
	@Input() popupData;
}
