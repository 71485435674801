import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CommentModel } from 'src/app/models/comment.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiFeedService } from 'src/app/services/api/api-feed.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { AppConstants } from 'src/app/utils/app.constants';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { FormLengthValidator } from 'src/app/utils/forms-length-validator';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-subcomments',
	templateUrl: './subcomments.component.html',
	styleUrls: [ './subcomments.component.less' ],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					// overflow: 'hidden',
					height: '*'
				})
			),
			state(
				'out',
				style({
					opacity: '0',
					overflow: 'hidden',
					height: '0px'
				})
			),
			transition('in => out', animate('400ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out'))
		])
	]
})
export class SubcommentsComponent implements OnInit, OnChanges {
	@Input() baseBackURL: string;
	@Input() comment: CommentModel;
	@Input() currentUserId;
	@Input() currentUserName;
	@Input() isMineGroup;
	@Input() mentionUsers;
	@Input() toggle;
	@Input() postId;
	@Input() errorTextUserNotFoundPart1;
	@Input() errorTextUserNotFoundPart2;

	@Output() likesCounter = new EventEmitter<any[]>(); // NOT NEEDED, SHOULD BE DELETED
	@Output() addedNewSubcomment = new EventEmitter<any>();
	@Output() deletedSubcomment = new EventEmitter<any>();

	get isAdmin() {
		return this.auth.isAdmin();
	}

	get showCommentData() {
		return (
			this.currentCommentToggler.parentCommentId === this.toggle.parentCommentId &&
			this.currentCommentToggler.isToggle
		);
	}

	baseURL = AppConstants.api.mediaFilesUrl;
	commentForm: FormGroup;
	currentComment: CommentModel;
	currentCommentToggler = {
		isToggle: false,
		parentCommentId: null
	};
	form: FormGroup;
	isDialog = false;
	isLoadingSubcomments = false;
	isSubCommentSending = false;
	modalType: string;
	showEditMenu = false;
	subCommentLength = FormLengthValidator.commentOrSubcommentsLength;
	subComments: CommentModel[];
	subCommentID: number;
	subCommentText: string;
	queryParams = {
		page: 1,
		pageSize: 10
	};
	disableButton = false;
	expandForm = false;

	constructor(
		private feedApiService: ApiFeedService,
		private errorsProcessorService: ErrorsProcessorService,
		private topNotificationsService: TopNotificationsService,
		private router: Router,
		private auth: AuthService,
		private loadingSpinnerService: LoadingSpinnerService,
		private routerInterceptorService: RouterInterceptorService,
		private ga: GoogleAnalyticsService
	) {}

	ngOnInit() {
		this.currentComment = this.comment;
		this.currentCommentToggler.isToggle = false;
		this.currentCommentToggler.parentCommentId = this.comment.id;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.toggle && !changes.toggle.firstChange) {
			if (changes.toggle.currentValue.parentCommentId === this.currentCommentToggler.parentCommentId) {
				this.currentCommentToggler.isToggle = !this.currentCommentToggler.isToggle;
				if (this.currentCommentToggler.isToggle && !changes.toggle.firstChange) {
					this.initForm();
					this.getSubcomments();
				} else {
					if (this.form) {
						this.form.reset();
					}
					this.subComments = [];
				}
			}
		}
	}

	showEditSubComment(subCommentId, subCommentText) {
		this.subCommentText = subCommentText;
		this.subCommentID = subCommentId;
		this.initEditForm();
		this.modalType = 'edit';
		this.isDialog = true;
	}

	updateSubComment() {
		if (!this.disableButton) {
			if (this.commentForm.valid && this.subCommentText !== this.commentForm.value.text) {
				this.loadingSpinnerService.show.next();
				const payload = {
					text: this.commentForm.value.text
				};
				this.feedApiService.editComment(this.subCommentID, payload).subscribe(
					() => {
						this.getSubcomments();
					},
					(httpErrorResponse: HttpErrorResponse) => {
						const error = this.errorsProcessorService.process(httpErrorResponse);
						this.topNotificationsService.notify(error, 'error');
						this.closeDialog();
						this.loadingSpinnerService.hide.next();
					},
					() => {
						this.closeDialog();
						this.loadingSpinnerService.hide.next();
					}
				);
			}
		}
	}

	initForm() {
		this.form = new FormGroup({
			text: new FormControl(
				null,
				Validators.compose([
					Validators.required,
					Validators.maxLength(FormLengthValidator.commentOrSubcommentsLength)
				])
			)
		});
	}

	initEditForm() {
		this.commentForm = new FormGroup({
			text: new FormControl(
				this.subCommentText,
				Validators.compose([
					Validators.required,
					Validators.maxLength(FormLengthValidator.commentOrSubcommentsLength)
				])
			)
		});
	}

	addSubComment() {
		if (!this.disableButton) {
			const comment = new CommentModel(this.form.value);
			if (comment.text !== '') {
				this.isSubCommentSending = true;
				this.feedApiService
					.sendComment(this.postId, comment, this.currentCommentToggler.parentCommentId)
					.subscribe(
						(data: CommentModel) => {
							this.ga.eventEmitter('subcommented-on-post', 'group-engagement', 'subcommented-on-post');
							this.subComments.push(data);
							this.form.reset();
							this.addedNewSubcomment.emit({
								parentCommentId: this.currentCommentToggler.parentCommentId
							});
						},
						(httpErrorResponse: HttpErrorResponse) => {
							const error = this.errorsProcessorService.process(httpErrorResponse);
							this.topNotificationsService.notify(error, 'error');
							this.isSubCommentSending = false;
						},
						() => {
							this.isSubCommentSending = false;
						}
					);
			}
		}
	}

	closeDialog() {
		if (!this.disableButton) {
			this.isDialog = false;
			this.modalType = '';
			this.subCommentID = null;
			this.subCommentText = '';
			if (this.commentForm) {
				this.commentForm.reset();
			}
		}
	}

	showDeleteSubComment(commentId, commentText) {
		this.subCommentText = commentText;
		this.subCommentID = commentId;
		this.modalType = 'delete';
		this.isDialog = true;
	}

	deleteSubComment() {
		this.loadingSpinnerService.show.next();
		this.feedApiService.deleteComment(this.subCommentID).subscribe(
			() => {
				this.deletedSubcomment.emit({ parentCommentId: this.currentCommentToggler.parentCommentId });
				this.getSubcomments();
				this.closeDialog();
				this.loadingSpinnerService.hide.next();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.closeDialog();
				this.loadingSpinnerService.hide.next();
			}
		);
	}

	getSubcomments() {
		this.isLoadingSubcomments = true;
		this.feedApiService.getCommentsOfComment(+this.comment.id).subscribe(
			(data: CommentModel[]) => {
				this.subComments = data;
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				this.isLoadingSubcomments = false;
			}
		);
	}

	loadMoreSubcomments() {
		this.isLoadingSubcomments = true;
		this.queryParams.page++;
		this.feedApiService.getCommentsOfComment(+this.comment.id, this.queryParams).subscribe(
			(data: CommentModel[]) => {
				this.subComments.push(...data);
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				this.isLoadingSubcomments = false;
			}
		);
	}

	setBackURL() {
		const backURL = this.baseBackURL + '/' + this.postId + '/' + this.comment.id;
		this.routerInterceptorService.setCustomPreviousUrl(backURL);
	}

	goToProfileFromComment(userId) {
		if (this.currentUserId !== +userId) {
			this.setBackURL();
			this.router.navigate([ '/home/profile/' + userId ]);
		}
	}

	getSelection() {
		const selection = window.getSelection();
		if (
			selection.toString().length === 0 &&
			selection.anchorNode.parentElement.className === 'mention-user-found-subcomment' &&
			selection.anchorNode.parentElement.innerHTML.match('@')
		) {
			const clickedUserName = selection.anchorNode.parentElement.innerHTML.trim().replace(/@/, '');
			if (clickedUserName !== this.currentUserName) {
				this.getUserId(clickedUserName);
			}
		}
	}

	getUserId(name: string) {
		if (!this.auth.isAuthorized()) {
			return;
		} else {
			this.feedApiService.getUserIdFromName(name).subscribe(
				(userId) => {
					this.goToProfileFromComment(userId);
				},
				() => {
					const message = `${this.errorTextUserNotFoundPart1} ${name} ${this.errorTextUserNotFoundPart2}`;
					this.topNotificationsService.notify(message, 'error');
				}
			);
		}
	}

	sendSubcommentLike(subcommentId, creatorId) {
		if (this.isMineGroup || this.isAdmin) {
			if (+this.currentUserId !== +creatorId) {
				this.feedApiService.sendCommentLike(this.postId, subcommentId).subscribe((data) => {
					for (let i = 0; i < this.subComments.length; i++) {
						if (this.subComments[i].id === subcommentId) {
							this.subComments[i].userHasLikeComment = !this.subComments[i].userHasLikeComment;
							data.isLiked ? this.subComments[i].amountOfLikes++ : this.subComments[i].amountOfLikes--;
							if (data.isLiked)
								this.ga.eventEmitter('liked-subcomment', 'group-engagement', 'liked-subcomment');
						}
					}
				});
			}
		}
	}

	toggleSubcommentEditMenu(subCommentId?) {
		if (subCommentId) {
			const comment = document.getElementById(this.comment.id).querySelector(`#edit-popup${subCommentId}-sub`);
			const wrapper = document.getElementById(this.comment.id).querySelector(`#popup-wrapper${subCommentId}-sub`);
			const commentText = document.getElementById(this.comment.id).querySelector(`#text${subCommentId - 1}-sub`);
			commentText.classList.toggle('expand');
			comment.classList.toggle('show');
			wrapper.classList.toggle('show');
		} else {
			this.showEditMenu = !this.showEditMenu;
		}
	}

	formatSelection(selection): string {
		return '<@' + selection.label + '>';
	}

	disableButtonToggler(eventName: string) {
		if (eventName === 'opened') {
			this.expandForm = true;
			this.disableButton = true;
		} else {
			this.expandForm = false;
			setTimeout(() => {
				this.disableButton = false;
			}, 200);
		}
	}

	trackByItems(index: number, item: CommentModel): number {
		return Number(item.id);
	}
}
