import { Component, OnInit } from '@angular/core';
import { ApiAccountService } from '../../services/api/api-account.service';
import { CordovaService } from '../../services/cordova.service';
import { facebookConnectPlugin } from '../../utils/facebook.plugin';
import { AuthService } from '../../services/auth.service';
import { TopNotificationsService } from '../../services/top-notifications.service';
import { TranslateService } from '@ngx-translate/core';

function _window(): any {
	return window;
}

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: [ './landing.component.less' ]
})
export class LandingComponent implements OnInit {
	spinnerText: string;

	constructor(
		private accountApiService: ApiAccountService,
		private cordova: CordovaService,
		private topNotificationsService: TopNotificationsService,
		private authService: AuthService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.translate.stream('SPINNER.SIGNING_IN').subscribe((data) => (this.spinnerText = data));
	}

	signupFacebook() {
		if (this.cordova.onCordova) {
			facebookConnectPlugin.login(
				[ 'public_profile', 'email' ],
				(data) => {
					this.authService.facebookLogin(data.authResponse.accessToken, this.spinnerText);
				},
				(data) => {
					this.topNotificationsService.notify(data.errorMessage, 'error');
				}
			);
		} else {
			this.accountApiService.getFacebookCredentials();
		}
	}
}
