import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppLocalStorage } from '../../../utils/app.local.storage';
import { AppConstants } from '../../../utils/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/services/state.service';

function _window(): any {
	return window;
}

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: [ './faq.component.less' ]
})
export class FaqComponent implements OnInit, OnDestroy {
	showMiniMenu = false;
	usersCurrnetLanguage;
	videoUrls: string[];
	videoLanguage: string;
	showPopUp = false;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private renderer: Renderer2,
		private auth: AuthService,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private stateService: StateService
	) {
		const snapshot = this.route.snapshot;
		// checking is there needed queryParam in url - do something with it and delete query
		if (snapshot.queryParams && snapshot.queryParams.howToGetPoints === 'true') {
			this.router.navigate([], { queryParams: null });
			setTimeout(() => {
				this.scrollTo('howToGetPoints');
			}, 500);
		}
		setTimeout(() => {
			this.stateService.setHideBottomMenu('hide');
		}, 200);
	}

	ngOnInit() {
		this.showMiniMenu = this.auth.isAuthorized();
		if (!this.auth.isAuthorized()) {
			this.defineLocale();
			this.initVideos();
			this.translate.onLangChange.subscribe((data) => {
				this.videoLanguage = data.lang;
				this.initVideos();
			});
		} else {
			this.defineLocale();
			this.initVideos();
		}
	}

	defineLocale() {
		this.usersCurrnetLanguage = AppLocalStorage.get('defaultLanguage')
			? AppLocalStorage.get('defaultLanguage').replace(/\"/g, '')
			: 'en';
		this.videoLanguage = this.usersCurrnetLanguage;
	}

	initVideos() {
		this.videoUrls = AppConstants.registerWeightVideoUrls[this.videoLanguage];
	}

	scrollTo(elementId: string) {
		const itemClass = 'show';
		// find element by id and scroll to it
		const elTo = document.getElementById(elementId);
		elTo.scrollIntoView({ behavior: 'smooth' });
		// find arrow and text wrapper and add them 'show' class
		const elTextWrapper = elTo.getElementsByClassName('entity-text__wrapper')[0] as HTMLElement;
		const arrowRotate = elTo.getElementsByClassName('entity__title')[0].children[0] as HTMLElement;
		this.renderer.addClass(elTextWrapper, itemClass);
		this.renderer.addClass(arrowRotate, itemClass);
	}

	togglePopUp() {
		this.showPopUp = !this.showPopUp;
	}

	toggleClass(e: any, itemClass: string) {
		const newTarget = e.target.parentNode.children[1];
		const arrowRotate = e.target.children[0];
		const hasClass = e.target.parentNode.children[1].classList.contains(itemClass);
		if (hasClass) {
			this.renderer.removeClass(newTarget, itemClass);
			this.renderer.removeClass(arrowRotate, itemClass);
		} else {
			this.renderer.addClass(newTarget, itemClass);
			this.renderer.addClass(arrowRotate, itemClass);
		}
	}

	goBack() {
		this.showMiniMenu ? this.routerInterceptorService.goBack() : this.router.navigate([ '..' ]);
	}

	ngOnDestroy() {
		this.stateService.setHideBottomMenu('show');
	}
}
