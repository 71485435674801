<div class="wrapper">
  <div class="top-bg"></div>
  <div class="main">
    <div class="content">
      <div class="block" [ngClass]="{'show-backgroud-circle': completedGroup}">
        <ng-container *ngIf="completedGroup; then showCompetedGroup; else showSpinner"></ng-container>

        <ng-template #showCompetedGroup> 
          <div class="padding-wrapper">
            <div class="title">{{"MVP_WINNER_1" | translate }}</div>
            <div class="subtitle">{{"MVP_WINNER_2" | translate }}</div>
            <div class="group-name">{{completedGroup.name}}</div>
            <div class="text">{{"MVP_WINNER_3" | translate }}</div>
            <div class="text congrats">{{"MVP_WINNER_4" | translate }}</div>
            <a class="button button__alt" [routerLink]="'/home/groups/all'">{{"TEAM_WINNER.ACTION.JOIN_NEW_GROUP"|translate}}</a>
          </div>
        </ng-template>

        <ng-template #showSpinner>
          <div class="spinner">
            <mat-progress-spinner diameter="50" color="accent" mode="indeterminate"></mat-progress-spinner>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
  <app-groups-preview title="{{'REGISTER_WEIGHT.GROUPS_PREVIEW.TITLE' | translate}}"></app-groups-preview>
</div>