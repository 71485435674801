<div class="header" [ngClass]="{'mini': !showNotifications}">
  <h1 class="title" *ngIf="showNotifications">JOINA</h1>
  <!-- Menu button -->
  <!-- <div class="header-button menu-button" *ngIf="!showBackButton" (click)="menuIsOpen(true)">
    <img src="assets/images/menu.png"
         srcset="assets/images/menu@2x.png 2x,
             assets/images/menu@3x.png 3x">
  </div> -->
  <div class="header-button back-button" *ngIf="showBackButton" (click)="goBack()">
    <img src="assets/images/arrow-lg-left.png"
         srcset="assets/images/arrow-lg-left@2x.png 2x,
             assets/images/arrow-lg-left@3x.png 3x"
         class="arrow-lg-left">
  </div>
<!--  <div class="header-button create-a-new-group-button" [routerLink]="['/home/create-group/step1']">-->
<!--        <img src="assets/images/add.svg">-->
<!--  </div>-->
  <div class="header-button notification-button" (click)="openNotifications()">
        <img src="assets/images/notifications.svg">
      <div class="count" [ngClass]="{'hidden': newNotificationsCount === 0}">{{newNotificationsCount}}</div>
  </div>
</div>

<app-main-menu
  [isActive]="isMenuOpen"
  (isCloseMenu)="menuIsOpen(false)"
  [user]="user"
  [showMenuItem]="showMenuItem"
></app-main-menu>
<app-notification-menu
  [isActive]="notificationsIsOpen"
  [isLoadingNotifications]="isLoadingNotifications"
  (notificationsIsOpen)="closeNotifications()"
  [messages]="messages"
  [markAllAsRead]="markAllAsRead"
  (notificationPageIndex)="loadMoreIndex($event)"
  (hasDeletedNotifications)="hasDeletedNotifications()"
  *ngIf="showNotifications"></app-notification-menu>
