import * as moment from "moment";

export class InfluencerInfoModel {
  userId: number;
  userName: string;

  constructor(data: any) {
    this.userId = data.userId || null;
    this.userName = data.userName || "";
  }
}

export class GroupModel {
  id: string;
  name: string;
  description: string;
  additionalDescription: string;
  image: string;
  isPrivate: boolean;
  startDate: string;
  endDate: string;
  startDateLastWeighing: string;
  isStarted: boolean;
  isCanJoin: boolean;
  bet: number;
  bettingAmount: number;
  amountOfParticipants: number;
  bonusPrize: string;
  pointsBonusPrize: number;
  initialPointsBonusPrize: number;
  discountCodes: string;
  isMine: boolean;
  isParticipant: boolean;
  isCreator: boolean;
  officialWeighins: boolean;
  isOfficialWeightSent: boolean;
  influencer: InfluencerInfoModel;
  creatorName: string;
  creatorId: number;
  isUnofficialWeightTime: boolean;
  isActive: boolean;
  rating: number;
  groupIsEnded: boolean;
  isTimeBeforeOfficialWeighins: boolean;
  isWaitigForReview: boolean;
  teamsCount: number;
  isTeamMember: boolean;
  teamId: number;
  isStartWeight: boolean;
  isFinalWeight: boolean;
  popularEndDate: string;
  isFree: boolean;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || "";
    this.description = data.description || "";
    this.additionalDescription = data.additionalDescription || "";
    this.image = data.image;
    this.isPrivate = data.isPrivate ? data.isPrivate : false;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.startDateLastWeighing = data.startDateLastWeighing;
    this.isStarted = this.populateIsStarted(data.startDate);
    this.isCanJoin = this.populateIsCanJoin(data.startDate);
    this.bet = data.bet || 300;
    this.bettingAmount = data.bettingAmount || 0;
    this.amountOfParticipants = data.amountOfParticipants;
    this.bonusPrize = data.bonusPrize;
    this.pointsBonusPrize = data.pointsBonusPrize;
    this.initialPointsBonusPrize = data.initialPointsBonusPrize || null;
    this.discountCodes = data.discountCodes;
    this.isParticipant = data.isParticipant || false;
    this.isCreator = data.isCreator || false;
    this.isMine = data.isParticipant || data.isCreator || false;
    this.officialWeighins = data.officialWeighins || false;
    this.isOfficialWeightSent = data.isOfficialWeightSent || false;
    this.influencer = data.influencer || null;
    this.creatorId = data.creatorId;
    this.creatorName = data.creatorName;
    this.isUnofficialWeightTime = this.populateIsActive(
      data.startDate,
      data.startDateLastWeighing
    );
    this.isActive = data.isActive || false;
    this.rating = data.rating || null;
    this.groupIsEnded = this.populateIsEnded(data.endDate);
    this.isTimeBeforeOfficialWeighins = this.populateBeforeOfficialWeighins(
      data.startDate
    );
    this.isWaitigForReview = data.isWaitigForReview || false;
    this.teamsCount = data.teamsCount || 0;
    this.isTeamMember = data.isTeamMember || false;
    this.teamId = data.teamId || null;
    this.isStartWeight = this.popuplateIsStartWeightShouldBeShown(
      data.startDateLastWeighing
    );
    this.isFinalWeight = this.popuplateIsFinalWeightShouldBeShown(
      data.startDateLastWeighing
    );
    this.popularEndDate = data.popularEndDate || null;
    this.isFree = data.isFree || false;
  }

  populateIsActive(startDate: string, startDateLastWeighing: string) {
    return (
      moment(startDate).add(-12, "hours") <= moment() &&
      moment() <= moment(startDateLastWeighing)
    );
  }

  populateIsStarted(startDate: string) {
    return moment() >= moment(startDate);
  }

  populateIsEnded(endDate: string) {
    return moment(endDate).add(24, "hours") < moment();
  }

  populateIsCanJoin(startDate: string) {
    return moment() <= moment(startDate).add(24, "hours");
  }

  populateBeforeOfficialWeighins(startDate: string) {
    return moment() <= moment(startDate).add(-12, "hours");
  }

  popuplateIsStartWeightShouldBeShown(startDateLastWeighing: string) {
    return moment() < moment(startDateLastWeighing);
  }
  popuplateIsFinalWeightShouldBeShown(startDateLastWeighing: string) {
    return moment() >= moment(startDateLastWeighing);
  }

  toPutJson() {
    return {
      name: this.name,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
}

export class Statistics {
  date: Date;
  value: number;
}
export class GroupStatisticsModel {
  initialWeight: number;
  currentWeight: number;
  targetWeight: number;
  weight: Array<Statistics>;
  waist: Array<Statistics>;
  bmi: Array<Statistics>;

  constructor(data: any = {}) {
    this.initialWeight = data.initialWeight || 0;
    this.currentWeight = data.currentWeight || 0;
    this.targetWeight = data.targetWeight || 0;
    this.weight = data.weight || [];
    this.waist = data.waist || [];
    this.bmi = data.bmi || [];
  }
}
