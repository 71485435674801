<div class="wrapper" [ngClass]="{'mini': !showMiniMenu}">
  <div class="container">
    <div class="terms">
      <a class="button__back" (click)="goBack()">
        <img src="assets/images/arrow-lg-left.png"
              srcset="assets/images/arrow-lg-left@2x.png 2x,
              assets/images/arrow-lg-left@3x.png 3x"
              class="arrow-lg-left">
        <div class="title">{{"SETTINGS.TERMS_OF_USE" | translate}}</div>
      </a>
      <h1>{{"SETTINGS.TERMS_OF_USE.H1" | translate}}</h1>
      <p>{{"SETTINGS.TERMS_OF_USE.P1"|translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_1" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_1.P1.1" | translate}} <b><a href="https://app.joina.io" style="cursor: pointer">{{"SETTINGS.TERMS_OF_USE.H2_1.P1.LINK" | translate}}</a></b> {{"SETTINGS.TERMS_OF_USE.H2_1.P1.2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_1.P2.1" | translate}} <b><a href="https://app.joina.io" style="cursor: pointer">{{"SETTINGS.TERMS_OF_USE.H2_1.P2.LINK" | translate}}</a></b>, {{"SETTINGS.TERMS_OF_USE.H2_1.P2.2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_1.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_1.P4" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_1.P5" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_2" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P4" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P5" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P6" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_2.P7" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_3" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_3.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_3.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_3.P3" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_4" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_4.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_4.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_4.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_4.P4" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_5" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_5.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_5.P2" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_6" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_6.P1.1" | translate}} <b [routerLink]="'/home/settings/privacy'" style="cursor: pointer">{{"SETTINGS.TERMS_OF_USE.H2_6.P1.LINK" | translate}}</b> {{"SETTINGS.TERMS_OF_USE.H2_6.P1.2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_6.P2_1" | translate}} <b [routerLink]="'/home/settings/privacy'" style="cursor: pointer">{{"SETTINGS.TERMS_OF_USE.H2_6.P2_2" | translate}}</b>. {{"SETTINGS.TERMS_OF_USE.H2_6.P2_3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_6.P2_4" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_7" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_7.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_7.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_7.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_7.P4" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_7.P5" | translate}}</p>
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_8" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_8.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_8.P2" | translate}}</p>
      <!--  -->
      <h2>{{"SETTINGS.TERMS_OF_USE.H2_9" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_9.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_9.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_9.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_9.P4" | translate}}</p>

      <h2>{{"SETTINGS.TERMS_OF_USE.H2_10" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P1" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P2" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P3" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P4" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P5" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P6" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P7" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P8" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P9" | translate}}</p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_10.P10" | translate}}</p>

      <h2>{{"SETTINGS.TERMS_OF_USE.H2_11" | translate}}</h2>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_11.P1" | translate}} <b><a href="mailto:support@joina.io">{{ "SETTINGS.TERMS_OF_USE.H2_11.P1.EMAIL" | translate }}</a></b></p>
      <p>{{"SETTINGS.TERMS_OF_USE.H2_11.P2" | translate}} <b>{{"SETTINGS.TERMS_OF_USE.H2_11.P2.BOLD"|translate}}</b></p>
      <ul>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI1" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI2" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI3" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI4" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI5" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI6" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI7" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI8" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI9" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI10" | translate}}</li>
        <li>{{"SETTINGS.TERMS_OF_USE.H2_11.UL.LI11" | translate}}</li>
      </ul>
    </div>
  </div>
</div>
