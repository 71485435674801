import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class LoadingSpinnerService {

  show: Subject<string> = new Subject<string>();
  hide: Subject<any> = new Subject<any>();

  constructor() {}

}
