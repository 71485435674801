import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CordovaService } from './app/services/cordova.service';

if (environment.production) {
  enableProdMode();
}

const onDeviceReady = () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

function _window(): any {
  // return the global native browser window object
  return window;
}

if (!!_window().cordova) {
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  onDeviceReady();
}
