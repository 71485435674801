import { Component, Input } from '@angular/core';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { Router } from '@angular/router';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { ApiLogsService, Log } from '../../../../services/api/api-logs.service';
import { AuthService } from '../../../../services/auth.service';

@Component({
	selector: 'app-show-payment-confirmation-popup',
	templateUrl: './show-payment-confirmation-popup.component.html',
	styleUrls: [ './show-payment-confirmation-popup.component.less' ]
})
export class ShowPaymentConfirmationPopupComponent {
	@Input() popupData;

	constructor(
		private loadingSpinnerService: LoadingSpinnerService,
		private passingDataService: PassingDataService,
		private groupsApiService: ApiGroupsService,
		private router: Router,
    private apiLogsService: ApiLogsService,
    private authService: AuthService
	) {}

	payWithPoints() {
		if (this.popupData.gotPointsToJoinGroup) {
			this.loadingSpinnerService.show.next();
			this.closePopup();
			this.router.navigate([ `home/pending/${this.popupData.groupId}` ], {
				state: {
					isPayWithPoints: true
				}
			});
		}
	}

	goToPayment() {
		this.groupsApiService.dropAllCacheData(this.popupData.groupId);
		this.closePopup();
    const user = this.authService.getUser();
    const log: Log = {
      operation: 'start',
      userId: user.id,
      groupId: this.popupData.groupId
    };
    this.apiLogsService.sendLog(log).subscribe((response) => {
      console.log(response);
    }, (error) => {
      console.log(error);
    });
		this.router.navigate([ `home/payment/${this.popupData.groupId}` ]);
	}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
