import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.less']
})
export class ScrollTopButtonComponent implements OnInit {

  @Input() element: ElementRef;

  show = false;

  constructor() { }

  ngOnInit() {
    const style = window.getComputedStyle(this.element.nativeElement, null);
    if (style.getPropertyValue('-webkit-overflow-scrolling') === 'touch') {

    } else {
      this.element.nativeElement.addEventListener('scroll', this.toggleButton);
    }
  }

  scrollTop() {
    this.scrollTo(this.element.nativeElement, 0, 200);
  }

  toggleButton: () => void = () =>  {
    this.show = this.element.nativeElement.scrollTop > 500;
  }

  scrollTo = (element, to, duration) => {
    if (duration <= 0) {
      return;
    }
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;

    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) { return; }
      this.scrollTo(element, to, duration - 10);
    }, 10);
  }

}
