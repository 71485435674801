<div class="wrapper">
    <div class="header" *ngIf="isMineGroup || isAdmin" #scrollToComposePrivatePost>
        <div class="content form-wrapper">
          <div class="title field" (click)="toggleForm()">
            <textarea *ngIf="isFormOpen === 'out'"
                      [rows]="1"
                      placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}"
                      (click)="textArea.focus()"
                      #textAreaLikeAnInput></textarea>
          </div>
          <div class="form-wrapper" [@slideInOut]="isFormOpen">

            <form [formGroup]="form" [ngClass]="{'expand' : expandForm === true}">

              <div class="field">
                <textarea id="text"
                          formControlName="text"
                          [rows]="5"
                          placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}"
                          [mention]="mentionUsers"
                          [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}"
                          (opened)="disableButtonToggler('opened')"
                          (closed)="disableButtonToggler('closed')"
                          #textArea></textarea>
                <div class="wr">
                    <div class="err-wr">
                        <span class="error-validation" *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
                            {{ "GROUP.POSTS.LENGTH" | translate }}
                        </span>
                    </div>
                        <span class="char-counter">{{textArea.value.length}} / {{this.postLength}}</span>
                </div>
              </div>
              <p *ngIf="teamId" class="private-checkbox">
                <input type="checkbox" id="teamPrivatePost" formControlName="teamPrivatePost">
                <label for="teamPrivatePost">{{ "CREATE_POST.PRIVATE_CHECKBOX"|translate}}</label>
              </p>
              <!-- ADDED BELOW -->
              <ng-template #postInputFile></ng-template>
              <!-- ADDED ABOVE -->

              <div class="buttons__wrapper">
                <button class="button button__main transparent" (click)="addMoreMedia()"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                  <path fill="#1ec6c1" d="M6 14V8H0V6h6V0h2v6h6v2H8v6z"/>
              </svg> {{ addMediaButtonText | translate }}</button>
                <!-- <button class="button button__main transparent" (click)="cancel()">
                  {{ 'CREATE_POST.CANCEL' | translate }}
                </button> -->
                <button [disabled]="!canUserSavePost" [ngClass]="{'disabled': !canUserSavePost}" class="button button__main" (click)="sendPost()">{{ 'CREATE_POST.SEND' | translate }}</button>
              </div>
            </form>
          </div>
          <div class="spinner" *ngIf="isPostSending">
            <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
          </div>
          <div class="showPrevious" *ngIf="(restPosts && restPosts.length)">
            <button class="button button__main" (click)="showRestPosts()">
              {{"GROUP.FEED_SHOW_PREVIOUS" | translate}}
            </button>
          </div>
        </div>
    </div>

      <div class="feed">
      <div class="post-filter" [ngClass]="{'collapsing-filter': isMiniFeedFilter}" *ngIf="isAuth && (isMineGroup || isAdmin || isCanJoin)">
        <ng-container
          *ngIf="isMiniFeedFilter; then showCollapsingFilter; else showOneLineFilter">
        </ng-container>

        <ng-template #showCollapsingFilter>
          <div class="active-filer" (click)="toggleMiniFeedFilterPopUp()">
            <div class="filter-posts">{{ "GROUP.FEED.FILTER_POSTS" | translate }}:</div>
            <div class="filter-item active" [ngClass]="{'collapsing': isMiniFeedFilter}">{{feedFilterValue}} <img [ngClass]="{'arrow-up': isMiniFeedFilterPopUp}" src="assets/images/blue_arrow_right.svg"></div>
          </div>
          <div class="mini-filter-wrapper" [@slideInOut]="isMiniFeedFilterOpened">
            <div class="filter-item active" (click)="filterFeed('all')" #all>{{ "GROUP.FEED.FILTER_ALL" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('teamPrivate')" #teamPrivate>{{ "GROUP.FEED.FILTER.PRIVATE_POSTS" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('teammates')" #teammates>{{ "GROUP.FEED.FILTER.TEAM_POSTS" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('favorites')" #favorites>{{ "GROUP.FEED.FILTER_FAVORITES" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('my')" #my>{{ "GROUP.FEED.FILTER_YOU" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('friends')" #friends>{{ "GROUP.FEED.FILTER_FRIENDS" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('influencer')" #influencer>{{ "GROUP.FEED.FILTER_INFLUENCER" | translate }}</div>
            <div class="filter-item" (click)="filterFeed('admin')" #admin>{{ "GROUP.FEED.FILTER_JOINA" | translate }}</div>
          </div>
        </ng-template>
        <ng-template #showOneLineFilter>
          <div class="filter-item active" (click)="filterFeed('all')" #all>{{ "GROUP.FEED.FILTER_ALL" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('teamPrivate')" #teamPrivate>{{ "GROUP.FEED.FILTER.PRIVATE_POSTS" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('teammates')" #teammates>{{ "GROUP.FEED.FILTER.TEAM_POSTS" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('favorites')" #favorites>{{ "GROUP.FEED.FILTER_FAVORITES" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('my')" #my>{{ "GROUP.FEED.FILTER_YOU" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('friends')" #friends>{{ "GROUP.FEED.FILTER_FRIENDS" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('influencer')" #influencer>{{ "GROUP.FEED.FILTER_INFLUENCER" | translate }}</div>
          <div class="filter-item" (click)="filterFeed('admin')" #admin>{{ "GROUP.FEED.FILTER_JOINA" | translate }}</div>
        </ng-template>
      </div>
      <div *ngIf="(!isMineGroup && !isCanJoin && !isAdmin) && !isLoading && isAuth" class="noPosts">
        <div class="second-row">{{"GLOBAL.NOTHING_TO_SHOW_2" | translate}}</div>
        <div class="second-row-bold">{{"GLOBAL.NOTHING_TO_SHOW_3" | translate}}</div>
        <a class="button button__alt all-groups" [routerLink]="'/home/groups/all'">{{"GLOBAL.NOTHING_TO_SHOW_BUTTON" | translate}}</a>
      </div>
      <div class="noPosts" *ngIf="(isMineGroup || isCanJoin) && !isGroupStarted && posts.length === 0 && !isLoading && filter === 'all'">
        <div class="second-row">{{"GLOBAL.NOTHING_TO_SHOW_4" | translate}}</div>
      </div>
      <div *ngIf="!isAuth && !isLoading" class="noPosts">
        {{ "GROUP.POSTS.UNAUTH_USER_FEED_MESSAGE" | translate }}
      </div>
      <div *ngIf="(isMineGroup || isAdmin || isCanJoin) && !isLoading">
        <app-group-post *ngFor="let post of posts" id="postId-{{post.id}}"
          [post]="post"
          [teamId]="teamId"
          [showCommentId]="_commentId"
          [mentionUsers]="mentionUsers"
          [isMineGroup]="isMineGroup"
          [isCreator]="isCreator"
          [isGroupParticipant]="isGroupParticipant"
          [isIOSSafari]="isIOSSafari"
          [isOnIOSDevice]="isOnIOSDevice"
          [commentPostId]="_postId"
        (refetchPosts)='init()' (emitCity)="emitCityToGroupComponent($event)"></app-group-post>
      </div>
      <button *ngIf="posts.length === 1 && showAllPosts" class="button button__main transparent" (click)="loadAllPosts()">
          {{"GROUP.FEED_SHOW_ALL_POSTS" | translate}}
      </button>
      <button *ngIf="posts.length >= queryParams.pageSize && loadedDataLength === queryParams.pageSize && isAuth" class="button button__main" (click)="loadMorePosts()">
          {{"GLOBAL.LOAD_MORE" | translate}}
      </button>
      <div class="spinner" *ngIf="isLoading">
          <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>
    </div>
</div>
