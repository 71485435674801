SETTINGS.RULES.BLOCK3.P3<div class="wrapper" [ngClass]="{'mini': !showMiniMenu}">
  <div class="container">
    <div class="rules">
      <a class="button__back" (click)="goBack()">
        <img src="assets/images/arrow-lg-left.png" srcset="assets/images/arrow-lg-left@2x.png 2x,
                     assets/images/arrow-lg-left@3x.png 3x" class="arrow-lg-left">
        <div class="title">{{'SETTINGS.RULES' | translate}}</div>
      </a>
      <h1>{{ "SETTINGS.RULES.HEADER" | translate}}</h1>
      <h2>{{ "SETTINGS.RULES.BLOCK1.HEADER" | translate}}</h2>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET1" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P1" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET2" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P2" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET3" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P3" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET4" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P4" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET5" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P5" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET6" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P6" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET7" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P7" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK1.BULLET8" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK1.P8" | translate}}</p>
      <h2>{{ "SETTINGS.RULES.BLOCK2.HEADER" | translate}}</h2>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK2.BULLET1" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK2.P2" | translate}}<b><a href="mailto:support@joina.io">support@joina.io</a></b></p>

      <h2>{{ "SETTINGS.RULES.BLOCK3.HEADER" | translate}}</h2>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK3.BULLET1" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK3.P1" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK3.BULLET2" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK3.P2" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK3.BULLET3" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK3.P3" | translate}}</p>
      <ul>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET1" | translate}}</i></li>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET2" | translate}}</i></li>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET3" | translate}}</i></li>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET4" | translate}}</i></li>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET5" | translate}}</i></li>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P3.BULLET6" | translate}}</i></li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK3.P4" | translate}}</p>
      <ul>
        <li><i>{{ "SETTINGS.RULES.BLOCK3.P5.BULLET1" | translate}}</i></li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK3.P5" | translate}}</p>
      <p>{{ "SETTINGS.RULES.BLOCK3.P6" | translate}}</p>
      <p>{{ "SETTINGS.RULES.BLOCK3.P7" | translate}}</p>

      <h2>{{ "SETTINGS.RULES.BLOCK4.HEADER" | translate}}</h2>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK4.BULLET1" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK4.P1" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK4.BULLET2" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK4.P2" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK4.BULLET3" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK4.P3" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET1" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET2" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET3" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET4" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET5" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET6" | translate}}</li>
        <li>{{ "SETTINGS.RULES.BLOCK4.P3.BULLET7" | translate}}</li>
      </ul>
      <p></p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK4.BULLET4" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK4.P4" | translate}}</p>
      <h2>{{ "SETTINGS.RULES.BLOCK5.HEADER" | translate}}</h2>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET1" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P1" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET2" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P2" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET3" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P3" | translate}}<b style="cursor: pointer" (click)="toggleInfluencer()"> {{ "SETTINGS.RULES.BLOCK5.P3_A" | translate }}</b></p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET4" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P4" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET5" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P5" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET6" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P6" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET7" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P7" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET8" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P8_1" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET9" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P9" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET10" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P10" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET11" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P11" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET12" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P12" | translate}}</p>
      <ul>
        <li>{{ "SETTINGS.RULES.BLOCK5.BULLET13" | translate}}</li>
      </ul>
      <p>{{ "SETTINGS.RULES.BLOCK5.P13" | translate}}</p>
    </div>
  </div>
</div>

<div class="pop-up" [ngClass]="{'mini': !showMiniMenu}" *ngIf="showInfluencerPopUp">
  <div class="wrapper">
    <img class="close-button" (click)="toggleInfluencer()"
          src="assets/images/cancel.png"
          srcset="assets/images/cancel@2x.png 2x,
                  assets/images/cancel@3x.png 3x">

    <span>{{ "SETTINGS.RULES.BLOCK5.INFLUENCER_POPUP1" | translate }}</span>
    <span>{{ "SETTINGS.RULES.BLOCK5.INFLUENCER_POPUP2" | translate }}<b><a href="mailto:vip@joina.io"> vip@joina.io</a></b></span>
  </div>
</div>

<div class="pop-up" [ngClass]="{'mini': !showMiniMenu}" *ngIf="showPotPopUp">
  <div class="wrapper">
    <img class="close-button" (click)="togglePot()"
          src="assets/images/cancel.png"
          srcset="assets/images/cancel@2x.png 2x,
                  assets/images/cancel@3x.png 3x">

    <span>{{ "SETTINGS.RULES.BLOCK5.POT_POPUP1" | translate }}</span>
    <span>{{ "SETTINGS.RULES.BLOCK5.POT_POPUP2" | translate }}</span>
  </div>
</div>
