<div
  *ngIf="type === FileInputDisplayTypes.MyAccountAvatar"
  class="myAccountAvatar"
>
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div *ngIf="!fileData && !isLoading && userAvatar" class="img-wrapper">
      <div
        class="img-wrapper"
        [style.background-image]="sanitizeAvatar(userAvatar)"
      ></div>
    </div>
    <div
      *ngIf="fileData"
      class="img-wrapper"
      [ngStyle]="{ 'background-image': 'url(\'' + fileData + '\')' }"
    ></div>
    <div class="label" *ngIf="!fileData">
      {{ "SETTINGS.MY_ACCOUNT.UPLOAD_NEW_IMAGE" | translate }}
    </div>
    <div class="label" *ngIf="fileData">
      {{ "CREATE_ACCOUNT.IMG_CHANGE" | translate }}
    </div>
  </label>
</div>

<div *ngIf="type === FileInputDisplayTypes.Avatar" class="avatar">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div *ngIf="!fileData" class="img-wrapper">
      <img
        src="assets/images/camera-icon.png"
        srcset="
          assets/images/camera-icon@2x.png 2x,
          assets/images/camera-icon@3x.png 3x
        "
        class="Camera-icon"
      />
    </div>
    <div
      *ngIf="fileData"
      class="img-wrapper"
      [ngStyle]="{ 'background-image': 'url(\'' + fileData + '\')' }"
    ></div>
    <div class="label" *ngIf="!fileData">
      {{ "CREATE_ACCOUNT.IMG" | translate }}
    </div>
    <div class="label" *ngIf="fileData">
      {{ "CREATE_ACCOUNT.IMG_CHANGE" | translate }}
    </div>
  </label>
</div>

<div *ngIf="type === FileInputDisplayTypes.GroupImage" class="image">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId" class="create-group-label">
    <div
      *ngIf="!fileData && !isLoading && prevImage"
      class="img-wrapper create-group"
    >
      <div
        class="img-wrapper create-group"
        [style.background-image]="sanitizeImage(prevImage)"
      ></div>
    </div>
    <div *ngIf="isLoading" class="img-wrapper create-group loader">
      <mat-progress-spinner
        diameter="50"
        color="accent"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
    <div
      *ngIf="fileData && !isLoading"
      class="img-wrapper create-group"
      [ngStyle]="{ 'background-image': 'url(\'' + fileData + '\')' }"
    ></div>
    <a class="button button__main transparent" *ngIf="!fileData && !prevImage">
      <svg width="14" height="14" viewBox="0 0 14 14">
        <path fill="#fff" d="M6 14V8H0V6h6V0h2v6h6v2H8v6z" />
      </svg>
      {{ "CREATE_ACCOUNT.IMG.1" | translate }}
    </a>
    <a
      class="create-group-button button button__main transparent"
      *ngIf="fileData || prevImage"
    >
      <svg width="14" height="14" viewBox="0 0 14 14">
        <path fill="#fff" d="M6 14V8H0V6h6V0h2v6h6v2H8v6z" />
      </svg>
      {{ "CREATE_ACCOUNT.IMG_CHANGE_1" | translate }}
    </a>
  </label>
</div>

<!-- Select Handbook -->
<div *ngIf="type === FileInputDisplayTypes.File" class="video">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    accept="application/pdf"
    (change)="onFileChanged($event)"
  />

  <label [for]="'file-input-' + uniqueId">
    <div *ngIf="!fileData && !isLoading && !prevImage" class="img-wrapper">
      <span class="material-icons" style="font-size: 40px">attach_file</span>
    </div>

    <div *ngIf="!fileData && !isLoading && prevImage" class="file-wrapper">
      <div>
        <a [href]="sanitizeFile(prevImage)">{{ prevImage }}</a>
      </div>
    </div>

    <div *ngIf="isLoading" class="img-wrapper">
      <mat-progress-spinner
        diameter="50"
        color="accent"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <div *ngIf="fileData && !isLoading" class="img-wrapper">
      <a [href]="sanitizeFile(prevImage)">{{ prevImage }}</a>
    </div>

    <a class="button button__main transparent" *ngIf="!fileData && !prevImage">
      {{ "BOOTCAMP.SELECT_HANDBOOK" | translate }}
    </a>
    <a class="button button__main transparent" *ngIf="fileData || prevImage">
      {{ "BOOTCAMP.CHANGE_HANDBOOK" | translate }}
    </a>
  </label>
</div>

<!-- Bootcamp/Group card images -->
<div
  *ngIf="
    type === FileInputDisplayTypes.Image ||
    type === FileInputDisplayTypes.BootcampChallenge ||
    type === FileInputDisplayTypes.BootcampStepsChallenge
  "
  class="image"
>
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div
      *ngIf="fileData == null && !isLoading && prevImage == null"
      class="img-wrapper"
    >
      <img
        src="assets/images/camera-icon.png"
        srcset="
          assets/images/camera-icon@2x.png 2x,
          assets/images/camera-icon@3x.png 3x
        "
        class="Camera-icon"
      />
    </div>
    <div
      *ngIf="(fileData != null || prevImage != null) && !isLoading"
      class="img-wrapper"
    >
      <div
        *ngIf="prevImage != null"
        class="img-wrapper"
        [style.background-image]="sanitizeImage(prevImage)"
      ></div>

      <div
        *ngIf="fileData != null"
        class="img-wrapper"
        [ngStyle]="{ 'background-image': 'url(' + fileData + ')' }"
      ></div>
    </div>
    <div *ngIf="isLoading" class="img-wrapper">
      <mat-progress-spinner
        diameter="50"
        color="accent"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <a class="button button__main transparent" *ngIf="!fileData && !prevImage">
      {{ "CREATE_ACCOUNT.IMG" | translate }}
    </a>
    <a class="button button__main transparent" *ngIf="fileData || prevImage">
      {{ "CREATE_ACCOUNT.IMG_CHANGE" | translate }}
    </a>
  </label>
</div>

<!-- Upload Video  -->
<div *ngIf="type === FileInputDisplayTypes.Video" class="video">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
    [disabled]="isLoading"
    #fileInputContainer
  />

  <label [for]="'file-input-' + uniqueId">
    <div
      *ngIf="videoUrl == null && prevVideo == null && !isLoading"
      class="img-wrapper"
    >
      <img
        src="assets/images/video.png"
        srcset="assets/images/video@2x.png 2x, assets/images/video@3x.png 3x"
      />
    </div>
    <div *ngIf="isLoading" class="img-wrapper">
      <mat-progress-spinner
        diameter="50"
        color="accent"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>

    <video
      *ngIf="(videoUrl != null || prevVideo != null) && !isLoading"
      [src]="prevVideo != null ? sanitizeFile(prevVideo) : videoUrl"
      class="video-preview"
    ></video>

    <a
      class="button button__main transparent"
      *ngIf="videoUrl == null && prevVideo == null"
    >
      {{ "GLOBAL.UPLOAD_MOVIE" | translate }}
    </a>
    <a
      class="button button__main transparent"
      *ngIf="prevVideo != null || videoUrl != null"
    >
      {{ "GLOBAL.UPLOAD_NEW_MOVIE" | translate }}
    </a>
  </label>
  <!-- <div id="test"></div> -->
  <!-- <button (click)="eraseUlopadedData()"><mat-icon>delete</mat-icon></button> -->
</div>

<div *ngIf="type === FileInputDisplayTypes.PostImage" class="post-image">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div class="post-image__button">
      <img
        src="assets/images/camera.png"
        srcset="assets/images/camera@2x.png 2x, assets/images/camera@3x.png 3x"
      />
      <span *ngIf="!imageUrl">{{ "CREATE_POST.IMG" | translate }}</span
      ><span *ngIf="imageUrl">{{ "CREATE_POST.IMG_CHANGE" | translate }}</span>
    </div>
  </label>
  <div class="loader" *ngIf="isLoading">
    <mat-progress-spinner
      diameter="50"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <img *ngIf="imageUrl" [src]="imageUrl" alt="" />
</div>

<!-- PostMedia -->
<div *ngIf="type === FileInputDisplayTypes.PostMedia" class="post-image">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div class="post-image__button">
      <img
        src="assets/images/camera.png"
        srcset="assets/images/camera@2x.png 2x, assets/images/camera@3x.png 3x"
      />
      <span *ngIf="!imageUrl">{{ "CREATE_POST.MEDIA" | translate }}</span
      ><span *ngIf="imageUrl">{{
        "CREATE_POST.MEDIA_CHANGE" | translate
      }}</span>
    </div>
  </label>
  <div class="loader" *ngIf="isLoading">
    <mat-progress-spinner
      diameter="50"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <img *ngIf="imageUrl" [src]="imageUrl + '?ngsw-bypass=true'" alt="imageUrl" />
  <img *ngIf="prevImage" [src]="setSanitizedUrl(prevImage)" alt="prevImage" />
  <div
    *ngIf="fileData && !isLoading"
    class="img-wrapper"
    [ngStyle]="{ 'background-image': 'url(\'' + fileData + '\')' }"
  ></div>
  <video *ngIf="postVideoUrl" preload="metadata" controls>
    <source [src]="postVideoUrl + '?ngsw-bypass=true'" />
    <p class="text-centrer">{{ "POST.VIDEO_IS_NOT_SUPPORTED" | translate }}</p>
  </video>
  <!-- <img *ngIf="thumbnail" class="img-wrapper plus" [src]="thumbnail"> -->
  <p *ngIf="thumbnail">{{ "CREATE_POST.VIDEO_UPLOADED" | translate }}</p>
</div>
<!-- PostMedia -->

<image-cropper
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true"
  [aspectRatio]="imageAspectRatio"
  [resizeToWidth]="1024"
  [onlyScaleDown]="true"
  [imageQuality]="100"
  [autoCrop]="true"
  format="{imageFormat}"
  (imageCropped)="imageCropped($event)"
  style="display: block; position: absolute; top: 0; left: -3072px"
></image-cropper>

<div *ngIf="type === FileInputDisplayTypes.UserImage" class="post-image">
  <input
    [id]="'file-input-' + uniqueId"
    type="file"
    [accept]="accept ? accept : '*'"
    (change)="onFileChanged($event)"
  />
  <label [for]="'file-input-' + uniqueId">
    <div class="post-image__button">
      <img
        src="assets/images/camera.png"
        srcset="assets/images/camera@2x.png 2x, assets/images/camera@3x.png 3x"
      />
      <span *ngIf="!imageUrl">{{ "CREATE_POST.IMG" | translate }}</span
      ><span *ngIf="imageUrl">{{ "CREATE_POST.IMG_CHANGE" | translate }}</span>
    </div>
  </label>

  <div class="loader" *ngIf="isLoading">
    <mat-progress-spinner
      diameter="50"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <img *ngIf="imageUrl" [src]="imageUrl" alt="" />
  <button
    *ngIf="imageUrl"
    (click)="saveImage()"
    class="button button__main transparent"
  >
    {{ "PROFILE.USER_PHOTOS.SAVE" | translate }}
  </button>
</div>
