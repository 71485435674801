<ng-container *ngIf="this.popupData.action === 'giftCard'; then showGiftCardData; else showTrustlyData"></ng-container>
<ng-template #showGiftCardData>
    <div class="showGiftCardPayout">
        <div class="wrapper">
          <span class="title">{{ "MY_WALLET.POPUP.GIFT_CARD.HEADER" | translate }}</span>
    
          <div class="amount-info">
            <p class="total">{{ "MY_WALLET.POPUP.GIFT_CARD.AMOUNT" | translate }}<span> {{popupData.moneyAmount}} SEK</span></p>
            <div class="divider"></div>

            <form [formGroup]="giftCardAmountForm" *ngIf="!viewStatus.giftCardSent && !viewStatus.showConfirmEmail">
                <div class="field no-padding">
                    <div class="input">
                        <mat-select id="cardValue" formControlName="amount" placeholder="{{'MY_WALLET.POPUP.GIFT_CARD.INPUT_PLACEHOLDER' | translate}}">
                            <mat-option *ngFor="let giftCardValue of giftCardsArray" [value]="giftCardValue">
                                {{giftCardValue}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <p *ngIf="giftCardAmountForm.controls['amount'].invalid &&
                            giftCardAmountForm.controls['amount'].touched &&
                            giftCardAmountForm.controls['amount'].errors.required"
                        class="input-error">{{ "MY_WALLET.POPUP.INPUT_ERROR.REQUIRED"|translate}}</p>
                    <p *ngIf="giftCardAmountForm.controls['amount'].invalid &&
                                (giftCardAmountForm.controls['amount'].touched && giftCardAmountForm.controls['amount'].dirty) &&
                                giftCardAmountForm.controls['amount'].errors.max"
                        class="input-error">{{ "MY_WALLET.POPUP.INPUT_ERROR.MORE"|translate}}</p>
                    <p *ngIf="giftCardAmountForm.controls['amount'].invalid &&
                            (giftCardAmountForm.controls['amount'].touched && giftCardAmountForm.controls['amount'].dirty) &&
                            giftCardAmountForm.controls['amount'].errors.min"
                        class="input-error">{{ "MY_WALLET.POPUP.INPUT_ERROR.LESS"|translate}}</p>
                </div>
            </form>
            <form [formGroup]="emailCardForm" *ngIf="!viewStatus.giftCardSent && viewStatus.showConfirmEmail">
                <div class="field no-padding">
                    <input type="text" formControlName="email" placeholder="{{ 'MY_WALLET.POPUP.GIFT_CARD.USE_NEW_EMAIL.INPUT.PLACEHOLDER'|translate }}">
                    <p *ngIf="emailCardForm.controls['email'].invalid &&
                                (emailCardForm.controls['email'].touched && emailCardForm.controls['email'].dirty) &&
                                emailCardForm.controls['email'].errors.email"
                        class="input-error email">{{ "MY_WALLET.POPUP.GIFT_CARD.USE_NEW_EMAIL.INPUT.INVALID" | translate}}</p>
                </div>
                <div class="gift-card-description new-email-checkbox">
                    <input type="checkbox" id="useNewEmail" (change)="checkCheckbox(useNewEmailCheckbox.checked)" #useNewEmailCheckbox>
                    <label for="useNewEmail">{{ "MY_WALLET.POPUP.GIFT_CARD.USE_NEW_EMAIL" | translate }}</label>
                </div>
            </form>
            <span class="gift-card-description">{{ "MY_WALLET.POPUP.GIFT_CARD.DESCRIPTION" | translate }}</span>
            <div class="center">

                <ng-container *ngIf="viewStatus.giftCardSent; then showGiftCardSent; else showActionButtons"></ng-container>
                <ng-template #showActionButtons>
                    <ng-container *ngIf="viewStatus.showConfirmEmail; then confirmEmail; else setGiftCardAmount"></ng-container>
                    <ng-template #setGiftCardAmount>
                        <button class="button button__alt" (click)="toggleShowConfirmEmail()">{{ "MY_WALLET.POPUP.GIFT_CARD.ACTION_BUTTON" | translate }}</button>
                    </ng-template>
                    <ng-template #confirmEmail>
                        <button class="button button__alt" (click)="requestGiftCard()">{{ "PAYOUT.GIFT_CARDS_CONFIRM" | translate }}</button>
                    </ng-template>
                </ng-template>
                <ng-template #showGiftCardSent>
                    <button class="button button__alt full-width transparent">{{ "PAYOUT.GIFT_CARDS_SENT" | translate }}</button>
                </ng-template>
                
                <button class="button button__alt disabled" (click)="closePopup()">{{ "ADMIN.CLOSE_BUTTON" | translate }}</button>
            </div>
            <div class="gift-card footer">{{ "MY_WALLET.POPUP.GIFT_CARD.DESCRIPTION.FOOTER" | translate }}</div>       
          </div>
    
        </div>
      </div>
</ng-template>
<ng-template #showTrustlyData>
    <div class="showTrustlyPayout">
        <div class="wrapper">
          <span class="title">{{ "MY_WALLET.POPUP.TRUSTLY.HEADER" | translate }}</span>
          <div class="amount-info" [ngClass]="{'set-minHeight': !popupData.isMobileDevice}">
            <p class="total" *ngIf="!viewStatus.isShowTrustly">{{ "MY_WALLET.POPUP.GIFT_CARD.AMOUNT" | translate }}<span> {{popupData.moneyAmount}} SEK</span></p>
            
            <ng-container *ngIf="viewStatus.isShowTrustly; then showTrustly; else showForm"></ng-container>
            <ng-template #showForm>
                <p class="description trustly">{{ "PAYOUT.EXCLAMATION" | translate }}</p>
                <div class="center">
                    <button class="button button__alt trustly" (click)="switchToGiftCards()">{{"MY_WALLET.POPUP.TRUSTLY.SHOW_GIFTCARDS_BUTTON"|translate}}</button>
                </div>
                <form [formGroup]="form">
                    <div class="field no-padding truslty">
                        <input formControlName="ammount" type="number" placeholder="{{'MY_WALLET.POPUP.TRUSTLY.INPUT_PLACEHOLDER'|translate}}" appNumberOnly>
                        <p *ngIf="form.controls['ammount'].invalid &&
                            form.controls['ammount'].touched &&
                            form.controls['ammount'].errors.required"
                            class="input-error">{{ "MY_WALLET.POPUP.INPUT_ERROR.REQUIRED"|translate}}</p>
                        <p *ngIf="form.controls['ammount'].invalid &&
                                    (form.controls['ammount'].touched && form.controls['ammount'].dirty) &&
                                    form.controls['ammount'].errors.max"
                            class="input-error">{{ "MY_WALLET.POPUP.TRUSTLY.INPUT_ERROR.MORE"|translate}}</p>
                        <p *ngIf="form.controls['ammount'].invalid &&
                                (form.controls['ammount'].touched && form.controls['ammount'].dirty) &&
                                form.controls['ammount'].errors.min"
                            class="input-error">{{ "MY_WALLET.POPUP.TRUSTLY.INPUT_ERROR.LESS"|translate}}</p>
                    </div>
                    <div class="center">
                        <button class="button button__alt" (click)="payout(form)">{{"PAYOUT.PAYOUT" | translate}}</button>
                        <button class="button button__alt disabled" (click)="closePopup()">{{"ADMIN.CLOSE_BUTTON" | translate}}</button>
                    </div>
                </form>
            </ng-template>
            <ng-template #showTrustly>
                <div class="payout trustly">
                    <iframe [src]="viewStatus.trustlyUrl ? viewStatus.trustlyUrl : null" frameborder="0" class="trustly" (load)="viewStatus.trustlyUrl ? loadedIFrame() : null"></iframe>
                </div>
            </ng-template>
    
    
          </div>
        </div>
      </div>
</ng-template>