import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { StateService } from '../../../services/state.service';
import { RouterInterceptorService } from '../../../services/router-interceptor.service';
import { MessagingService } from '../../../services/messaging.service';
import { ApiAccountService } from 'src/app/services/api/api-account.service';
import { UserNotificationsModel } from 'src/app/models/user-notifications.model';
import { CordovaService } from '../../../services/cordova.service';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { Subscription } from 'rxjs';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.less' ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
	backUrl = false;
	isLoadingNotifications = false;
	isMenuOpen = false;
	isScrollToTop = false;
	markAllAsRead = false;
	messages: UserNotificationsModel;
	needUpdate = false;
	newNotificationsCount = 0;
	notificationsIsOpen = false;
	showBackButton = false;
	showMenuItem = false;
	showNotifications = false;
	toGroup = false;
	user;

	getNotifications$: Subscription = Subscription.EMPTY;

	constructor(
		private accountApiService: ApiAccountService,
		private authService: AuthService,
		private cordovaService: CordovaService,
		private groupsApiService: ApiGroupsService,
		private messagingService: MessagingService,
		private routerInterceptorService: RouterInterceptorService,
		private stateService: StateService,
		private passingDataService: PassingDataService,
		private cdr: ChangeDetectorRef
	) {
		this.getNotifications = this.getNotifications.bind(this);
		this.newNotificationHandler = this.newNotificationHandler.bind(this);
	}

	newNotificationHandler(payload) {
		this.needUpdate = true;
		if (payload) {
			if (payload.data.type) {
				switch (payload.data.type) {
					case 'FriendStarted':
						this.groupsApiService.dropIsAll();
						break;
					case 'GroupFinish' ||
						'InitWeightBeforeStart' ||
						'InitWeightOnStart' ||
						'FinalWeight' ||
						'WaitForModeration' ||
						'TeamMemberAskedToJoin' ||
						'TeamMemberLeft' ||
						'TeamJoinRequestApproved' ||
						'TeamJoinRequestRejected':
						this.groupsApiService.dropIsMy();
						if (JSON.parse(payload.data.data).groupId) {
							this.groupsApiService.deleteItemFormLastGroupFetchArray(
								JSON.parse(payload.data.data).groupId
							);
						}
						break;
					case 'Disqualified' || 'ChangeStartDate' || 'Participant' || 'KickedFromGroup' || 'FriendJoined':
						if (JSON.parse(payload.data.data).groupId) {
							this.groupsApiService.dropAllCacheData(JSON.parse(payload.data.data).groupId);
						}
						break;
					default:
						break;
				}
			}
		}
	}

	ngOnInit() {
		if (this.authService.isAuthorized()) {
			this.authService.localStorageUser.subscribe((data) => {
				this.user = data;
				this.user.image = this.user.image ? this.user.image.replace(/\"/g, '') : '';
			});
			this.showNotifications = true;
			this.showMenuItem = true;
			this.stateService.routeData.subscribe((data) => {
				this.showBackButton = data && data.showBackButton;
				this.toGroup = data && data.toGroup;
				this.isScrollToTop = data && data.scrollToTop;
				if (this.isScrollToTop) {
					this.scrollToTop();
				}
			});

			this.getNotifications();
			if (this.cordovaService.onCordova) {
				this.cordovaService.onMessage.subscribe(this.newNotificationHandler);
			} else {
				this.messagingService.notificationRecieved.subscribe(this.newNotificationHandler);
			}
		} else {
			this.showNotifications = false;
			this.showMenuItem = false;
			this.user = null;
		}
	}

	ngAfterViewInit() {
		this.passingDataService.notificationsCount$.subscribe((value) => {
			this.newNotificationsCount = value;
			this.cdr.detectChanges();
		});
	}

	openNotifications() {
		this.notificationsIsOpen = true;
		// Notifications will be fetched each time user open notification menu
		this.getNotifications(1);
	}

	closeNotifications() {
		this.notificationsIsOpen = false;
		this.isLoadingNotifications = false;
	}

	hasDeletedNotifications() {
		this.getNotifications(1);
	}

	getNotifications(pageIndex = null) {
		this.getNotifications$.unsubscribe();
		let tmpData = null;
		const __self = this;
		this.isLoadingNotifications = true;
		this.getNotifications$ = this.accountApiService
			.getNotifications(pageIndex)
			.subscribe((data: UserNotificationsModel) => {
				tmpData = data;
				if (this.messages) {
					if (pageIndex !== null) {
						if (pageIndex > 1) {
							tmpData.notifications.forEach((element) => {
								__self.messages.notifications.push(element);
							});
						} else {
							__self.messages = tmpData;
						}
					}
				} else {
					__self.messages = tmpData;
				}
				__self.newNotificationsCount = tmpData.notificationsUnRead;
				this.passingDataService.setNotificationsCount('setUnread', tmpData.notificationsUnRead);
				tmpData = null;
				/* NEW CODE BELOW */
				if (
					__self.messages.notificationsTotal === __self.messages.notifications.length &&
					__self.messages.notificationsUnRead
				) {
					this.markAllAsRead = true;
				} else {
					this.markAllAsRead = false;
				}
				this.isLoadingNotifications = false;
			});
	}

	loadMoreIndex(pageIndex) {
		pageIndex === 1 ? this.getNotifications(pageIndex) : this.getNotifications(pageIndex);
	}

	menuIsOpen(trigger: boolean) {
		this.isMenuOpen = trigger;
	}

	scrollToTop() {
		const profileTag = document.querySelector('app-profile');
		let isProfileHeader = null;
		if (profileTag) {
			isProfileHeader = profileTag.querySelectorAll('.profile__header')[0];
		}
		if (isProfileHeader) {
			isProfileHeader.scrollIntoView(true);
		}
		this.isScrollToTop = false;
	}

	goBack() {
		this.showBackButton = false;
		const routeHistory = AppLocalStorage.getObject('routerHistory');
		if (routeHistory && routeHistory.length >= 2) {
			this.routerInterceptorService.goBack();
		} else {
			this.routerInterceptorService.goBack(this.toGroup);
		}
	}
}
