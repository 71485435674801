import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mentionHightlight'
})
export class MentionHightlightPipe implements PipeTransform {
	transform(text: string, additionalClass = null): string {
		if (text) {
			return text.replace(
				/<@(.*?)>/g,
				`<b style="color: #1ec6c1" ${additionalClass ? `class=${additionalClass}` : null}>@$1</b>`
			);
		} else {
			return null;
		}
	}
}
