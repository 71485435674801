import { Component, OnInit, Input } from '@angular/core';
import { AppConstants } from 'src/app/utils/app.constants';
import { Router } from '@angular/router';
import { GroupModel } from 'src/app/models/group.model';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { TeamService } from '../service/team-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { EditTeamInterfaceDTO } from '../../../../../create-team-module/components/team-creation/team-creation.component';
import { StateService } from 'src/app/services/state.service';

@Component({
	selector: 'app-group-statistics-my-team',
	templateUrl: './group-statistics-my-team.component.html',
	styleUrls: [ './group-statistics-my-team.component.less' ]
})
export class GroupStatisticsMyTeamComponent implements OnInit {
	@Input() group: GroupModel;
	@Input() team: EditTeamInterfaceDTO;
	@Input() isTeamCaptain: boolean;

	baseURL = AppConstants.api.mediaFilesUrl;
	isLoading = false;
	isShowLeamTeamConfirmation = false;
	teamHasParticipants: number;
	teamParticipantNeeded: string;
	needMoreParticipants: number;

	constructor(
		private router: Router,
		private passingDataService: PassingDataService,
		private teamsService: TeamService,
		private errorsProcessorService: ErrorsProcessorService,
		private topNotificationsService: TopNotificationsService,
		private loadingSpinnerService: LoadingSpinnerService,
		private stateService: StateService
	) {}

	ngOnInit() {
		if (this.group.isCanJoin) {
			this.isLoading = true;
			this.teamsService.getTeamComposition(Number(this.group.id), Number(this.group.teamId)).subscribe(
				(data) => {
					this.needMoreParticipants = data.availableTeamSize;
					this.teamParticipantNeeded = `${data.minTeamSize} - ${data.maxTeamSize}`;
					this.teamHasParticipants = data.currentTeamSize;
				},
				(error: HttpErrorResponse) => {
					this.handleError(error);
					this.isLoading = false;
				},
				() => {
					this.isLoading = false;
				}
			);
		}
	}

	editTeam() {
		this.setCurrentTabsValues();
		this.stateService.setHideBottomMenu('hide');
		this.router.navigate([ `/home/create-team/${this.team.id}/edit` ], { state: { id: this.group.id } });
	}

	/* goToUserProfile(id: number) {
		if (id !== this.auth.getUser().id) {
			this.setCurrentTabsValues();
			this.router.navigate([ `home/profile/${id}` ]);
		}
	} */

	composePrivatePost() {
		this.passingDataService.setMainActiveTab('feed');
		this.passingDataService.setScrollToComposePrivatePost('.header');
	}

	setCurrentTabsValues() {
		this.passingDataService.setMainActiveTab('statistics');
	}

	handleError(httpErrorResponse: HttpErrorResponse) {
		const error = this.errorsProcessorService.process(httpErrorResponse);
		this.topNotificationsService.notify(error, 'error');
		this.loadingSpinnerService.hide.next();
	}
}
