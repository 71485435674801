import {StorageDecorator} from './storage.decorator';

@StorageDecorator('localStorage')
export class AppLocalStorage {
    static add(key: string, item: any): void {
    }

    static get(key: string): any {
    }

    static getObject(key: string): any {
    }

    static remove(key: string): void {
    }

    static clear(): void {
    }
}
