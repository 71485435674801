<div class="showGiftCardPayout">
    <div class="wrapper">
      <span class="title">{{ "MY_POINTS.POPUP.HEADER" | translate }}</span>
      
      <div class="amount-info">
        <p class="total">{{ "MY_POINTS.POPUP.AMOUNT" | translate }}<span>{{popupData.totalPointsAmount}}</span></p>
  
        <div class="field no-padding">
        <span class="footer">{{ "MY_POINTS.POPUP.DESCRIPTION" | translate }}</span>
        <form [formGroup]="form">
            <input type="number" placeholder="{{'MY_POINTS.POPUP.INPUT_PLACEHOLDER'|translate }}" formControlName="amount" appNumberOnly>
            <p *ngIf="form.controls['amount'].invalid &&
                        form.controls['amount'].touched &&
                        form.controls['amount'].errors.required"
                class="input-error">{{ "MY_POINTS.POPUP.INPUT_ERROR.REQUIRED"|translate}}</p>
            <p *ngIf="form.controls['amount'].invalid &&
                        (form.controls['amount'].touched && form.controls['amount'].dirty) &&
                        form.controls['amount'].errors.max"
                class="input-error">{{ "MY_POINTS.POPUP.INPUT_ERROR.MORE"|translate}}</p>
            <p *ngIf="form.controls['amount'].invalid &&
                        (form.controls['amount'].touched && form.controls['amount'].dirty) &&
                        form.controls['amount'].errors.min"
                class="input-error">{{ "MY_POINTS.POPUP.INPUT_ERROR.LESS"|translate}}</p>
        </form>
        </div>
      </div>
      <div class="center">
        <button class="button button__alt" (click)="transferPointsToTheWallet()">
          {{ "MY_POINTS.POPUP.ACTION_BUTTON" | translate }}
        </button>
        <button class="button button__alt disabled" (click)="closePopup()">
          {{ "ADMIN.CLOSE_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>