<div class="doughnut" *ngIf="isGroupStarted && statistics">
    <div class="doughnut__canvas">
      <div class="percent-value">{{statisticsPercent}}</div>
      <canvas baseChart
              [data]="doughnutChartData"
              [chartType]="doughnutChartType"
              [options]="doughnutOptions"
              [colors]="doughnutColors">
      </canvas>
    </div>
    <p class="text">{{ "GROUP.STATISTICS_TEXT" | translate }}</p>
    <div class="doughnut__data">
      <div class="stats-data">{{ "GROUP.STATISTICS.START_WEIGHT" | translate }} <span>{{statistics.initialWeight}}Kg</span></div>
      <div class="stats-data">{{ "GROUP.STATISTICS.CURRENT_WEIGHT" | translate }} <span>{{statistics.currentWeight}}Kg</span></div>
      <div class="stats-data">{{ "GROUP.STATISTICS.GOAL_WEIGHT" | translate }} <span>{{statistics.targetWeight}}Kg</span></div>
    </div>
    <a class="button fullwidth button__main"
    *ngIf="(this.group.isOfficialWeightSent && this.group.isUnofficialWeightTime && this.group.isParticipant) ||
           (!this.group.officialWeighins && this.group.isUnofficialWeightTime && this.group.isParticipant)"
    (click)="registerWeight()">
      {{ "GLOBAL.UPDATE_WEIGHT" | translate }} 
    </a>
  </div>
  <div class="not-started-group" *ngIf="!isGroupStarted">{{ "GLOBAL.GROUP_NOT_STARTED" | translate }}</div>  