<div class="popup-wrapper" [class.show]="this.showEditMenu" (click)="toggleEditMenu()"></div>

  <span class="private-team-post" *ngIf="post.teamPost">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <path fill="none" d="M0 0h12v12H0z"/>
      <path fill="#222" d="M11 4.5h-.5v-1a2.5 2.5 0 1 0-5 0v1H5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1zM8 9a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm1.55-4.5h-3.1v-1a1.55 1.55 0 1 1 3.1 0z" transform="translate(-2 -.5)"/>
  </svg> {{ "GROUP.POST.PRIVATE_POST" | translate }}</span>

  <span class="pinned" *ngIf="post.pin">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <g>
        <path fill="none" d="M0 0H12V12H0z"/>
    </g>
    <g>
        <path fill="#222" fill-rule="evenodd" d="M10.671 5.609V3.031h.516a.517.517 0 0 0 .516-.516.517.517 0 0 0-.516-.515H6.031a.517.517 0 0 0-.516.516.517.517 0 0 0 .516.516h.516v2.577A1.545 1.545 0 0 1 5 7.156v1.031h3.078V11.8l.516.516.516-.516V8.187h3.109V7.156a1.545 1.545 0 0 1-1.548-1.547z" transform="translate(2.578 1.031) translate(-5 -2)"/>
    </g>
  </svg> {{ "GROUP.POST.PINNED_POST" | translate }}</span>

<div class="header" *ngIf="authedUser">
  <div class="image-wrapper">
    <img (click)="goToProfile()"
      src="{{post.userImage | userAvatarUrl}}" alt="" class="image">
  </div>
  <div class="content">
    <div class="title"><span (click)="goToProfile()">{{post.userName}}</span>&nbsp;<span class="city" (click)="emitCityName(post.userCity)">{{post.userCity}}</span></div>
    <app-time-ago class="time-ago" [params]="[{'dateTime': post.dateTime}]"></app-time-ago>
    <div class="edit" (click)="toggleEditMenu()"><img src="assets/images/Edit.png" srcset="assets/images/Edit@2x.png 2x,
              assets/images/Edit@3x.png 3x">
    </div>
    <div class="edit-popup" [class.show]="this.showEditMenu">
      <ng-container *ngIf="(adminUser || isCreator) && !post.teamPost">
        <p class="edit-popup-entity" (click)="toggleEditMenu(); togglePin()">
          <b *ngIf="!post.pin">{{"GROUP.POST.PIN"|translate}}</b>
          <b *ngIf="post.pin">{{"GROUP.POST.UNPIN"|translate}}</b>
        </p>
        <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
      </ng-container>
      <p class="edit-popup-entity" (click)="toggleEditMenu(); updateFavorites()">
        <b *ngIf="!post.isFavorites">{{ "GROUP.POST.ADD_TO_FAVORITES" | translate }}</b>
        <b *ngIf="post.isFavorites">{{ "GROUP.POST.FRMOVE_FROM_FAVORITES" | translate }}</b>
      </p>
      <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>

      <ng-container *ngIf="isMinePost || isAdmin">
        <div class="is-mine">
          <p class="edit-popup-entity" (click)="toggleEditMenu(); editPost(post.id)">
            <b>{{ "GROUP.POST.EDIT" | translate }}</b></p>
          <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
          <p class="edit-popup-entity" (click)="deletePost(post.id); toggleEditMenu();">
            <b>{{ "GROUP.POST.DELETE" | translate }}</b></p>
            <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
        </div>
      </ng-container>
      <p class="edit-popup-entity alt-color" (click)="toggleEditMenu()"><b>{{ "CREATE_POST.CANCEL" | translate }}</b></p>
    </div>
  </div>
</div>
<div class="main" *ngIf="authedUser">
  <div class="text" [ngClass]="{'clickable': post.metadata.joinedUserId}">
    <span (click)="getSelection()" [innerHTML]="post.text | linkHightlight | mentionHightlight:'mention-user-found-post' | safe:'html'"></span></div>

    <ng-container
        *ngIf="isOldStyleMedia; then oldStyleMedia; else newStyleMedia">
    </ng-container>
    <ng-template #oldStyleMedia>
      <div class="image">
        <img *ngIf="postImageUrl" [src]="postImageUrl" alt="">
        <video *ngIf="postVideoUrl" preload="metadata" playsinline>
          <source [src]="postVideoUrl + '#t=0.5'" />
          <p class="text-centrer">{{ 'POST.VIDEO_IS_NOT_SUPPORTED' | translate }}</p>
        </video>
      </div>
      </ng-template>

      <ng-template #newStyleMedia>
        <app-slider [itemsLength]="post.media.length" [itemsPerSlide]="1" [dots]="post.media.length > 1 ? true : false"
                    [postsSlider]="true">
          <div class="slider__item" *ngFor="let mediaItem of post.media">
            <img *ngIf="mediaItem.contentType === 'image'" style="object-fit: contain;"
                  [src]="mediaLinkBuilder(mediaItem.contentType, mediaItem.fileName) | safe:'resourceUrl'"
                  (click)="isFullscreenToggle(mediaItem.fileName)">
            <video *ngIf="mediaItem.contentType === 'video'" class="newVideoContainer"
                    [attr.preload]="'metadata'"
                    controls
                    [attr.muted]="isIOSSafari ? '' : null"
                    [attr.playsinline]="isIOSSafari ? '' : null"
                    [attr.webkit-playsinline]="isIOSSafari ? '' : null">
              <source [src]="mediaLinkBuilder(mediaItem.contentType, mediaItem.fileName) | safe:'resourceUrl'"
                      [type]="videoTypeSetter(mediaItem.fileName)"/>
              <p class="text-centrer">{{ 'POST.VIDEO_IS_NOT_SUPPORTED' | translate }}</p>
            </video>
          </div>
        </app-slider>
      </ng-template>

</div>
<div class="footer" *ngIf="authedUser">
  <div class="buttons">
    <div class="left-side">
      <div (click)="sendLike()">
        <img class="like" *ngIf="!post.userHasLikePost" src="assets/images/heart-gray.png" srcset="assets/images/heart-gray@2x.png 2x,
                     assets/images/heart-gray@3x.png 3x">
        <img class="like active" *ngIf="post.userHasLikePost" src="assets/images/heart-red.png" srcset="assets/images/heart-red@2x.png 2x,
                     assets/images/heart-red@3x.png 3x">
        <span>{{post.amountOfLikes}}</span>
      </div>
      <div (click)="toggleCommentsOpen()">
        <img class="comments-toggler" src="assets/images/comment.png" srcset="assets/images/comment@2x.png 2x,
                       assets/images/comment@3x.png 3x">
        <span>{{post.amountOfComments}}</span>
      </div>
    </div>
    <div class="right-side" *ngIf="isGroupParticipant" (click)="updateFavorites(true)">
      <svg *ngIf="!post.isFavorites" width="24.999" height="24" version="1.1" viewBox="0 0 24.999 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(.78124 0 0 .77419 -.00037601 -.38632)" style="fill:#1ec6c1">
          <path id="star" d="m31.881 12.056c-0.277-0.799-0.988-1.384-1.844-1.511l-8.326-1.238-3.619-7.514c-0.381-0.789-1.196-1.294-2.092-1.294s-1.711 0.505-2.092 1.294l-3.619 7.514-8.327 1.238c-0.855 0.127-1.566 0.712-1.842 1.511-0.275 0.801-0.067 1.683 0.537 2.285l6.102 6.092-1.415 8.451c-0.144 0.851 0.225 1.705 0.948 2.203 0.397 0.273 0.864 0.412 1.331 0.412 0.384 0 0.769-0.094 1.118-0.281l7.259-3.908 7.26 3.908c0.349 0.187 0.734 0.281 1.117 0.281 0.467 0 0.934-0.139 1.332-0.412 0.723-0.498 1.09-1.352 0.947-2.203l-1.416-8.451 6.104-6.092c0.603-0.603 0.81-1.485 0.537-2.285zm-8.293 6.806c-0.512 0.51-0.744 1.229-0.627 1.934l1.416 8.451-7.26-3.906c-0.348-0.188-0.732-0.281-1.118-0.281-0.384 0-0.769 0.094-1.117 0.281l-7.26 3.906 1.416-8.451c0.118-0.705-0.114-1.424-0.626-1.934l-6.102-6.092 8.326-1.24c0.761-0.113 1.416-0.589 1.743-1.268l3.621-7.512 3.62 7.513c0.328 0.679 0.982 1.154 1.742 1.268l8.328 1.24z" style="fill:#1ec6c1"/>
        </g>
      </svg>
      <svg *ngIf="post.isFavorites" width="24.999" height="24" version="1.1" viewBox="0 0 24.999 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(.78124 0 0 .77419 -.00037601 -.38632)" style="fill:#1ec6c1">
          <path id="star" d="m31.881 12.056c-0.277-0.799-0.988-1.384-1.844-1.511l-8.326-1.238-3.619-7.514c-0.381-0.789-1.196-1.294-2.092-1.294s-1.711 0.505-2.092 1.294l-3.619 7.514-8.327 1.238c-0.855 0.127-1.566 0.712-1.842 1.511-0.275 0.801-0.067 1.683 0.537 2.285l6.102 6.092-1.415 8.451c-0.144 0.851 0.225 1.705 0.948 2.203 0.397 0.273 0.864 0.412 1.331 0.412 0.384 0 0.769-0.094 1.118-0.281l7.259-3.908 7.26 3.908c0.349 0.187 0.734 0.281 1.117 0.281 0.467 0 0.934-0.139 1.332-0.412 0.723-0.498 1.09-1.352 0.947-2.203l-1.416-8.451 6.104-6.092c0.603-0.603 0.81-1.485 0.537-2.285zm-7.504 17.191c-2.666-19.94-6.8466-5.3309-16.755 0 17.618-8.814 17.256-1.3713 8.9195-7.5451 10.847-2.4499 2.9078 12.494 5.7962 7.151 4.5972 8.2992-5.8389-18.136 5.313-16.476-15.268-7.3219-5.0812 6.0824-3.2737 16.87z" style="fill:#1ec6c1"/>
        </g>
      </svg>{{"GROUP.POST.ADD_TO_FAVORITES"|translate}}
    </div>
  </div>
  <div class="comments" *ngIf="isCommentsOpen" [@slideInOut]="isCommentsOpen">

    <form [formGroup]="form" *ngIf="isMineGroup || isAdmin" [@slideInOut]="isCommentsOpen" [ngClass]="{'expand' : expandForm === true}">
      <div class="field">
        <label [for]="'text-' + post.id"><img src="assets/images/comment_grey.png" srcset="assets/images/comment_grey@2x.png 2x,
                  assets/images/comment_grey@3x.png 3x">{{'CREATE_COMMENT.TITLE' | translate}}</label>
        <textarea [id]="'text-' + post.id" formControlName="text" [rows]="3"
          placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}" [mention]="mentionUsers"
          [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}" (opened)="disableButtonToggler('opened')" (closed)="disableButtonToggler('closed')" #textArea></textarea>
        <div class="wr">
          <div class="err-wr">
            <span class="error-validation"
              *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
              {{ "GROUP.POST.COMMENTS.LENGTH" | translate }}
            </span>
          </div>
          <span class="char-counter">{{textArea.value.length}} / {{commentLength}}</span>
        </div>
      </div>

      <div class="buttons__wrapper">
        <button class="button button__main transparent" [disabled]="form.invalid" (click)="addComment()">
          {{ 'CREATE_POST.SEND' | translate }}
        </button>
      </div>
      <div class="spinner" *ngIf="isCommentSending">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
      </div>
    </form>

    <div class="list" [@slideInOut]="isCommentsOpen">
      <div *ngIf="comments.length" id="{{post.id}}">
      <div class="comment" *ngFor="let comment of comments; let i = index; trackBy: trackByItems" id="commentId-{{comment.id}}">
          <div class="image-wrapper" (click)="goToProfile(comment.userId, comment.id)">
            <img
              src="{{comment.userImage | userAvatarUrl}}"
              alt="" class="image">
          </div>
          <div class="content">
            <div class="content-wrapper">
              <img src="assets/images/comment_grey.png" srcset="assets/images/comment_grey@2x.png 2x,
                      assets/images/comment_grey@3x.png 3x">
            <div class="title" (click)="goToProfile(comment.userId, comment.id)">{{comment.userName}} <span class="city">{{comment.userCity}}</span></div>
              <app-time-ago class="time-ago" [params]="[{'dateTime': comment.dateTime}]"></app-time-ago>
            </div>

            <div class="text" id="text{{i}}">
              <span (click)="getSelection(comment.id)" [innerHTML]="comment.text | linkHightlight | mentionHightlight:'mention-user-found-comment' | safe:'html'"></span>
            </div>

            <div class="buttons">
              <div class="left-side">
              <div class="comment-like" (click)="sendCommentLike(comment)">
                <img *ngIf="!comment.userHasLikeComment" src="assets/images/heart-gray.png" srcset="assets/images/heart-gray@2x.png 2x,
                            assets/images/heart-gray@3x.png 3x">
                <img *ngIf="comment.userHasLikeComment" src="assets/images/heart-red.png" srcset="assets/images/heart-red@2x.png 2x,
                            assets/images/heart-red@3x.png 3x">
                <span>{{comment.amountOfLikes}}</span>
              </div>
              <div *ngIf="!comment.parentCommentId" class="comment-like" (click)="toggleCommentsOnCommentOpen(comment.id)">
                <img src="assets/images/comment.png" srcset="assets/images/comment@2x.png 2x,
                                 assets/images/comment@3x.png 3x">
                <span>{{comment.amountOfSubcomments || 0}}</span>
              </div>
              </div>
            </div>

            <app-subcomments
                [toggle]="toggle"
                [comment]="comment"
                [currentUserId]="currentUser.id"
                [currentUserName]="currentUser.name"
                [mentionUsers]="mentionUsers"
                [isMineGroup]="isMineGroup"
                [postId]="post.id"
                [baseBackURL]="baseBackURL"
                [errorTextUserNotFoundPart1]="errorTextUserNotFoundPart1"
                [errorTextUserNotFoundPart2]="errorTextUserNotFoundPart2"
                (likesCounter)="likesCounterFromSubcomment($event)"
                (addedNewSubcomment)="increaseCommentCounter($event)"
                (deletedSubcomment)="decreaseCommentCounter($event)"></app-subcomments>

            <div class="popup-wrapper" id="popup-wrapper{{i+1}}" (click)="toggleEditMenu(i+1)"></div>
            <div *ngIf="this.comment.userId === this.currentUser.id || isAdmin" class="edit-comment"
              (click)="toggleEditMenu(i+1)"><img src="assets/images/Edit.png" srcset="assets/images/Edit@2x.png 2x,
                      assets/images/Edit@3x.png 3x"></div>
            <div class="edit-popup mini" id="edit-popup{{i+1}}">

              <p class="edit-popup-entity" (click)="toggleEditMenu(i+1); editComment(comment.id, comment.text)">
                <b>{{ "GROUP.POST.EDIT" | translate }}</b></p>
              <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
              <p class="edit-popup-entity" (click)="toggleEditMenu(i+1); deleteComment(comment.id, comment.text)">
              <b>{{ "GROUP.POST.DELETE" | translate }}</b></p>
              <span class="dots-delimeter">&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;</span>
              <p class="edit-popup-entity alt-color" (click)="toggleEditMenu(i+1)"><b>{{ "CREATE_POST.CANCEL" | translate }}</b></p>

          </div>
        </div>
      </div>
      <div *ngIf="!isLoadingComments && (!comments.length || comments.length === 0)" class="noComments">
        {{"GLOBAL.NOTHING_TO_SHOW" | translate}}
      </div>
      <button *ngIf="!isLoadingComments && comments.length !== 0 && comments.length < post.amountOfCommentsPaginator"
        class="button button__main transparent" (click)="loadMoreComments()">
        {{"GLOBAL.LOAD_MORE" | translate}}
      </button>
    </div>

  </div>
</div>
<div class="delete-or-edit-dialog" [class.show]="this.isDialog">
  <div class="delete-or-edit-dialog-main" *ngIf="deleteType === 'Post'">
    <div>
      <div class="header">{{ "GROUP.POST.DELETE_HEADER" | translate }}</div>
      <div class="text" [innerHTML]="post.text | mentionHightlight | safe:'html'"></div>
    </div>
    <div class="delete-or-edit-dialog-buttons">
      <button class="button button__alt" (click)="deletePostOrComment()">
        {{ 'ADMIN.USERS_TABLE.COLUMN_NAME.DELETE' | translate }}
      </button>
      <button class="button button__alt transparent" (click)="closeDialog()">
        {{ 'CREATE_POST.CANCEL' | translate }}
      </button>
    </div>
  </div>

  <div class="delete-or-edit-dialog-main" *ngIf="deleteType === 'Comment' || deleteType === 'SubComment'">
    <div>
      <div class="header">{{ "GROUP.COMMENT.DELETE_HEADER" | translate }}</div>
      <div class="text" [innerHTML]="commentText | mentionHightlight | safe:'html'"></div>
    </div>
    <div class="delete-or-edit-dialog-buttons">
      <button class="button button__alt" (click)="deletePostOrComment(this.commentId)">
        {{ 'ADMIN.USERS_TABLE.COLUMN_NAME.DELETE' | translate }}
      </button>
      <button class="button button__alt transparent" (click)="closeDialog()">
        {{ 'CREATE_POST.CANCEL' | translate }}
      </button>
    </div>
  </div>


  <div class="delete-or-edit-dialog-main" *ngIf="editType === 'Post'">
    <div class="post-wrapper">
      <div class="header">{{ "GROUP.COMMENT.EDIT_HEADER" | translate }}</div>
      <form [formGroup]="postForm" [ngClass]="{'expand' : expandForm === true}">

        <div class="field">
          <textarea id="text" formControlName="postText" [rows]="5"
            placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}" [mention]="mentionUsers"
            [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}" (opened)="disableButtonToggler('opened')" (closed)="disableButtonToggler('closed')" #textArea></textarea>
          <div class="wr">
            <div class="err-wr">
              <span class="error-validation"
                *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
                {{ "GROUP.POSTS.LENGTH" | translate }}
              </span>
            </div>
            <span class="char-counter">{{textArea.value.length}} / {{postLength}}</span>
          </div>
        </div>
        <p *ngIf="teamId" class="private-checkbox">
          <input type="checkbox" id="postTeamPrivate" formControlName="postTeamPrivate">
          <label for="postTeamPrivate">{{ "CREATE_POST.PRIVATE_CHECKBOX"|translate}}</label>
        </p>
        <!-- <div *ngIf="isOldStyleMedia">
          <app-file-input *ngIf="this.post.image" formControlName="postImage" [prevImage]="this.post.image"
          [displayType]="FileInputDisplayTypes.Image" [accept]="'image/*'"></app-file-input>

          <app-file-input *ngIf="this.post.video" formControlName="postImage"
            [displayType]="FileInputDisplayTypes.Video" [accept]="'video/*'"></app-file-input>
        </div> -->
        <!-- <div *ngIf="!isOldStyleMedia"> -->

        <div>
          <ng-template #postInputFile></ng-template>
          <div class="buttons__wrapper" *ngIf="mediaItems.length < maxMediaItems">
            <button class="button button__alt transparent" (click)="addMoreMedia()">
              {{ addMediaButtonText | translate }}
            </button>
          </div>
        </div>


        <div class="buttons__wrapper">
          <button class="button button__main transparent" (click)="closeDialog()">
            {{ 'CREATE_POST.CANCEL' | translate }}
          </button>
          <button class="button button__main" [disabled]="!canUserSavePost" [ngClass]="{'disabled': !canUserSavePost}" (click)="updatePostOrComment()">
            {{ 'GLOBAL.SAVE' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="delete-or-edit-dialog-main" *ngIf="editType === 'Comment'">
    <div>
      <div class="header">{{ "GROUP.COMMENT.EDIT_HEADER" | translate }}</div>
      <form [formGroup]="commentForm" [ngClass]="{'expand' : expandForm === true}">
        <div class="field">
          <textarea formControlName="text" [rows]="3" placeholder="{{'CREATE_POST.TEXT_PLACEHOLDER' | translate}}"
            [mention]="mentionUsers" [mentionConfig]="{mentionSelect: formatSelection, allowSpace: true}" (opened)="disableButtonToggler('opened')" (closed)="disableButtonToggler('closed')" #textArea></textarea>
          <div class="wr">
            <div class="err-wr">
              <span class="error-validation"
                *ngIf="form.controls['text'].errors !== null && form.controls['text'].errors.maxlength">
                {{ "GROUP.POST.COMMENTS.LENGTH" | translate }}
              </span>
            </div>
            <span class="char-counter">{{textArea.value.length}} / {{commentLength}}</span>
          </div>
        </div>
        <div class="buttons__wrapper">
          <button class="button button__main transparent" (click)="closeDialog()">
            {{ 'CREATE_POST.CANCEL' | translate }}
          </button>
          <button class="button button__main" [disabled]="!canUserSaveComment" [ngClass]="{'disabled': !canUserSaveComment}" (click)="updatePostOrComment()">
            {{ 'GLOBAL.SAVE' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
