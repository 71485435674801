<div class="wrapper">
    <ng-container
     *ngIf="isLoading; then showSpinner; else showData"></ng-container>
     
    <ng-template #showSpinner>
        <div class="spinner">
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
        </div>
    </ng-template>

    <ng-template #showData>
        <app-group-doughnut-chart [group]="group" [isGroupStarted]="isGroupStarted"></app-group-doughnut-chart> 

        <app-tabs [tabs]="tabs" [currentTab]="currentTab" [oneLineTabs]="true" (tabChanged)="currentTab = $event" (tabChanged)="getChartData($event)"></app-tabs>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'Weight'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-group-line-chart [chartData]="chartData" *ngIf="showWeight"></app-group-line-chart>
            <p class="stats-text__no-data" *ngIf="!showWeight">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'Waist'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-group-line-chart [chartData]="chartData" *ngIf="showWaist"></app-group-line-chart>
            <p class="stats-text__no-data" *ngIf="!showWaist">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'BMI'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-group-line-chart [chartData]="chartData" *ngIf="showBMI"></app-group-line-chart>
            <p class="stats-text__no-data" *ngIf="!showBMI">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
    
        <div class="profile-box__content profile-box__content--flow"
            *ngIf="group.isTeamMember">
            <div class="button__main full-width team-statistics-top" #teamStatisticsTop> {{ "STATISTICS.MY_TEAM" | translate }}</div>

            <ng-container
             *ngIf="team; then showTeamStatistics; else showSpinner">
            </ng-container>

            <ng-template #showSpinner>
                <div class="spinner">
                    <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
                </div>
            </ng-template>

            <ng-template #showTeamStatistics>
                <div class="statistics-wrapper" *ngIf="team">
                    <app-group-statistics-my-team [group]="group" [team]="team" [isTeamCaptain]="isTeamCaptain"></app-group-statistics-my-team>
                    <app-group-statistics-team-statistics
                        [groupId]="group.id"
                        [groupIsCanJoin]="group.isCanJoin"
                        [team]="team"
                        [teamMembersStatistics]="teamParticipants"
                    ></app-group-statistics-team-statistics>
                    <app-teams-list [groupId]="group.id" [showAtStatistics]="true"></app-teams-list>
                </div>
            </ng-template>

        </div>
    </ng-template>
</div>