import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { TabsComponent } from '../components/_layout/tabs/tabs.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileInputComponent } from '../components/_layout/file-input/file-input.component';
import { PostFileInputComponent } from '../components/_layout/post-file-input/post-file-input.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SliderComponent } from '../components/_layout/slider/slider.component';
import { GroupsPreviewComponent } from '../components/_layout/groups-preview/groups-preview.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from '../pipes/safe.pipe';
import { MomentDateAgoPipe } from '../pipes/moment-date-ago.pipe';
import { ProgressFillPercentagePipe } from '../pipes/progress-fill-percentage.pipe';
import { UserAvatarUrlPipe } from '../pipes/user-avatar-url.pipe';
import { GroupCardComponent } from '../components/_layout/group-card/group-card.component';
import { AuthService } from '../services/auth.service';
import { NotificationImagePipe } from '../pipes/notification-image.pipe';
import { RatingComponent } from '../components/_layout/rating/rating.component';
import { NumberOnlyDirective } from '../directives/only-number.directive';
import { LinkHightlightPipe } from '../pipes/link-hightlight.pipe';
import { MentionHightlightPipe } from '../pipes/mention-hightlight.pipe';

@NgModule({
	declarations: [
		LoadingSpinnerComponent,
		TabsComponent,
		FileInputComponent,
		PostFileInputComponent,
		SliderComponent,
		GroupsPreviewComponent,
		SafePipe,
		MomentDateAgoPipe,
		ProgressFillPercentagePipe,
		UserAvatarUrlPipe,
		GroupCardComponent,
		NotificationImagePipe,
		RatingComponent,
		NumberOnlyDirective,
		LinkHightlightPipe,
		MentionHightlightPipe
	],
	imports: [
		RouterModule,
		CommonModule,
		ReactiveFormsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatProgressBarModule,
		MomentModule,
		FormsModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ HttpClient ]
			},
			isolate: false
		}),
		ImageCropperModule
	],
	exports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatProgressBarModule,
		MomentModule,
		FormsModule,
		LoadingSpinnerComponent,
		TabsComponent,
		FileInputComponent,
		SliderComponent,
		GroupsPreviewComponent,
		SafePipe,
		MomentDateAgoPipe,
		ProgressFillPercentagePipe,
		UserAvatarUrlPipe,
		GroupCardComponent,
		NotificationImagePipe,
		RatingComponent,
		NumberOnlyDirective,
		LinkHightlightPipe,
		MentionHightlightPipe
	],
	providers: [ AuthService ]
})
export class SharedModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
