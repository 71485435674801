/*
  ENV FOR TEST TEAMS-DEV SERVER BUILD with TEAMS flow
*/

export const environment = {
	appVersion: '2.48.1',
	production: true,
	enableServiceWorker: true,
	apiBaseUrl: 'https://apidev.joina.io/api/',
	mediaFilesUrl: 'https://formsatsningencdn.azureedge.net/formsatsningen/',
	baseFrontendUrl: 'https://admintest.joina.io/',
	firebase: {
		apiKey: 'AIzaSyAEhXnlP87uqA4IdSVl7hlSCra8NG_LsXw',
		authDomain: 'formsatsningen.firebaseapp.com',
		databaseURL: 'https://formsatsningen.firebaseio.com',
		projectId: 'formsatsningen',
		storageBucket: '',
		messagingSenderId: '958116224775',
		appId: '1:958116224775:web:61cea56c5d879d3a' // Test web app id
	},
	instagramClientId: '729c51c48b2444d18e8265c02ca5714c',
	instagramAppId: '716181629214358', // Test account which looks on teams server
	instagramAppSecret: 'a6581c71e16ae789d0d85295ecbaa512',
	googleAnalyticsConfig: 'G-QNJYQEXW20' // testing code: G-QNJYQEXW20 (if you want to switch on test - also make chenges into index.html),
};
