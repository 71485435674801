import { ApiBaseService } from './api-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import { PostModel } from '../../models/post.model';
import { CommentModel } from '../../models/comment.model';

@Injectable()
export class ApiFeedService extends ApiBaseService {
	private shouldEraseUlopadedData = new BehaviorSubject('');
	public shouldEraseUlopadedData$ = this.shouldEraseUlopadedData.asObservable();

	constructor(protected http: HttpClient) {
		super(http);
	}

	getPosts(groupId: string, queryParams?): Observable<PostModel[]> {
		return this.getData(`group/${groupId}/posts`, queryParams).pipe(
			map((data: {}) => {
				return _map(data, (item) => {
					return new PostModel(item);
				});
			})
		);
	}

	getPinnedPosts(groupId: string): Observable<PostModel[]> {
		return this.getData(`group/${groupId}/pin-posts`).pipe(
			map((data: {}) => {
				return _map(data, (item) => {
					return new PostModel(item);
				});
			})
		);
	}

	setPostAsPinned(postId: number | string): Observable<any> {
		return this.postData(`post/${postId}/pin`, {});
	}
	setPostAsUnpinned(postId: number | string): Observable<any> {
		return this.postData(`post/${postId}/unpin`, {});
	}

	/*
	POST /api/post/{POST_ID}/pin
POST /api/post/{POST_ID}/unpin
GET /api/group/{GROUP_ID}/pin-posts
	*/

	getCommentsOfComment(parentCommentId: number, queryParams?): Observable<any> {
		return this.getData(`comment/${parentCommentId}/subcomments`, queryParams);
	}

	getCurrentPost(groupId: string, postId: string): Observable<PostModel> {
		return this.getData(`group/${groupId}/post/${postId}`);
	}

  getCurrentPostWithHistory(groupId: string, postId: string): Observable<PostModel[]> {
    return this.getData(`group/${groupId}/post/${postId}/history`);
  }

	getCurrentComment(commentId: string): Observable<CommentModel> {
		return this.getData(`comment/${commentId}`);
	}

	deletePost(postId: number): Observable<any> {
		return this.deletePostOrComments(`post/${postId}`);
	}

	deleteComment(commentId: number): Observable<any> {
		return this.deletePostOrComments(`comment/${commentId}`);
	}

	editPost(postId: number, payload): Observable<any> {
		return this.putData(`post/${postId}`, payload);
	}

	editComment(commentId: number, payload): Observable<any> {
		return this.putData(`comment/${commentId}`, payload);
	}

	sendPost(groupId: string, post: PostModel): Observable<any> {
		return this.postData(`group/${groupId}/post`, post.toPostJson());
	}

	getComments(postId: string, queryParams?): Observable<CommentModel[]> {
		return this.getData(`post/${postId}/comments`, queryParams).pipe(
			map((data: {}) => {
				return _map(data, (item) => {
					return new CommentModel(item);
				});
			})
		);
	}

	sendComment(postId: string, comment: CommentModel, parentCommentId = null): Observable<any> {
		const payload = {
			text: comment.text,
			parentCommentId: parentCommentId
		};
		return this.postData(`post/${postId}/comment`, payload).pipe(
			map((data) => {
				return new CommentModel(data);
			})
		);
	}

	sendLike(postId: string): Observable<any> {
		return this.postData(`post/${postId}/like`, {});
	}

	updateFavorites(postId: string): Observable<any> {
		return this.postData(`post/${postId}/favorites`, {});
	}

	sendCommentLike(postId: string, commentId: string): Observable<any> {
		return this.postData(`post/${postId}/comment/${commentId}/like`, {});
	}

	getUserIdFromName(userName: string): Observable<number> {
		const params = new HttpParams().set('name', String(userName));
		return this.getData(`userProfile/userId`, params);
	}

	updateShouldEraseUlopadedData() {
		this.shouldEraseUlopadedData.next('');
	}
}
