<div class="wrapper">
  <div class="container">
    <div class="content" *ngIf="initPaymentForm">

      <div class="top-bg" [ngClass]="{'success': isPayed}">
        <div class="group-info">
          <div *ngIf="!isPayed">
            <div class="group-info__img" #groupImage>
              <img src="" alt="">
            </div>
            <div class="group-info__title">
              {{group.name}}
            </div>
            <div class="group-info__bet">
              {{"PAYMENT.TO_PAY" | translate}}: {{group.bet}}kr
            </div>
          </div>
          <div *ngIf="isPayed">
            <div class="group-info__title">
              {{"PAYMENT.COMPLETED" | translate}}
            </div>
          </div>
        </div>
      </div>

      <div class="payment-form" *ngIf="!isPayed">
        <iframe [src]="trustlyUrl ? trustlyUrl : null" frameborder="0" class="trustly" (load)="trustlyUrl ? loadedIFrame() : null"></iframe>
      </div>

      <div class="success-content" *ngIf="isPayed">
        <button class="button button__main" [routerLink]="'/home/groups/mine/group/' + group.id">
          {{"PAYMENT.FINISH.BUTTON" | translate}}
        </button>
        <app-groups-preview title="{{'PAYMENT.GROUPS_PREVIEW.TITLE' | translate}}"></app-groups-preview>
      </div>
    </div>
  </div>
</div>
