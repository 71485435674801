import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'progressFillPercentage'
})
export class ProgressFillPercentagePipe implements PipeTransform {
	transform(resultPercent: number): string {
		let style;
		if (resultPercent >= 100) {
			style = `linear-gradient(to left, #1ec6c1 0%, #1ec6c1 100%)`;
		} else {
			style = `linear-gradient(to left, #fff 0%, #F8FDFD ${100 - resultPercent}%, #E3EAF2 100%)`;
		}
		return style;
	}
}

// OLD gradient style with 4 points
/* const backgroungCoverage = resultPercent > 100 ? 100 : resultPercent;
    
		const style = `linear-gradient(90deg, #fb6f44 0%, #fb6f44 ${backgroungCoverage +
      '%'}, #ffffff ${backgroungCoverage + '%'}, #ffffff 100%)`; */
