import {
	Component,
	ElementRef,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
	AfterViewInit,
	EventEmitter
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: [ './slider.component.less' ]
})
export class SliderComponent implements OnInit, AfterViewInit {
	@Input()
	set itemsLength(value: any) {
		this._itemsLength = value;
		setTimeout(() => {
			if (value > 0) {
				this.setSliderDots();
				this.setItemWidth();
			}
		}, 0);
	}

	@Input()
	set itemsPerSlide(value: any) {
		this._itemsPerSlide = value;
		setTimeout(() => {
			if (value > 0) {
				this.setSliderDots();
				this.setItemWidth();
			}
		}, 0);
	}

	@Input()
	set sliderStatusInput(value: number) {
		if (value) {
			this.slideTo(value);
		} else {
			return;
		}
	}

	@Input() arrows = false;
	@Input() arrowsOnText = false;
	@Input() dots = false;
	@Input() isClickableDots = true;
	@Input() isSliderInfinite = false;
	@Input() postsSlider = false;
	@Input() sendSliderStatusBack = false;
	@Input() transparentWhiteDots = false;

	@ViewChild('sliderNav') sliderNav: ElementRef;

	private slider: any;
	private itemWidth: number;
	private _itemsLength: number;
	private _itemsPerSlide = 3;
	sliderDots: any[] = [];
	private sliderPosition = 0;
	private sliderStatus = 0;
	show = false;
	ua = window.navigator.userAgent;
	apple;

	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription;

	get sldrStatus() {
		return this.sliderStatus;
	}

	@Output() currentSliderIndex: EventEmitter<number> = new EventEmitter<number>();

	constructor(private element: ElementRef, private renderer: Renderer2) {}

	ngOnInit() {
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe((resizeEvent) => {
			this.setItemWidth();
		});
		this.apple = !!this.ua.match(/iPad/i) || !!this.ua.match(/iPhone/i) || !!this.ua.match(/Macintosh/i);
	}

	ngAfterViewInit() {
		this.slider = this.element.nativeElement.querySelector('.slider__container');
		this.updateSliderPosition();
	}

	setItemWidth() {
		const sliderWidth = this.element.nativeElement.querySelector('.slider__wrapper').offsetWidth;
		this.itemWidth = sliderWidth / this._itemsPerSlide;
		const items = this.element.nativeElement.querySelectorAll('.slider__item');
		for (const item of items) {
			this.renderer.setStyle(item, 'width', this.itemWidth + 'px');
		}
		this.show = true;
	}

	updateSliderPosition() {
		this.sliderPosition = this.sliderStatus / this._itemsLength * 100;
		/* this.renderer.setStyle(this.slider, 'transform', 'translateX(-' + this.sliderPosition + '%)'); */
		// iOS fix for slider transform
		if (this.apple) {
			this.renderer.setStyle(
				this.slider,
				'-webkit-transform',
				'translate3d(-' + this.sliderPosition + '%, 0, 0)'
			);
		} else {
			this.renderer.setStyle(this.slider, 'transform', 'translateX(-' + this.sliderPosition + '%)');
		}
		this.sendCurrentStatusBack();
	}

	slideLeft() {
		if (this.isStart()) {
			if (this.isSliderInfinite) {
				this.sliderStatus = this._itemsLength - 1;
				this.updateSliderPosition();
				return;
			}
			return;
		}
		this.sliderStatus--;
		this.updateSliderPosition();
	}

	slideRight() {
		if (this.isEnd()) {
			if (this.isSliderInfinite) {
				this.sliderStatus = 0;
				this.updateSliderPosition();
				return;
			}
			return;
		}
		this.sliderStatus++;
		this.updateSliderPosition();
	}

	slideTo(index: number) {
		this.sliderStatus = index;
		this.updateSliderPosition();
	}

	setSliderDots() {
		const dotsLength = Math.ceil(this._itemsLength / this._itemsPerSlide);
		this.sliderDots.length = dotsLength;
	}

	sendCurrentStatusBack() {
		if (this.sendSliderStatusBack) {
			this.currentSliderIndex.emit(this.sliderStatus);
		}
	}

	isStart() {
		return this.sliderStatus - 1 < 0;
	}

	isEnd() {
		return this.sliderStatus + 1 > this._itemsLength - this._itemsPerSlide;
	}

	ngOnDestroy() {
		this.resizeSubscription$.unsubscribe();
	}
}
