<div class="teams-list-header">
    <div class="teams-list-header__back-button">
        <div class="text">{{ "GROUP.TEAMS.LIST_HEADER.CREATE_OR_JOIN_TEAM" | translate }}</div>
        <div class="back-button" (click)="closeTab()">
            <svg viewBox="0 0 24 24">
                <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
            </svg>
            {{ "GROUP.TEAMS.LIST_HEADER.BACK_TO_THE_GROUP" | translate }}
        </div>
    </div>
    <div class="button__main full-width"> {{ "TEAM.JOIN_A_TEAM" | translate }} </div>
</div>