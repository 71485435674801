import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class StateService {
	routeData = new BehaviorSubject(undefined);
	hideBottomMenu$ = new BehaviorSubject(false);
	weightGroupId$ = new BehaviorSubject(null);

	setRouteData(val: any) {
		if (val) {
			this.routeData.next(val);
		}
	}

	setHideBottomMenu(val: 'show' | 'hide') {
		val === 'show' ? this.hideBottomMenu$.next(false) : this.hideBottomMenu$.next(true);
	}

	setWeightGroupId(val: number | 'clear') {
		this.weightGroupId$.next(val === 'clear' ? null : val);
	}
}
