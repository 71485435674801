import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { AuthGuard } from './guards/auth.guard';
import { NonauthGuard } from './guards/nonauth.guard';
import { HomeComponent } from './components/home/home.component';
import { GroupsComponent } from './components/groups/groups.component';
import { GroupComponent } from './components/group/group.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UnauthorizedJoinGroupComponent } from './components/unauthorized-join-group/unauthorized-join-group.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfilePicturesComponent } from './components/_layout/profile-info/profile-pictures/profile-pictures.component';
import { ProfileFriendsComponent } from './components/_layout/profile-info/profile-friends/profile-friends.component';
import { TermsComponent } from './settings-module/components/terms/terms.component';
import { PrivacyComponent } from './settings-module/components/privacy/privacy.component';
import { FaqComponent } from './settings-module/components/faq/faq.component';
import { FacebookComponent } from './components/facebook/facebook.component';
import { AdminGuard } from './guards/admin.guard';
import { InvitesComponent } from './components/invites/invites.component';
import { HowItWorksComponent } from './settings-module/components/how-it-works/how-it-works.component';
import { RulesComponent } from './settings-module/components/rules/rules.component';
import { PendingComponent } from './components/payment/pending/pending.component';
import { FinishedMvpComponent } from './components/_layout/finished-mvp/finished-mvp.component';
import { LogoutComponent } from './components/_layout/logout/logout.component';

const routes: Routes = [
	{ path: '', component: LandingComponent, canActivate: [ NonauthGuard ] },
	{
		path: 'start',
		loadChildren: () => import('./start-module/start.module').then((m) => m.StartModule),
		canActivate: [ NonauthGuard ]
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [ AuthGuard ],
		children: [
			{
				path: 'groups/:type',
				component: GroupsComponent,
				data: { showBackButton: false, title: 'ROUTING.PAGE_TITLE.GROUPS' }
			},
			{
				path: 'groups/:type/group/:id',
				component: GroupComponent,
				data: { showBackButton: true, title: 'ROUTING.PAGE_TITLE.GROUP' }
			},
			{
				path: 'groups/:type/group/:id/invite',
				loadChildren: () =>
					import('./invite-friend-module/invite-friend.module').then((m) => m.InviteFriendModule)
			},
			{
				path: 'groups/:type/group/:id/:post',
				component: GroupComponent,
				data: { showBackButton: true, title: 'ROUTING.PAGE_TITLE.GROUP' }
			},
			{
				path: 'groups/:type/group/:id/:post/:comment',
				component: GroupComponent,
				data: { showBackButton: true, title: 'ROUTING.PAGE_TITLE.GROUP' }
			},
			{
				path: 'create-group',
				loadChildren: () => import('./create-group-module/create-group.module').then((m) => m.CreateGroupModule)
			},
			{
				path: 'create-team',
				loadChildren: () => import('./create-team-module/create-team.module').then((m) => m.CreateTeamModule)
			},
			{
				path: 'register-weight',
				loadChildren: () =>
					import('./register-weight-module/register-weight.module').then((m) => m.RegisterWeightModule)
			},
			{
				path: 'payout',
				loadChildren: () => import('./my-wallet-module/my-wallet.module').then((m) => m.MyWalletModule)
			},
			{
				path: 'my-points',
				loadChildren: () => import('./my-points-module/my-points.module').then((m) => m.MyPointsModule)
			},
			{
				path: 'payment/:id',
				component: PaymentComponent,
				data: { showBackButton: false, title: 'ROUTING.PAGE_TITLE.PAYMENT' }
			},
			{ path: 'pending/:id', component: PendingComponent },
			{ path: 'pending/:id/:token', component: PendingComponent },
			{
				path: 'profile',
				component: ProfileComponent,
				data: { title: 'ROUTING.PAGE_TITLE.PROFILE' },
				children: [
					{
						path: 'pictures',
						component: ProfilePicturesComponent,
						data: {
							showBackButton: true,
							scrollToTop: true,
							isMine: true,
							title: 'ROUTING.PAGE_TITLE.PROFILE.PICTURES'
						}
					},
					{
						path: 'friends',
						component: ProfileFriendsComponent,
						data: {
							showBackButton: true,
							scrollToTop: true,
							isMine: true,
							title: 'ROUTING.PAGE_TITLE.PROFILE.FRIENDS'
						}
					}
				]
			},
			{
				path: 'profile/:id',
				component: ProfileComponent,
				data: { scrollToTop: true, showBackButton: true, title: 'ROUTING.PAGE_TITLE.PROFILE.ANOTHER_USER' },
				children: [
					{
						path: 'pictures',
						component: ProfilePicturesComponent,
						data: {
							showBackButton: true,
							scrollToTop: true,
							isMine: false,
							title: 'ROUTING.PAGE_TITLE.PROFILE.ANOTHER_USER.PICTURES'
						}
					},
					{
						path: 'friends',
						component: ProfileFriendsComponent,
						data: {
							showBackButton: true,
							scrollToTop: true,
							isMine: false,
							title: 'ROUTING.PAGE_TITLE.PROFILE.ANOTHER_USER.FRIENDS'
						}
					}
				]
			},
			{
				path: 'settings',
				loadChildren: () => import('./settings-module/settings.module').then((m) => m.SettingsModule)
			},
			{
				path: 'menu',
				loadChildren: () => import('./main-menu-module/main-menu.module').then((m) => m.MainMenuModule)
			},
			{
				path: 'finished-group/:id',
				loadChildren: () => import('./team-winner-module/team-winner.module').then((m) => m.TeamWinnerModule)
			},
			{
				path: 'my-group-results/:id',
				loadChildren: () =>
					import('./member-finished-group-module/member-finished-group.module').then(
						(m) => m.MemberFinishedGroupModule
					)
			},
			{
				path: 'finished-as-mvp/:id',
				component: FinishedMvpComponent,
				data: { title: 'ROUTING.PAGE_TITLE.MAIN_PAGE.FINISHED_AS_MVP' }
			},
			{
				path: 'team-join-requests',
				loadChildren: () =>
					import('./team-join-requset-module/team-join-requset.module').then((m) => m.TeamJoinRequsetModule)
			},
			{ path: 'logout', component: LogoutComponent, data: { title: 'ROUTING.PAGE_TITLE.MAIN_PAGE.LOGOUT' } },
			{
				path: 'admin',
				loadChildren: () => import('./admin-module/admin.module').then((m) => m.AdminModule),
				canActivate: [ AdminGuard ]
			}
		]
	},
	{
		path: 'all-groups',
		component: GroupsComponent,
		data: { title: 'ROUTING.PAGE_TITLE.GROUPS.UNAUTH' },
		children: [
			{ path: 'group/:id', component: GroupComponent, data: { title: 'ROUTING.PAGE_TITLE.GROUP.UNAUTH' } }
		]
	},
	{
		path: 'how-it-works',
		component: HowItWorksComponent,
		data: { title: 'ROUTING.PAGE_TITLE.SETTINGS.HOW_DOES_IT_WORKS' }
	},
	{ path: 'all-faq', component: FaqComponent, data: { title: 'ROUTING.PAGE_TITLE.SETTINGS.FAQ' } },
	{ path: 'all-rules', component: RulesComponent, data: { title: 'ROUTING.PAGE_TITLE.SETTINGS.RULES' } },
	{ path: 'all-privacy', component: PrivacyComponent, data: { title: 'ROUTING.PAGE_TITLE.SETTINGS.PRIVACY' } },
	{ path: 'all-terms', component: TermsComponent, data: { title: 'ROUTING.PAGE_TITLE.SETTINGS.TERMS_OF_USE' } },
	{ path: 'join-group/:id', component: UnauthorizedJoinGroupComponent },
	{ path: 'facebook', component: FacebookComponent },
	{ path: 'invites/:token', component: InvitesComponent },
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes /* , // enableTracing - for sesting purposes only
    { enableTracing: true } */)
	],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}

export const Guards = [ AuthGuard, NonauthGuard, AdminGuard ];
