import { Subject } from 'rxjs';
import { TopNotificationsModel } from '../models/top-notifications.model';
import { Injectable } from "@angular/core";

@Injectable()
export class TopNotificationsService {
    notification = new Subject<TopNotificationsModel>();

    notify(message: string, type: string = 'success') {
        this.notification.next(
            new TopNotificationsModel(message, type)
        );
    }
}
