import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
    private regex: RegExp = new RegExp(/[^0-9]/);
    private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'Control' ];
    private specialCmdKeyCodes: Array<number> = [ 224, 17, 91, 93 ]; // Depends on browser
    private previousKey: string;

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        if (this.previousKey) {
            this.previousKey = null;
            return;
        }
        if (this.specialKeys.indexOf(event.key) !== -1 || this.specialCmdKeyCodes.indexOf(event.keyCode) !== -1) {
            this.previousKey = event.key;
            return;
        }

        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', [ '$event' ])
    onPaste(event: any) {
        let clipboardData = event.clipboardData.getData('Text');
        if (clipboardData && clipboardData.match(this.regex)) {
            event.preventDefault();
        }
    }
}
