<div class="wrapper">
        <app-profile-doughnut-chart [isMineProfile]="isMineProfile" [currentUserId]="currentUserId"
                                    (showLineCharts)="toggleLineCharts($event)"></app-profile-doughnut-chart>

        <app-tabs [oneLineTabs]="true" [tabs]="tabs" [currentTab]="currentTab" (tabChanged)="currentTab = $event" (tabChanged)="getChartData($event)"></app-tabs>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'Weight'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-profile-line-chart [chartData]="chartData" *ngIf="showWeight && showLineCharts"></app-profile-line-chart>
            <p class="stats-text__no-data" *ngIf="!showWeight">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'Waist'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-profile-line-chart [chartData]="chartData" *ngIf="showWaist && showLineCharts"></app-profile-line-chart>
            <p class="stats-text__no-data" *ngIf="!showWaist">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
        <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'BMI'">
            <p class="stats-text">{{ "GLOBAL.STATISTICS" | translate }}</p>
            <app-profile-line-chart [chartData]="chartData" *ngIf="showBMI && showLineCharts"></app-profile-line-chart>
            <p class="stats-text__no-data" *ngIf="!showBMI">{{ "GLOBAL.NOTHING_TO_SHOW" | translate }}</p>
        </div>
        
        <div class="spinner" *ngIf="this.isLoading">
                <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
        </div>
</div>