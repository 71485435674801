import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '../../../services/loading-spinner.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.less']
})
export class LoadingSpinnerComponent implements OnInit {

  show = false;
  isWaiting = false;
  text: string;

  constructor(private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit() {
    this.loadingSpinnerService.show.subscribe(
      (text: string) => {
          this.text = text;
          this.show = true;
          this.isWaiting = true;
          setTimeout(() => {
            this.isWaiting = false;
          }, 300);
      }
    );

    this.loadingSpinnerService.hide.subscribe(
      () => {
          this.show = false;
      }
    );
  }

}
