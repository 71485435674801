<app-slider [itemsLength]="6" [itemsPerSlide]="1" [dots]="true" [arrowsOnText]="true">
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_3" | translate}}</div>
      <div class="info__text">{{"GROUP_INFO.SLIDE_1.ADD.TEXT" | translate}}</div>
      <div class="info__text__second_row__bold">{{"GROUP_INFO.SLIDE_1.ADD.TEXT.B" | translate}}</div>
    </div>
  </div>
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_1" | translate}}</div>
      <div class="info__text">{{"GROUP_INFO.SLIDE_2.ADD.TEXT" | translate}}</div>
      <div class="info__text__second_row2">{{"GROUP_INFO.SLIDE_2.ADD.TEXT_1" | translate}}</div>
    </div>
  </div>
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_4" | translate}}</div>
      <div class="info__text">{{"GROUP_INFO.SLIDE_3.ADD.TEXT" | translate}}</div>
      <div class="info__text__second_row2">{{"GROUP_INFO.SLIDE_3.ADD.TEXT_1" | translate}}</div>
    </div>
  </div>
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_5" | translate}}</div>
      <div class="info__text">{{"GROUP_INFO.SLIDE_4.ADD.TEXT" | translate}}</div>
      <div class="info__text__second_row2">{{"GROUP_INFO.SLIDE_4.ADD.TEXT_1" | translate}}</div>
    </div>
  </div>
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_6" | translate}}</div>
      <div class="info__text">{{"GROUP_INFO.SLIDE_5.ADD.TEXT" | translate}}
        <div class="info__list">
          <ul>
            <li>&nbsp;{{"GROUP_INFO.SLIDE_5.ADD.TEXT_1" | translate}}</li>
            <li>&nbsp;{{"GROUP_INFO.SLIDE_5.ADD.TEXT_2" | translate}}</li>
            <li>&nbsp;{{"GROUP_INFO.SLIDE_5.ADD.TEXT_3" | translate}}</li>
            <li>&nbsp;{{"GROUP_INFO.SLIDE_5.ADD.TEXT_4" | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="slider__item">
    <div class="info">
      <div class="info__title">{{"GROUP_INFO.SLIDE.TITLE_7" | translate}}</div>
      <div class="info__text">
        {{"GROUP_INFO.SLIDE_6.ADD.TEXT" | translate}}
        <a class="button button__alt center join" *ngIf="isCanJoin && !isStarted; else cantJoin" (click)="joinGroup()">{{"GROUP_INFO.SLIDE_6.ADD.BUTTON" | translate}}</a>
        <ng-template #cantJoin><p></p></ng-template>
        {{"GROUP_INFO.SLIDE_6.ADD.TEXT_1" | translate}}
        <span class="info__faq" routerLink="/home/settings/faq">{{"GROUP_INFO.SLIDE_6.ADD.TEXT_2" | translate}}</span>
      </div>
    </div>
  </div>
</app-slider>
