<div class="overlay" [ngClass]="{'active': isActive}" (click)="closeMenu()"></div>
<div class="main-menu" [ngClass]="{'active': isActive}" >

  <div class="header" (click)="closeMenu()">
    <div class="header-button menu-button">
      <img src="assets/images/arrow-white-left.png"
           srcset="assets/images/arrow-white-left@2x.png 2x,
             assets/images/arrow-white-left@3x.png 3x"
      >&emsp;This menu will be removed soon</div>
  </div>

  <ng-content
    *ngIf="showMenuItem; then showAuthedMenuItems; else showUnAuthedMenuItems"></ng-content>
  <ng-template #showAuthedMenuItems>
    <div class="user" routerLink="/home/profile" routerLinkActive="active" (click)="closeMenu()">
      <div class="user__img">
          <img src="{{user.image | userAvatarUrl}}">
      </div>
      <div class="user__name">{{user?.userName}}</div>
    </div>
    <nav>
      <ul (click)="closeMenu()">
        <li routerLink="/home/groups/mine" routerLinkActive="active">
          <img src="assets/images/heart.png"
               srcset="assets/images/heart@2x.png 2x,
               assets/images/heart@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_1" | translate}}</span>
        </li>
        <li routerLink="/home/groups/all" routerLinkActive="active">
          <img src="assets/images/search.png"
               srcset="assets/images/search@2x.png 2x,
               assets/images/search@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_2" | translate}}</span>
        </li>
        <li routerLink="/home/profile" routerLinkActive="active">
          <img src="assets/images/profile.png"
               srcset="assets/images/profile@2x.png 2x,
               assets/images/profile@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_3" | translate}}</span>
        </li>
        <!-- FIXME: Replace with my points -->
        <!-- <li routerLink="/home/payout" routerLinkActive="active">
          <img src="assets/images/favorite.png"
               srcset="assets/images/favorite@2x.png 2x,
               assets/images/favorite@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_4" | translate}}**</span>
        </li> -->
        <!-- FIXME: Delete code above -->
        <li routerLink="/home/my-points" routerLinkActive="active">
          <img src="assets/images/favorite.png"
               srcset="assets/images/favorite@2x.png 2x,
               assets/images/favorite@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM.MY_POINTS" | translate}}</span>
        </li>
        <li routerLink="/home/payout" routerLinkActive="active">
          <svg id="account_balance_wallet-24px" width="16" height="15.158" version="1.1" viewBox="0 0 16 15.158" xmlns="http://www.w3.org/2000/svg">
            <path d="m-3-5.8421h24v24h-24z" style="fill:none" data-name="Path 795"/>
            <path d="m15.158 3.6042v-1.92a1.6893 1.6893 0 0 0-1.6842-1.6842h-11.789a1.6842 1.6842 0 0 0-1.6842 1.6842v11.789a1.6842 1.6842 0 0 0 1.6842 1.6842h11.789a1.6893 1.6893 0 0 0 1.6842-1.6842v-1.92a1.6842 1.6842 0 0 0 0.8421-1.4484v-5.0526a1.6842 1.6842 0 0 0-0.8421-1.4484zm-0.84211 1.4484v5.0526h-5.8947v-5.0526zm-12.632 8.4211v-11.789h11.789v1.6842h-5.0526a1.6893 1.6893 0 0 0-1.6842 1.6842v5.0526a1.6893 1.6893 0 0 0 1.6842 1.6842h5.0526v1.6842z" style="fill:#1ec6c1;stroke-width:.84211" data-name="Path 796"/>
            <circle cx="10.947" cy="7.5789" r="1.2632" style="fill:#1ec6c1;stroke-width:.84211" data-name="Ellipse 365"/>
           </svg>
          <span>{{"MAIN_MENU.ITEM.MY_WALLET" | translate}}</span>
        </li>
        <li routerLink="/home/settings" routerLinkActive="active">
          <img src="assets/images/general-settings.png"
               srcset="assets/images/general-settings@2x.png 2x,
               assets/images/general-settings@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_5" | translate}}</span>
        </li>
        <li routerLink="/home/create-group/step1" routerLinkActive="active">
          <img src="assets/images/Add.png"
               srcset="assets/images/add@2x.png 2x,
               assets/images/add@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_8" | translate}}</span>
        </li>
        <li routerLink="/home/settings/feedback" routerLinkActive="active">
          <img src="assets/images/Feedback.png"
              srcset="assets/images/Feedback@2x.png 2x,
              assets/images/Feedback@3x.png 3x"
              >
          <span>{{"SETTINGS.FEEDBACK" | translate}}</span>
        </li>
        <li class="contact-us">
            <img class="startImg" src="assets/images/pen-blue.png"
                 srcset="assets/images/pen-blue@2x.png 2x,
                 assets/images/pen-blue@3x.png 3x"
                 >
            <span><a href="mailto:info@joina.io">{{ "SETTINGS.PRIVACY.H2_14" | translate }} info@joina.io</a></span>
        </li>
      </ul>
    </nav>
  </ng-template>
  <ng-template #showUnAuthedMenuItems>
    <nav class="mini">
      <ul (click)="closeMenu()">
        <li routerLink="all-groups" routerLinkActive="active">
          <img src="assets/images/search.png"
               srcset="assets/images/search@2x.png 2x,
               assets/images/search@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_2" | translate}}</span>
        </li>
        <li routerLink="how-it-works" routerLinkActive="active">
          <img src="assets/images/help.png"
               srcset="assets/images/help@2x.png 2x,
               assets/images/help@3x.png 3x"
               >
          <span>{{"MAIN_MENU.ITEM_6" | translate}}</span>
        </li>
        <li routerLink="all-faq" routerLinkActive="active">
          <img src="assets/images/help.png"
               srcset="assets/images/help@2x.png 2x,
               assets/images/help@3x.png 3x"
               >
          <span>{{"SETTINGS.FAQ" | translate}}</span>
        </li>
        <li routerLink="all-rules" routerLinkActive="active">
          <img src="assets/images/help.png"
               srcset="assets/images/help@2x.png 2x,
               assets/images/help@3x.png 3x"
               >
          <span>{{"SETTINGS.RULES" | translate}}</span>
        </li>
        <li routerLink="all-privacy" routerLinkActive="active">
          <img class="startImg" src="assets/images/Lock.png"
               srcset="assets/images/Lock@2x.png 2x,
               assets/images/Lock@3x.png 3x"
               >
          <span>{{"SETTINGS.PRIVACY" | translate}}</span>
        </li>
        <li routerLink="all-terms" routerLinkActive="active">
          <img class="startImg" src="assets/images/rules.png"
              srcset="assets/images/rules@2x.png 2x,
              assets/images/rules@3x.png 3x"
              >
          <span>{{"SETTINGS.TERMS_OF_USE" | translate}}</span>
        </li>
        <li class="contact-us">
            <img class="startImg" src="assets/images/pen-blue.png"
                 srcset="assets/images/pen-blue@2x.png 2x,
                 assets/images/pen-blue@3x.png 3x"
                 >
            <span><a href="mailto:info@joina.io">{{ "SETTINGS.PRIVACY.H2_14" | translate }} info@joina.io</a></span>
        </li>
      </ul>
    </nav>
  </ng-template>

  <!-- <div class="user" routerLink="/home/profile" routerLinkActive="active" (click)="closeMenu()" *ngIf="showMenuItem">
    <div class="user__img">
        <img src="{{user.image | userAvatarUrl}}">
    </div>
    <div class="user__name">{{user?.userName}}</div>
  </div>
  <nav *ngIf="showMenuItem">
    <ul (click)="closeMenu()">
      <li routerLink="/home/groups/mine" routerLinkActive="active">
        <img src="assets/images/heart.png"
             srcset="assets/images/heart@2x.png 2x,
             assets/images/heart@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_1" | translate}}</span>
      </li>
      <li routerLink="/home/groups/all" routerLinkActive="active">
        <img src="assets/images/search.png"
             srcset="assets/images/search@2x.png 2x,
             assets/images/search@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_2" | translate}}</span>
      </li>
      <li routerLink="/home/profile" routerLinkActive="active">
        <img src="assets/images/profile.png"
             srcset="assets/images/profile@2x.png 2x,
             assets/images/profile@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_3" | translate}}</span>
      </li>
      <li routerLink="/home/payout" routerLinkActive="active">
        <img src="assets/images/favorite.png"
             srcset="assets/images/favorite@2x.png 2x,
             assets/images/favorite@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_4" | translate}}</span>
      </li>
      <li routerLink="/home/settings" routerLinkActive="active">
        <img src="assets/images/general-settings.png"
             srcset="assets/images/general-settings@2x.png 2x,
             assets/images/general-settings@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_5" | translate}}</span>
      </li>
      <li routerLink="/home/create-group/step1" routerLinkActive="active">
        <img src="assets/images/Add.png"
             srcset="assets/images/add@2x.png 2x,
             assets/images/add@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_8" | translate}}</span>
      </li>
      <li routerLink="/home/settings/feedback" routerLinkActive="active">
        <img src="assets/images/Feedback.png"
            srcset="assets/images/Feedback@2x.png 2x,
            assets/images/Feedback@3x.png 3x"
            >
        <span>{{"SETTINGS.FEEDBACK" | translate}}</span>
      </li>
      <li class="contact-us">
          <img class="startImg" src="assets/images/pen-blue.png"
               srcset="assets/images/pen-blue@2x.png 2x,
               assets/images/pen-blue@3x.png 3x"
               >
          <span><a href="mailto:info@formsatsningen.se">{{ "SETTINGS.PRIVACY.H2_14" | translate }} info@formsatsningen.se</a></span>
      </li>
    </ul>
  </nav> -->
  <!-- NAVIGATION FOR UNAUTH USERS -->
  <!-- <nav *ngIf="!showMenuItem" class="mini">
    <ul (click)="closeMenu()">
      <li routerLink="all-groups" routerLinkActive="active">
        <img src="assets/images/search.png"
             srcset="assets/images/search@2x.png 2x,
             assets/images/search@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_2" | translate}}</span>
      </li>
      <li routerLink="how-it-works" routerLinkActive="active">
        <img src="assets/images/help.png"
             srcset="assets/images/help@2x.png 2x,
             assets/images/help@3x.png 3x"
             >
        <span>{{"MAIN_MENU.ITEM_6" | translate}}</span>
      </li>
      <li routerLink="all-faq" routerLinkActive="active">
        <img src="assets/images/help.png"
             srcset="assets/images/help@2x.png 2x,
             assets/images/help@3x.png 3x"
             >
        <span>{{"SETTINGS.FAQ" | translate}}</span>
      </li>
      <li routerLink="all-rules" routerLinkActive="active">
        <img src="assets/images/help.png"
             srcset="assets/images/help@2x.png 2x,
             assets/images/help@3x.png 3x"
             >
        <span>{{"SETTINGS.RULES" | translate}}</span>
      </li>
      <li routerLink="all-privacy" routerLinkActive="active">
        <img class="startImg" src="assets/images/Lock.png"
             srcset="assets/images/Lock@2x.png 2x,
             assets/images/Lock@3x.png 3x"
             >
        <span>{{"SETTINGS.PRIVACY" | translate}}</span>
      </li>
      <li routerLink="all-terms" routerLinkActive="active">
        <img class="startImg" src="assets/images/rules.png"
            srcset="assets/images/rules@2x.png 2x,
            assets/images/rules@3x.png 3x"
            >
        <span>{{"SETTINGS.TERMS_OF_USE" | translate}}</span>
      </li>
      <li class="contact-us">
          <img class="startImg" src="assets/images/pen-blue.png"
               srcset="assets/images/pen-blue@2x.png 2x,
               assets/images/pen-blue@3x.png 3x"
               >
          <span><a href="mailto:info@formsatsningen.se">{{ "SETTINGS.PRIVACY.H2_14" | translate }} info@formsatsningen.se</a></span>
      </li>
    </ul>
  </nav> -->

  <!-- Conflicting code with the new menu -->
  <!-- <div class="switch-control-wrapper" [ngClass]="{'mini': !showMenuItem}">
    <div class="switch-control" [ngClass]="{'checked': isDefaultLanguage}">
      <input type="checkbox"
             id="isDefaultLanguage"
             name="isDefaultLanguage"
             class="switch-control-input"
             (ngModelChange)="changeLanguage($event)"
             [(ngModel)]="isDefaultLanguage"
      >
      <span class="switch-control-text-left" [ngClass]="{'transparent': isDefaultLanguage}" (click)="changeLanguage(false)">
                      EN
                  </span>
      <label class="switch-control-label" for="isDefaultLanguage">
      </label>
      <span class="switch-control-text-right" [ngClass]="{'transparent': !isDefaultLanguage}" (click)="changeLanguage(true)">
                      SV
                  </span>
    </div>
  </div> -->

  <nav *ngIf="showMenuItem">
    <ul (click)="closeMenu()">
      <li *ngIf="isAdmin || isReferee" routerLink="/home/admin" routerLinkActive="active">
        <span>Admin page</span>
      </li>
      <li  routerLink="/home/logout" routerLinkActive="active">
        <span>{{"GLOBAL.LOGOUT" | translate}}</span>
      </li>
    </ul>
  </nav>

  <nav *ngIf="appVersion">
    <ul>
      <li class="app-version">version {{appVersion}}</li>
    </ul>
  </nav>

</div>
