import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppLocalStorage } from '../utils/app.local.storage';
import { AppConstants } from '../utils/app.constants';

@Pipe({
  name: 'momentDateAgo'
})
export class MomentDateAgoPipe implements PipeTransform {

  transform(dateTime: string): string {
      moment.locale(AppLocalStorage.getObject(AppConstants.keys.defaultLanguage));
      const newDateTime = (dateTime.indexOf('"') > -1) ? moment.utc(JSON.parse(dateTime)) : moment.utc(dateTime);
      return moment(moment.utc(newDateTime)).fromNow();
  }

}
