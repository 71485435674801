import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppConstants } from '../../../utils/app.constants';
import { AppLocalStorage } from '../../../utils/app.local.storage';
import { RolesComponent } from '../../_abstract/roles.component';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: [ './logout.component.less' ]
})
export class LogoutComponent extends RolesComponent implements OnInit {
	spinnerText: string;

	constructor(
		authService: AuthService,
		private loadingSpinnerService: LoadingSpinnerService,
		private translateService: TranslateService,
		@Inject(ApiGroupsService) private apiGroupsService
	) {
		super(authService);
	}

	ngOnInit() {
		this.translateService.stream('SPINNER.LOGGIN_OUT').subscribe((data) => (this.spinnerText = data));
	}

	unauthorize(save: boolean) {
		this.loadingSpinnerService.show.next(this.spinnerText);
		if (save) {
			const rememberMeData = AppLocalStorage.getObject(AppConstants.keys.authResponse);
			AppLocalStorage.add(AppConstants.keys.rm, {
				i: btoa(rememberMeData.image),
				n: btoa(rememberMeData.userName),
				t: btoa(rememberMeData.token + '123'),
				e: btoa(rememberMeData.expires)
			});
			this.dropCache();
			this.authService.unauthorize();
		} else {
			if (AppLocalStorage.getObject(AppConstants.keys.rm)) {
				AppLocalStorage.remove(AppConstants.keys.rm);
			}
			this.dropCache();
			this.authService.unauthorize();
		}
	}

	dropCache() {
		this.apiGroupsService.dropAllFetches();
		this.apiGroupsService.dropIsAll();
		this.apiGroupsService.dropIsMy();
	}
}
