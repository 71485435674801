<!-- PostMedia -->
<div *ngIf="type === FileInputDisplayTypes.PostMedia" class="post-image">
    <input  [id]="'file-input-' + type + filesCounter"
            type="file"
            [accept]="accept ? accept : '*'"
            (change)="onFileChanged($event)"
    />
    <label [for]="'file-input-' + type + filesCounter">
        <div class="post-image__button">

            <div class="loader" *ngIf="isLoading">
                <mat-progress-spinner diameter="50" color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>

            <div class="dummy-image" *ngIf="!postVideoUrl && !prevImage && !imageUrl && !isLoading">
                <img src="assets/images/camera.png"
                    srcset="assets/images/camera@2x.png 2x,
                            assets/images/camera@3x.png 3x">
            </div>
            
            <img class="post-image" *ngIf="imageUrl && !isLoading" [src]="imageUrl + '?ngsw-bypass=true'" alt="imageUrl">
            <img class="post-image" *ngIf="prevImage && !isLoading" [src]="setSanitizedUrl(prevImage)" alt="prevImage">

            <video *ngIf="postVideoUrl && !isLoading" preload="metadata" [attr.poster]="isOnIOSDevice ? thumbnail : null" class="video-preview">
                <source [src]="postVideoUrl + '?ngsw-bypass=true'" />
                <p class="text-centrer">{{ 'POST.VIDEO_IS_NOT_SUPPORTED' | translate }}</p>
            </video>
            <a *ngIf="!imageUrl && !postVideoUrl && !prevImage">{{"BUTTON.ADD_MEDIA"|translate}}</a>
            <a *ngIf="imageUrl || postVideoUrl || prevImage">{{"BUTTON.ADD_MORE_MEDIA.NEW"|translate}}</a>
        </div>
    </label>
    <div [ngClass]="{'transparent': !imageUrl && !postVideoUrl && !prevImage}" class="delete-media-item" (click)="deleteMediaItem()"><span>+</span> {{"BUTTON.REMOVE_MEDIA"|translate}}</div>
</div>
<!-- PostMedia -->

<image-cropper *ngIf="!isOnIOSDevice"
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="true"
  [aspectRatio]="imageAspectRatio"
  [containWithinAspectRatio]="true"
  [resizeToWidth]="1024"
  [onlyScaleDown]="true"
  [imageQuality]="100"
  [autoCrop]="true"
  
  format="jpeg"
  (imageCropped)="imageCropped($event)"
  backgroundColor="#ffffff"
  style="display: block; position: absolute; top: 0; left: -3072px"></image-cropper>



