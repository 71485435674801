import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-time-ago',
  templateUrl: './time-ago.component.html',
  styleUrls: ['./time-ago.component.less']
})
export class TimeAgoComponent implements OnInit {

  @Input() params; // waiting for [{'name': value},{'name': value}...]
  value = null;

  constructor() { }

  ngOnInit() {
    if (this.params.length === 1) {
      if (Object.keys(this.params[0])[0] === 'dateTime') {
        this.value = this.params[0].dateTime;
      }
    }
  }

}
