import { Injectable } from '@angular/core';
import { ApiAccountService } from '../services/api/api-account.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PassingDataService } from './passing-data.service';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	notificationRecieved: Subject<any> = new Subject<any>();
	messagingServiceSubscription: Subscription = new Subscription();

	currentMessage = new BehaviorSubject(null);
	token: string;

	constructor(
		private accountApiService: ApiAccountService,
		private angularFireMessaging: AngularFireMessaging,
		private passingDataService: PassingDataService
	) {
		this.angularFireMessaging.messaging.subscribe((_messaging) => {
			_messaging.onMessage = _messaging.onMessage.bind(_messaging);
			_messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
		});
	}

	/**
   * update token in firebase database
   */
	updateToken(token) {
		this.token = token;
		this.accountApiService
			.updateNotificationToken(token)
			.subscribe(() => {}, (err) => console.warn('Something wrong with update token', err));
	}

	/**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
	requestPermission() {
		return this.angularFireMessaging.requestToken;
	}

	refreshToken() {
		if (this.token) {
			this.angularFireMessaging.deleteToken(this.token);
			return new BehaviorSubject(true);
		} else {
			return new BehaviorSubject(true);
		}
	}

	/**
   * hook method when new notification received in foreground
   */
	receiveMessage() {
		this.messagingServiceSubscription.unsubscribe();
		this.messagingServiceSubscription = this.angularFireMessaging.messages.subscribe((payload) => {
			this.passingDataService.setNotificationsCount('increaseByOne');
			this.currentMessage.next(payload);
			this.notificationRecieved.next(payload);
		});
	}
}
