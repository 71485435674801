<div class="teams-success-request" id="teamsSuccessRequestTop">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
        <g id="Group_970" data-name="Group 970" transform="translate(-110 -211)">
          <g id="Group_969" data-name="Group 969" transform="translate(128.5 236.738)">
            <path id="Checkbox" d="M17.928,32.561,1,15.633l4.017-4.017L17.928,24.241,41.169,1l4.017,4.017Z" transform="translate(-1 -1)" fill="#fb6f44"/>
          </g>
          <g id="Ellipse_370" data-name="Ellipse 370" transform="translate(110 211)" fill="none" stroke="#fb6f44" stroke-width="5">
            <circle cx="40" cy="40" r="40" stroke="none"/>
            <circle cx="40" cy="40" r="37.5" fill="none"/>
          </g>
        </g>
      </svg>
    <p class="title">{{"TEAMS.SUCCESS_JOIN_REQUEST.TITLE"|translate}}</p>
    <p class="text-message">{{"TEAMS.SUCCESS_JOIN_REQUEST.TEXT"|translate}}</p>
    <a class="button button__alt" (click)="backToGroup()">{{"TEAMS.SUCCESS_JOIN_REQUEST.CONFIRM_BUTTON" | translate}}</a>
    <a class="button button__alt disabled" (click)="cancelPrivateRequest()">{{"TEAMS.SUCCESS_JOIN_REQUEST.DECLINE_BUTTON"|translate}}</a>
</div>