import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GroupModel } from 'src/app/models/group.model';

@Component({
	selector: 'app-teams-list-header',
	templateUrl: './teams-list-header.component.html',
	styleUrls: [ './teams-list-header.component.less' ]
})
export class TeamsListHeaderComponent {
	@Input() group: GroupModel;
	@Output() closeCreateOrJoinTeam = new EventEmitter<boolean>();

	closeTab() {
		this.closeCreateOrJoinTeam.emit(true);
	}
}
