import { ApiBaseService } from './api-base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Log {
  operation: string;
  userId: number;
  groupId: number;
  inviteToken?: string;
}
@Injectable()
export class ApiLogsService extends ApiBaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  sendLog(params: Log): Observable<any> {
    return this.postData(`frontlog/auth`, params);
  }
}
