<div class="wrapper">
  <a class="button__back" (click)="goBack()">
    <img src="assets/images/arrow-lg-left.png"
         srcset="assets/images/arrow-lg-left@2x.png 2x,
             assets/images/arrow-lg-left@3x.png 3x"
         class="arrow-lg-left">
  </a>

  <div class="content">

    <div class="title">
      {{"PAYMENT.UNAUTHORIZED.TITLE" | translate}}
    </div>
    <div class="buttons">
      <button class="button full-width button__facebook" (click)="signUp('facebook')">
        {{'LANDING.FACEBOOK_SIGNUP' | translate}}
      </button>
      <button class="button full-width button__alt" (click)="signUp('email')">
        {{'LANDING.EMAIL_SIGNUP' | translate}}
      </button>
    </div>

    <div class="title">
      {{"JOINGROUP.UNAUTH.SIGN_IN" | translate}}
    </div>
    <div class="buttons">
      <button class="button full-width button__main" (click)="signIn()">
        {{'GLOBAL.LOGIN' | translate}}
      </button>
    </div>

  </div>
</div>
