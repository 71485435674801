export class MeasurementModel {
  id: string;
  video: string;
  weight: string;
  height: string;
  waist: string;
  isApproved: boolean;
  approvalDate: string;
  videoThumbnail: string;
  videoFile: string;
  groupId: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.video = data.video;
    this.weight = data.weight;
    this.height = data.height;
    this.waist = data.waist || null;
    this.isApproved = data.isApproved;
    this.approvalDate = data.approvalDate;
    this.groupId = data.groupId;
  }

  addOfficialMeasurements(data: any) {
    this.weight = data.weight;
    this.height = data.height;
    this.groupId = data.groupId;
    this.waist = data.waist;
    this.video = data.video;
  }

  toPostJson() {
    return {
      video: this.video,
      weight: this.weight,
      height: this.height,
      waist: this.waist,
      groupId: this.groupId
    };
  }

}

export class UnofficionalMeasurementModel {
  weight: string;
  waist: string;

  constructor(data: any = {}) {
    this.weight = data.weight;
    this.waist = data.waist || null;
  }
}
