<div class="wrapper" [ngClass]="{'mini': !showMiniMenu}">
  <div class="container">
    <div class="faq">
      <a class="button__back" (click)="goBack()">
        <img src="assets/images/arrow-lg-left.png" srcset="assets/images/arrow-lg-left@2x.png 2x,
                   assets/images/arrow-lg-left@3x.png 3x" class="arrow-lg-left">
        <div class="title">{{'SETTINGS.FAQ' | translate}}</div>
      </a>
      <div class="accordion">
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">1. {{ "FAQ.ACCORDION_1.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_1.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">2. {{ "FAQ.ACCORDION_2.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_2.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_2.P2" | translate }} <u><a
              (click)="togglePopUp()">{{ "FAQ.ACCORDION_2.P2_A" | translate}}</a></u>.
            </p>
            <p class="entity-text">{{ "FAQ.ACCORDION_2.P3" | translate }}</p>
            </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">3. {{ "FAQ.ACCORDION_3.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_3.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">4. {{ "FAQ.ACCORDION_4.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_4.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">5. {{ "FAQ.ACCORDION_5.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_5.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">6. {{ "FAQ.ACCORDION_6.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_6.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">7. {{ "FAQ.ACCORDION_7.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_7.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">8. {{ "FAQ.ACCORDION_8.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_8.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">9. {{ "FAQ.ACCORDION_9.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_9.P1_1" | translate }}
              <b><a href="mailto:support@joina.io">{{ "FAQ.ACCORDION_9.P1_2" | translate }}</a></b>
                {{ "FAQ.ACCORDION_9.P1_3" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">10. {{ "FAQ.ACCORDION_10.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_10.P1" | translate }}</p>
            <ul class="entity-text">
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_1" | translate }}</i></li>
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_2" | translate }}</i></li>
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_3" | translate }}</i></li>
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_4" | translate }}</i></li>
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_5" | translate }}</i></li>
              <li><i>{{ "FAQ.ACCORDION_10.UL1.LI_6" | translate }}</i></li>
            </ul>
            <p class="entity-text">{{ "FAQ.ACCORDION_10.P2" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">11. {{ "FAQ.ACCORDION_11.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                  assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_11.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">12. {{ "FAQ.ACCORDION_12.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_12.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">13. {{ "FAQ.ACCORDION_13.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_13.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_13.P2" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">14. {{ "FAQ.ACCORDION_14.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_14.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">15. {{ "FAQ.ACCORDION_15.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_15.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_15.P2" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_15.P3" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_15.P4" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_15.P5" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">16. {{ "FAQ.ACCORDION_16.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_16.P1" | translate }}</p>
            <p class="entity-text"><u>{{ "FAQ.ACCORDION_16.P2_U2" | translate }}</u>
              {{ "FAQ.ACCORDION_16.P2" | translate }}</p>
            <p class="entity-text"><u>{{ "FAQ.ACCORDION_16.P3_U3" | translate }}</u>
              {{ "FAQ.ACCORDION_16.P3" | translate }}</p>
            <p class="entity-text"><u>{{ "FAQ.ACCORDION_16.P4_U4" | translate }}</u>
              {{ "FAQ.ACCORDION_16.P4" | translate }}</p>
            <p class="entity-text"><u>{{ "FAQ.ACCORDION_16.P5_U5" | translate }}</u>
              {{ "FAQ.ACCORDION_16.P5" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">17. {{ "FAQ.ACCORDION_17.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_17.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">18. {{ "FAQ.ACCORDION_18.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_18.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity" id="whatIsMVP">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">19. {{ "FAQ.ACCORDION_19.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_19.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_19.P2" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">20. {{ "FAQ.ACCORDION_20.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{"FAQ.ACCORDION_20.P1" | translate}} <b (click)="scrollTo('whatIsMVP')">{{"FAQ.ACCORDION_20.LINKED_TEXT" | translate}}</b>. {{"FAQ.ACCORDION_20.P2" | translate}}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">21. {{ "FAQ.ACCORDION_21.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_21.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity" id="howToGetPoints">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">22. {{ "FAQ.ACCORDION_22.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_22.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_22.P2" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_22.P3" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_22.P4" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_22.P5" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">23. {{ "FAQ.ACCORDION_23.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_23.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_23.P2" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_23.P3" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">24. {{ "FAQ.ACCORDION_24.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_24.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">25. {{ "FAQ.ACCORDION_25.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">
              {{ "FAQ.ACCORDION_25.P1_1" | translate }} <b><a href="mailto:support@joina.io">{{ "FAQ.ACCORDION_25.P1_2" | translate }}</a></b>
            </p>
            <p class="entity-text"><b>{{ "FAQ.ACCORDION_25.P2" | translate }}</b></p>
            <p class="entity-text">{{ "FAQ.ACCORDION_25.P3" | translate }}</p>
            <p class="entity-text"><b>{{ "FAQ.ACCORDION_25.P4" | translate }}</b></p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">26. {{ "FAQ.ACCORDION_26.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_26.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">27. {{ "FAQ.ACCORDION_27.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_27.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">28. {{ "FAQ.ACCORDION_28.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_28.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">29. {{ "FAQ.ACCORDION_29.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_29.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">30. {{ "FAQ.ACCORDION_30.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_30.P1" | translate }}</p>
          </div>
        </div>
        <div class="entity">
          <h2 class="entity__title" (click)="toggleClass($event,'show')">31. {{ "FAQ.ACCORDION_31.H2" | translate }}
            <img class="endImg" src="assets/images/Blue-arrow-right.png" srcset="assets/images/Blue-arrow-right@2x.png 2x,
                    assets/images/Blue-arrow-right@3x.png 3x">
          </h2>
          <div class="entity-text__wrapper">
            <p class="entity-text">{{ "FAQ.ACCORDION_31.P1" | translate }}</p>
            <p class="entity-text">{{ "FAQ.ACCORDION_31.P2" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="pop-up" *ngIf="showPopUp">
  <div class="wrapper">
    <img class="close-button" (click)="togglePopUp()" src="assets/images/cancel.png" srcset="assets/images/cancel@2x.png 2x,
                  assets/images/cancel@3x.png 3x">
    <div class="slider" *ngIf="videoUrls">
      <div class="slider__item" *ngFor="let url of videoUrls">
        <iframe width="100%" height="100%" [src]="url | safe:'resourceUrl'" allowfullscreen="allowfullscreen">
        </iframe>
      </div>
    </div>
  </div>
</div>
