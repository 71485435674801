<form [formGroup]="form" class="wrapper" [ngClass]="{'open': isOpen}">
  <div class="search-wrapper">
    <div class="field search-field">
      <span class="field-button search-button">
        <img src="assets/images/search.png"
             srcset="assets/images/search@2x.png 2x,
             assets/images/search@3x.png 3x">
      </span>

      <input type="text" formControlName="name" placeholder="{{ 'GROUP_FILTERS.SEARCH' | translate }}">

      <span class="field-button filter-button" (click)="toggleFilters()">
        <img src="assets/images/filter.png"
             srcset="assets/images/filter@2x.png 2x,
             assets/images/filter@3x.png 3x">
      </span>
    </div>
  </div>
  <div class="filters-wrapper" [@slideInOut]="helpMenuOpen">
    <div class="filters-block">
      <div class="row">
        <div class="col">
          <div class="field">
            <label for="startDate">{{ 'GLOBAL.START_DATE' | translate }}</label>
            <input id="startDate" type="text" formControlName="startDate" matInput [matDatepicker]="pickerStartDate" placeholder="{{ 'GLOBAL.START_DATE' | translate }}" (click)="pickerStartDate.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerStartDate disabled="false"></mat-datepicker>
          </div>
        </div>
        <div class="col">
          <div class="field">
            <label for="endDate">{{ 'GLOBAL.END_DATE' | translate }}</label>
            <input id="endDate" type="text" formControlName="endDate" matInput [matDatepicker]="pickereEndDate" placeholder="{{ 'GLOBAL.END_DATE' | translate }}" (click)="pickereEndDate.open()">
            <mat-datepicker-toggle matSuffix [for]="pickereEndDate"></mat-datepicker-toggle>
            <mat-datepicker #pickereEndDate disabled="false"></mat-datepicker>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="input">
          <mat-select id="bet" formControlName="bettingAmount" placeholder="{{ 'GLOBAL.BET' | translate }}">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let bet of betsArray" [value]="bet">
              {{bet}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="field">
            <label>{{ 'GLOBAL.PARTICIPANTS' | translate }}</label>
            <input type="text" type="number" formControlName="minAmountOfParticipants" placeholder="Min" appNumberOnly>
          </div>
        </div>
        <div class="col">
          <div class="field">
            <input type="text" type="number" formControlName="maxAmountOfParticipants" placeholder="Max" appNumberOnly>
          </div>
        </div>
      </div>

      <p>
        <input type="checkbox" id="bonusprizes" formControlName="bonusprizes">
        <label for="bonusprizes">{{ 'GLOBAL.VIP_INFLUENCER' | translate }}</label>
      </p>
      <p>
        <input type="checkbox" id="private" formControlName="private">
        <label for="private">{{ 'GLOBAL.PRIVACY' | translate }}</label>
      </p>

      <button class="button button__main" (click)="filterGroups()">
        {{'GROUP_FILTERS.FILTER' | translate}}
      </button>
    </div>
  </div>
</form>
