export class PostMetadata {
	joinedUserId: string;

	constructor(data: any) {
		this.joinedUserId = data.joinedUserId || '';
	}
}

export class PostMedia {
	contentType: string;
	order: number;
	fileName: string;
	constructor(data: any) {
		this.contentType = data.contentType || '';
		this.order = data.order || null;
		this.fileName = data.fileName || '';
	}
}

export class PostModel {
	id: string;
	text: string;
	image: string;
	video: string;
	media: PostMedia[];
	dateTime: string;
	amountOfComments: number;
	amountOfCommentsPaginator: number;
	amountOfLikes: number;
	userId: string;
	userName: string;
	userCity: string;
	userImage: string;
	userHasLikePost: boolean;
	isFavorites: boolean;
	metadata: PostMetadata;
	pin: boolean;
	team: number;
	teamPost: boolean;

	constructor(data: any = {}) {
		this.id = data.id;
		this.text = data.text || '';
		this.image = data.image;
		this.video = data.video;
		this.media = data.media || [];
		this.dateTime = data.dateTime;
		this.amountOfComments = data.amountOfComments || 0;
		this.amountOfCommentsPaginator = data.amountOfCommentsPaginator || 0;
		this.amountOfLikes = data.amountOfLikes || 0;
		this.userId = data.userId;
		this.userName = data.userName;
		this.userCity = data.userCity || '';
		this.userImage = data.userImage || null;
		this.userHasLikePost = data.userHasLikePost || false;
		this.isFavorites = data.isFavorites || false;
		this.metadata = data.metadata || null;
		this.pin = data.pin || false;
		this.team = data.team || null;
		this.teamPost = data.teamPost || false;
	}

	toPostJson() {
		return {
			text: this.text,
			image: this.image,
			video: this.video,
			media: this.media,
			team: this.team
		};
	}
}
