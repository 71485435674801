import { Component, ElementRef, Input, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { GroupModel } from '../../../models/group.model';
import { AppConstants } from '../../../utils/app.constants';
import { Router } from '@angular/router';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';

@Component({
	selector: 'app-group-card',
	templateUrl: './group-card.component.html',
	styleUrls: [ './group-card.component.less' ]
})
export class GroupCardComponent implements AfterViewInit {
	@Input() group: GroupModel;
	@Input() isPublicMode: boolean;
	@Input() type = 'all';

	@ViewChild('groupImage', { static: true })
	groupImage: ElementRef;
	@ViewChild('groupView', { static: true })
	groupView: ElementRef;

	constructor(private renderer: Renderer2, private router: Router, private apiGroupService: ApiGroupsService) {}

	ngAfterViewInit() {
		this.setGroupImage();
	}

	setGroupImage() {
		if (this.group) {
			const imageUrl = !!this.group.image
				? AppConstants.api.mediaFilesUrl + 'small/' + this.group.image.replace(/\"/g, '')
				: null;
			if (imageUrl) {
				this.renderer.setStyle(this.groupImage.nativeElement, 'background-image', `url(${imageUrl})`);
			}
		} else {
			const parent = this.renderer.parentNode(this.groupView.nativeElement);
			this.renderer.setStyle(parent, 'display', 'none');
		}
	}

	goToGroup() {
		const url = this.isPublicMode
			? `/all-groups/group/${this.group.id}`
			: `/home/groups/${this.type}/group/${this.group.id}`;
		this.apiGroupService.getUpdateFeed(this.group.id);
		this.router.navigate([ url ]);
	}
}
