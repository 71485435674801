<!-- <ng-content
  *ngIf="threeTabsOneLine === true; then threeTabsOneLine; else standard">
</ng-content>

<ng-template #standard> -->
  <div class="tab"
      *ngFor="let tab of tabs"
      [ngClass]="{
                  'active': isTabActive(tab.value),
                  'full-width': tabs.length === 1,
                  'accent-color': tab.value === 'statistics',
                  'two-tabs': tabs.length === 2,
                  'three-in-line': oneLineTabs === true
                }"
      (click)="changeTab(tab.value)">{{tab.title | translate}}</div>
<!-- </ng-template>

<ng-template #threeTabsOneLine>
  <div class="tab three-in-line"
       *ngFor="let tab of tabs"
       [ngClass]="{'active': isTabActive(tab.value)}"
       (click)="changeTab(tab.value)">
    {{tab.title | translate}}

    <br/>
    <pre>{{threeTabsOneLine || 'nothing passed'}}</pre>
  </div>
</ng-template> -->
