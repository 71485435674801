import { ApiBaseService } from './api-base.service';
import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AppConstants} from '../../utils/app.constants';
import {MeasurementModel} from '../../models/measurement.model';

@Injectable()
export class ApiMeasurementService extends ApiBaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getWordOfDay(): Observable<string> {
    const options = {responseType: 'text' as 'json'};
    return this.http.get<string>(AppConstants.api.baseUrl + `measurement/wordOfDay`, options);
  }

  registerWeight(data: MeasurementModel): Observable<MeasurementModel> {
    return this.postData<MeasurementModel>('measurement', data.toPostJson());
  }

  isCanUseOldWeight(): Observable<any> {
    return this.getData('measurement/last-approved');
  }

  applyLastApprovedWeight(measurementId: number, grouptId: number): Observable<any> {
    const body = {
      measurementId: measurementId,
      grouptId: grouptId
    };
    return this.postData('measurement/apply-approved', body);
  }
}
