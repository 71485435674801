import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';

function _window(): any {
	return window;
}
@Component({
	selector: 'app-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: [ './how-it-works.component.less' ]
})
export class HowItWorksComponent implements OnInit, OnDestroy {
	showMiniMenu = false;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private renderer: Renderer2,
		private auth: AuthService,
		private router: Router,
		private stateService: StateService
	) {
		setTimeout(() => {
			this.stateService.setHideBottomMenu('hide');
		}, 200);
	}

	ngOnInit() {
		this.showMiniMenu = this.auth.isAuthorized();
	}

	toggleClass(e: any, itemClass: string) {
		const newTarget = e.target.parentNode.children[1];
		const arrowRotate = e.target.children[0];
		const hasClass = e.target.parentNode.children[1].classList.contains(itemClass);
		if (hasClass) {
			this.renderer.removeClass(newTarget, itemClass);
			this.renderer.removeClass(arrowRotate, itemClass);
		} else {
			this.renderer.addClass(newTarget, itemClass);
			this.renderer.addClass(arrowRotate, itemClass);
		}
	}

	goBack() {
		this.showMiniMenu
			? /* this.routerInterceptorService.goBack() */ this.router.navigate([ '/home/menu' ])
			: this.router.navigate([ '..' ]);
	}

	ngOnDestroy() {
		this.stateService.setHideBottomMenu('show');
	}
}
