export interface IActiveTab {
	group: boolean;
	wallet: boolean;
	points: boolean;
	profile: boolean;
	menu: boolean;
}

import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';

@Component({
	selector: 'app-bottom-menu',
	templateUrl: './bottom-menu.component.html',
	styleUrls: [ './bottom-menu.component.less' ],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [ style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 })) ]),
			transition(':leave', [ style({ opacity: 1 }), animate('0.5s ease-in', style({ opacity: 0 })) ])
		])
	]
})
export class BottomMenuComponent implements OnInit {
	activeTab: IActiveTab = {
		group: false,
		wallet: false,
		points: false,
		profile: false,
		menu: false
	};

	passedParams = null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private passingDataService: PassingDataService,
		private apiGroupsService: ApiGroupsService
	) {}

	ngOnInit(): void {
		if (this.router.url) {
			this.findUrls(this.router.url);
		}
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.findUrls(event.urlAfterRedirects);
			}
		});
	}

	findUrls(url: string) {
		if (url.includes('/my-points')) {
			this.setActiveTab('points');
		} else if (url.includes('/payout')) {
			this.setActiveTab('wallet');
		} else if (url.includes('/profile')) {
			this.passingDataService.routeParamsFromChildComponent$.subscribe((params) => {
				this.passedParams = params;
				this.passedParams && this.passedParams.id ? this.setInactiveTabs() : this.setActiveTab('profile');
			});
		} else if (url.includes('/menu')) {
			this.setActiveTab('menu');
		} else if (url.includes('/groups/all') || url.includes('/groups/mine')) {
			this.setActiveTab('group');
		} else {
			this.setInactiveTabs();
		}
	}

	setActiveTab(tabName: 'group' | 'wallet' | 'points' | 'profile' | 'menu', shouldRedirect = false) {
		for (let [ key ] of Object.entries(this.activeTab)) {
			tabName === key ? (this.activeTab[key] = true) : (this.activeTab[key] = false);
		}
		if (shouldRedirect) {
			this.redirectTo(tabName);
			this.passingDataService.setRouteParamsFromChildComponent(null);
		}
	}

	setInactiveTabs() {
		for (let [ key ] of Object.entries(this.activeTab)) {
			this.activeTab[key] = false;
		}
	}

	redirectTo(tabName: string) {
		switch (tabName) {
			case 'group':
				this.apiGroupsService.dropIsMy();
				this.apiGroupsService.dropIsAll();
				this.router.navigate([ '/home/groups/all' ]);
				break;
			case 'wallet':
				this.router.navigate([ '/home/payout' ]);
				break;
			case 'points':
				this.router.navigate([ '/home/my-points' ]);
				break;
			case 'profile':
				this.router.navigate([ '/home/profile' ]);
				break;
			case 'menu':
				// Commented code adds /home/groups/all before /home/menu
				// So back button will redirect the user to the all-groups
				// it was done for all items in this SWITCH
				/* this.router
					.navigateByUrl('/', { skipLocationChange: true })
					.then(() => this.router.navigate([ '/home/menu' ])); */
				this.router.navigate([ '/home/menu' ]);
				break;
			default:
				break;
		}
	}
}
