<div class="group" #groupView>
  <div class="group__header">
    <div class="group__img" #groupImage>

    </div>
    <div class="group__content">
      <div class="group__title break cancel">{{group?.name}}</div>
      <div class="group__timestamp">{{'GLOBAL.START_DATE' | translate}}: {{group?.startDate | amDateFormat: 'DD-MM-YYYY'}}</div>
      <div class="group__description break cancel">{{group?.description}}</div>
    </div>
  </div>
  <div class="group__properties">
    <!-- Bet -->
    <div class="property">
      <div class="property__value">
        {{group?.bet}}kr
      </div>
      <div class="property__title">
        {{'GLOBAL.BET_SHORT' | translate}}
      </div>
    </div>
    <!-- Participants -->
    <div class="property__divider"></div>
    <div class="property">
      <div class="property__value">
        {{group?.amountOfParticipants}}
      </div>
      <div class="property__title">
        {{'GLOBAL.PARTICIPANTS_SHORT' | translate}}
      </div>
    </div>
    <!-- Teams -->
    <div class="property__divider"></div>
    <div class="property">
      <div class="property__value">
        {{group?.teamsCount}}
      </div>
      <div class="property__title">
        {{'GLOBAL.TEAMS' | translate}}
      </div>
    </div>
    <!-- In The Team Pot -->
    <div class="property__divider"></div>
    <div class="property">
      <div class="property__value">
        {{group.bettingAmount}}kr
      </div>
      <div class="property__title">
        {{'GLOBAL.IN_THE_POT' | translate}}
      </div>
    </div>
  </div>
  <a class="button button__alt" (click)="goToGroup()">
    {{'GLOBAL.GO_TO_GROUP' | translate}}
  </a>

  <div class="group__bonusprizes" *ngIf="group.pointsBonusPrize">
    <div class="bonusprizes">
      <div class="bonusprizes__title">
        <img src="assets/images/celebrate_icon_left.svg">{{"GLOBAL.BONUSPRIZE" | translate}}<img src="assets/images/celebrate_icon_right.svg">
      </div>
      <div class="bonusprizes__item">{{"GROUP.POINTS_BONUS_PRIZE" | translate}} <b>{{group.pointsBonusPrize}}kr.</b></div>
    </div>
  </div>

  <ng-container *ngIf="group?.influencer">
    <div class="verified"><img src="assets/images/verified_icon.svg">{{"GROUP.IS_VERIFIED" | translate}}</div>
  </ng-container>

</div>
