import {AuthService} from '../../services/auth.service';
import {UserRolesEnum} from '../../enums/UserRoles.enum';

export abstract class RolesComponent {
  UserRolesEnum = UserRolesEnum;

  constructor(public authService: AuthService) {
  }

  get isAdmin() {
    return this.authService.isAdmin();
  }

  get isReferee() {
    return this.authService.isReferee();
  }

  hasRole(role: UserRolesEnum) {
    return this.authService.hasRole(role);
  }
}
