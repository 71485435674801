import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '../utils/app.constants';

const noImage = 'url(assets/icons/notification_new_default_logo-3x.png)';

@Pipe({
	name: 'notificationImage'
})
export class NotificationImagePipe implements PipeTransform {
	transform(value: string | null): string {
		return value !== null && value !== ''
			? 'url(' + AppConstants.api.mediaFilesUrl + 'small/' + value.replace(/\"/g, '') + ')'
			: noImage;
	}
}
