import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams-list-footer',
  templateUrl: './teams-list-footer.component.html',
  styleUrls: ['./teams-list-footer.component.less']
})
export class TeamsListFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
