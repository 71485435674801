export interface ExtendedBalanceDTO {
	userId: number;
	points: number;
	gifts: number;
}

export interface ExtendedPointsDTO {
	userId: number;
	points: number;
}

export interface PointsHistoryDTO {
	date: string;
	amount: number;
	event: string;
}

export interface PayoutsDTO {
	date: string;
	amount: number;
	method: string;
	starus: string;
}

import { ApiBaseService } from './api-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanPayout } from 'src/app/models/can-payout.model';
import { ApiGroupsService } from './api-groups.service';

@Injectable()
export class ApiPaymentService extends ApiBaseService {
	private defaultCard = new Subject<any>();
	userDefaultCreditCard$ = this.defaultCard.asObservable();

	constructor(protected http: HttpClient, private apiGroupService: ApiGroupsService) {
		super(http);
	}

	createPurchase(
		nonce: string,
		chargeAmount: number,
		groupId: string,
		token?: string,
		locale = 'en'
	): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Accept-Language': locale
			})
		};
		const body = {
			nonce: nonce,
			token: token,
			amount: chargeAmount,
			groupId: groupId,
			locale: locale
		};
		return this.postDataWithOptions('payment/payment', httpOptions, body).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	getPayouts(): Observable<PayoutsDTO[]> {
		return this.getData('payment/payouts').pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	redeemPayout(payload: any): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Accept-Language': payload.locale
			})
		};
		return this.postDataWithOptions('payment/payout', httpOptions, payload).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	redeemPayoutGiftCard(amount: string): Observable<any> {
		return this.postDataWithResponce('payment/giftCard', { observe: 'response' }, { amount: amount });
	}

	redeemPayoutGiftCardGogift(amount: number): Observable<any> {
		return this.postDataWithResponce('payment/giftCardGogift', { observe: 'response' }, { amount: amount });
	}

	getUserBalance(): Observable<any> {
		return this.getData('userProfile/balance');
	}

	getExtendedUserBalance(): Observable<ExtendedBalanceDTO> {
		return this.getData('userProfile/extendedbalance');
	}

	getExtendedUserPoints(): Observable<ExtendedPointsDTO> {
		return this.getData('/userProfile/extendedpoints');
	}

	getPointsHistory(): Observable<PointsHistoryDTO[]> {
		return this.getData('payment/pointshistory');
	}

	transferPoints(amount: number): Observable<HttpResponseBase> {
		const payload = { amount };
		return this.postDataWithResponce('payment/transferpoints', { observe: 'response' }, payload);
	}

	payJoinGroupWithPoints(groupId: number, inviteFriendToken: string | null): Observable<any> {
		this.apiGroupService.dropAllCacheData(String(groupId));
		const payload = inviteFriendToken ? { token: inviteFriendToken } : {};
		return this.postData(`group/${groupId}/joinwithpoints`, payload);
	}

	canPayout(payload: any): Observable<CanPayout> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Accept-Language': payload.locale
			})
		};
		return this.postDataWithOptions(`payment/canpayout`, httpOptions, payload);
	}
}
