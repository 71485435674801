<div class="bottom-menu">
    <div class="bottom-menu__item" (click)="setActiveTab('group', true)">
        <div class="bottom-menu__item--icon-wrapper">
            <ng-container *ngIf="activeTab.group; then showActiveGroup; else showUnActiveGroup"></ng-container>
            <ng-template #showActiveGroup>
                <img src="assets/images/groups_filled.svg" [@inOutAnimation]>
            </ng-template>
            <ng-template #showUnActiveGroup>
                <img src="assets/images/groups-outlined.svg" [@inOutAnimation]>
            </ng-template>
        </div>
        <span class="bottom-menu__item--name">{{"BOTTOM_MENU.GROUPS"|translate}}</span>
    </div>
    <div class="bottom-menu__item" (click)="setActiveTab('wallet', true)">
        <div class="bottom-menu__item--icon-wrapper">
            <ng-container *ngIf="activeTab.wallet; then showActiveWallet; else showUnActiveWallet"></ng-container>
            <ng-template #showActiveWallet>
                <img src="assets/images/wallet_filled.svg" [@inOutAnimation]>
            </ng-template>
            <ng-template #showUnActiveWallet>
                <img src="assets/images/wallet_outlined.svg" [@inOutAnimation]>
            </ng-template>
        </div>
        <span class="bottom-menu__item--name">{{"BOTTOM_MENU.WALLET"|translate}}</span>
    </div>
    <div class="bottom-menu__item" (click)="setActiveTab('points', true)">
        <div class="bottom-menu__item--icon-wrapper">
            <ng-container *ngIf="activeTab.points; then showActivePoints; else showUnActivePoints"></ng-container>
            <ng-template #showActivePoints>
                <img src="assets/images/my-points_filled.svg" [@inOutAnimation]>
            </ng-template>
            <ng-template #showUnActivePoints>
                <img src="assets/images/my-points_outlined.svg" [@inOutAnimation]>
            </ng-template>
        </div>
        <span class="bottom-menu__item--name">{{"BOTTOM_MENU.POINTS"|translate}}</span>
    </div>
    <div class="bottom-menu__item" (click)="setActiveTab('profile', true)">
        <div class="bottom-menu__item--icon-wrapper">
            <ng-container *ngIf="activeTab.profile; then showActiveProfile; else showUnActiveProfile"></ng-container>
            <ng-template #showActiveProfile>
                <img src="assets/images/profile_filled.svg" [@inOutAnimation]>
            </ng-template>
            <ng-template #showUnActiveProfile>
                <img src="assets/images/profile_outlined.svg" [@inOutAnimation]>
            </ng-template>
        </div>
        <span class="bottom-menu__item--name">{{"BOTTOM_MENU.PROFILE"|translate}}</span>
    </div>
    <div class="bottom-menu__item" (click)="setActiveTab('menu', true)">
        <div class="bottom-menu__item--icon-wrapper">
            <ng-container *ngIf="activeTab.menu; then showActiveMenu; else showUnActiveMenu"></ng-container>
            <ng-template #showActiveMenu>
                <img class="menu" src="assets/images/more-menu_selected.svg" [@inOutAnimation]>
            </ng-template>
            <ng-template #showUnActiveMenu>
                <img class="menu" src="assets/images/more-menu_unselected.svg" [@inOutAnimation]>
            </ng-template>
        </div>
        <span class="bottom-menu__item--name">{{"BOTTOM_MENU.MENU"|translate}}</span>
    </div>
</div>
