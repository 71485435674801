import * as moment from 'moment';

export class DatepickerHelper {
    static generateSpecificDateObject(dateString: string) {
        if (dateString && dateString.length) {
            return new Date(dateString);
        }

        return null;
    }

    static format(date: Date) {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }
}