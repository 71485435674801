import { Component, ElementRef, OnInit, Renderer2, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { TabModel } from '../../models/tab.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { concat, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { AppConstants } from '../../utils/app.constants';
import { ApiUserProfileService, IUserPhoto } from 'src/app/services/api/api-user-profile.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { StateService } from 'src/app/services/state.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [ './profile.component.less' ]
})
export class ProfileComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('userImage') userImage: ElementRef;

	currentTab: string;
	currentUserId = null;
	endedGames = null;
	isMineProfile: boolean;
	isPrivatePhoto = true;
	isPrivateStatistics = true;
	profile: any;
	tabs: TabModel[];
	userFriends = [];
	userFriendsAmount;
	userPhotos: IUserPhoto[];
	userPhotosAmount: number;
	// Subscriptions
	getCompletedGames$;
	getUserProfile$;
	getUserFriends$;
	subscriptionsOnInit$: Subscription = Subscription.EMPTY;
	userPhotos$;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loadingSpinnerService: LoadingSpinnerService,
		private renderer: Renderer2,
		private userProfile: ApiUserProfileService,
		private topNotificationsService: TopNotificationsService,
		private errorsProcessorService: ErrorsProcessorService,
		private passingDataService: PassingDataService,
		private stateService: StateService,
		private ga: GoogleAnalyticsService
	) {
		this.getCompletedGames$ = (id: number = null) => this.userProfile.getCompletedGames(id);
		this.getUserFriends$ = (id: number = null) => this.userProfile.getUserFriends(id);
		this.getUserProfile$ = (id: number = null) => this.userProfile.getUserProfile(id);
		this.userPhotos$ = this.userProfile.getUserTwoPhotos$.pipe(
			map((data) => {
				this.userPhotos = data && data.images ? data.images : [];
				this.userPhotosAmount = data && data.amount ? data.amount : 0;
			})
		);
	}

	ngOnInit() {
		this.route.params.subscribe(
			() => {
				this.userPhotos$.subscribe();
				const route = this.route.snapshot;
				if (route.params.id) {
					if (+route.params.id === AppLocalStorage.getObject('authResponse').id) {
						this.router.navigate([ 'home/profile/' ]);
						return;
					}
					this.isMineProfile = false;
					this.currentUserId = route.params.id;
					this.passingDataService.setRouteParamsFromChildComponent(route.params);
					this.getUserProfile(route.params.id / 1);
				} else {
					this.passingDataService.setRouteParamsFromChildComponent(null);
					this.isMineProfile = true;
					this.getUserProfile();
				}
			},
			(httpErrorResponse: HttpErrorResponse) => {
				this.loadingSpinnerService.hide.next();
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			}
		);
	}

	ngOnChanges() {
		if ((this.currentUserId && !this.isPrivatePhoto) || !this.currentUserId) {
			this.userProfile.getTwoUserPhotos(this.currentUserId);
		}
	}

	getUserProfile(id: number = null) {
		this.loadingSpinnerService.show.next();
		this.subscriptionsOnInit$ = concat(
			this.getUserProfile$(id),
			this.getUserFriends$(id),
			this.getCompletedGames$(id)
		)
			.pipe(
				finalize(() => {
					this.setUserImage();
					this.setBoxTabs();
					this.stateService.setHideBottomMenu('show');
					this.loadingSpinnerService.hide.next();
				})
			)
			.subscribe(
				(data: any) => {
					if (data.hasOwnProperty('userName') && data.hasOwnProperty('goalWeight')) {
						this.profile = data;
						if (id) {
							if (!data.isPrivatePhoto) {
								this.isPrivatePhoto = false;
								this.userProfile.getTwoUserPhotos(id);
							}
							if (!data.isPrivateStatistics) {
								this.isPrivateStatistics = false;
							}
						} else {
							this.isPrivatePhoto = false;
							this.isPrivateStatistics = false;
							this.userProfile.getTwoUserPhotos();
						}
					} else if (data.hasOwnProperty('amount') && data.hasOwnProperty('friends')) {
						this.userFriends = data.friends;
						this.userFriendsAmount = data.amount;
					} else if (
						data.length > 0 &&
						data[0].hasOwnProperty('isMVP') &&
						data[0].hasOwnProperty('endDate')
					) {
						this.endedGames = data;
						if (this.endedGames.length === 0) {
							this.endedGames = null;
						}
					} else {
						return;
					}
				},
				(httpErrorResponse: HttpErrorResponse) => {
					this.loadingSpinnerService.hide.next();
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
					if (error === 'User not found') {
						this.router.navigate([ 'home/groups/all' ]);
					}
				}
			);
	}

	refetchUserProfile() {
		this.userProfile.getUserProfile().subscribe((data) => (this.profile = data));
	}

	getUserFriends(id: number = null) {
		this.getUserFriends$(id).subscribe(
			(data) => {
				this.userFriends = data.friends;
				this.userFriendsAmount = data.amount;
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			}
		);
	}

	getCompletedGames(id: number = null) {
		this.getCompletedGames$(id).subscribe(
			(data) => {
				this.endedGames = data;
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				if (this.endedGames.length === 0) {
					this.endedGames = null;
				}
			}
		);
	}

	setUserImage() {
		setTimeout(() => {
			const imageUrl = !!this.profile.userImage
				? AppConstants.api.mediaFilesUrl + 'small/' + this.profile.userImage.replace(/\"/g, '')
				: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
			this.renderer.setStyle(this.userImage.nativeElement, 'background-image', `url(${imageUrl})`);
		}, 10);
	}

	setBoxTabs() {
		this.tabs = [ new TabModel('GLOBAL.PROFILE', 'profile'), new TabModel('GLOBAL.STATISTICS', 'statistics') ];
		const rH = AppLocalStorage.getObject('routerHistory');
		if (rH) {
			if (rH.length >= 2) {
				if (rH[rH.length - 2] === '/home/register-weight/unofficial/profile') {
					this.currentTab = 'statistics';
					return;
				}
			}
		}
		this.currentTab = 'profile';
	}

	addUserToFriends() {
		const param = { friendId: this.currentUserId };
		this.userProfile.postUserFriend(param).subscribe(
			() => {
				this.profile.isFriend = true;
				this.ga.eventEmitter('added-friend', 'friends-engagement', 'added-friend');
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			}
		);
	}

	editProfile() {
		if (this.isMineProfile) {
			this.router.navigate([ 'settings/my-profile' ], { relativeTo: this.route.parent });
		}
	}

	ngOnDestroy() {
		this.subscriptionsOnInit$.unsubscribe();
	}
}
