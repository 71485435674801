<ng-container *ngIf="group.teamId && group.teamId !== 0; then standardView"></ng-container>

<ng-template #standardView>
    <div class="my-team-header" *ngIf="!this.isLoading">
        <div class="my-team-header__team-name">
            <span class="break cancel">{{team.name}}</span>
        </div>
        <a class="my-team-header__compose-private-post" (click)="composePrivatePost()">{{"TEAM.COMPOSE_PRIVATE_POST"|translate}}</a>
        <div *ngIf="!group.isStarted" class="my-team-header__control-buttons">
            <a *ngIf="isTeamCaptain" (click)="editTeam()">
                <img class="color" src="assets/images/pen-blue.png"
                    srcset="assets/images/pen-blue@2x.png 2x,
                            assets/images/pen-blue@3x.png 3x">
                &nbsp; {{ "TEAM.EDIT" | translate }}
            </a> 
        </div>
    </div>
    <div class="need-more-participants" *ngIf="group.isCanJoin && needMoreParticipants > 0 && !this.isLoading">
        <p class="need-more-participants__text">
            {{ "TEAM.PARTICIPANTS_NEEDED.P1" | translate }}&nbsp;<b>{{teamParticipantNeeded}}</b>&nbsp;{{ "TEAM.PARTICIPANTS_NEEDED.P2" | translate }}<br/>
            {{ "TEAM.PARTICIPANTS_HAS.P1" | translate }}&nbsp;<b>{{teamHasParticipants}}</b>&nbsp;{{ "TEAM.PARTICIPANTS_HAS.P2" | translate }}
        </p>
    </div>
</ng-template>

<div class="spinner" *ngIf="this.isLoading">
    <mat-progress-spinner color="accent" mode="indeterminate" diameter="50"></mat-progress-spinner>
</div>
