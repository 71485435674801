<div class="slider posts" (swipeleft)="slideRight()" (swiperight)="slideLeft()" [ngClass]="{'with-arrows': arrows}">
  <div class="slider__wrapper" [ngClass]="{'post-slider': postsSlider}">
    <div class="slider__container">
      <ng-content *ngIf="show"></ng-content>
    </div>
  </div>
  <div class="slider__dots" *ngIf="show && dots">
    <div *ngFor="let dot of sliderDots; let index = index"
          class="slider__dot"(click)="isClickableDots ? slideTo(index): ''"
          [ngClass]="{'transparent-white': transparentWhiteDots, 'active': index === sldrStatus}"></div>
  </div>
  <div *ngIf="show && arrows || show && arrowsOnText">
    <div *ngIf="!isStart()" class="slider__control slider__control--left" (click)="slideLeft()">
      <img src="assets/images/blue-arrow-down.png" srcset="assets/images/blue-arrow-down@2x.png 2x,
                assets/images/blue-arrow-down@3x.png 3x">
    </div>
    <div *ngIf="!isEnd()" class="slider__control slider__control--right" (click)="slideRight()">
      <img src="assets/images/blue-arrow-down.png" srcset="assets/images/blue-arrow-down@2x.png 2x,
                assets/images/blue-arrow-down@3x.png 3x">
    </div>
  </div>
</div>
