export interface PayloadToUpdateGoalWeight {
  isPrivatePhoto: boolean;
  isPrivateStatistics: boolean;
  goalWeight: number;
  quickFacts: {
    bestHealthyFood: string;
    bestUnhealthyFood: string;
    favoriteWeightLossMethod: string;
    favoriteTrainingUniform: string;
    worstHabit: string;
    superpower: string;
    dinnerWith: string;
  };
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SingleDataSet } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';
import { Router } from '@angular/router';
import { UserProfileModel } from 'src/app/models/user-profile.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-profile-doughnut-chart',
  templateUrl: './profile-doughnut-chart.component.html',
  styleUrls: ['./profile-doughnut-chart.component.less']
})
export class ProfileDoughnutChartComponent implements OnInit {

  statistics;
  statisticsPercent;
  dropTarget;
  leftToReach;
  reached;
  showUpdateGoalWeight = false;
  @Input() currentUserId;
  @Input() isMineProfile;
  @Output() showLineCharts = new EventEmitter();
  payloadForGoalWeightUpdate: PayloadToUpdateGoalWeight = {
    isPrivatePhoto: false,
    isPrivateStatistics: false,
    goalWeight: null,
    quickFacts: {
      bestHealthyFood: '',
      bestUnhealthyFood: '',
      favoriteTrainingUniform: '',
      favoriteWeightLossMethod: '',
      worstHabit: '',
      superpower: '',
      dinnerWith: ''
    }
  };
  updateNotification: string;
  doughnutChartData: SingleDataSet = [0, 0];
  doughnutChartType: ChartType = 'doughnut';
  doughnutColors = [
    {
      backgroundColor: [
        'rgba(226,233,241, 1)',
        'rgba(251,111,68, 1)'
      ],
      borderColor: [
        'rgba(226,233,241, 1)',
        'rgba(251,111,68, 1)'
      ],
      borderWidth: 10,
      backgroundBorderRadius: 10,
      borderAlign: 'center',
    }
  ];

  doughnutOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    rotation : 2 * Math.PI,
    circumference: 2 * Math.PI,
    cutoutPercentage: 96.5,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  };

  constructor(
    private router: Router,
    private userService: ApiUserProfileService,
    private translate: TranslateService,
    private topNotificationsService: TopNotificationsService,
    private errorsProcessorService: ErrorsProcessorService,
    private loadingSpinnerService: LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.getUserStatistics(this.currentUserId);
  }

  getUserStatistics(userId: number = null) {
    this.userService.getUserStatistics(userId).subscribe(
      (stats) => {
        this.statistics = stats;
        if (this.statistics.initialWeight === 0 ||
          this.statistics.currentWeight === 0 ||
          this.statistics.targetWeight  === 0 ||
          this.statistics.initialWeight <= this.statistics.targetWeight ||
          this.statistics.initialWeight <= this.statistics.currentWeight
          ) {
          this.doughnutChartData[0] = 100;
          this.doughnutChartData[1] = 0;
          this.statisticsPercent = 0 + '%';
        } else if (this.statistics.currentWeight <= this.statistics.targetWeight) {
          this.doughnutChartData[0] = 0;
          this.doughnutChartData[1] = 100;
          this.dropTarget = this.statistics.initialWeight - this.statistics.targetWeight;
          this.statisticsPercent = Math.floor((this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget) + '%';
        } else {
          this.dropTarget = this.statistics.initialWeight - this.statistics.targetWeight;
          this.leftToReach = (this.dropTarget - (this.statistics.initialWeight - this.statistics.currentWeight)) * 100 / this.dropTarget;
          this.reached = (this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget;
          this.doughnutChartData[0] = this.leftToReach;
          this.doughnutChartData[1] = this.reached;
          this.statisticsPercent = Math.floor((this.statistics.initialWeight - this.statistics.currentWeight) * 100 / this.dropTarget) + '%';
        }
      },
      () => {},
      () => {
        this.loadingSpinnerService.hide.next();
      }
    );
  }

  toggleShowUpdateGoalWeight() {
    this.loadingSpinnerService.show.next();
    this.userService.getUserProfile(this.currentUserId).subscribe(
      (data: UserProfileModel) => {
        this.payloadForGoalWeightUpdate.isPrivatePhoto = data.isPrivatePhoto;
        this.payloadForGoalWeightUpdate.isPrivateStatistics = data.isPrivateStatistics;
        this.payloadForGoalWeightUpdate.goalWeight = data.goalWeight;
        this.payloadForGoalWeightUpdate.quickFacts.bestHealthyFood = data.quickFacts.bestHealthyFood;
        this.payloadForGoalWeightUpdate.quickFacts.bestUnhealthyFood = data.quickFacts.bestUnhealthyFood;
        this.payloadForGoalWeightUpdate.quickFacts.favoriteTrainingUniform = data.quickFacts.favoriteTrainingUniform;
        this.payloadForGoalWeightUpdate.quickFacts.favoriteWeightLossMethod = data.quickFacts.favoriteWeightLossMethod;
        this.payloadForGoalWeightUpdate.quickFacts.worstHabit = data.quickFacts.worstHabit;
        this.payloadForGoalWeightUpdate.quickFacts.superpower = data.quickFacts.superpower;
        this.payloadForGoalWeightUpdate.quickFacts.dinnerWith = data.quickFacts.dinnerWith;
      },
      (httpErrorResponse: HttpErrorResponse) => {
        const error = this.errorsProcessorService.process(httpErrorResponse);
        this.topNotificationsService.notify(error, 'error');
        this.toggleAndEmit();
        this.loadingSpinnerService.hide.next();
      },
      () => {
        this.loadingSpinnerService.hide.next();
        this.translate.stream('PROFILE.UPDATE_GOAL_WEIGHT.SUCCESS_MESSAGE').subscribe(data => this.updateNotification = data);
      }
    );
    this.toggleAndEmit();
  }

  updateGoalWeight(newGoalWeight) {
    this.loadingSpinnerService.show.next();
    this.payloadForGoalWeightUpdate.goalWeight = newGoalWeight;
    this.userService.updateUserProfile(this.payloadForGoalWeightUpdate).subscribe(
      () => {
        this.doughnutChartData = [];
        this.getUserStatistics(this.currentUserId);
      },
      (httpErrorResponse: HttpErrorResponse) => {
        const error = this.errorsProcessorService.process(httpErrorResponse);
        this.topNotificationsService.notify(error, 'error');
        this.toggleAndEmit();
        this.loadingSpinnerService.hide.next();
      },
      () => {
        this.toggleAndEmit();
        this.topNotificationsService.notify(this.updateNotification, 'success');
      }
    );
  }

  registerWeight() {
    this.router.navigate([`home/register-weight/unofficial/profile`]);
  }

  toggleAndEmit() {
    this.showUpdateGoalWeight = !this.showUpdateGoalWeight;
    this.showLineCharts.emit(!this.showUpdateGoalWeight);
  }
}
