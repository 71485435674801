import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AppConstants} from '../../utils/app.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiGroupsService} from '../../services/api/api-groups.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {GroupModel} from '../../models/group.model';
import {ApiPaymentService} from '../../services/api/api-payment.service';
import {StateService} from '../../services/state.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TopNotificationsService} from '../../services/top-notifications.service';
import {ErrorsProcessorService} from '../../services/errors-processor.service';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import {AppLocalStorage} from '../../utils/app.local.storage';
import { DomSanitizer } from '@angular/platform-browser';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.less']
})
export class PaymentComponent implements OnInit {

  groupId: string;
  group: GroupModel;
  initPaymentForm = false;

  isPayed = false;
  trustlyUrl;
  inviteEntityFromService = null;
  isMobileDevice: boolean;
  @ViewChild('groupImage') groupImage: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupsApiService: ApiGroupsService,
    private paymentApiService: ApiPaymentService,
    private loadingSpinnerService: LoadingSpinnerService,
    private renderer: Renderer2,
    private stateService: StateService,
    private topNotificationsService: TopNotificationsService,
    private errorsProcessorService: ErrorsProcessorService,
    private routerInterceptorService: RouterInterceptorService,
    private sanitizer: DomSanitizer,
    private passedDataService: PassingDataService
  ) {
    this.passedDataService.inviteTokenForWebView$.subscribe(
      token => this.inviteEntityFromService = token
    );
  }

  ngOnInit() {
    if (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      navigator.userAgent.match(/ZuneWP7/i)
    ) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
    this.loadingSpinnerService.show.next();
    this.groupId = this.route.snapshot.params['id'];
    if (this.route.snapshot.data) {
      this.stateService.setRouteData(this.route.snapshot.data);
    }
    let inviteEntity = AppLocalStorage.getObject(AppConstants.keys.invite) ?
                          AppLocalStorage.getObject(AppConstants.keys.invite) :
                          null;
    // Added additional checks after storing invite token at passing data service
    if (this.inviteEntityFromService && inviteEntity) {
      if (Number(this.inviteEntityFromService.groupId) !== Number(inviteEntity.groupId)) {
        inviteEntity = this.inviteEntityFromService;
      }
    } else {
      if (this.inviteEntityFromService) {
        inviteEntity = this.inviteEntityFromService;
      }
    }
    this.groupsApiService.getGroup(this.groupId).subscribe(
      (data) => {
        this.group = data;
        this.initPaymentForm = true;
        this.setGroupImage();
        let token = null;
        if (inviteEntity && Number(inviteEntity.groupId) === Number(this.groupId)) {
          token = inviteEntity.token;
        }
        const locale = AppLocalStorage.get(AppConstants.keys.defaultLanguage) ? AppLocalStorage.get(AppConstants.keys.defaultLanguage).replace(/\"/g, '') : 'en';
        this.paymentApiService.createPurchase('string', this.group.bet, this.group.id, token, locale).subscribe(
          (trustly) => {
            if (this.isMobileDevice) {
              window.open(trustly.url, '_self');
            } else {
              this.trustlyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(trustly.url);
            }
          },
          (response: HttpErrorResponse) => {
            this.loadingSpinnerService.hide.next();
            const error = this.errorsProcessorService.process(response);
            this.topNotificationsService.notify(error, 'error');
            this.router.navigate([`home/groups/all/group/${this.group.id}`]);
          },
          () => { this.groupsApiService.dropAllCacheData(this.group.id); }
        );
      },
      (response: HttpErrorResponse) => {
        this.loadingSpinnerService.hide.next();
        const error = this.errorsProcessorService.process(response);
        this.topNotificationsService.notify(error, 'error');
      }
    );
  }

  loadedIFrame() {
    this.loadingSpinnerService.hide.next();
  }

  setGroupImage() {
    setTimeout(() => {
      const imageUrl = !!this.group.image ? AppConstants.api.mediaFilesUrl + 'small/' + this.group.image.replace(/\"/g, '') : 'assets/images/group-img.jpg';
      this.renderer.setStyle(this.groupImage.nativeElement, 'background-image', `url(${imageUrl})`);
    }, 0);
  }

  goBack() {
    this.routerInterceptorService.goBack();
  }
}
