<div [ngSwitch]="popupData.popupType">
    <ng-container *ngSwitchCase="'inviteToGroup'">
        <app-invite-to-group-popup [popupData]="popupData"></app-invite-to-group-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'afterJoinTheGroup'">
        <app-after-join-group-popup [popupData]="popupData"></app-after-join-group-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'pointsTransfer'">
        <app-points-transfer-popup [popupData]="popupData"></app-points-transfer-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'walletPayout'">
        <app-wallet-payout-popup [popupData]="popupData"></app-wallet-payout-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'participants'">
        <app-participants-popup [popupData]="popupData"></app-participants-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'teamMembers'">
        <app-participants-popup [popupData]="popupData"></app-participants-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'discountCodes'">
        <app-discount-codes-popup [popupData]="popupData"></app-discount-codes-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'startWeightInfo'">
        <app-start-weight-info-popup [popupData]="popupData"></app-start-weight-info-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'showPaymentConfirmation'">
        <app-show-payment-confirmation-popup [popupData]="popupData"></app-show-payment-confirmation-popup>
    </ng-container>
    <ng-container *ngSwitchCase="'teamMembersSize'">
        <app-team-members-size-popup></app-team-members-size-popup>
    </ng-container>
</div>
