import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { CordovaService } from '../../services/cordova.service';
import { ApiAccountService } from '../../services/api/api-account.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { RouterInterceptorService } from '../../services/router-interceptor.service';
import { AppConstants } from '../../utils/app.constants';
import { AppLocalStorage } from '../../utils/app.local.storage';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

declare let gtag: Function;
declare let fbq: Function;

function _window(): any {
  return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.less' ]
})
export class AppComponent implements OnInit {
  appPlatform = '';
  onAuthorizeSubscription: Subscription;
  message: any;
  messagingServiceSubscription: Subscription = new Subscription();

  private onRegistrationSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private routerInterceptorService: RouterInterceptorService,
    private cordova: CordovaService,
    private accountApiService: ApiAccountService,
    private messagingService: MessagingService,
    private titleService: Title,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
    this.initWebNotifications = this.initWebNotifications.bind(this);
  }

  ngOnInit() {
    this.translate.addLangs([ 'en', 'sv' ]);
    this.translate.setDefaultLang('sv');

    if (!AppLocalStorage.get('defaultLanguage')) {
      const browserLang = _window().navigator.userLanguage || _window().navigator.language;
      let setNewLang;
      if (browserLang.match(/en/)) {
        setNewLang = 'en';
      } else {
        setNewLang = 'sv';
      }
      AppLocalStorage.add(AppConstants.keys.defaultLanguage, setNewLang);
      this.translate.use(setNewLang);
    } else {
      const browserLang = AppLocalStorage.getObject(AppConstants.keys.defaultLanguage);
      let setNewLang;
      if (browserLang.match(/en/)) {
        setNewLang = 'en';
      } else {
        setNewLang = 'sv';
      }
      if (AppLocalStorage.getObject(AppConstants.keys.defaultLanguage) !== setNewLang) {
        AppLocalStorage.remove(AppConstants.keys.defaultLanguage);
        AppLocalStorage.add(AppConstants.keys.defaultLanguage, setNewLang);
      }
      this.translate.use(setNewLang);
    }

    this.authService.onUnauthorize.subscribe(() => {
      this.messagingServiceSubscription.unsubscribe();
      if (this.onRegistrationSubscription) {
        this.onRegistrationSubscription.unsubscribe();
        this.onRegistrationSubscription = null;
      }
      this.loadingSpinnerService.hide.next();
      this.router.navigate([ '/' ]);
    });

    this.onAuthorizeSubscription = this.authService.onAuthorize.subscribe(() => {
      if (!this.cordova.onCordova) {
        this.initWebNotifications();
      } else {
        this.initCordovaNotifications();
      }
    });

    if (this.cordova.onCordova) {
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);

      // FIX CODE BELOW FOR CORDOVA APPs

      /* if (IOS APPLICATION) {
        this.appPlatform = 'IOS_App';
      } else {
        this.appPlatform = 'Android_App';
      } */
      this.appPlatform = 'Cordova_App';
    } else {
      this.initWebNotifications();
      this.appPlatform = 'Web_Browser';
    }

    this.insertGoogleAnalytics();
  }

  insertGoogleAnalytics() {
    let isFirstPageLoad = true;
    this.router.events.subscribe((event) => {
      if (isFirstPageLoad) {
        isFirstPageLoad = false;
        return;
      }
      if (event instanceof NavigationEnd) {
        gtag('config', AppConstants.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
          page_title: this.titleService.getTitle()
        });

        gtag('event', 'formsats_platform', {
          page_title: this.titleService.getTitle(),
          event_category: this.appPlatform,
          event_label: this.appPlatform,
          non_interaction: true
        });

        fbq('trackCustom', {
          page_path: event.urlAfterRedirects,
          page_title: this.titleService.getTitle(),
          platform: this.appPlatform,
        });
      }
    });
  }

  onDeviceReady() {
    this.cordova.init();
    if (this.authService.isAuthorized()) {
      this.initCordovaNotifications();
    }
    document.addEventListener(
      'backbutton',
      (evt) => {
        evt.preventDefault();
        if (this.routerInterceptorService.routeHistory.length > 1) {
          this.routerInterceptorService.goBack();
        } else {
          throw new Error('Exit'); // This will suspend the app
        }
      },
      false
    );
  }

  initCordovaNotifications() {
    this.cordova.init();
    if (this.onRegistrationSubscription) {
      return;
    }
    this.onRegistrationSubscription = this.cordova.registration.subscribe((data) => {
      if (data) {
        if (this.authService.isAuthorized()) {
          this.accountApiService.updateNotificationToken(data.registrationId).subscribe((response) => {
            this.messagingServiceSubscription = this.cordova.subscribeMsg((data) => {
              if (data) {
                // here should be handler for notification
                if (data && data.additionalData) {
                  if (data.additionalData.foreground) {
                  } else {
                    console.log('in background');
                    if (data.additionalData.coldstart) {
                      // we are here after clicking on the notification popup
                    } else {
                      // we are here after clicking on the notification popup
                    }
                  }
                }
                this.cordova.finish();
              }
            });
          });
        }
      }
    });
  }

  initWebNotifications() {
    this.messagingServiceSubscription.unsubscribe();
    if ('serviceWorker' in navigator) {
      this.messagingService.refreshToken().subscribe(
        () => {
          this.messagingServiceSubscription = this.messagingService.requestPermission().subscribe(
            (token) => {
              if (this.authService.isAuthorized() && !!token) {
                this.messagingService.updateToken(token);
              }
            },
            () => {
              console.warn('Your browser not supports PUSH WEB API or your didn\'t allow notifications');
            }
          );
          this.messagingService.receiveMessage();
        },
        () => {
          console.warn('Your browser not supports PUSH WEB API or your didn\'t allow notifications');
        }
      );
    }
  }
}
