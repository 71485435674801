import { Component, OnInit } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: [ './terms.component.less' ]
})
export class TermsComponent implements OnInit {
	showMiniMenu = false;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private auth: AuthService,
		private router: Router
	) {}

	ngOnInit() {
		this.showMiniMenu = this.auth.isAuthorized();
	}

	goBack() {
		this.showMiniMenu ? this.routerInterceptorService.goBack() : this.router.navigate([ '..' ]);
	}
}
