import { Injectable } from '@angular/core';

declare let gtag: Function;
declare let fbq: Function;

@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {
	public eventEmitter(
		eventName: string = '',
		eventCategory: string = '',
		/* eventAction: string = '', */
		eventLabel: string = '',
		eventValue: number = null
	) {
		gtag('event', eventName, {
			eventCategory: eventCategory,
			eventLabel: eventLabel,
			/* eventAction: eventAction, */
			eventValue: eventValue
		});
    // track events for Facebook Pixel
    fbq('trackCustom', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      /* eventAction: eventAction, */
      eventValue: eventValue
    });
	}
}
