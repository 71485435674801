export class UserModel {
  id: number;
  name: string;
  email: string;
  image: string;
  role: string;

  constructor(data: any = {}) {
    this.id = data.id || null;
    this.name = data.userName || '';
    this.email = data.email || '';
    this.image = data.image || '';
    this.role = data.role || '';
  }
}

export class UserModelWithBalance {
  id: number;
  name: string;
  email: string;
  image: string;
  role: string;
  balance: number;
  points: number;

  constructor(data: any = {}) {
    this.id = data.id || null;
    this.name = data.userName || '';
    this.email = data.email || '';
    this.image = data.image || '';
    this.role = data.role || '';
    this.balance = data.balance || null;
    this.points = data.points || null;
  }
}

export class UserStepsHistory {
  date: string;
  modifiedDate: string;
  stepsCount: number;
  userId: number;
  constructor(data: any = {}) {
    this.userId = data.userId;
    this.date = data.date;
    this.modifiedDate = data.modifiedDate;
    this.stepsCount = data.stepsCount;
  }
}
export class UserGroup {
  groupId: number;
  groupName: string;
  groupType: string;
  isPaused: boolean;
  stepsGoal: number;
  userId: number;
  constructor(data: any = {}) {
    this.userId = data.userId;
    this.groupId = data.groupId;
    this.groupName = data.groupName;
    this.groupType = data.groupType;
    this.isPaused = data.isPaused;
    this.stepsGoal = data.stepsGoal;
  }
}
