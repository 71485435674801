<div class="wrapper">
    <div class="title">
        <p>{{"GROUP.PAYMENT_CONFIRMATION_POPUP.TITLE"|translate}}</p>
        <p class="group-name">{{popupData.groupName}}</p>
    </div>

    <div class="payment-info">
        <p class="bet">{{"GROUP.PAYMENT_CONFIRMATION_POPUP.TO_JOIN"|translate}} {{popupData.groupBet}}kr.</p>
        <p class="got-points-to-join" *ngIf="popupData.gotPointsToJoinGroup">{{"GROUP.PAYMENT_CONFIRMATION_POPUP.CAN_PAY_WITH_POINTS.TEXT"|translate}}</p>
    </div>
    <a *ngIf="popupData.gotPointsToJoinGroup" class="button button__alt" (click)="payWithPoints()">{{ "GROUP.JOIN.PAYMENT_CONFIRMATION.BUTTON.CONFIRM.POINTS" | translate }}</a>
    <a class="button button__main" (click)="goToPayment()">{{ "GROUP.JOIN.PAYMENT_CONFIRMATION.BUTTON.CONFIRM" | translate }}</a>
    <a class="button button__main disabled" (click)="closePopup()">{{ "GROUP.JOIN.PAYMENT_CONFIRMATION.BUTTON.CANCEL" | translate }}</a>
</div>