import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	Input,
	ChangeDetectorRef,
	AfterViewInit,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { UserNotificationModel } from '../../../models/user-notifications.model';
import { MessageParametersByType, RedirectUrlsByType } from '../../../enums/Notifications.enum';
import { ApiAccountService } from 'src/app/services/api/api-account.service';
import { AppLocalStorage } from 'src/app/utils/app.local.storage';
import { Router } from '@angular/router';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-notification-menu',
	templateUrl: './notification-menu.component.html',
	styleUrls: [ './notification-menu.component.less' ]
})
export class NotificationMenuComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() isActive = false;
	@Input() isLoadingNotifications;
	@Input() markAllAsRead;
	@Input() messages;
	@Output() hasDeletedNotifications = new EventEmitter<boolean>();
	@Output() notificationPageIndex = new EventEmitter<number>();
	@Output() notificationsIsOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

	currentUserId;
	deleteNotification$: Subscription = Subscription.EMPTY;
	isDeleting;
	hasDeletations = false;
	markAllNotificationsAsRead$: Subscription = Subscription.EMPTY;
	markNotificationAsRead$: Subscription = Subscription.EMPTY;
	notifications;
	notificationsCount$ = this.passingDataService.notificationsCount$;
	pageIndex = 1;
	queryParams = null;

	constructor(
		private accountApiService: ApiAccountService,
		private cdr: ChangeDetectorRef,
		private groupsApiService: ApiGroupsService,
		private passingDataService: PassingDataService,
		private router: Router
	) {}

	ngOnInit() {
		this.currentUserId = AppLocalStorage.getObject('authResponse').id;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.markAllAsRead && !changes.markAllAsRead.firstChange) {
			if (this.markAllAsRead) {
				this.markAllNotificationsAsRead();
			}
		}
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	getRedirectUrl(message: UserNotificationModel) {
		this.queryParams = null;
		if (message.type === 'Admin' || message.type === 'GroupRemove') {
			return `home/groups/all`;
		} else if (message.type === 'Post' || message.type === 'Comment' || message.type === 'Like') {
			this.queryParams = { feedTab: 'true' };
			if (this.isRedirectToComment(message)) {
				return this.makeCommentLink(message);
			} else if (this.isRedirectToPost(message)) {
				return this.makePostLink(message);
			} else {
				return this.makeLikeLink(message);
			}
		} else if (message.type === 'InviteByToken') {
			return `home/my-points`;
		} else if (message.type === 'TeamMemberAskedToJoin') {
			return `home/team-join-requests`;
		} else if (message.type === 'TeamRulesBeforeStart') {
			return (
				RedirectUrlsByType[message.type] +
				JSON.parse(message.data)[MessageParametersByType[message.type]] +
				'/invite'
			);
		} else if (
			message.type === 'TeamMemberWeightUpdated' ||
			message.type === 'UserBecomeCaptain' ||
			message.type === 'YouJoinedTeam' ||
			message.type === 'NewCaptainInTeam'
		) {
			this.queryParams = { statsticsOnTop: 'true' };
			return RedirectUrlsByType[message.type] + JSON.parse(message.data)[MessageParametersByType[message.type]];
		} else if (message.type === 'GoalReached') {
			this.queryParams = { groupWinner: 'true' };
			return RedirectUrlsByType[message.type] + JSON.parse(message.data)[MessageParametersByType[message.type]];
		} else if (message.type === 'GoalNotReached') {
			this.queryParams = { groupWinner: 'false' };
			return RedirectUrlsByType[message.type] + JSON.parse(message.data)[MessageParametersByType[message.type]];
		} else if (message.type === 'TeamJoinRequestRejectedAuto') {
			this.queryParams = { joinTheTeam: 'true' };
			return RedirectUrlsByType[message.type] + JSON.parse(message.data)[MessageParametersByType[message.type]];
		} else {
			return RedirectUrlsByType[message.type] + JSON.parse(message.data)[MessageParametersByType[message.type]];
		}
	}

	isRedirectToComment(message) {
		return !!JSON.parse(message.data)['postId'] && !!JSON.parse(message.data)['commentId'];
	}
	isRedirectToPost(message) {
		return !!JSON.parse(message.data)['postId'] && !!!JSON.parse(message.data)['commentId'];
	}

	makeCommentLink(message) {
		return (
			RedirectUrlsByType[message.type] +
			JSON.parse(message.data)['groupId'] +
			'/' +
			JSON.parse(message.data)['postId'] +
			'/' +
			JSON.parse(message.data)['commentId']
		);
	}
	makePostLink(message) {
		return (
			RedirectUrlsByType[message.type] +
			JSON.parse(message.data)['groupId'] +
			'/' +
			JSON.parse(message.data)['postId']
		);
	}
	makeLikeLink(message) {
		return RedirectUrlsByType[message.type] + JSON.parse(message.data)['groupId'];
	}

	loadMore() {
		this.hasDeletations ? (this.pageIndex = 1) : this.pageIndex++;
		this.notificationPageIndex.next(this.pageIndex);
		this.hasDeletations = false;
	}

	deleteNotification(id, index) {
		this.deleteNotification$.unsubscribe();
		this.isDeleting = index;
		this.deleteNotification$ = this.accountApiService.deleteNotification(id).subscribe(() => {
			this.messages.notificationsTotal--;
			this.messages.notifications.splice(index, 1);
			this.hasDeletations = true;
			this.isDeleting = null;
		});
	}

	markNotificationsAsRead(messageId, notificationHasRead, message) {
		if (!notificationHasRead) {
			this.markNotificationAsRead$.unsubscribe();
			this.markNotificationAsRead$ = this.accountApiService.markNotificationAsRead(messageId).subscribe(() => {
				this.passingDataService.setNotificationsCount('decreaseByOne');
			});
		}
		switch (message.type) {
			case 'FriendStarted':
				this.groupsApiService.dropIsAll();
				break;
			case 'InitWeightBeforeStart' || 'InitWeightOnStart' || 'FinalWeight' || 'WaitForModeration':
				this.groupsApiService.dropIsMy();
				if (JSON.parse(message.data).groupId) {
					this.groupsApiService.deleteItemFormLastGroupFetchArray(JSON.parse(message.data).groupId);
				}
				break;
			case 'GroupFinish' ||
				'Disqualified' ||
				'ChangeStartDate' ||
				'FriendJoined' ||
				'Participant' ||
				'KickedFromGroup' ||
				'TeamMemberLeft' ||
				'TeamMemberJoined' ||
				'TeamMemberAskedToJoin' ||
				'TeamJoinRequestApproved' ||
				'TeamJoinRequestRejected' ||
				'GroupFinishWithTeams':
				if (JSON.parse(message.data).groupId) {
					this.groupsApiService.dropAllCacheData(JSON.parse(message.data).groupId);
				}
				break;
			default:
				break;
		}
		if (
			(message.type === 'Participant' &&
				JSON.parse(message.data).userId === this.currentUserId &&
				JSON.parse(message.data).groupId === null) ||
			message.type === 'GroupRemove'
		) {
			this.closeMenu();
		} else if (message.type !== 'Admin') {
			const url = this.getRedirectUrl(message);
			this.router.routeReuseStrategy.shouldReuseRoute = function() {
				return false;
			};
			this.router.navigate([ `${url}` ], { queryParams: this.queryParams ? this.queryParams : null });
		} else {
			this.closeMenu();
		}
	}

	markAllNotificationsAsRead() {
		this.markAllNotificationsAsRead$.unsubscribe();
		this.markAllNotificationsAsRead$ = this.accountApiService.markAllNotificationsAsRead().subscribe(() => {
			this.messages.notificationsUnRead = 0;
			this.messages.notifications = this.messages.notifications.map((el) => {
				el.isRead = true;
				return el;
			});
			this.passingDataService.setNotificationsCount('makeItZero');
		});
	}

	closeMenu() {
		this.notificationsIsOpen.next();
		if (this.hasDeletations) {
			this.hasDeletedNotifications.next(this.hasDeletations);
			this.hasDeletations = false;
		}
		this.pageIndex = 1;
	}

	trackByItems(index: number, item: UserNotificationModel): number {
		return item.id;
	}
}
