import { Component, OnInit } from '@angular/core';
import { RouterInterceptorService } from 'src/app/services/router-interceptor.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-rules',
	templateUrl: './rules.component.html',
	styleUrls: [ './rules.component.less' ]
})
export class RulesComponent implements OnInit {
	showInfluencerPopUp = false;
	showPotPopUp = false;
	showMiniMenu = false;

	constructor(
		private routerInterceptorService: RouterInterceptorService,
		private auth: AuthService,
		private router: Router
	) {}

	ngOnInit() {
		this.showMiniMenu = this.auth.isAuthorized();
	}

	toggleInfluencer() {
		this.showInfluencerPopUp = !this.showInfluencerPopUp;
	}

	togglePot() {
		this.showPotPopUp = !this.showPotPopUp;
	}
	goBack() {
		this.showMiniMenu ? this.routerInterceptorService.goBack() : this.router.navigate([ '..' ]);
	}
}
