import { Component, Input, OnInit } from '@angular/core';
import { TabModel } from 'src/app/models/tab.model';
import { UserProfileStatisticsModel } from 'src/app/models/user-profile.model';
import { ApiUserProfileService } from 'src/app/services/api/api-user-profile.service';

@Component({
  selector: 'app-profile-statistics',
  templateUrl: './profile-statistics.component.html',
  styleUrls: ['./profile-statistics.component.less']
})
export class ProfileStatisticsComponent implements OnInit {

  @Input() currentUserId;
  @Input() isMineProfile;

  tabs: TabModel[];
  currentTab: string;
  userStatistics: UserProfileStatisticsModel = {
    initialWeight: null,
    currentWeight: null,
    targetWeight: null,
    weight: [],
    waist: [],
    bmi: []
  };
  chartData = [];
  showWeight = false;
  showWaist = false;
  showBMI = false;
  isLoading;
  showLineCharts = true;

  constructor(
    private userService: ApiUserProfileService
  ) { }

  ngOnInit() {
    this.setBoxTabs();
    this.isLoading = true;
    this.userService.getUserStatistics(this.currentUserId).subscribe(data => {
      this.userStatistics = data;
    }, () => {},
    () => {
      this.getChartData('Weight');
    });
  }

  setBoxTabs() {
    this.tabs = [
      new TabModel('GLOBAL.WEIGHT', 'Weight'),
      new TabModel('SETTINGS.MY_PROFILE.WAIST', 'Waist'),
      new TabModel('GLOBAL.BMI', 'BMI')
    ];

    this.currentTab = 'Weight';
  }

  getChartData(tabsName: string) {
    switch (tabsName) {
        case('Weight'):
        this.userStatistics.weight.length >= 1 ? this.showWeight = true : this.showWeight = false;
        this.chartData = this.userStatistics.weight;
          break;
        case('Waist'):
        this.userStatistics.waist.length >= 1 ? this.showWaist = true : this.showWaist = false;
        this.chartData = this.userStatistics.waist;
          break;
        default:
        this.userStatistics.bmi.length >= 1 ? this.showBMI = true : this.showBMI = false;
        this.chartData = this.userStatistics.bmi;
          break;
    }
    this.isLoading = false;
  }

  toggleLineCharts(valueFromDoughnutChart: boolean) {
    this.showLineCharts = valueFromDoughnutChart;
  }
}
