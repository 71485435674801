import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TabModel } from '../../models/tab.model';
import { GroupModel } from '../../models/group.model';
import { ApiGroupsService } from '../../services/api/api-groups.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsTypesToRequestEnum } from '../../enums/GroupsTypesToRequest.enum';
import { GroupsFilteringQueryParams } from '../../models/groups-filtering-query-params';
import { tap } from 'rxjs/internal/operators/tap';
import { empty, merge, Subscription } from 'rxjs';
import { isEqual as _isEqual, merge as _merge } from 'lodash-es';
import { AuthService } from '../../services/auth.service';
import { debounceTime, map } from 'rxjs/operators';
import { StateService } from 'src/app/services/state.service';
import {AppLocalStorage} from '../../utils/app.local.storage';
import {AppConstants} from '../../utils/app.constants';

function _window(): any {
	return window;
}

@Component({
	selector: 'app-groups',
	templateUrl: './groups.component.html',
	styleUrls: [ './groups.component.less' ]
})
export class GroupsComponent implements OnInit {
	@ViewChild('wrapper', { static: true })
	wrapperElement: ElementRef;

	queryParams: GroupsFilteringQueryParams = new GroupsFilteringQueryParams();

	currentPage = this.queryParams.page;
	groups: GroupModel[] = [];
	isFirstTimeLoad = true;
	isLoading = true;
	isPublicMode = false;
	isUserGroups = false;
	routeQueryParams: any;
	scrollCallback;
	stopScrolling = false;
	tabs: TabModel[] = [
		new TabModel('GLOBAL.ALL_GROUPS', 'home/groups/all'),
		new TabModel('GLOBAL.MINE_GROUPS', 'home/groups/mine')
	];
	type: string;

	getGroups$: Subscription = Subscription.EMPTY;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private groupsApiService: ApiGroupsService,
		private stateService: StateService
	) {
		this.scrollCallback = this.getGroups.bind(this);
	}

	ngOnInit() {
	  if (AppLocalStorage.get(AppConstants.keys.joinGroup)) {
      this.router.navigate([ `/home/groups/all/group/${AppLocalStorage.get(AppConstants.keys.joinGroup).replace(/"/g, '')}` ]);
      AppLocalStorage.remove(AppConstants.keys.joinGroup);
    };
		this.isPublicMode = !this.authService.isAuthorized();
		this.type = this.route.snapshot.params.type;
		this.getRouteParams();
	}

	getRouteParams() {
		merge(this.route.params, this.route.queryParams).pipe(debounceTime(1)).subscribe(() => {
			this.getGroups$.unsubscribe();
			const params = this.route.snapshot.params;
			const queryParams = this.route.snapshot.queryParams;

			this.isUserGroups = GroupsTypesToRequestEnum[params['type']] === 'true';
			if (params.type !== this.type && !(queryParams && Object.keys(queryParams).length)) {
				this.queryParams = new GroupsFilteringQueryParams();
				this.routeQueryParams = undefined;
			}

			if (Object.keys(queryParams).length) {
				if (_isEqual(this.routeQueryParams, queryParams)) {
					return;
				}
				this.routeQueryParams = queryParams;
				_merge(this.queryParams, this.routeQueryParams);
			} else if (
				params.type === this.type &&
				this.routeQueryParams &&
				Object.keys(this.routeQueryParams).length &&
				!Object.keys(queryParams).length
			) {
				return;
			} else {
				this.queryParams = new GroupsFilteringQueryParams();
			}
			this.type = params.type;
			this.getGroups$ = this.getGroups(true).subscribe(() => {});
		});
	}

	getGroups(clearGroups?: boolean) {
		if (clearGroups) {
			this.currentPage = 1;
			this.groups = [];
			this.stopScrolling = false;
		}

		if (!this.stopScrolling) {
			this.isLoading = true;
			this.queryParams.page = this.currentPage;
			const shouldStart = this.isFirstTimeLoad && !this.isUserGroups;
			return this.groupsApiService.getGroups(this.queryParams, this.isUserGroups, shouldStart).pipe(
				map((data: any) => {
					this.stateService.setHideBottomMenu('show');
					if (shouldStart) {
						this.isFirstTimeLoad = false;
					}
					return data.groups;
				}),
				tap(this.processData.bind(this))
			);
		} else {
			return empty();
		}
	}

	processData(groups) {
		this.isLoading = false;
		this.currentPage++;
		this.groups = this.groups.concat(groups);

		if (!groups.length) {
			this.stopScrolling = true;
		}
	}

	goBack() {
		this.router.navigate([ '..' ]);
	}

	trackByItems(index: number, item: any): number {
		return Number(item.id);
	}
}
