import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TabModel } from 'src/app/models/tab.model';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { GroupStatisticsModel, GroupModel } from 'src/app/models/group.model';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { TeamService, MemberStatistic, TeamStatisticsDTO } from './team-statistics-tabs/service/team-service.service';
import { concat, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { finalize } from 'rxjs/operators';
import { EditTeamInterfaceDTO } from 'src/app/create-team-module/components/team-creation/team-creation.component';

@Component({
	selector: 'app-group-statistics',
	templateUrl: './group-statistics.component.html',
	styleUrls: [ './group-statistics.component.less' ]
})
export class GroupStatisticsComponent implements OnInit, OnDestroy {
	@Input() group: GroupModel;
	@Input() isGroupStarted;

	@ViewChild('teamStatisticsTop') teamStatisticsTop: ElementRef;

	chartData = [];
	currentTab: string;
	currentUserId: number;
	groupStatistics: GroupStatisticsModel = {
		initialWeight: null,
		currentWeight: null,
		targetWeight: null,
		weight: [],
		waist: [],
		bmi: []
	};
	isLoading = true;
	isTeamCaptain: boolean;
	showBMI = false;
	showWeight = false;
	showWaist = false;
	tabs: TabModel[];
	team: EditTeamInterfaceDTO;
	teamParticipants: MemberStatistic[];

	scrollToAnchor$: Subscription = Subscription.EMPTY;

	constructor(
		private groupsApiService: ApiGroupsService,
		private topNotificationsService: TopNotificationsService,
		private errorsProcessorService: ErrorsProcessorService,
		private passingDataService: PassingDataService,
		private teamsService: TeamService,
		private auth: AuthService
	) {}

	ngOnInit() {
		this.setBoxTabs();
		this.currentUserId = this.auth.getUser().id;
		const subscribtions = [];
		subscribtions.push(this.groupsApiService.getGroupStatistics(this.group.id));
		if (this.group.isParticipant && this.group.isTeamMember) {
			subscribtions.push(this.teamsService.getTeam(Number(this.group.id), Number(this.group.teamId)));
			subscribtions.push(
				this.teamsService.getTeamMembersStatistics(Number(this.group.id), Number(this.group.teamId))
			);
		}
		concat(...subscribtions)
			.pipe(
				finalize(() => {
					if (this.group.isParticipant && this.group.isTeamMember) {
						this.scrollToAnchor$ = this.passingDataService.scrollToAnchor$.subscribe((payload) => {
							if (payload.anchor === '.team-statistics-top') {
								setTimeout(() => {
									this.setAnchor();
								}, 500);
							}
						});
					}
				})
			)
			.subscribe(
				(payload: GroupStatisticsModel | EditTeamInterfaceDTO | TeamStatisticsDTO | any) => {
					if (payload.hasOwnProperty('initialWeight')) {
						this.setGroupStatistics(payload);
					} else if (payload.hasOwnProperty('membersCount')) {
						this.setTeamInfo(payload);
					} else if (payload.hasOwnProperty('statistic')) {
						this.setTeamParticipants(payload);
					}
				},
				(httpErrorResponse: HttpErrorResponse) => {
					const error = this.errorsProcessorService.process(httpErrorResponse);
					this.topNotificationsService.notify(error, 'error');
					this.isLoading = false;
				}
			);
	}

	getChartData(tabsName: string) {
		this.passingDataService.setMainActiveTab('statistics');
		switch (tabsName) {
			case 'Weight':
				this.groupStatistics.weight.length >= 1 ? (this.showWeight = true) : (this.showWeight = false);
				this.chartData = this.groupStatistics.weight;
				break;
			case 'Waist':
				this.groupStatistics.waist.length >= 1 ? (this.showWaist = true) : (this.showWaist = false);
				this.chartData = this.groupStatistics.waist;
				break;
			default:
				this.groupStatistics.bmi.length >= 1 ? (this.showBMI = true) : (this.showBMI = false);
				this.chartData = this.groupStatistics.bmi;
				break;
		}
		this.isLoading = false;
	}

	setAnchor() {
		this.teamStatisticsTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
	}

	setBoxTabs() {
		this.tabs = [
			new TabModel('GLOBAL.WEIGHT', 'Weight'),
			new TabModel('SETTINGS.MY_PROFILE.WAIST', 'Waist'),
			new TabModel('GLOBAL.BMI', 'BMI')
		];

		this.currentTab = 'Weight';
	}

	setGroupStatistics(data) {
		this.groupStatistics = data;
		this.getChartData('Weight');
	}

	setTeamInfo(data) {
		this.team = data;
	}

	setTeamParticipants(data) {
		this.teamParticipants = data.statistic;
		this.teamParticipants.forEach((participant) => {
			if (participant.userId === this.currentUserId) {
				if (participant.isCaptain) {
					this.isTeamCaptain = true;
				}
			}
		});
	}

	ngOnDestroy() {
		this.scrollToAnchor$.unsubscribe();
		this.passingDataService.setScrollToAnchor('none');
	}
}
