  <div class="title" *ngIf="title" [ngStyle]="{'text-transform': case === 'uppercase' ? 'uppercase' : 'none'}">
    {{title}}
  </div>
  <app-group-card *ngFor="let group of groups" [group]="group" [isPublicMode]="isPublicMode"></app-group-card>

  <div class="spinner" *ngIf="isLoading">
    <mat-progress-spinner diameter="50" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="nothing_to_show" *ngIf="!isLoading && !groups?.length">
    {{'GLOBAL.NOTHING_TO_SHOW' | translate }}
  </div>

  <div class="link__wrapper">
    <a class="link" [routerLink]="'home/groups/all'">
      {{'LANDING.SEE_ALL_GROUPS' | translate}}
    </a>
  </div>
  