export interface TeamsCreateOrJoinListDTO {
	amount: number;
	teams: TeamsCreateOrJoinList[];
}

export interface TeamsCreateOrJoinList {
	name: string;
	description: string;
	image: string;
	isPrivate: boolean;
	groupId: number;
	captainName: string;
	captainId: number;
	membersCount: number;
	isFull: boolean;
	id: number;
	currentUserStatus: string;
	isConfirm?: boolean;
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TeamService } from '../../group-statistics/team-statistics-tabs/service/team-service.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ApiGroupsService } from 'src/app/services/api/api-groups.service';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

function _window(): any {
	return window;
}

@Component({
	selector: 'app-teams-list',
	templateUrl: './teams-list.component.html',
	styleUrls: [ './teams-list.component.less' ]
})
export class TeamsListComponent implements OnInit {
	@Input() groupId;
	@Input() showAtStatistics = false;
	@Output() privateRequestSent = new EventEmitter<boolean>();
	@Output() privateTeamId = new EventEmitter<number>();

	groupTop: HTMLElement;
	isLoading = false;
	spinnerText: string;
	teamList: TeamsCreateOrJoinList[] = [];

	constructor(
		private teamsService: TeamService,
		private loadingSpinnerService: LoadingSpinnerService,
		private errorsProcessorService: ErrorsProcessorService,
		private topNotificationsService: TopNotificationsService,
		private auth: AuthService,
		private router: Router,
		private apiGroupService: ApiGroupsService,
		private passingDataService: PassingDataService,
		private translate: TranslateService,
		private stateService: StateService,
		private ga: GoogleAnalyticsService
	) {
		this.translate.stream('SPINNER.JOIN_THE_TEAM').subscribe((data) => (this.spinnerText = data));
	}

	ngOnInit() {
		this.isLoading = true;
		this.teamsService.getTeams(Number(this.groupId)).subscribe(
			(data: TeamsCreateOrJoinListDTO) => {
				// extend teamList private teams with isConfirm param
				this.teamList = data.teams.map((el) => (el.isPrivate ? Object.assign({ isConfirm: false }, el) : el));
				this.isLoading = false;
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.isLoading = false;
			}
		);
	}

	createNewTeam() {
		this.router.navigate([ 'home/create-team' ], { state: { id: this.groupId } });
	}

	confirmRequestSend(team) {
		team.isConfirm = !team.isConfirm;
	}

	scrollToGroupTop() {
		// groupTop is almost the top of group template
		this.groupTop = _window().document.querySelectorAll('.container.team-member');
		if (this.groupTop && this.groupTop[0]) {
			this.groupTop[0].scrollIntoView({ behavior: 'smooth' });
		}
	}

	showTeamMembersList(team: TeamsCreateOrJoinList) {
		this.loadingSpinnerService.show.next();
		this.teamsService.getTeamMembers(team.groupId, team.id).subscribe(
			(members) => {
				this.passingDataService.passHomePopupData('teamMembers', { participants: members.members });
				this.passingDataService.setShowPopupAtHome(true);
				this.loadingSpinnerService.hide.next();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
				this.loadingSpinnerService.hide.next();
			}
		);
	}

	showTeamMembersSizePopup() {
		this.passingDataService.passHomePopupData('teamMembersSize', {});
		this.passingDataService.setShowPopupAtHome(true);
	}

	userTeamStatus(team: TeamsCreateOrJoinList): string {
		if (!team.isFull && team.currentUserStatus !== 'Pending') {
			return 'join';
		} else if (!team.isFull && team.currentUserStatus === 'Pending') {
			return 'Pending';
		}
	}

	joinTheTeam(teamId: number) {
		this.loadingSpinnerService.show.next(this.spinnerText);

		this.teamsService.joinToTheTeam(this.groupId, teamId, this.auth.getUser().id).subscribe(
			() => {
				this.ga.eventEmitter('joined-open-team', 'teams', 'joined-open-team');
				// delete group from cache then reset and go back
				this.apiGroupService.deleteItemFormLastGroupFetchArray(this.groupId);
				// set STATISTICS as active tab at the group's page
				this.passingDataService.setMainActiveTab('statistics');
				// Force reload group component
				const uri = `/home/groups/mine/group/${this.groupId}`;
				this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
					this.router.navigate([ uri ], {
						queryParams: { presetTab: false }
					})
				);
				this.stateService.setHideBottomMenu('show');
				this.loadingSpinnerService.hide.next();
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
			}
		);
	}

	toggleRequestWasSent(teamId: number) {
		this.privateRequestSent.emit(true);
		this.privateTeamId.emit(teamId);
	}

	requestToJoinThePrivateTeam(team: TeamsCreateOrJoinList) {
		this.loadingSpinnerService.show.next(this.spinnerText);
		this.teamsService.joinToTheTeam(this.groupId, team.id, this.auth.getUser().id).subscribe(
			() => {
				this.toggleRequestWasSent(team.id);
				this.loadingSpinnerService.hide.next();
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
			}
		);
	}

	handleError(httpErrorResponse: HttpErrorResponse) {
		const error = this.errorsProcessorService.process(httpErrorResponse);
		this.topNotificationsService.notify(error, 'error');
		this.loadingSpinnerService.hide.next();
	}

	trackByItems(index: number, item: any): number {
		return Number(item.id);
	}
}
