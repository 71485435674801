import { ApiBaseService } from "./api-base.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConstants } from "../../utils/app.constants";

@Injectable()
export class ApiMediaFilesService extends ApiBaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file);
    const headers = new HttpHeaders().append("ngsw-bypass", "true");
    const options = { headers: headers, responseType: "text" as "json" };
    return this.http.post<string>(
      AppConstants.api.baseUrl + "mediaFiles/file",
      formData,
      options
    );
  }

  uploadImage(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file);
    const headers = new HttpHeaders().append("ngsw-bypass", "true");
    const options = { headers: headers, responseType: "text" as "json" };
    return this.http.post<string>(
      AppConstants.api.baseUrl + "mediaFiles/image",
      formData,
      options
    );
  }

  uploadStaticImages(type: string, file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file);
    const headers = new HttpHeaders().append("ngsw-bypass", "true");
    const options = { headers: headers, responseType: "text" as "json" };
    return this.http.post<string>(
      AppConstants.api.baseUrl + `mediaFiles/image/${type}`,
      formData,
      options
    );
  }

  uploadVideo(file: File): Observable<any> {
    const headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data;charset=UTF-8")
      .append("ngsw-bypass", "true");
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    const options = {
      headers: headers,
      responseType: "text" as "json",
      reportProgress: true,
      observe: "events" as "body",
    };
    return this.http.post<any>(
      AppConstants.api.baseUrl + "mediaFiles/video",
      formData,
      options
    );
  }
}
