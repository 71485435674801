export interface GroupParticipants {
	amount: number;
	participants: Array<GroupParticipant>;
}

export interface GroupParticipant {
	email: string;
	isMVP: boolean;
	isMVP2: boolean;
	userId: number;
	userName: string;
	inactive: boolean;
	userCity: string;
}

import { Component, ElementRef, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupModel } from '../../models/group.model';
import { ApiGroupsService, ITeamByToken } from '../../services/api/api-groups.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { StateService } from '../../services/state.service';
import { merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AppConstants } from '../../utils/app.constants';
import { TabModel } from '../../models/tab.model';
import { RouterInterceptorService } from '../../services/router-interceptor.service';
import { AppLocalStorage } from '../../utils/app.local.storage';
import { ApiPaymentService } from 'src/app/services/api/api-payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TopNotificationsService } from 'src/app/services/top-notifications.service';
import { ErrorsProcessorService } from 'src/app/services/errors-processor.service';
import { PassingDataService, IJoinPopupAtHome } from 'src/app/services/passing-data.service';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

function _window(): any {
	return window;
}
@Component({
	selector: 'app-group',
	templateUrl: './group.component.html',
	styleUrls: [ './group.component.less' ]
})
export class GroupComponent implements OnInit, OnDestroy {
	baseUrl = AppConstants.baseFrontendUrl;
	commentId = null;
	currentTeamTab: string;
	createOrJoinTeamMode = false;
	currentTab: string;
	gotPointsToJoinGroup = false;
	group: GroupModel;
	inviteToken: string;
	isAdmin = false;
	isOnIOSDevice = false;
	isIOSSafari = false;
	isGroupStarted = false;
	isInvited = false;
	isMineGroup: boolean;
	isPublicMode = false;
	postId = null;
	showInvitePopupUnderGroup = false;
	tabs: TabModel[];
	teamName: string;
	teamStatisticsTop;
	/* teamTabs: TabModel[]; */
	toggleFullBonusPrizes = false;
	toggleFullDescription = false;
	// Subscriptions
	private subscription: Subscription = Subscription.EMPTY;
	getGroup$;
	getGroupsParticipants$;
	getExtendedUserWalletBalance$;
	payJoinGroupWithPoints$;

	@ViewChild('groupImage') groupImage: ElementRef;
	@ViewChild('groupImagePopup') groupImagePopup: ElementRef;
	@ViewChild('wrapper', { static: true })
	wrapperElement: ElementRef;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private groupsApiService: ApiGroupsService,
		private loadingSpinnerService: LoadingSpinnerService,
		private stateService: StateService,
		private apiPaymentService: ApiPaymentService,
		private renderer: Renderer2,
		private routerInterceptorService: RouterInterceptorService,
		private topNotificationsService: TopNotificationsService,
		private errorsProcessorService: ErrorsProcessorService,
		private passingDataService: PassingDataService,
		private location: Location,
		private ga: GoogleAnalyticsService
	) {
		this.getGroup$ = (id) => this.groupsApiService.getGroup(id);
		this.getGroupsParticipants$ = (id) => this.groupsApiService.getGroupsParticipants(id);
		this.getExtendedUserWalletBalance$ = this.apiPaymentService.getExtendedUserBalance();
		// NOTICE: There was changes in API = payJoinGroupWithPoints uses wallet money!
		// changed by customers desire at 07.2020
		// backend API endpoint wasn't changed
		this.payJoinGroupWithPoints$ = (id, token) => this.apiPaymentService.payJoinGroupWithPoints(id, token);
	}

	ngOnInit() {
		this.isPublicMode = !this.authService.isAuthorized();
		if (this.authService.isAuthorized()) {
			this.isAdmin = this.authService.isAdmin();
		}
		merge(this.route.params, this.route.data).pipe(debounceTime(1)).subscribe(() => {
			const route = this.route.snapshot;
			if (route.params.id && route.params.post && !route.params.comment) {
				this.postId = route.params.post;
			} else if (route.params.id && route.params.post && route.params.comment) {
				this.postId = route.params.post;
				this.commentId = route.params.comment;
			}
			if (route.data) {
				this.stateService.setRouteData(route.data);
			}
			this.getGroup(route.params.id);
		});
		const style = window.getComputedStyle(this.wrapperElement.nativeElement, null);

		if (style.getPropertyValue('-webkit-overflow-scrolling') === 'touch') {
			/* this.preventTouchIssue(); */
			// Checking for Apple mobile device
			const ua = window.navigator.userAgent;
			const apple = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/Macintosh/i);
			const safari = !!ua.match(/Version/i) && !ua.match(/CriOS/i) && !ua.match(/Chrome/i);
			if (apple) {
				this.isOnIOSDevice = true;
			}
			if (apple && safari) {
				this.isIOSSafari = true;
			}
		}
		this.subscription.add(this.getGroup$);
		this.subscription.add(this.getGroupsParticipants$);
		this.subscription.add(this.getExtendedUserWalletBalance$);
		this.subscription.add(this.payJoinGroupWithPoints$);
	}
	/* preventTouchIssue() {
		let lastY = 0;
		this.wrapperElement.nativeElement.addEventListener('touchstart', function(event) {
			lastY = event.touches[0].clientY;
		});
		this.wrapperElement.nativeElement.addEventListener('touchmove', function(event) {
			let top = event.touches[0].clientY;
			let scrollTop = event.currentTarget.scrollTop;
			let direction = lastY - top < 0 ? 'up' : 'down';
			if (scrollTop === 0 && direction === 'up') {
				event.preventDefault();
			} else if (
				scrollTop >= event.currentTarget.scrollHeight - event.currentTarget.offsetHeight &&
				direction === 'down'
			) {
				event.preventDefault();
			}
			lastY = top;
		});
	} */

	sendBonusPrizeEvent() {
		this.ga.eventEmitter('bonus-prizes-click', 'rewards-engagement', 'bonus-prizes-click');
	}

	triggerToggleFullBonusPrizes() {
		this.toggleFullBonusPrizes = !this.toggleFullBonusPrizes;
		if (this.toggleFullBonusPrizes)
			this.ga.eventEmitter('opened-prizes-discounts', 'rewards-engagement', 'opened-prizes-discounts');
	}

	scrollToGroupTop() {
		if (this.group.isTeamMember) {
			this.passingDataService.setMainActiveTab('statistics');
			this.passingDataService.setScrollToAnchor('.team-statistics-top');
		}
	}

	showJoinPopupAtHome(popupData) {
		this.passingDataService.passHomePopupData('afterJoinTheGroup', popupData);
		this.passingDataService.setShowPopupAtHome(true);
	}

	showInvitePopup(passedGroup, inviteToken) {
		this.groupsApiService.getTeamByIniteToken(inviteToken).subscribe(
			(team: ITeamByToken) => {
				// if Public - show popup under group component
				// else - show popup under home component
				if (this.isPublicMode) {
					this.teamName = team.teamName;
					this.showInvitePopupUnderGroup = true;
					this.setGroupImage();
				} else {
					passedGroup = Object.assign({ teamName: team.teamName }, passedGroup);
					// should also add afterJoinTheGroup
					this.passingDataService.passHomePopupData('inviteToGroup', passedGroup);
					this.passingDataService.setShowPopupAtHome(true);
				}
				// replace invite at localStorage to prevent showing popup once more
				AppLocalStorage.remove(AppConstants.keys.invite);
				AppLocalStorage.add(AppConstants.keys.invite, {
					groupId: passedGroup.id,
					token: inviteToken,
					shouldInvitePopupBeShown: false
				});
			},
			// FIXME: Fix error handling
			(err) => {
				console.log('Error', err);
				this.passingDataService.passHomePopupData('inviteToGroup', passedGroup);
			}
		);
	}

	getGroup(id: string) {
		// make sure that no old group id for this group left
		this.stateService.setWeightGroupId('clear');
		// check if user have invite token for this group
		const invite = AppLocalStorage.getObject(AppConstants.keys.invite);
		if (invite && invite.groupId == this.route.snapshot.params.id && invite.token) {
			this.isInvited = true;
		}
		this.loadingSpinnerService.show.next();
		this.getGroup$(id).subscribe(
			(group) => {
				const snapshot = this.route.snapshot;
				this.group = group;

				// if the user have joined to the group - show popup
				if (snapshot.queryParams && snapshot.queryParams.joinedNow === 'true') {
					this.location.replaceState(`/home/groups/mine/group/${this.group.id}`);
					const popupData: IJoinPopupAtHome = { groupId: group.id, groupName: group.name };
					this.showJoinPopupAtHome(popupData);
					// should be shown invite popup?
				} else if (this.isInvited && this.group.isCanJoin && !group.isMine && invite.shouldInvitePopupBeShown) {
					let passedGroup = Object.assign({}, this.group);
					this.showInvitePopup(passedGroup, invite.token);
				}
				this.isGroupStarted = group.isStarted;
				this.isMineGroup = group.isMine;
				this.group.discountCodes = this.group.discountCodes;
				this.group.bonusPrize = this.group.bonusPrize;
				this.setGroupImage();
				this.setBoxTabs();
				// checking is there needed queryParam in url - do something with it and delete query
				if (snapshot.queryParams) {
					if (snapshot.queryParams.statsticsOnTop === 'true') {
						this.location.replaceState(`/home/groups/mine/group/${this.group.id}`);
						this.scrollToGroupTop();
					} else if (snapshot.queryParams.presetTab) {
						this.location.replaceState(`/home/groups/mine/group/${this.group.id}`);
					} else if (snapshot.queryParams.joinTheTeam) {
						this.location.replaceState(`/home/groups/mine/group/${this.group.id}`);
						this.handleTabNameChange('teams');
					} else if (snapshot.queryParams.feedTab) {
						this.location.replaceState(`/home/groups/mine/group/${this.group.id}`);
						this.handleTabNameChange('feed');
					}
				}
			},
			(httpErrorResponse: HttpErrorResponse) => this.handleError(httpErrorResponse),
			() => {
				this.stateService.setHideBottomMenu('show');
				this.loadingSpinnerService.hide.next();
			}
		);
	}

	joinGroup() {
		if (!this.isPublicMode) {
			this.loadingSpinnerService.show.next();
			this.getExtendedUserWalletBalance$.subscribe(
				(userBalances) => {
					if (userBalances.points >= this.group.bet) {
						this.gotPointsToJoinGroup = true;
					}
					this.toggleShowPaymentConfirmation();
					this.loadingSpinnerService.hide.next();
				},
				(httpErrorResponse: HttpErrorResponse) => this.handleError(httpErrorResponse)
			);
		} else {
			this.router.navigate([ `join-group/${this.group.id}` ]);
		}
	}

	toggleShowPaymentConfirmation() {
		this.passingDataService.passHomePopupData('showPaymentConfirmation', {
			groupId: this.group.id,
			groupName: this.group.name,
			groupBet: this.group.bet,
			gotPointsToJoinGroup: this.gotPointsToJoinGroup
		});
		this.gotPointsToJoinGroup = false;
		this.passingDataService.setShowPopupAtHome(true);
	}

	setGroupImage() {
		setTimeout(() => {
			const imageUrl = !!this.group.image
				? AppConstants.api.mediaFilesUrl + 'small/' + this.group.image.replace(/\"/g, '')
				: null;
			if (imageUrl) {
				this.renderer.setStyle(this.groupImage.nativeElement, 'background-image', `url(${imageUrl})`);
				if (this.showInvitePopupUnderGroup) {
					this.renderer.setStyle(this.groupImagePopup.nativeElement, 'background-image', `url(${imageUrl})`);
				}
			}
		}, 0);
	}

	inviteFriend() {
		this.router.navigate([ `home/groups/mine/group/${this.group.id}/invite` ]);
	}

	showGroupsParticipants(id, city = null) {
		if (!this.group.isPrivate || this.group.isMine || this.authService.isAdmin()) {
			const groupParticipants: GroupParticipant[] = [];
			this.loadingSpinnerService.show.next();
			this.getGroupsParticipants$(id).subscribe(
				(data) => {
					if (city) {
						data.participants.forEach((participant) => {
							if (participant.userCity === city) {
								groupParticipants.push(participant);
							}
						});
					} else {
						groupParticipants.push(...data.participants);
					}
					this.passingDataService.passHomePopupData('participants', {
						participants: groupParticipants,
						selectedCity: city
					});
					this.passingDataService.setShowPopupAtHome(true);
				},
				(httpErrorResponse: HttpErrorResponse) => this.handleError(httpErrorResponse),
				() => {
					this.loadingSpinnerService.hide.next();
				}
			);
		}
	}

	toggleDiscountPopUp() {
		this.passingDataService.passHomePopupData('discountCodes', {
			discountCodes: this.group.discountCodes
		});
		this.passingDataService.setShowPopupAtHome(true);
	}

	setBoxTabs() {
		if (!this.isAdmin && !this.group.isCreator && !this.group.isMine) {
			this.tabs = [ new TabModel('GLOBAL.INFO', 'info'), new TabModel('GLOBAL.FEED', 'feed') ];
			this.currentTab = 'info';
		} else if (!this.isAdmin && !this.group.isCreator && this.group.isParticipant) {
			this.tabs = [
				new TabModel('GLOBAL.FEED', 'feed'),
				new TabModel('GLOBAL.STATISTICS_4_PERCENT', 'statistics')
			];
			/* this.passTeamCreateOrJoinTab(); */
			this.setPassedActiveTab('feed');
		} else if (this.isAdmin || (this.group.isCreator && !this.group.isParticipant)) {
			this.tabs = [ new TabModel('GLOBAL.FEED', 'feed') ];
			this.setPassedActiveTab('feed');
		} else if (this.isMineGroup && this.group.isParticipant) {
			this.tabs = [
				new TabModel('GLOBAL.FEED', 'feed'),
				new TabModel('GLOBAL.STATISTICS_4_PERCENT', 'statistics')
			];
			/* this.passTeamCreateOrJoinTab(); */
			this.setPassedActiveTab('feed');
		} else {
			this.tabs = [ new TabModel('GLOBAL.INFO', 'info'), new TabModel('GLOBAL.FEED', 'feed') ];
			this.setPassedActiveTab(this.isMineGroup ? 'feed' : 'info');
		}
	}

	/* setTeamBoxTabs() {
		this.teamTabs = [
			new TabModel('STATISTICS.MY_TEAM', 'MyTeam'),
			new TabModel('STATISTICS.TEAM_STATS', 'TeamStatistics'),
			new TabModel('STATISTICS.TEAM_WEIGHT', 'TeamWeight')
		];
	} */

	/* passTeamCreateOrJoinTab() {
		if (this.group.isCanJoin && this.group.isParticipant && !this.group.isTeamMember) {
			this.tabs.push(new TabModel('GLOBAL.JOIN_CREATE.TEAM', 'teams'));
		}
	} */

	handleTabNameChange(tabName: string) {
		this.currentTab = tabName;
		if (tabName === 'teams') {
			this.createOrJoinTeamMode = true;
			this.stateService.setHideBottomMenu('hide');
		} else {
			this.createOrJoinTeamMode = false;
		}
	}

	hideCreateOrJoinTeamMode(event) {
		this.stateService.setHideBottomMenu('show');
		this.createOrJoinTeamMode = false;
		this.handleTabNameChange('feed');
	}

	setPassedActiveTab(defaultTabName: 'info' | 'feed') {
		if (defaultTabName === 'info') {
			this.handleTabNameChange(defaultTabName);
		} else {
			this.passingDataService.mainActiveTab$.subscribe((tabName: 'feed' | 'statistics' | 'teams' | null) => {
				if (tabName) {
					this.handleTabNameChange(tabName);
				} else {
					this.handleTabNameChange('feed');
				}
				/* else {
					this.passingDataService.teamActiveTab$.subscribe(
						(teamTab: 'MyTeam' | 'TeamStatistics' | 'TeamWeight' | null) => {
							if (teamTab) {
								this.currentTab = teamTab;
							} else {
								this.currentTab = 'feed';
							}
						}
					);
				} */
			});
		}
	}

	registerWeight() {
		this.stateService.setWeightGroupId(Number(this.group.id));
		this.router.navigate([ 'home/register-weight/start' ] /* { state: { id: this.group.id } } */);
	}

	goToInfluencerProfile(influencerId = '') {
		this.router.navigate([ `home/profile/${influencerId}` ]);
	}

	showStartWeightInfo() {
		this.passingDataService.passHomePopupData('startWeightInfo', {
			startDate: this.group.startDate
		});
		this.passingDataService.setShowPopupAtHome(true);
	}

	goBack() {
		this.router.navigate([ '/all-groups' ]);
	}

	closeInvitePopup() {
		this.showInvitePopupUnderGroup = false;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	handleError(httpErrorResponse: HttpErrorResponse) {
		const error = this.errorsProcessorService.process(httpErrorResponse);
		this.topNotificationsService.notify(error, 'error');
		this.loadingSpinnerService.hide.next();
	}
}
