import { Component } from '@angular/core';
import { TopNotificationsService } from '../../../services/top-notifications.service';
import { TopNotificationsModel } from '../../../models/top-notifications.model';


@Component({
    selector: 'app-top-notifications',
    templateUrl: './top-notifications.component.html',
    styleUrls: ['./top-notifications.component.less']
})
export class TopNotificationsComponent {

    notification: TopNotificationsModel = new TopNotificationsModel();

    isVisible = false;
    timeout = 5000; // ms

    constructor(private topNotificationsService: TopNotificationsService) {
        this.topNotificationsService.notification.subscribe(
            (notification: TopNotificationsModel) => {
                this.notification = notification;
                this.showNotification();
            });
    }

    showNotification() {
        this.isVisible = true;

        setTimeout(() => {
            this.isVisible = false;
        }, this.timeout);
    }

}
