export class CompletedGroupModel {
  id: string;
  name: string;
  image: string;
  endDate: string;
  isMVP: boolean;
  isMVP2: boolean;
  isWinner: boolean;
  points: number;
  bonusPrize: string;
  discountCodes: string;
  userRating: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.image = data.image;
    this.endDate = data.endDate;
    this.isMVP = data.isMVP;
    this.isMVP2 = data.isMVP2;
    this.isWinner = data.isWinner;
    this.points = data.points;
    this.bonusPrize = data.bonusPrize || '';
    this.discountCodes = data.discountCodes || '';
    this.userRating = data.userRating || null;
  }
}
