import { Component, Input, OnInit } from '@angular/core';
import { GroupModel } from '../../../models/group.model';
import { GroupsFilteringQueryParams } from '../../../models/groups-filtering-query-params';
import { ActivatedRoute } from '@angular/router';
import { ApiGroupsService } from '../../../services/api/api-groups.service';
import { AuthService } from '../../../services/auth.service';

@Component({
	selector: 'app-groups-preview',
	templateUrl: './groups-preview.component.html',
	styleUrls: [ './groups-preview.component.less' ]
})
export class GroupsPreviewComponent implements OnInit {
	@Input() case: string;
	@Input() pageSize = 2;
	@Input() title: string;

	currentGroupId: number;
	groups: GroupModel[] = [];
	isLoading = false;
	isPublicMode: boolean;
	queryParams: GroupsFilteringQueryParams = new GroupsFilteringQueryParams();

	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,
		private groupsApiService: ApiGroupsService
	) {}

	ngOnInit() {
		this.currentGroupId = this.route.snapshot.params.id || null;
		this.isPublicMode = !this.authService.isAuthorized();
		this.getGroups();
	}

	getGroups() {
		this.isLoading = true;
		this.queryParams.pageSize = 10;
		return this.groupsApiService.getGroups(this.queryParams, false).subscribe((data) => {
			this.isLoading = false;
			this.setGroups(data);
		});
	}

	setGroups(groupsArray) {
		if (groupsArray.length >= 2) {
			this.groups = this.getRandom(groupsArray.groups, this.pageSize);
		} else {
			this.groups = groupsArray.groups;
		}
	}

	getRandom(arr, n) {
		let result = new Array(n),
			len = arr.length,
			taken = new Array(len);
		while (n--) {
			let x = Math.floor(Math.random() * len);
			result[n] = arr[x in taken ? taken[x] : x];
			taken[x] = --len in taken ? taken[len] : len;
		}
		if (result[0].id === result[1].id) {
			result.pop();
		}
		if (this.currentGroupId) {
			for (let i = 0; i <= result.length; i++) {
				if (result[i].id === this.currentGroupId) {
					result.slice(i, 1);
					i--;
				}
			}
		}
		return result;
	}
}
