<div class="notification-overlay" [ngClass]="{'active': isActive}" (click)="closeMenu()"></div>
<div class="notification-menu" [ngClass]="{'active': isActive}" >

  <div class="header" (click)="closeMenu()">
    <div class="header-button notifications-button">
      <img src="assets/images/_Notifications.png"
           srcset="assets/images/_Notifications@2x.png 2x,
             assets/images/_Notifications@3x.png 3x"
      >
    </div>
  </div>
  <nav>
    <ul *ngIf="(notificationsCount$ | async) === 0">
        <li class="no-new-notifications">{{ "NOTIFICATIONS.NO_NEW_NOTIFICATIONS" | translate }}</li>
    </ul>
    <ul *ngIf="messages">
      <li class="no-new-notifications action" (click)="markAllNotificationsAsRead()" *ngIf="(notificationsCount$ | async) > 0">{{ "NOTIFICATIONS.MARK_ALL_AS_READ" | translate }}</li>
      <li *ngFor="let message of messages?.notifications; let index = index; trackBy: trackByItems">
        <ng-container
          *ngIf="isDeleting === index; then showSpinner; else showNotification"></ng-container>
        <ng-template #showNotification>
          <div class="message-wrapper" (click)="markNotificationsAsRead(message.id, message.isRead, message); message.isRead = true">
            <div class="image" [ngStyle]="{'background-image': message.image | notificationImage}"></div>
            <div class="message-text-wrapper">
              <span class="title" [ngClass]="{'has-read': message.isRead}">{{message.title}}<span class="body" *ngIf="message.body"><br/>{{message.body}}</span></span>
              <span class="time">{{message.dateTime | momentDateAgo}}</span>
            </div>
        </div>
        <div class="delimeter"></div>
        <div class="delete-btn" (click)="deleteNotification(message.id, index)">x</div>
        </ng-template>
        <ng-template #showSpinner>
          <div class="delete-spinner">
            <mat-progress-spinner diameter="25" color="accent" mode="indeterminate"></mat-progress-spinner>
          </div>
        </ng-template>
        
      </li>
      <ng-container
        *ngIf="isLoadingNotifications; then showSpinner; else showLoadMore">
      </ng-container>

      <ng-template #showSpinner>
        <li class="load-more">
          <mat-progress-spinner diameter="25" color="accent" mode="indeterminate"></mat-progress-spinner>
        </li>
      </ng-template>
      <ng-template #showLoadMore>
        <li class="load-more" *ngIf="messages.notifications.length < messages.notificationsTotal" (click)="loadMore()">
          <div class="load-more">{{ "GLOBAL.LOAD_MORE" | translate }}</div>
        </li>
      </ng-template>
    </ul>
  </nav>

</div>
