import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let transformedRequest;
		transformedRequest = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${this.authService.getToken()}`)
		});
		// excluding additional headers for Instagram access tokens
		if (
			req &&
			(req.url.match('https://api.instagram.com/oauth/access_token') ||
				req.url.match('https://graph.instagram.com/'))
		) {
			transformedRequest = req.clone();
		}

		return next.handle(transformedRequest);
	}
}
