import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiMeasurementService } from './api/api-measurement.service';
import { MeasurementModel } from '../models/measurement.model';

@Injectable()
export class MeasurementService {

  model: MeasurementModel = new MeasurementModel();

  constructor(private measurementApiService: ApiMeasurementService) {}

  registerWeight(payload): Observable<any> {
    return this.measurementApiService.registerWeight(payload);
  }

}
