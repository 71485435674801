export interface IJoinPopupAtHome {
	groupId: number;
	groupName: string;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PassingDataService {
	private videoFileName = new BehaviorSubject(null);
	videoFileName$ = this.videoFileName.asObservable();

	private imageFileName = new BehaviorSubject(null);
	imageFileName$ = this.imageFileName.asObservable();

	private removedImageId = new BehaviorSubject(null);
	removedImageId$ = this.removedImageId.asObservable();

	private inviteTokenForWebView = new BehaviorSubject(null);
	inviteTokenForWebView$ = this.inviteTokenForWebView.asObservable();

	private mainActiveTab = new BehaviorSubject(null);
	mainActiveTab$ = this.mainActiveTab.asObservable();

	// TODO: REMOVE teamActiveTab
	private teamActiveTab = new BehaviorSubject(null);
	teamActiveTab$ = this.teamActiveTab.asObservable();

	private scrollToAnchor = new BehaviorSubject({ anchor: 'none' });
	scrollToAnchor$ = this.scrollToAnchor.asObservable();

	private scrollToComposePrivatePost = new BehaviorSubject({ anchor: 'none' });
	scrollToComposePrivatePost$ = this.scrollToComposePrivatePost.asObservable();

	private unreadNotifications = 0;
	private notificationsCount: BehaviorSubject<number> = new BehaviorSubject(this.unreadNotifications);
	notificationsCount$ = this.notificationsCount.asObservable();

	private routeParamsFromChildComponent = new BehaviorSubject(null);
	routeParamsFromChildComponent$ = this.routeParamsFromChildComponent.asObservable();

	private showPopupAtHome = new BehaviorSubject(false);
	showPopupAtHome$ = this.showPopupAtHome.asObservable();

	private homePopupData = new BehaviorSubject({});
	homePopupData$ = this.homePopupData.asObservable();

	constructor() {}

	setVideoFileName(message: string) {
		this.videoFileName.next(message);
	}

	setImageFileName(fileName: string | null, fileId?: number | null, isImageMine?: boolean | null) {
		if (fileName && fileId && isImageMine) {
			this.imageFileName.next({
				fileName: fileName.trim().replace(/\"/g, ''),
				fileId: fileId,
				isImageMine: isImageMine
			});
		} else if (fileName) {
			this.imageFileName.next(fileName.trim().replace(/\"/g, ''));
		} else {
			this.imageFileName.next(null);
		}
	}

	setInviteToken(localStorageInviteObject) {
		this.inviteTokenForWebView.next(localStorageInviteObject);
	}

	setMainActiveTab(tabName: 'feed' | 'statistics' | 'teams' | null) {
		this.mainActiveTab.next(tabName);
	}

	// use it to scroll to given anchor
	setScrollToAnchor(anchor: string | 'none') {
		this.scrollToAnchor.next({ anchor: anchor });
	}

	setScrollToComposePrivatePost(anchor: string | 'none') {
		this.scrollToComposePrivatePost.next({ anchor: anchor });
	}

	// TODO: REMOVE setTeamActiveTab
	setTeamActiveTab(tabName: 'MyTeam' | 'TeamStatistics' | 'TeamWeight' | null) {
		this.teamActiveTab.next(tabName);
	}

	setNotificationsCount(action: 'increaseByOne' | 'decreaseByOne' | 'makeItZero' | 'setUnread', value = null) {
		switch (action) {
			case 'increaseByOne':
				this.unreadNotifications++;
				this.notificationsCount.next(this.unreadNotifications);
				break;
			case 'decreaseByOne':
				this.unreadNotifications--;
				this.notificationsCount.next(this.unreadNotifications);
				break;
			case 'makeItZero':
				this.unreadNotifications = 0;
				this.notificationsCount.next(this.unreadNotifications);
				break;
			case 'setUnread':
				this.unreadNotifications = value;
				this.notificationsCount.next(this.unreadNotifications);
				break;
			default:
				console.warn('Notifications count breaks with default');
				break;
		}
	}

	setRouteParamsFromChildComponent(params) {
		if (params) {
			this.routeParamsFromChildComponent.next(params);
		} else {
			this.routeParamsFromChildComponent.next(null);
		}
	}

	setShowPopupAtHome(showPopup: boolean) {
		this.showPopupAtHome.next(showPopup);
	}

	setRemovedImageId(imageId: number = null) {
		this.removedImageId.next(imageId);
	}

	passHomePopupData(
		popupType?:
			| 'inviteToGroup'
			| 'afterJoinTheGroup'
			| 'pointsTransfer'
			| 'walletPayout'
			| 'participants'
			| 'teamMembers'
			| 'discountCodes'
			| 'startWeightInfo'
			| 'showPaymentConfirmation'
			| 'teamMembersSize',
		data?: Object
	) {
		const popup = popupType && data ? Object.assign({ popupType }, data) : {};
		this.homePopupData.next(popup);
	}
}
