import { FormControl } from '@angular/forms';
import { AppConstants } from '../utils/app.constants';

export function EmailValidator(input: FormControl) {
	const inputValue = String(input.value).trim();

	if (inputValue.match(AppConstants.emailRegexp) || input.value === null || inputValue === '') {
		return null;
	} else {
		return {
			email: {
				valid: false
			}
		};
	}
}
