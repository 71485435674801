export enum RedirectUrlsByType {
	'Post' = '/home/groups/mine/group/',
	'Comment' = '/home/groups/mine/group/',
	'Like' = '/home/groups/mine/group/',
	'Friend' = '/home/profile/',
	'Participant' = '/home/profile/',
	'GroupFinish' = '/home/finished-group/',
	'Disqualified' = '/home/groups/mine/group/',
	'Winner' = '/home/finished-group/',
	'MVP' = '/home/finished-as-mvp/',
	'InitWeightBeforeStart' = '/home/groups/mine/group/',
	'InitWeightOnStart' = '/home/groups/mine/group/',
	'KickedFromGroup' = '/home/groups/all/group/',
	'FriendJoined' = '/home/groups/all/group/',
	'FriendStarted' = '/home/groups/all/group/',
	'FinalWeight' = '/home/groups/mine/group/',
	'WaitForModeration' = '/home/finished-group/',
	'ChangeStartDate' = '/home/groups/mine/group/',
	'TeamMemberLeft' = '/home/groups/mine/group/',
	'TeamMemberJoined' = '/home/profile/',
	'TeamMemberWeightUpdated' = '/home/groups/mine/group/',
	'TeamMemberAskedToJoin' = '/home/',
	'TeamRulesBeforeStart' = '/home/groups/mine/group/',
	'InviteTokenApplied' = '/home/groups/mine/group/',
	'TeamJoinRequestApproved' = '/home/groups/mine/group/',
	'TeamJoinRequestRejected' = '/home/groups/mine/group/',
	'GoalReached' = '/home/my-group-results/',
	'GoalNotReached' = '/home/my-group-results/',
	'GroupFinishWithTeams' = '/home/finished-group/',
	'InviteByToken' = '/home/',
	'UserBecomeCaptain' = '/home/groups/mine/group/',
	'YouJoinedTeam' = '/home/groups/mine/group/',
	'NewCaptainInTeam' = '/home/groups/mine/group/',
	'TeamJoinRequestRejectedAuto' = '/home/groups/mine/group/'
}

export enum MessageParametersByType {
	'Post' = 'groupId',
	'Comment' = 'groupId',
	'Like' = 'groupId',
	'Friend' = 'friendId',
	'Participant' = 'userId',
	'GroupFinish' = 'groupId',
	'Disqualified' = 'groupId',
	'Winner' = 'groupId',
	'MVP' = 'groupId',
	'InitWeightBeforeStart' = 'groupId',
	'InitWeightOnStart' = 'groupId',
	'KickedFromGroup' = 'groupId',
	'FriendJoined' = 'groupId',
	'FriendStarted' = 'groupId',
	'FinalWeight' = 'groupId',
	'WaitForModeration' = 'groupId',
	'ChangeStartDate' = 'groupId',
	'TeamMemberLeft' = 'groupId',
	'TeamMemberJoined' = 'userId',
	'TeamMemberWeightUpdated' = 'groupId',
	'TeamMemberAskedToJoin' = 'team-join-requests',
	'TeamRulesBeforeStart' = 'groupId',
	'InviteTokenApplied' = 'groupId',
	'TeamJoinRequestApproved' = 'groupId',
	'TeamJoinRequestRejected' = 'groupId',
	'GoalReached' = 'groupId',
	'GoalNotReached' = 'groupId',
	'GroupFinishWithTeams' = 'groupId',
	'InviteByToken' = 'my-points',
	'UserBecomeCaptain' = 'groupId',
	'YouJoinedTeam' = 'groupId',
	'NewCaptainInTeam' = 'groupId',
	'TeamJoinRequestRejectedAuto' = 'groupId'
}
