import {Injectable} from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        if (this.authService.isAuthorized()) {
            return true;
        }

        this.router.navigate(['']);
        return false;
    }

    canActivateChild(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): boolean {
      return this.canActivate(next, state);
    }
}
