<router-outlet></router-outlet>
<div class="wrapper">
  <div class="container">
    <div *ngIf="profile">
      <div class="profile">
        <div class="profile__header">
          <div class="profile__img" #userImage (click)="editProfile()">
            <img class="influencer" src="assets/images/influencer-badge.png"  srcset="assets/images/influencer-badge@2x.png 2x,
            assets/images/influencer-badge@3x.png 3x" *ngIf="profile.isInfluencer">
          </div>
          <div class="profile__content">
            <div class="profile__title">
            <span>{{profile.userName}} <span class="city">{{profile.userCity}}</span></span>
            </div>
            <a class="button button__main" *ngIf="!this.profile.isFriend && !this.isMineProfile" (click)="addUserToFriends()">
              {{'GLOBAL.ADD_FRIEND' | translate}}
            </a>
            <a class="button button__main" *ngIf="this.profile.isFriend && !this.isMineProfile">
              {{ "PROFILE.ALREADY_YOUR_FRIEND" | translate }}
            </a>
            <a class="button button__main friends" *ngIf="!this.profile.isFriend && this.isMineProfile" (click)="editProfile()">
              {{ "PROFILE.EDIT_MY_PROFILE" | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="profile-box">
        <div class="profile-box__wrapper">
          <app-tabs [tabs]="tabs" [currentTab]="currentTab" (tabChanged)="currentTab = $event"></app-tabs>
          <div class="profile-box__content profile-box__content--flow" *ngIf="currentTab === 'profile'">
            <app-profile-info [bestHealthyFood]="this.profile.quickFacts.bestHealthyFood"
                              [bestUnhealthyFood]="this.profile.quickFacts.bestUnhealthyFood"
                              [favoriteTrainingUniform]="this.profile.quickFacts.favoriteTrainingUniform"
                              [favoriteWeightLossMethod]="this.profile.quickFacts.favoriteWeightLossMethod"
                              [worstHabit]="this.profile.quickFacts.worstHabit"
                              [superpower]="this.profile.quickFacts.superpower"
                              [dinnerWith]="this.profile.quickFacts.dinnerWith"
                              [endedGames]="this.endedGames"
                              [userName]="this.profile.userName"
                              [userPhotos]="this.userPhotos"
                              [userPhotosLength]="this.userPhotosAmount"
                              [userFriends]="this.userFriends"
                              [userFriendsLength]="this.userFriendsAmount"
                              [isPrivatePhoto]="this.isPrivatePhoto"
                              [instagramToken]="this.profile.instagramToken"
                              [instagramId]="this.profile.instagramId"
                              [instagramExpiresIn]="this.profile.instagramExpiresIn"
                              (refetchUserProfile)="refetchUserProfile()"
            ></app-profile-info>
          </div>
          <div class="profile-box__content profile-box__content--statistics" *ngIf="currentTab === 'statistics'">
            <p class="privateStatistics" *ngIf="isPrivateStatistics">{{profile.userName}} {{ "PROFILE.PICTURES.STATISTICS.HIDDEN" | translate}}</p>
              <div class="doughnut" *ngIf="!isPrivateStatistics">
                <app-profile-statistics [isMineProfile]="isMineProfile" [currentUserId]="currentUserId"></app-profile-statistics>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
