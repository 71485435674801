import { Component, Input } from '@angular/core';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-start-weight-info-popup',
	templateUrl: './start-weight-info-popup.component.html',
	styleUrls: [ './start-weight-info-popup.component.less' ]
})
export class StartWeightInfoPopupComponent {
	@Input() popupData;

	constructor(private passingDataService: PassingDataService) {}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
