import { Component, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { PassingDataService } from 'src/app/services/passing-data.service';
import { AppConstants } from 'src/app/utils/app.constants';

@Component({
	selector: 'app-invite-to-group-popup',
	templateUrl: './invite-to-group-popup.component.html',
	styleUrls: [ './invite-to-group-popup.component.less' ]
})
export class InviteToGroupPopupComponent {
	@Input() popupData; // <- GroupModel with additional teamName parameter

	@ViewChild('groupImage', { static: true })
	groupImage: ElementRef;

	constructor(private renderer: Renderer2, private passingDataService: PassingDataService) {}

	ngAfterViewInit() {
		this.setGroupImage();
	}

	setGroupImage() {
		if (this.popupData) {
			const imageUrl = !!this.popupData.image
				? AppConstants.api.mediaFilesUrl + 'small/' + this.popupData.image.replace(/\"/g, '')
				: null;
			if (imageUrl) {
				this.renderer.setStyle(this.groupImage.nativeElement, 'background-image', `url(${imageUrl})`);
			}
		}
	}

	closePopup() {
		this.passingDataService.passHomePopupData();
		this.passingDataService.setShowPopupAtHome(false);
	}
}
