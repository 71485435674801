import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FileInputDisplayTypes } from '../../../enums/FileInputDisplayTypes.enum';
import { ApiMediaFilesService } from '../../../services/api/api-media-files.service';
import { HttpEventType } from '@angular/common/http';
import { AppConstants } from '../../../utils/app.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TopNotificationsService } from '../../../services/top-notifications.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ApiFeedService } from 'src/app/services/api/api-feed.service';
import { base64images } from '../../../enums/base64images.enum';
import { PostMediaModel } from 'src/app/models/posts-media.model';

function _window(): any {
  return window;
}

@Component({
  selector: 'app-post-file-input',
  templateUrl: './post-file-input.component.html',
  styleUrls: ['./post-file-input.component.less']
})
export class PostFileInputComponent implements OnInit, OnDestroy {
  // Input variables
  public accept: string;
  public isOnIOSDevice: boolean;
  public isIOSSafari: boolean;
  public text: string;
  public type: FileInputDisplayTypes;
  public prevImage: string;
  public postVideoUrl: string;
  public filesCounter: number;
  // Output variables
  public onVideoUploaded = new EventEmitter<any>();
  public onVideoUploadProgress = new EventEmitter<number>();
  public uploadedFileName = new EventEmitter<PostMediaModel>();
  public autoDestroy = new EventEmitter();
  // Component variables
  croppedImage: any = null;

  error: string;
  errorType: string;

  fileData;
  FileInputDisplayTypes = FileInputDisplayTypes;

  imageAsEvent;
  imageAspectRatio: any;
  imageChangedEvent: any = '';
  imageUrl: string;
  isLoading = false;

  thumbnail;

  uploadSizeLimitReached: string;

  // Component observables
  imageUpload$;
  shouldEraseUlopadedData$;
  videoUpload$;

  debugStatus = '';

  private propagateChange = (_: any) => { };

  get imgURL() {
    return this.imageUrl;
  }

  constructor(
    private mediaFilesService: ApiMediaFilesService,
    private translate: TranslateService,
    private topNotificationsService: TopNotificationsService,
    private sanitizer: DomSanitizer,
    private loadingSpinnerService: LoadingSpinnerService,
    private apiFeedService: ApiFeedService
  ) {
    this.imageUpload$ = (file) => this.mediaFilesService.uploadImage(file);
    this.videoUpload$ = (file) => this.mediaFilesService.uploadVideo(file);
    this.translate.get('GLOBAL.FILE_UPLOAD_ERROR').subscribe(
      (value) => {
        this.error = value;
      }
    );
    this.translate.get('GLOBAL.FILE_UPLOAD_ERROR.FILE_TYPE_ERROR').subscribe(
      (value) => {
        this.errorType = value;
      }
    );
    this.translate.get('GLOBAL.FILE_UPLOAD_ERROR.VIDEO_SIZE_LIMIT_REACHED').subscribe(
      (value) => {
        this.uploadSizeLimitReached = value;
      }
    );
    this.shouldEraseUlopadedData$ = this.apiFeedService.shouldEraseUlopadedData$.subscribe(
      () => { this.eraseUlopadedData(); }
    );
  }

  ngOnInit() { }

  sanitizeImage(prImage: any) {
    if (prImage) {
      prImage = prImage.replace(/\"/g, '');
    }
    return this.sanitizer
    .bypassSecurityTrustStyle(`url('${AppConstants.api.mediaFilesUrl}small/${prImage}')`);
  }

  sanitizeThumbnail(thumb: any) {
    if (thumb) {
      thumb = thumb.replace(/\"/g, '');
    }
    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(`${thumb}`);
  }

  setSanitizedUrl(imageOrVideo: string) {
    if (imageOrVideo) {
      imageOrVideo = imageOrVideo.replace(/\"/g, '').trim();
    }
    return imageOrVideo.includes('jpeg') ? this.sanitizer.bypassSecurityTrustUrl(`${
                                                    AppConstants.api.mediaFilesUrl}small/${imageOrVideo}`) :
                                            this.sanitizer.bypassSecurityTrustUrl(`${
                                                    AppConstants.api.mediaFilesUrl}${imageOrVideo}`);
  }

  mediaLinkBuilder(imageOrVideo: string) {
    if (imageOrVideo) {
      imageOrVideo = imageOrVideo.replace(/\"/g, '').trim();
    }
    return imageOrVideo.includes('jpeg') ? `${AppConstants.api.mediaFilesUrl}small/${imageOrVideo}` :
                                            (`${AppConstants.api.mediaFilesUrl}${imageOrVideo}`);
  }


  writeValue(obj: any): void {
    this.imageUrl = null;
    this.fileData = null;
  }

  registerOnChange(fn: any): void {
      this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  eraseUlopadedData() {
    this.imageUrl = null;
    this.thumbnail = null;
    this.postVideoUrl = null;
    this.autoDestroy.emit(true);
  }

  onFileChanged(event: any) {
    const fileReader = new FileReader();
    if (event.target.files && event.target.files.length) {
      if (this.postVideoUrl) {
        this.postVideoUrl = null;
      }

      this.fileData = null;
      const file = event.target.files[0];
      this.isLoading = true;

      let tempType = null;
      if (this.type === FileInputDisplayTypes.PostMedia) {
        if (event.target.files[0].type.includes('image')) {
          this.type = FileInputDisplayTypes.PostImage;
          tempType = FileInputDisplayTypes.PostMedia;
          this.thumbnail = null;
        } else if (event.target.files[0].type.includes('video')) {
          this.type = FileInputDisplayTypes.Video;
          tempType = FileInputDisplayTypes.PostMedia;
          this.imageUrl = null;
        }
      }

      if (this.type === FileInputDisplayTypes.PostImage) {
        if (tempType) {
          this.type = tempType;
        }
        if (this.isOnIOSDevice && file.type) {
          // if Apple Device - upload image without cropping
          this.uploadImageToTheServer(file);
        } else {
          // if NON Apple Device - image should be cropped
          fileReader.readAsDataURL(file);
          fileReader.addEventListener('loadend', (e: any) => {
            const i = new Image();
            i.src = e.target.result;
            i.onload = () => {
              if (i.naturalWidth && i.naturalHeight) {
                this.imageAspectRatio = this.reduceRatio(i.width, i.height);
                this.fileData = e.target.result;
                // Passing event to start image cropper work
                this.imageChangedEvent = event;
              }
            };
            i.onerror = () => {
              if (this.isOnIOSDevice) {
                alert('something goes wrong with image uploading, please choose another image');
              }
              this.imageUrl = null;
              this.isLoading = false;
            };
          });
        }
      } else if (this.type === FileInputDisplayTypes.Video) {
        if (tempType) {
          this.type = tempType;
        }
       if (file.size <= 524288000) {
          if (file.type.match('video/')) {
            this.videoUpload$(file).subscribe(
            (video_event) => {
              this.thumbnail = null;
              this.prevImage = null;
              this.imageUrl = null;
              if (video_event.type === HttpEventType.UploadProgress) {
                this.onVideoUploadProgress.emit(Math.round(video_event.loaded / video_event.total * 100));
              } else if (video_event.type === HttpEventType.Response) {
                if (video_event.status === 200) {
                  this.postVideoUrl = this.mediaLinkBuilder(video_event.body);
                  this.uploadedFileName.emit({contentType: 'video', order: this.filesCounter, fileName: video_event.body.replace(/\"/g, '')});
                }
                fileReader.readAsArrayBuffer(file);
                fileReader.addEventListener('loadend', (e) => {
                  this.setVideoThumbnail(file);
                  this.propagateChange(video_event.body);
                });
              }
            }, () => {
              this.handleError();
            }
          );
          } else {
            this.isLoading = false;
            this.topNotificationsService.notify(this.errorType, 'error');
          }
        } else {
          this.isLoading = false;
          this.topNotificationsService.notify(this.uploadSizeLimitReached, 'error');
        }
      }
    }
  }

  reduceRatio(width, height) {
      let gcd, temp, divisor;
      gcd = function (a, b) {
          if (b === 0) {return a; }
          return gcd(b, a % b);
      };
      if (!this.isInteger(width) || !this.isInteger(height)) {return; }
      if (width === height) {return 1; }
      if (+width < +height) {
          temp = width;
          width = height;
          height = temp;
      }
      divisor = gcd(+width, +height);
      return 'undefined' === typeof(temp) ?
        ((width / divisor) / (height / divisor)) :
        ((height / divisor) / (width / divisor));
  }
  isInteger(value) {
      return /^[0-9]+$/.test(value);
  }

  imageCropped(image: ImageCroppedEvent) {
    this.croppedImage = base64ToFile(image.base64);
    if (this.croppedImage) {
      this.uploadImageToTheServer(this.croppedImage);
      if (this.type === FileInputDisplayTypes.Image) {
        this.fileData = image.base64;
      }
    } else {
      this.loadingSpinnerService.hide.next();
    }
  }

  uploadImageToTheServer(file) {
    this.imageUpload$(file).subscribe(
      (imageUrl: any) => {
        this.uploadedFileName.emit({contentType: 'image', order: this.filesCounter, fileName: imageUrl.replace(/\"/g, '')});
        this.imageUrl = AppConstants.api.mediaFilesUrl + imageUrl.replace(/\"/g, '');
        this.prevImage = null;
        this.propagateChange(imageUrl);
      }, () => {
        this.loadingSpinnerService.hide.next();
        this.handleError();
      }, () => {
        this.loadingSpinnerService.hide.next();
        this.isLoading = false;
        this.imageChangedEvent = '';
      }
    );
  }

  deleteMediaItem() {
    if (this.imageUrl || this.prevImage) {
      this.uploadedFileName.emit({contentType: 'image', order: this.filesCounter, fileName: ''});
      this.imageUrl = null;
      this.prevImage = null;
    } else if (this.postVideoUrl) {
      this.uploadedFileName.emit({contentType: 'video', order: this.filesCounter, fileName: ''});
      this.postVideoUrl = null;
    }
  }

  private setVideoThumbnail(file) {
    this.thumbnail = base64images.videoSuccessUploaded;
    this.fileData = this.thumbnail;
    this.isLoading = false;
    this.onVideoUploaded.next(
      {
        'video': file,
        'thumbnail': this.thumbnail
      }
    );
  }

  private handleError() {
    this.isLoading = false;
    this.topNotificationsService.notify(this.error, 'error');
  }

  ngOnDestroy() {
    this.shouldEraseUlopadedData$.unsubscribe();
  }

}
