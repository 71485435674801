import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';
import {map, pairwise} from 'rxjs/operators';
import {filter} from 'rxjs/internal/operators/filter';
import {exhaustMap} from 'rxjs/internal/operators/exhaustMap';

interface ScrollPosition {
  sH: number;
  sT: number;
  cH: number;
}

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective implements AfterViewInit {

  private scrollEvent$;
  private userScrolledDown$;
  private requestOnScroll$;
  @Input() scrollCallback;
  @Input() immediateCallback;
  @Input() scrollPercent = 90;

  constructor(private elm: ElementRef) { }

  ngAfterViewInit() {
    this.registerScrollEvent();
    this.streamScrollEvents();
    this.requestCallbackOnScroll();
  }

  private registerScrollEvent() {
    this.scrollEvent$ = fromEvent(this.elm.nativeElement, 'scroll');
  }

  private streamScrollEvents() {
    this.userScrolledDown$ = this.scrollEvent$.pipe(
      map((e: any): ScrollPosition => {
        return {
          sH: e.target.scrollHeight,
          sT: e.target.scrollTop,
          cH: e.target.clientHeight
        };
      }),
      pairwise(),
      filter((positions) => {
        return this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1]);
      })
    );
  }

  private requestCallbackOnScroll() {

    this.requestOnScroll$ = this.userScrolledDown$;

    this.requestOnScroll$.pipe(
      exhaustMap(() => this.scrollCallback())
    ).subscribe(() => {});

  }

  private isUserScrollingDown = (positions) => {
    return positions[0].sT < positions[1].sT;
  }

  private isScrollExpectedPercent = (position) => {
    return ((position.sT + position.cH) / position.sH) > (this.scrollPercent / 100);
  }

}
