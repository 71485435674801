import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiGroupsService } from '../../services/api/api-groups.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { GroupModel } from '../../models/group.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TopNotificationsService } from '../../services/top-notifications.service';
import { ErrorsProcessorService } from '../../services/errors-processor.service';
import { AppLocalStorage } from '../../utils/app.local.storage';
import { AppConstants } from '../../utils/app.constants';
import { AuthService } from '../../services/auth.service';
import { PassingDataService } from 'src/app/services/passing-data.service';

@Component({
	selector: 'app-invites',
	templateUrl: './invites.component.html',
	styleUrls: [ './invites.component.less' ]
})
export class InvitesComponent implements OnInit {
	group: GroupModel;
	inviteToken: string;

	constructor(
		private auth: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private groupsApiService: ApiGroupsService,
		private loadingSpinnerService: LoadingSpinnerService,
		private topNotificationsService: TopNotificationsService,
		private errorsProcessorService: ErrorsProcessorService,
		private passingDataService: PassingDataService
	) {}

	ngOnInit() {
		this.loadingSpinnerService.show.next();
		this.inviteToken = this.route.snapshot.params.token;
		this.groupsApiService.getGroupByToken(this.inviteToken).subscribe(
			(data: GroupModel) => {
				this.group = data;
				if (this.inviteToken) {
					const localStorageInviteObject = {
						groupId: this.group.id,
						token: this.inviteToken,
						shouldInvitePopupBeShown: true
					};
					if (localStorageInviteObject.groupId) {
						AppLocalStorage.add(AppConstants.keys.invite, localStorageInviteObject);
						// Setting the same invite token to service (hack for webview)
						this.passingDataService.setInviteToken(localStorageInviteObject);
					}
				}
				this.loadingSpinnerService.hide.next();
			},
			(httpErrorResponse: HttpErrorResponse) => {
				this.redirect(this.group);
				this.loadingSpinnerService.hide.next();
				const error = this.errorsProcessorService.process(httpErrorResponse);
				this.topNotificationsService.notify(error, 'error');
			},
			() => {
				this.redirect(this.group);
			}
		);
	}

	redirect(group: GroupModel) {
		if (group) {
			if (this.auth.isAuthorized()) {
				this.router.navigate([ '/home/groups/all/group/' + group.id ]);
			} else {
				this.router.navigate([ '/all-groups/group/' + group.id ]);
			}
		} else {
			if (this.auth.isAuthorized()) {
				this.router.navigate([ '/home/groups/all' ]);
			} else {
				this.router.navigate([ '/all-groups' ]);
			}
		}
	}
}
