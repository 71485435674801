export interface IUserPhotos_DTO {
	amount: number;
	images: IUserPhoto[];
}
export interface IUserPhoto {
	image: string;
	id: number;
}

export interface IWinnerTeams {
	groupName: string;
	winnerTeamNames: string[];
	memberPrize: number;
	userRating: number;
}

export interface IComletedGroup {
	groupName: string;
	groupRating: number;
}

import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserProfileModel, UpdateUserProfileModel, UserProfileStatisticsModel } from '../../models/user-profile.model';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../utils/app.constants';
import { CompletedGroupModel } from '../../models/completed-group.model';
import { LoadingSpinnerService } from '../loading-spinner.service';

@Injectable()
export class ApiUserProfileService extends ApiBaseService {
	newArr: IUserPhoto[] = [];
	getUserPhotosParams;

	private userTwoPhotos = new BehaviorSubject<IUserPhotos_DTO>(null);
	getUserTwoPhotos$: Observable<IUserPhotos_DTO> = this.userTwoPhotos.asObservable();

	private userPhotos = new BehaviorSubject<IUserPhoto[]>([]);
	getUserPhotos$: Observable<IUserPhoto[]> = this.userPhotos.asObservable();

	constructor(protected http: HttpClient, private loadingService: LoadingSpinnerService) {
		super(http);
	}

	getUserProfile(userId?: number): Observable<UserProfileModel> {
		const data = userId ? new HttpParams().set('userId', `${userId}`) : null;
		return this.getData('userProfile', data);
	}
	updateUserProfile(userProfile: UpdateUserProfileModel): Observable<UserProfileModel> {
		return this.putData(`userProfile`, userProfile);
	}

	getUserStatistics(userId?: number): Observable<UserProfileStatisticsModel> {
		const data = userId ? new HttpParams().set('userId', `${userId}`) : null;
		return this.getData('userProfile/statistic', data);
	}

	getUserPhotos(userId?: number, queryParams?) {
		this.loadingService.show.next();
		if (userId !== null && queryParams) {
			this.getUserPhotosParams = new HttpParams()
				.set('userId', `${userId}`)
				.set('page', `${queryParams.page}`)
				.set('pageSize', `${queryParams.pageSize}`);
		} else if (userId === null && queryParams) {
			this.getUserPhotosParams = new HttpParams()
				.set('page', `${queryParams.page}`)
				.set('pageSize', `${queryParams.pageSize}`);
		} else if (userId === null && !queryParams) {
			this.getUserPhotosParams = new HttpParams().set('page', '1').set('pageSize', '12');
		}
		return this.getData('userPhoto', this.getUserPhotosParams).subscribe(
			(photos: IUserPhotos_DTO) => {
				if (this.getUserPhotosParams.get('page') === '1') {
					this.newArr = photos.images;
				} else if (this.getUserPhotosParams.get('page') !== '1') {
					for (let i = 0; i < photos.images.length; i++) {
						this.newArr.push(photos.images[i]);
					}
				}
				this.userPhotos.next(this.newArr);
			},
			() => {
				this.loadingService.hide.next();
			},
			() => {
				this.loadingService.hide.next();
			}
		);
	}

	getTwoUserPhotos(userId?: number) {
		const data = userId
			? new HttpParams().set('userId', `${userId}`).set('pageSize', '2')
			: new HttpParams().set('pageSize', '2');
		return this.getData('userPhoto', data).subscribe((twoPhotos: IUserPhotos_DTO) => {
			this.userTwoPhotos.next(twoPhotos);
		});
	}

	deleteUserPhoto(imageId: number): Observable<any> {
		return this.deleteDataById('userPhoto/', imageId.toString());
	}

	postUserPhotos(imageName: string): Observable<any> {
		return this.postData('userPhoto', { image: `${imageName}` });
	}

	getUserFriends(userId?: number): Observable<any> {
		const data = userId ? new HttpParams().set('userId', `${userId}`) : null;
		return this.getData('friend', data);
	}

	postUserFriend(param): Observable<any> {
		return this.postData('friend', param);
	}

	deleteUserFriend(friendId: number): Observable<any> {
		return this.http.delete(AppConstants.api.baseUrl + `friend/${friendId}`);
	}

	getCompletedGames(userId?: number): Observable<any> {
		const data = userId ? new HttpParams().set('userId', `${userId}`) : null;
		return this.getData(`userProfile/completedGames`, data);
	}

	getSingleCompletedGame(groupId: string): Observable<any> {
		return this.getData(`userProfile/completedGames/${groupId}`).pipe(map((data) => new CompletedGroupModel(data)));
	}

	getWinnerTeams(groupId: string): Observable<IWinnerTeams> {
		return this.getData(`userProfile/getWinnerTeams/${groupId}`);
	}

	getWinnerGroup(groupId: string): Observable<IComletedGroup> {
		return this.getData(`userProfile/getWinnerTeams/${groupId}`).pipe(
			map((data: IWinnerTeams) => {
				return {
					groupName: data.groupName,
					groupRating: data.userRating
				};
			})
		);
	}

	postRating(groupId, userRating): Observable<any> {
		const payload = {
			userRating: userRating
		};
		return this.postData(`userProfile/completedGames/${groupId}/rategroup`, payload);
	}

	postMVPWantToBeContacted(groupId): Observable<any> {
		return this.postData(`group/${groupId}/mvp/iswanttobecontacted`, {});
	}

	postMVP2WantToBeContacted(groupId): Observable<any> {
		return this.postData(`group/${groupId}/mvp2/iswanttobecontacted`, {});
	}

	getNotificationSettings(): Observable<any> {
		return this.getData(`account/notificationSettings`);
	}
	putNotificationSettings(settings): Observable<any> {
		return this.putData(`account/notificationSettings`, settings);
	}

	postReport(report): Observable<any> {
		return this.postData(`userProfile/report`, report);
	}

	postFeedback(feedback): Observable<any> {
		return this.postData(`userProfile/feedback`, feedback);
	}

	changeNotificationLanguage(language: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Accept-Language': language
			})
		};
		return this.postDataWithOptions(`account/language`, httpOptions, { language: language });
	}
}
