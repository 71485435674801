<div class="wrapper" [ngClass]="{'is-public': isPublicMode}" #wrapper>
  <div class="container" [ngClass]="{'team-member': group?.isTeamMember}">
    <a class="button__back" (click)="goBack()" *ngIf="isPublicMode" >
      <img src="assets/images/arrow-lg-left.png"
           srcset="assets/images/arrow-lg-left@2x.png 2x,
             assets/images/arrow-lg-left@3x.png 3x"
           class="arrow-lg-left">
    </a>
    <div *ngIf="group">
      <!-- <div *ngIf="isMineGroup && !isAdmin">
        <app-groups-dropdown [_currentGroup]="group"></app-groups-dropdown>
      </div> -->
      <div class="group" [ngClass]="{'is-mine': isMineGroup}">
        <div class="group__card">
          <div class="group__header">
            <div class="group__img" #groupImage></div>
            <div class="group__content">
              <div class="group__title">
                <span class="break cancel" >{{group.name}}</span>
              </div>
              <div class="group__timestamp">{{'GLOBAL.START_DATE' | translate}}: {{group.startDate | amDateFormat: 'DD-MM-YYYY'}}</div>
              <div class="group__timestamp">{{'GLOBAL.END_DATE' | translate}}: {{group.endDate | amDateFormat: 'DD-MM-YYYY'}}</div>
              <ng-container *ngIf="group.influencer; then showVIPInfluencer; else showInfluencer"></ng-container>
              <ng-template #showVIPInfluencer>
                <span class="infuencer" (click)="goToInfluencerProfile(group.influencer.userId)"><b class="black-color">{{ "SETTINGS.RULES.BLOCK5.BULLET2_VIP" | translate }}:</b><br/><b class="main-color">{{group.influencer.userName}}<img src="assets/images/verified_icon.svg"></b></span>
              </ng-template>
              <ng-template #showInfluencer>
                <span class="infuencer" (click)="goToInfluencerProfile(group.creatorId)"><b class="black-color">{{ "SETTINGS.RULES.BLOCK5.BULLET2" | translate }}:</b><br/><b class="main-color">{{group.creatorName}}</b></span>
              </ng-template>
              <ng-container *ngIf="!isAdmin && group.isCreator && !group.isStarted">
                <br/><a class="edit-group" [routerLink]="['/home/create-group/step1/' + group.id + '/edit']">{{"GLOBAL.EDIT_GROUP" | translate}}</a>
              </ng-container>
            </div>
          </div>
          <div class="group__properties">
              <!-- Bet -->
              <div class="property">
                  <div class="property__value">{{group.bet}}kr</div>
                  <div class="property__title">{{'GLOBAL.BET_SHORT' | translate}}</div>
              </div>
              <!-- Participants -->
              <div class="property__divider"></div>
              <div class="property" style="cursor: pointer;" (click)="showGroupsParticipants(group.id)">
                  <div class="property__value">{{group.amountOfParticipants}}</div>
                  <div class="property__title">{{'GLOBAL.PARTICIPANTS_SHORT' | translate}}</div>
              </div>
              <!-- Teams -->
              <div class="property__divider"></div>
              <div class="property">
                  <div class="property__value">{{group.teamsCount}}</div>
                  <div class="property__title">{{'GLOBAL.TEAMS' | translate}}</div>
              </div>
              <!-- In The Team Pot -->
              <div class="property__divider"></div>
              <div class="property">
                  <div class="property__value">{{group.bettingAmount}}kr</div>
                  <div class="property__title">{{'GLOBAL.IN_THE_POT' | translate}}</div>
              </div>
          </div>

          <ng-container *ngIf="toggleFullDescription; then showFullDescription; else showMiniDescription"></ng-container>
          <ng-template #showFullDescription>
            <span class="group__description">{{group.description}}<br/><br/>{{group.additionalDescription}}<br/><br/><div class="show-less" (click)="toggleFullDescription = !toggleFullDescription">{{"GROUP.SHOW_LESS" | translate}}<img src="assets/images/blue_arrow_right.svg"></div></span>
          </ng-template>
          <ng-template #showMiniDescription>
            <span class="group__description">{{group.description}} <div *ngIf="group.additionalDescription" class="show-more" (click)="toggleFullDescription = !toggleFullDescription">{{"GROUP.SHOW_MORE" | translate}}<img src="assets/images/blue_arrow_right.svg"></div></span>
          </ng-template>
        </div>

        <ng-container
          *ngIf="!createOrJoinTeamMode; then showDescriptionAndBonuses"></ng-container>

        <ng-template #showDescriptionAndBonuses>
          <div class="group__card" *ngIf="(group.discountCodes || group.bonusPrize || group.pointsBonusPrize)">
          <div class="group__bonusprizes">
            <div class="bonusprizes">
              <div class="bonusprizes__title">
                <img src="assets/images/celebrate_icon_left.svg">{{"GLOBAL.BONUSPRIZE" | translate}}<img src="assets/images/celebrate_icon_right.svg">
              </div>

              <ng-container *ngIf="toggleFullBonusPrizes; then showFullBonusPrizes; else showMiniBonusPrizes"></ng-container>

              <ng-template #showFullBonusPrizes>
                <span class="bonusprizes__item">{{"GROUP.POINTS_BONUS_PRIZE" | translate}} <b>{{group.pointsBonusPrize}}kr.</b>
                  <ng-container *ngIf="group.bonusPrize || group.discountCodes; then showPrizesAndCodes; else showNoPrizes"></ng-container>

                  <ng-template #showPrizesAndCodes>
                    <div class="opened-prizes">
                      <ng-container *ngIf="group.bonusPrize; then showBonusPrizes; else showNoBonusPrizes"></ng-container>
                      <ng-template #showBonusPrizes>
                        <div class="bonus-prizes" [innerHTML]="group.bonusPrize | linkHightlight | safe:'html'" (click)="sendBonusPrizeEvent()"></div>
                      </ng-template>
                      <ng-template #showNoBonusPrizes>
                        <p class="no-prizes__title">{{"GROUP.NO_PRIZES.TITLE" | translate}}</p>
                        <p class="no-prizes__motivation">{{"GROUP.NO_PRIZES.MOTIVATION" | translate}}</p>
                        <p class="no-prizes__text">{{"GROUP.NO_PRIZES.TEXT" | translate}}</p>
                      </ng-template>

                      <p class="no-prizes__motivation">{{"GROUP.MORE_BONUS" | translate}}</p>

                      <ng-container *ngIf="group.discountCodes">
                          <ng-container *ngIf="group.isMine">
                            <!--<p class="no-prizes__motivation">{{"GROUP.MORE_BONUS" | translate}}</p>-->
                            <div class="discount-codes" (click)="toggleDiscountPopUp()">{{ "GROUP.SHOW_DISCOUNT_CODE" | translate }}</div>
                          </ng-container>
                          <ng-container *ngIf="!group.isMine">
                            <p class="join-to-access-discount-codes">{{"GROUP.JOIN_TO_ACCESS_CODES" | translate}}</p>
                          </ng-container>
                      </ng-container>
                    </div>

                    <div class="show-less" (click)="triggerToggleFullBonusPrizes()">{{"GROUP.HIDE_MORE_BONUSES" | translate}}
                      <img src="assets/images/blue_arrow_right.svg">
                    </div>
                  </ng-template>

                  <ng-template #showNoPrizes>
                    <div class="opened-prizes">
                      <p class="no-prizes__title">{{"GROUP.NO_PRIZES.TITLE" | translate}}</p>
                      <p class="no-prizes__motivation">{{"GROUP.NO_PRIZES.MOTIVATION" | translate}}</p>
                      <p class="no-prizes__text">{{"GROUP.NO_PRIZES.TEXT" | translate}}</p>
                    </div>
                    <div class="show-less" (click)="triggerToggleFullBonusPrizes()">{{"GROUP.HIDE_MORE_BONUSES" | translate}}
                      <img src="assets/images/blue_arrow_right.svg">
                    </div>
                  </ng-template>
                </span>
              </ng-template>

              <ng-template #showMiniBonusPrizes>
                <span class="bonusprizes__item">{{"GROUP.POINTS_BONUS_PRIZE" | translate}} <b>{{group.pointsBonusPrize}}kr.</b><br/><div class="show-more" (click)="triggerToggleFullBonusPrizes()">{{"GROUP.SHOW_MORE_BONUSES" | translate}}<img src="assets/images/blue_arrow_right.svg"></div></span>
              </ng-template>
            </div>
          </div>
        </div>
        </ng-template>

        <ng-container *ngIf="group.isTeamMember && group.isParticipant && group.isActive">
          <!-- OFFICIAL Weight registration -->
          <div class="group__card alarm" *ngIf="!group.isWaitigForReview && !group.isTimeBeforeOfficialWeighins && group.officialWeighins && !group.isOfficialWeightSent">
            <span class="register-official-weight" (click)="registerWeight()">
              <ng-container *ngIf="group.isStartWeight">{{ "GROUP.REGISTER_START_WEIGHT" | translate }}</ng-container>
              <ng-container *ngIf="group.isFinalWeight">{{ "GROUP.REGISTER_FINAL_WEIGHT" | translate }}</ng-container>
              <svg viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></span>
          </div>
          <!-- OFFICIAL Weight registration popup -->
          <div class="group__card" *ngIf="!group.isWaitigForReview && group.isTimeBeforeOfficialWeighins && !group.officialWeighins && !group.isOfficialWeightSent">
            <span class="register-official-weight" (click)="showStartWeightInfo()">{{ "GLOBAL.REGISTER_WEIGHT" | translate }}<img src="assets/images/info_icon.svg"></span>
          </div>
          <!-- OFFICIAL Weight registration waiting for review -->
          <div class="group__card" *ngIf="group.isWaitigForReview && group.officialWeighins && group.isOfficialWeightSent">
            <span class="on-review">{{ "GROUP.VIDEO_IS_ON_REVIEW" | translate }}</span>
          </div>
        </ng-container>


        <!-- Block below has not been drawn on new design -->
        <!-- <div class="additional-info">
          <div class="official-weight-registration red" *ngIf="group.isParticipant && !group.isActive && !isAdmin && !group.groupIsEnded"><a href="mailto:support@formsatsningen.se">{{ "GROUP.DISQUALIFIED_FROM_GROUP" | translate }} support@formsatsningen.se</a></div>
          <div class="official-weight-registration red" *ngIf="group.isParticipant && group.groupIsEnded"><a href="mailto:info@formsatsningen.se">{{ "GROUP.GROUP_IS_ENDED" | translate }} info@formsatsningen.se</a></div>
        </div> -->

        <ng-container *ngIf="!isAdmin && ((group.isPrivate && (isInvited || group.isCreator)) || !group.isPrivate) && !group.isParticipant">
          <ng-container *ngIf="!group.isStarted || (group.isStarted && group.isCanJoin)">
          <!-- Not joined creator -->
            <div class="creator-buttons" *ngIf="group.isCreator">
              <a class="button button__main center join" (click)="joinGroup()">{{'GLOBAL.JOIN' | translate}}</a>
              <a class="button button__alt center join" (click)="inviteFriend()">{{'GLOBAL.INVITE' | translate}}</a>
            </div>
          <!-- Not joined user -->
            <a class="button button__alt center join" *ngIf="!group.isCreator" (click)="joinGroup()">{{'GLOBAL.JOIN' | translate}}</a>
          </ng-container>
          <!-- Group has been started or ended -->
          <a style="cursor:default" class="button button__alt center inactive" *ngIf="group.isStarted && !group.isCanJoin && !group.groupIsEnded">{{'GLOBAL.GROUP_STARTED' | translate}}</a>
          <a style="cursor:default; width:215px" class="button button__alt center inactive" *ngIf="group.groupIsEnded">{{ "GLOBAL.GROUP_IS_ENDED" | translate }}</a>
        </ng-container>

        <div class="group__locked" *ngIf="group.isPrivate && !group.isMine && !isInvited && !isAdmin">
          <div class="locked">
            <div class="locked__title">
              <span>{{"GROUP.LOCKED.TITLE" | translate}}</span> <img src="assets/images/lock-black.png"
                                                                     srcset="assets/images/lock-black@2x.png 2x,
             assets/images/lock-black@3x.png 3x">
            </div>
            <div class="locked__text">
              {{"GROUP.LOCKED.TEXT" | translate}}
            </div>
          </div>
        </div>

        <div class="create-or-join-team"
          *ngIf="group.isParticipant &&
                !group.isTeamMember &&
                group.isCanJoin &&
                !createOrJoinTeamMode">
          <a class="create-or-join-team__button button button__main" (click)="handleTabNameChange('teams')">{{ "GROUP.JOIN_OR_CREATE_THE_TEAM.BUTTON" | translate }}</a>
          <p class="create-or-join-team__text">{{ "GROUP.TEAMS.LIST_HEADER_TEXT.2" | translate }}</p>
          <a class="create-or-join-team__button button button__alt" (click)="inviteFriend()">{{'GLOBAL.INVITE' | translate}}</a>
        </div>

        <ng-container *ngIf="group.isTeamMember">
            <ng-container *ngIf="group.isCanJoin; then showInviteFriend; else showGoToTeam"></ng-container>
            <ng-template #showInviteFriend>
              <div class="member-buttons">
                <a class="button button__main center join" (click)="scrollToGroupTop()">{{'GROUP.TO_MY_TEAM' | translate}}</a>
                <a class="button button__alt center join" (click)="inviteFriend()">{{'GLOBAL.INVITE' | translate}}</a>
              </div>
            </ng-template>
            <ng-template #showGoToTeam >
              <a class="button button__main center join" (click)="scrollToGroupTop()">{{'GROUP.TO_MY_TEAM' | translate}}</a>
            </ng-template>
        </ng-container>
      </div>

      <div class="group-box" *ngIf="isMineGroup || !group.isPrivate || isAdmin">
        <div class="group-box__wrapper">
          <ng-container
          *ngIf="createOrJoinTeamMode; then chooseTeamMode; else tabsMode"></ng-container>

          <ng-template #chooseTeamMode>
            <!-- Join/create team -->
            <div class="group-box__content group-box__content--teams">
              <app-group-teams [group]="group" (closeCreateOrJoinTeam)="hideCreateOrJoinTeamMode($event)"></app-group-teams>
            </div>
          </ng-template>

          <ng-template #tabsMode>
            <app-tabs [tabs]="tabs" [currentTab]="currentTab" (tabChanged)="handleTabNameChange($event)"></app-tabs>
            <div class="top-tabs">
              <div class="group-box__content group-box__content--info" *ngIf="currentTab === 'info'">
                <app-group-info (iWantToJoin)="joinGroup()" [isCanJoin]="group.isCanJoin" [isStarted]="group.isStarted"></app-group-info>
              </div>
              <div class="group-box__content group-box__content--flow" *ngIf="currentTab === 'feed'">
                <app-group-feed [isMineGroup]="isMineGroup"
                                [isCreator]="group.isCreator"
                                [groupId]="group.id"
                                [postId]="postId"
                                [commentId]="commentId"
                                [isInvited]="isInvited"
                                [isCanJoin]="group.isCanJoin"
                                [isGroupParticipant]="group.isParticipant"
                                [isIOSSafari]="isIOSSafari"
                                [isGroupStarted]="isGroupStarted"
                                [isOnIOSDevice]="isOnIOSDevice"
                                [teamId]="group.teamId"
                                (showSelectedCityParicipants)="showGroupsParticipants(group.id, $event)"></app-group-feed>
              </div>
              <div class="group-box__content group-box__content--statistics" *ngIf="currentTab === 'statistics'">
                <app-group-statistics [group]="group" [isGroupStarted]="isGroupStarted"></app-group-statistics>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
      <div *ngIf="!isMineGroup && !isInvited">
        <app-groups-preview title="{{'GLOBAL.MORE_GROUPS' | translate}}" case="uppercase"></app-groups-preview>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isPublicMode && showInvitePopupUnderGroup">
  <div class="unauthed-invite-popup-wrapper">
    <div class="overlay">
      <div class="popup-wrapper">
        <i class="close-popup material-icons" (click)="closeInvitePopup()">close</i>
        <div class="static">
          <span class="title">{{"INVITE_TO_GROUP_POPUP.TITLE" | translate}}</span>
          <span class="welcome">{{"INVITE_TO_GROUP_POPUP.WELCOME" | translate}}</span>
          <span class="details">{{"INVITE_TO_GROUP_POPUP.DETAILS" | translate}}</span>
        </div>
        <div class="dynamic">
          <div class="team-name">
              <ng-container *ngIf="teamName; then showTeamName; else showNoTeam"></ng-container>
              <ng-template #showTeamName>{{"INVITE_TO_GROUP_POPUP.TEAM_NAME.1" | translate}} {{teamName}} {{"INVITE_TO_GROUP_POPUP.TEAM_NAME.2" | translate}}</ng-template>
              <ng-template #showNoTeam>{{"INVITE_TO_GROUP_POPUP.TEAM_NAME.NO_TEAM_NAME" | translate}}</ng-template>
          </div>
          <div class="group">
              <div class="group__header">
                <div class="group__img" #groupImagePopup></div>
                <div class="group__content">
                  <div class="group__title break cancel">{{group?.name}}</div>
                  <div class="group__description break cancel">{{group?.description}}</div>
                </div>
              </div>
              <div class="group__properties">
                <!-- Bet -->
                <div class="property">
                  <div class="property__value">{{group?.bet}}kr</div>
                  <div class="property__title">{{'GLOBAL.BET_SHORT' | translate}}</div>
                </div>
                <!-- Participants -->
                <div class="property__divider"></div>
                <div class="property">
                  <div class="property__value">{{group?.amountOfParticipants}}</div>
                  <div class="property__title">{{'GLOBAL.PARTICIPANTS_SHORT' | translate}}</div>
                </div>
                <!-- Teams -->
                <div class="property__divider"></div>
                <div class="property">
                  <div class="property__value">{{group?.teamsCount}}</div>
                  <div class="property__title">{{'GLOBAL.TEAMS' | translate}}</div>
                </div>
                <!-- In The Team Pot -->
                <div class="property__divider"></div>
                <div class="property">
                  <div class="property__value">{{group?.bettingAmount}}kr</div>
                  <div class="property__title">{{'GLOBAL.IN_THE_POT' | translate}}</div>
                </div>
              </div>
              <a class="button button__alt" (click)="closeInvitePopup()">{{'GLOBAL.GO_TO_GROUP' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
