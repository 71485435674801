import { Injectable, NgZone, EventEmitter } from '@angular/core';
import {BehaviorSubject, Observable, Subject, PartialObserver, Subscription, Observer} from 'rxjs';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';

function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable()
export class CordovaService {

  private resume: BehaviorSubject<boolean>;
  notification: Subject<any> = new Subject<any>();
  private registrationData: BehaviorSubject<any> = new BehaviorSubject(null);
  currentMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  onMessage: EventEmitter<any> = new EventEmitter();
  private pushService: any;

  constructor(private zone: NgZone) {
    this.resume = new BehaviorSubject<boolean>(null);
    this.pushService = null;
    fromEvent(document, 'resume').subscribe(event => {
      this.zone.run(() => {
        this.onResume();
      });
    });
  }

  get cordova(): any {
    return _window().cordova;
  }
  get onCordova(): Boolean {
    return !!_window().cordova;
  }
  get firebase(): any {
    return _window().cordova;
  }
  get pushNotification(): any {
    return _window().PushNotification;
  }
  get registration(): any {
      return this.registrationData;
  }
  public onResume(): void {
    this.resume.next(true);
    this.init();
  }
  public subscribe(handler: any): Subscription {
    return this.notification.subscribe(handler);
  }

  public unsubscribe(): void {
      this.notification.unsubscribe();
  }

  public subscribeMsg(handler: any): Subscription {
      return this.currentMessage.subscribe(handler);
  }

  public unsubscribeMsg(): void {
      this.currentMessage.unsubscribe();
  }

  get navigator(): any {
      return _window().navigator;
  }

  public vibrate(duration: number): void {
      if (this.navigator && this.navigator.vibrate) {
        this.navigator.vibrate(duration);
      }
  }

  public finish(): void {
      if (this.pushService) {
          this.pushService.finish(() => {
              console.log('finish successfully called');
          });
      }
  }

  public hideSplashScreen() {
      if (this.onCordova && this.navigator.splashscreen) {
        this.navigator.splashscreen.hide();
      }
  }

  public init(): void {
      this.hideSplashScreen();
      if (this.onCordova && this.pushNotification && this.pushService === null) {
          console.log('init cordova push notifications');
          this.pushService = this.pushNotification.init({
              android: {
                  forceShow: 'true',
                  sound: 'true',
                  vibrate: 'true'
              },
              browser: {
                  pushServiceURL: 'http://push.api.phonegap.com/v1/push'
              },
              ios: {
                  alert: 'true',
                  badge: 'true',
                  sound: 'true'
              },
              windows: {}
          });

          this.pushService.on('registration', (data) => {
              // data.registrationId
              this.registrationData.next(data);
              console.log(data);
          });

          this.pushService.on('notification', (data) => {
              // data.message,
              // data.title,
              // data.count,
              // data.sound,
              // data.image,
              // data.additionalData
              console.log(data);
              this.notification.next(data);
              this.currentMessage.next(data);
              this.onMessage.emit(data);
          });

          this.pushService.on('error', (e) => {
              // e.message
              console.log(e);
          });
      }
  }
}
