import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppConstants} from '../../utils/app.constants';
import {AppLocalStorage} from '../../utils/app.local.storage';
import {RouterInterceptorService} from '../../services/router-interceptor.service';
import { ApiAccountService } from 'src/app/services/api/api-account.service';

@Component({
  selector: 'app-unauthorized-join-group',
  templateUrl: './unauthorized-join-group.component.html',
  styleUrls: ['./unauthorized-join-group.component.less']
})
export class UnauthorizedJoinGroupComponent implements OnInit {

  groupId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routerInterceptorService: RouterInterceptorService,
    private accountApiService: ApiAccountService
    ) { }

  ngOnInit() {
    this.groupId = this.route.snapshot.params.id;
  }

  signUp(type: string) {
    if (type === 'email') {
      AppLocalStorage.add(AppConstants.keys.joinGroup, this.groupId);
      this.router.navigate(['/start/signup']);
    } else if (type === 'facebook') {
      AppLocalStorage.add(AppConstants.keys.joinGroup, this.groupId);
      this.accountApiService.getFacebookCredentials();
    }
  }

  signIn() {
    AppLocalStorage.add(AppConstants.keys.joinGroup, this.groupId);
    this.router.navigate(['/start/login']);
  }

  goBack() {
    this.routerInterceptorService.goBack();
  }

}
